/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
import React from 'react';
import styled from 'styled-components/macro';
import {
  format,
  parse,
  addMonths,
  getDay,
  differenceInMinutes,
  getWeek,
  addDays,
  differenceInDays,
  differenceInWeeks,
  getYear,
  isWithinInterval,
  areIntervalsOverlapping,
} from 'date-fns';
import { Link } from 'react-router-dom';
import {
  statusList,
  projectStatus,
  timesheetStatus,
  Unit,
  Team,
  TeamUnit,
  projectType as ProjectType,
} from '../constants/data';
import {
  ContractInputCell,
  ContractButtonCell,
  ContractFooterCell,
  ClientButtonCell,
  ClientInputCell,
  DropdownCell,
  TextInputCell,
  HourInputCell,
  TimesheetButtonCell,
  ExpandButtonCell,
  HashtagCell,
  RealDaysCell,
  SpentInputCell,
  LinkToTeamCell,
  LinkToProjectsCell,
  ProgressCell,
  ColorCodeSpentdayCell,
  DeactivateUserCell,
  UserDataChangeCell,
  LongTermLeaveDateEditCell,
  LongTermLeaveNotesCell,
  LongTermLeaveStartDateCell,
  LongTermLeaveEndDateCell,
  ContractDaysCell,
  SubHeaderCell,
  CheckboxCell,
} from '../components';
import { theme } from '../style/theme';
import { convertDurationToHourAndMin, convertMinToDays } from './datetime';
import chevron from '../assets/images/chevron.svg';
import chevronRed from '../assets/images/chevron-red.svg';

const StyledLink = styled(Link)`
  color: red;
  &:after {
    content: url('${chevronRed}');
    display: inline-block;
    margin-left: 16px;
    height: 18px;
    vertical-align: middle;
  }
  &:hover {
    color: red;
  }
`;

const ViewDetails = styled(Link)`
  font-family: 'futura-pt', sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: ${theme.colors.asiance_navy};
  &:after {
    content: url('${chevron}');
    display: inline-block;
    margin-left: 16px;
    height: 18px;
    vertical-align: middle;
  }
  &:hover {
    color: inherit;
  }
`;

export const getContractDaysColumns = (months, startDate) => {
  const monthColumns = months.map((month) => ({
    Header: `${month.slice(0, 4)}.${month.slice(4)}`,
    accessor: month,
    width: '100px',
    Cell: ContractInputCell,
    Footer: (data) => {
      const total = data.rows.reduce(
        (sum, row) => (row.values[month] ? sum + row.values[month] : sum + 0),
        0,
      );
      return Number(total.toFixed(3));
    },
    extraStyles: {
      backgroundColor: '#F9F9F9',
    },
  }));

  let startYear = null;
  if (startDate) {
    startYear = getYear(new Date(startDate));
  }

  let columns = [];

  if (startYear > 2020 || !startYear) {
    columns = [
      {
        Header: 'Name',
        accessor: 'name',
        Footer: 'Total days',
        sticky: 'left',
        width: '200px',
        left: '0',
        footerStyles: {
          fontWeight: '700',
        },
        extraStyles: {
          backgroundColor: 'white',
        },
      },
      {
        Header: 'Role',
        accessor: 'role',
        Footer: '',
        sticky: 'left',
        width: '150px',
        left: '200px',
        headerStyles: {
          borderRight: 'none',
        },
        footerStyles: {
          borderRight: 'none',
        },
        extraStyles: {
          borderRight: `1px solid ${theme.colors.bluish_light_gray}`,
          backgroundColor: 'white',
        },
      },
      ...monthColumns,
      {
        Header: 'Total Days',
        accessor: 'total',
        Footer: ContractFooterCell,
        sticky: 'right',
        width: '120px',
        right: '60px',
        extraStyles: {
          borderLeft: `1px solid ${theme.colors.bluish_light_gray}`,
          backgroundColor: 'white',
        },
        headerStyles: {
          borderLeft: 'none',
        },
        footerStyles: {
          borderLeft: 'none',
          fontWeight: '700',
        },
      },
      {
        Header: '',
        accessor: 'remove',
        Footer: '',
        sticky: 'right',
        width: '60px',
        right: '0',
        Cell: ContractButtonCell,
        extraStyles: {
          backgroundColor: 'white',
        },
      },
    ];
  } else {
    columns = [
      {
        Header: 'Name',
        accessor: 'name',
        Footer: 'Total days',
        sticky: 'left',
        width: '200px',
        left: '0',
        footerStyles: {
          fontWeight: '700',
        },
        extraStyles: {
          backgroundColor: 'white',
        },
      },
      {
        Header: 'Role',
        accessor: 'role',
        Footer: '',
        sticky: 'left',
        width: '150px',
        left: '200px',
        headerStyles: {
          borderRight: 'none',
        },
        footerStyles: {
          borderRight: 'none',
        },
        extraStyles: {
          borderRight: `1px solid ${theme.colors.bluish_light_gray}`,
          backgroundColor: 'white',
        },
      },
      {
        Header: 'Spent days',
        accessor: 'spentDays',
        width: '100px',
        Cell: SpentInputCell,
        Footer: ({ rows }) => {
          const total = rows.reduce(
            (sum, row) => sum + row.values.spentDays,
            0,
          );
          return Number(total.toFixed(3));
        },
        headerStyles: {
          borderRight: 'none',
        },
        footerStyles: {
          borderRight: 'none',
        },
        extraStyles: {
          borderRight: `1px solid ${theme.colors.bluish_light_gray}`,
          backgroundColor: 'white',
        },
      },
      ...monthColumns,
      {
        Header: 'Total Days',
        accessor: 'total',
        Footer: ContractFooterCell,
        sticky: 'right',
        width: '120px',
        right: '60px',
        extraStyles: {
          borderLeft: `1px solid ${theme.colors.bluish_light_gray}`,
          backgroundColor: 'white',
        },
        headerStyles: {
          borderLeft: 'none',
        },
        footerStyles: {
          borderLeft: 'none',
          fontWeight: '700',
        },
      },
      {
        Header: '',
        accessor: 'remove',
        Footer: '',
        sticky: 'right',
        width: '60px',
        right: '0',
        Cell: ContractButtonCell,
        extraStyles: {
          backgroundColor: 'white',
        },
      },
    ];
  }

  return columns;
};

export const mapMemberStateToTableData = (
  members,
  totalContractDays,
  editable,
  status,
) => {
  if (!members.length) {
    return [];
  }

  const result = members.map((member) => {
    const rowData = {
      memberId: member.id,
      name: member.name,
      role: member.role,
      spentDays: member.spentDays ? member.spentDays.days : 0,
      totalContractDays,
      editable,
      status,
    };

    member.contractDays.forEach((contract) => {
      rowData[contract.month] = contract.days;
    });

    const totalDays = member.contractDays.reduce(
      (total, curr) => total + curr.days,
      0,
    );
    rowData.total = parseFloat(totalDays.toFixed(3));

    return rowData;
  });

  return result;
};

export const projectListColumns = [
  {
    Header: 'Hashtag',
    accessor: 'hashtag',
    width: '20%',
  },
  {
    Header: 'Name',
    accessor: 'name',
    width: '22%',
  },
  {
    Header: 'Division',
    accessor: 'division',
    width: '10%',
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: '8%',
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    width: '11%',
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    width: '11%',
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: '8%',
    Cell: ({ value }) => {
      return (
        <span className={statusList[value].toLowerCase()}>
          {statusList[value]}
        </span>
      );
    },
  },
  {
    Header: '',
    accessor: 'details',
    width: '8%',
    extraStyles: {
      justifyContent: 'flex-end',
    },
    Cell: ({ row: { original } }) => {
      const { id, status, isReviewer } = original;
      return isReviewer && status === projectStatus.PENDING ? (
        <StyledLink to={`/projects/${id}`}>Review</StyledLink>
      ) : (
        <ViewDetails to={`/projects/${id}`}>View Details</ViewDetails>
      );
    },
  },
];

export const mapProjectListToTableData = (data, userId) => {
  if (!data) return [];

  return data.map((project) => {
    const {
      id,
      hashtag,
      name,
      unit,
      projectType,
      startDate,
      endDate,
      status,
      Reviewer,
    } = project;
    return {
      id,
      hashtag,
      name,
      startDate,
      endDate,
      division: unit.length === 1 ? unit : unit.join(', '),
      type: projectType,
      status,
      isReviewer: Reviewer ? Reviewer.id === userId : false,
    };
  });
};

export const getClientListColumns = (info) => [
  {
    Header: 'Name',
    accessor: 'name',
    width: '100%',
    Cell: ClientInputCell,
    info,
  },
  {
    Header: '',
    accessor: 'remove',
    Cell: ClientButtonCell,
    info,
  },
];

export const getTimeEntryColumns = (days, extra, userLeaveDates) => {
  const { status, currentMonthYear, hashtagOptions } = extra;
  const monthYear =
    currentMonthYear &&
    `${currentMonthYear.toString().slice(0, 4)}-${currentMonthYear
      .toString()
      .slice(4)}`;
  const nextMonthYear = Number(
    parse(addMonths(new Date(`${monthYear}-01`), 1), 'yyyyMM', new Date()),
  );

  const leaveDateExists = {};

  if (userLeaveDates.length > 0) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < userLeaveDates.length; i++) {
      const { startDate, endDate } = userLeaveDates[i];

      const selectedWeekIsDuringLeave = areIntervalsOverlapping(
        {
          start: new Date(days[0]),
          end: new Date(days[days.length - 1]),
        },
        {
          start: new Date(startDate),
          end: new Date(endDate),
        },
        { inclusive: true },
      );

      if (selectedWeekIsDuringLeave) {
        leaveDateExists.leaveStart = startDate;
        leaveDateExists.leaveEnd = endDate;
        break;
      }
    }
  }

  const daysColumns = days.map((day) => {
    const date = new Date(day);
    const year = format(date, 'yyyy');
    const month = format(date, 'MM');
    const columnMonthYear = Number(year + month);
    const isWeekend = getDay(date) === 0 || getDay(date) === 6;

    let isDuringLeaveDate = false;
    if (leaveDateExists.leaveStart && leaveDateExists.leaveEnd) {
      isDuringLeaveDate = isWithinInterval(date, {
        start: new Date(leaveDateExists.leaveStart),
        end: new Date(leaveDateExists.leaveEnd),
      });
    }
    const disabled =
      (status === timesheetStatus.OPEN && currentMonthYear < columnMonthYear) ||
      (status !== timesheetStatus.OPEN && nextMonthYear < columnMonthYear);
    // || isDuringLeaveDate;

    const dayColumnStyles = {
      backgroundColor: '#F9F9F9',
      maxWidth: '100px',
      minWidth: '85px',
      whiteSpace: 'pre-line',
    };

    return {
      Header: `${day.split('-').slice(1).join('.')}\n${format(date, 'ccc')}`,
      accessor: day,
      Cell: HourInputCell,
      Footer: (info) => {
        const total = info.rows.reduce(
          (sum, row) => (row.values[day] ? sum + row.values[day] : sum),
          0,
        );
        const converted = convertDurationToHourAndMin(total);
        const displayedTotal = converted || '00:00';
        return displayedTotal;
      },
      headerStyles:
        isWeekend || isDuringLeaveDate
          ? {
              fontSize: '14px',
              color: theme.colors.bluish_gray,
              textAlign: 'center',
            }
          : {
              fontSize: '14px',
              color: theme.colors.asiance_navy,
              textAlign: 'center',
            },
      extraStyles:
        disabled || isWeekend || isDuringLeaveDate
          ? {
              ...dayColumnStyles,
              color: theme.colors.bluish_gray,
            }
          : {
              ...dayColumnStyles,
            },
      disabled,
      isWeekend,
      isDuringLeaveDate,
    };
  });

  return [
    {
      Header: 'Project',
      accessor: 'hashtag',
      Cell: DropdownCell,
      Footer: 'Total',
      width: '35%',
      extraStyles: {
        minWidth: '200px',
        maxWidth: 'none',
      },
      footerStyles: {
        fontWeight: 'bold',
      },
      hashtagOptions,
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: TextInputCell,
      Footer: '',
      width: '35%',
      headerStyles: {
        borderRight: 'none',
      },
      footerStyles: {
        borderRight: 'none',
      },
      extraStyles: {
        minWidth: '150px',
        maxWidth: 'none',
        borderRight: `1px solid ${theme.colors.bluish_light_gray}`,
      },
    },
    ...daysColumns,
    {
      Header: '',
      accessor: 'total',
      Cell: TimesheetButtonCell,
      Footer: (info) => {
        const total = info.rows.reduce((sum, row) => sum + row.values.total, 0);
        const converted = convertDurationToHourAndMin(total);
        const displayedTotal = converted || '00:00';
        return displayedTotal;
      },
      extraStyles: {
        minWidth: '90px',
        maxWidth: 'none',
        borderLeft: `1px solid ${theme.colors.bluish_light_gray}`,
      },
      headerStyles: { borderLeft: 'none' },
      footerStyles: {
        fontWeight: 'bold',
        borderLeft: 'none',
      },
    },
  ];
};

export const mapGoogleCalendarToTimeEntries = (
  events,
  hashtagOptions,
  startDate,
) => {
  const thisWeek = getWeek(startDate, {
    weekStartsOn: 1,
  });
  return events
    .reduce((acc, curr) => {
      if (curr.start.dateTime && curr.end.dateTime) {
        const { start, end, summary } = curr;
        const date = start.dateTime.split('T')[0];
        const summaryHashtag = summary.split(' ')[0];
        const summaryDesc = summary.split(' ').slice(1).join(' ');
        const startDateTime = new Date(start.dateTime);
        const endDateTime = new Date(end.dateTime);
        const project = hashtagOptions.filter(
          (option) => option.label === summaryHashtag,
        )[0];
        const projectId = project ? project.value : null;
        const hashtag = project ? summaryHashtag : null;
        const description = project ? summaryDesc : summary;

        const eventWeek = getWeek(startDateTime, {
          weekStartsOn: 1,
        });
        const eventDay = getDay(startDateTime);
        const sameDayOfThisWeek = addDays(startDate, eventDay - 1);
        const formattedDate = format(sameDayOfThisWeek, 'yyyy-MM-dd');

        const calObj = {
          hashtag,
          projectId,
          description,
          date: eventWeek !== thisWeek ? formattedDate : date,
          duration: differenceInMinutes(endDateTime, startDateTime),
          hashtagError: !projectId,
        };
        acc.push(calObj);
      }
      return acc;
    }, [])
    .sort((a, b) => a.projectId - b.projectId);
};

export const mergeByDate = (timeEntries) => {
  const mergedTimeEntries = [];
  timeEntries.forEach((entry) => {
    const found = mergedTimeEntries.find((item) => {
      return entry.hashtag
        ? item.hashtag === entry.hashtag &&
            item.description === entry.description &&
            item.date === entry.date
        : item.projectId === entry.projectId &&
            item.description === entry.description &&
            item.date === entry.date;
    });
    if (found) {
      found.duration += entry.duration;
    } else {
      mergedTimeEntries.push(entry);
    }
  });
  return mergedTimeEntries;
};

export const mergeByHashtagAndDesc = (mergedEntries) => {
  const mergedState = [];
  mergedEntries.forEach((entry) => {
    const found = mergedState.find((item) => {
      return entry.hashtag
        ? item.hashtag === entry.hashtag &&
            item.description === entry.description
        : item.projectId === entry.projectId &&
            item.description === entry.description;
    });
    if (found) {
      const { id, date, duration } = entry;
      found.entries.push({
        id,
        date,
        duration,
      });
    } else {
      const {
        id,
        projectId,
        hashtag,
        description,
        date,
        duration,
        hashtagError,
      } = entry;
      const row = {
        projectId,
        hashtag,
        description,
        entries: [{ id, date, duration }],
        hashtagError,
      };
      mergedState.push(row);
    }
  });

  const indexed = mergedState.map((row, index) => {
    row.id = index;
    return row;
  });

  return indexed;
};

export const resetEntries = (timesheet) => {
  return timesheet.map((row) => {
    row.entries = [];
    return row;
  });
};

export const mapTimesheetStateToTableData = (timesheet) => {
  return timesheet.map((row) => {
    const entryObj = row.entries.reduce((obj, e) => {
      obj[e.date] = obj[e.date] ? obj[e.date] + e.duration : e.duration;
      return obj;
    }, {});

    const total = row.entries.reduce((acc, curr) => acc + curr.duration, 0);
    return {
      ...row,
      ...entryObj,
      total,
    };
  });
};

export const getEmptyEntry = () => {
  return [
    {
      id: Date.now(),
      projectId: '',
      hashtag: '',
      description: '',
      entries: [],
    },
  ];
};

export const getDailyTotal = (timesheet) => {
  const dailyTotal = [];
  timesheet.forEach((row) => {
    row.entries.forEach((entry) => {
      const found = dailyTotal.find((total) => total.date === entry.date);
      if (found) {
        found.duration += entry.duration;
      } else {
        const { date, duration } = entry;
        dailyTotal.push({ date, duration });
      }
    });
  });
  return dailyTotal;
};

export const getDailyTotalOfTheLastWeek = (timesheet, currentMonthYear) => {
  const dailyTotal = [];
  timesheet.forEach((row) => {
    row.entries.forEach((entry) => {
      const entryDate = new Date(entry.date);
      const year = format(entryDate, 'yyyy');
      const month = format(entryDate, 'MM');
      const entryMonthYear = Number(year + month);
      if (entryMonthYear === currentMonthYear) {
        const found = dailyTotal.find((total) => total.date === entry.date);
        if (found) {
          found.duration += entry.duration;
        } else {
          const { date, duration } = entry;
          dailyTotal.push({ date, duration });
        }
      }
    });
  });
  return dailyTotal;
};

export const getTeamColumns = () => [
  {
    Header: 'Name',
    accessor: 'name',
    width: '25%',
  },
  {
    Header: 'Unit/Team',
    accessor: 'team',
    extraStyles: {
      justifyContent: 'center',
    },
    width: '25%',
    Cell: ({
      row: {
        original: { unit, team, role },
      },
    }) => {
      if (role === 'UNIT_LEADER') return unit;
      return `${unit} / ${team}`;
    },
  },
  {
    Header: 'Timesheet status',
    accessor: 'timesheetStatus',
    width: '15%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ({ value }) => {
      return (
        <span className={timesheetStatus[value].toLowerCase()}>
          {timesheetStatus[value]}
        </span>
      );
    },
  },
  {
    Header: 'User Leave Dates',
    accessor: 'leaveDate',
    width: '20%',
    Cell: ({
      row: {
        original: { leaveDate, id, name },
      },
    }) => {
      return <DeactivateUserCell id={id} leaveDate={leaveDate} name={name} />;
    },
    disableSortBy: true,
  },
  {
    Header: 'Internship',
    accessor: 'InternshipPeriod',
    width: '20%',
    Cell: ({
      row: {
        original: { id, name, InternshipPeriod },
      },
    }) => {
      return (
        <UserDataChangeCell
          userId={id}
          internshipPeriod={InternshipPeriod}
          name={name}
        />
      );
    },
  },
  {
    Header: '',
    accessor: 'details',
    width: '15%',
    Cell: ({
      row: {
        original: { id },
      },
    }) => {
      return (
        <ViewDetails
          to={{ pathname: '/monthly-report', state: { userId: id } }}
        >
          View Details
        </ViewDetails>
      );
    },
    disableSortBy: true,
  },
];

export const getLongTermLeavesColumns = (info) => [
  {
    Header: 'Start Date',
    accessor: 'startDate',
    width: '25%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: LongTermLeaveStartDateCell,
    info,
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    width: '25%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: LongTermLeaveEndDateCell,
    info,
  },
  {
    Header: 'Notes',
    accessor: 'notes',
    width: '30%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: LongTermLeaveNotesCell,
    info,
  },
  {
    Header: 'Edit',
    accessor: 'id',
    width: '20%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: LongTermLeaveDateEditCell,
    info,
  },
];

export const getTeamColumnsNoEdit = () => [
  {
    Header: 'Name',
    accessor: 'name',
    width: '50%',
  },
  {
    Header: 'Unit',
    accessor: 'unit',
    extraStyles: {
      justifyContent: 'center',
    },
    width: '10%',
  },
  {
    Header: 'Team',
    accessor: 'team',
    extraStyles: {
      justifyContent: 'center',
    },
    width: '10%',
  },
  {
    Header: 'Timesheet status',
    accessor: 'timesheetStatus',
    width: '15%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ({ value }) => {
      return (
        <span className={timesheetStatus[value].toLowerCase()}>
          {timesheetStatus[value]}
        </span>
      );
    },
  },
  {
    Header: '',
    accessor: 'details',
    width: '15%',
    Cell: ({
      row: {
        original: { id },
      },
    }) => {
      return (
        <ViewDetails
          to={{ pathname: '/monthly-report', state: { userId: id } }}
        >
          View Details
        </ViewDetails>
      );
    },
    disableSortBy: true,
  },
];

export const getTimesheetMonthReportColumns = () => [
  {
    Header: 'Project Hashtag',
    accessor: 'hashtag',
    width: '40%',
    Cell: HashtagCell,
    colSpan: 1,
  },
  {
    Header: 'Type',
    accessor: 'projectType',
    width: '15%',
    extraStyles: {
      justifyContent: 'center',
    },
  },
  {
    Header: 'Contract Days',
    accessor: 'contractDays',
    width: '23%',
    Cell: ({ row, cell: { value } }) => {
      return <ContractDaysCell value={value} row={row} />;
    },
  },
  {
    Header: 'Real Days',
    accessor: 'realDays',
    width: '15%',
    Cell: RealDaysCell,
  },
  {
    Header: '',
    id: 'expander',
    width: '7%',
    Cell: ExpandButtonCell,
  },
];

export const mapMonthlyReportToTableData = (timesheet) => {
  const tableData =
    (timesheet.AssignedProjects &&
      timesheet.AssignedProjects.reduce((acc, curr) => {
        const totalProjecthours = curr.TimeEntries.map((timeEntry) =>
          convertMinToDays(timeEntry.duration),
        ).reduce((a, b) => a + b, 0);

        const mergedEntries = curr.TimeEntries.map((timeEntry) => ({
          hashtag: `- ${timeEntry.description || 'miscellaneous tasks'}`,
          contractDays: timeEntry.date,
          realDays: timeEntry.duration,
        })).sort((a, b) => {
          return new Date(b.contractDays) - new Date(a.contractDays);
        });

        const projectDetails = mergedEntries.map((entry) => ({
          ...entry,
          realDays: convertDurationToHourAndMin(entry.realDays),
        }));

        projectDetails.unshift({
          hashtag: 'Detail',
          contractDays: 'Date',
          realDays: 'Hour',
        });

        acc.push({
          hashtag: curr.hashtag,
          projectType: curr.projectType,
          description: '',
          contractDays:
            curr.ContractDays && curr.ContractDays.length > 0
              ? curr.ContractDays[0].days
              : 0,
          realDays: totalProjecthours,
          subRows: projectDetails,
        });

        return acc;
      }, []).filter((prj) => prj.contractDays || prj.realDays)) ||
    [];

  const totals = tableData.reduce(
    (acc, curr) => {
      let {
        clientContractDays,
        internalContractDays,
        clientRealDays,
        internalRealDays,
      } = acc;
      const { projectType, contractDays, realDays } = curr;
      if (projectType === ProjectType.CLIENT) {
        clientContractDays += contractDays;
        clientRealDays += realDays;
      }
      if (projectType === ProjectType.INTERNAL) {
        internalContractDays += contractDays;
        internalRealDays += realDays;
      }
      // realDays += curr.realDays;
      return {
        clientContractDays,
        internalContractDays,
        clientRealDays,
        internalRealDays,
      };
    },
    {
      clientContractDays: 0,
      internalContractDays: 0,
      contractDays: 0,
      clientRealDays: 0,
      internalRealDays: 0,
      realDays: 0,
    },
  );

  tableData.push({
    hashtag: 'Total',
    contractDays: `Client: ${Number(
      totals.clientContractDays.toFixed(2),
    )} | Internal: ${Number(totals.internalContractDays.toFixed(2))}`,
    realDays: `Client: ${Number(
      totals.clientRealDays.toFixed(2),
    )} | Internal: ${Number(totals.internalRealDays.toFixed(2))}`,
  });

  const monthlyReportData = tableData.map((row) => {
    if (typeof row.contractDays === 'number') {
      row.contractDays = Number(row.contractDays.toFixed(2)) || '';
      row.realDays = Number(row.realDays.toFixed(2)) || '';
    }

    return row;
  });

  return monthlyReportData;
};

export const getDashboardTableColumns = () => [
  {
    Header: 'Team',
    accessor: 'team',
    width: '25%',
    Cell: ({ value }) => {
      if (value in Unit) {
        return `${value} (Unit Leader)`;
      }
      return value;
    },
    className: 'bold-text',
  },
  {
    Header: 'Timesheet Submitted',
    id: 'submitted',
    width: '25%',
    Cell: LinkToTeamCell,
    extraStyles: {
      justifyContent: 'center',
    },
  },
  {
    Header: 'Timesheet Not Submitted',
    accessor: 'notSubmitted',
    width: '25%',
    Cell: LinkToTeamCell,
  },
  {
    Header: 'Ongoing Project',
    accessor: 'ongoing',
    width: '25%',
    Cell: LinkToProjectsCell,
    className: 'gray-bg',
  },
];

export const mapTeamMembersToTableData = (users, projects) => {
  const teams = new Set(users.map((user) => user.team || user.unit));

  return [...teams]
    .map((key) => {
      const team =
        (TeamUnit[key] === Unit.PROD
          ? `${Unit.PROD} ${Team[key]}`
          : Team[key]) || Unit[key];

      const teamMembers = users.filter(
        (user) =>
          (user.team && user.team === key) || (!user.team && user.unit === key),
      );
      const teamCount = teamMembers.length;
      const submittedMembers = users.filter(
        (user) =>
          ((user.team && user.team === key) ||
            (!user.team && user.unit === key)) &&
          user.timesheetStatus === timesheetStatus.SUBMITTED,
      );
      const submitted = submittedMembers.length;
      const notSubmitted = teamCount - submitted;

      const ongoingPrjs = projects.filter(
        (project) =>
          !project.unit.includes(Unit.ASIANCE) &&
          ((key in Team &&
            project.Team.some((member) => member.team === key)) ||
            (key in Unit &&
              project.Team.some((member) => member.unit === key))),
      );
      const ongoing = ongoingPrjs.length;

      const filter = {
        filterStatus: [
          {
            label: statusList[projectStatus.ACTIVE],
            value: projectStatus.ACTIVE,
          },
        ],
        filterUnit: key in Unit ? [key] : null,
        filterTeam: key in Team ? [key] : null,
        asiancersOptions: [
          { label: key in Unit ? key : Team[key], value: key },
        ],
      };

      return {
        team,
        submitted,
        notSubmitted,
        ongoing,
        filter,
      };
    })
    .sort((row1, row2) => (row1.team > row2.team ? 1 : -1));
};

export const reportProjectListColumns = [
  {
    Header: 'Client',
    accessor: 'client',
    width: '14%',
    extraStyles: {
      fontWeight: 'bold',
    },
  },
  {
    Header: 'Hashtag',
    accessor: 'hashtag',
    width: '18%',
    extraStyles: {
      fontWeight: 'bold',
    },
  },
  {
    Header: 'Real Progress',
    accessor: 'realProgress',
    width: '18%',
    Cell: ({ row: { original }, cell: { value } }) => {
      return <ProgressCell row={original} value={value} />;
    },
  },
  {
    Header: 'Theoretical Progress',
    accessor: 'theoreticalProgress',
    width: '10%',
  },
  {
    Header: 'Spent Days / Contract Days',
    accessor: 'spentDays_contractDays',
    width: '12%',
    Cell: ({ row: { original }, cell: { value } }) => {
      return <ColorCodeSpentdayCell row={original} value={value} />;
    },
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    width: '10%',
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    width: '10%',
  },
  {
    Header: '',
    accessor: 'details',
    width: '8%',
    Cell: ({ row: { original } }) => {
      const { id } = original;
      return (
        <ViewDetails to={`/report/projects/${id}`}>View Details</ViewDetails>
      );
    },
  },
];

export const mapReportProjectListToTableData = (data) => {
  if (!data) return [];

  data.sort((a, b) => (a.hashtag < b.hashtag ? 1 : -1));
  data.sort((a, b) => (a.projectType > b.projectType ? 1 : -1));

  return data.map((project) => {
    const {
      id,
      hashtag,
      name,
      unit,
      projectType,
      status,
      Client,
      SpentDays,
      TimeEntries,
      dayForcast,
      startDate,
      endDate,
    } = project;
    let totalTimeEntryDays = 0;
    if (TimeEntries.length !== 0) {
      totalTimeEntryDays =
        TimeEntries.reduce((acc, curr) => acc + curr.duration, 0) / 60 / 8;
    }
    let totalSpentDays = 0;
    if (SpentDays.length !== 0) {
      totalSpentDays = SpentDays.reduce((acc, curr) => acc + curr.days, 0);
    }
    let totalRealDaysSpent = totalSpentDays + totalTimeEntryDays;
    const projDurationInDays = differenceInDays(
      new Date(project.endDate),
      new Date(project.startDate),
    );
    const projDurationInWeeks = projDurationInDays / 7;
    const dayPerWeekInContract = dayForcast / projDurationInWeeks;
    const fullWeeksSinceProjectStart = -differenceInWeeks(
      new Date(project.startDate),
      new Date(),
    );

    const theoreticalDaysSpent =
      fullWeeksSinceProjectStart * dayPerWeekInContract;
    let theoreticalProgress = (theoreticalDaysSpent / dayForcast) * 100;
    if (theoreticalProgress > 100) {
      theoreticalProgress = 100;
    }
    if (fullWeeksSinceProjectStart < 0) {
      theoreticalProgress = 0;
    }
    let realProgress = (totalRealDaysSpent / dayForcast) * 100;

    if (!dayForcast) {
      totalTimeEntryDays = 'N/A';
      totalSpentDays = 'N/A';
      realProgress = 'N/A';
      theoreticalProgress = 'N/A';
      totalRealDaysSpent = 'N/A';
    }

    return {
      id,
      hashtag,
      name,
      client: projectType === 'INTERNAL' ? 'ASIANCE' : Client.name,
      division: unit.length === 1 ? unit : unit.join(', '),
      type: projectType,
      status,
      realProgress: dayForcast ? `${realProgress.toFixed(2)}%` : 'N/A',
      spentDays_contractDays: dayForcast
        ? `${totalRealDaysSpent.toFixed(2)} / ${dayForcast}`
        : 'N/A',
      theoreticalProgress: dayForcast
        ? `${theoreticalProgress.toFixed(2)}%`
        : 'N/A',
      startDate: startDate ? `${startDate.replaceAll('-', '/')}` : 'N/A',
      endDate: endDate ? `${endDate.replaceAll('-', '/')}` : 'N/A',
    };
  });
};

export const getReportProjectProgressDetailColumns = (handleCheck) => [
  {
    id: 'selection',
    Cell: ({ row }) => <CheckboxCell row={row} handleCheck={handleCheck} />,
    width: '5%',
    extraStyles: {
      display: 'flex',
      alignItems: 'baseline',
    },
  },
  {
    Header: 'Name',
    accessor: 'name',
    width: '25%',
    Cell: HashtagCell,
    colSpan: 2,
  },
  {
    Header: 'Role',
    accessor: 'projectRole',
    width: '14%',
    extraStyles: {
      justifyContent: 'center',
    },
  },
  {
    Header: 'Real Progress',
    accessor: 'realProgress',
    width: '22%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ({ row: { original }, cell: { value } }) => {
      return <ProgressCell row={original} value={value} />;
    },
  },
  {
    Header: 'Theoretical Progress',
    accessor: 'theoreticalProgress',
    width: '12%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ({ row: { depth, index }, cell: { value } }) => {
      return <SubHeaderCell depth={depth} index={index} value={value} />;
    },
  },
  {
    Header: 'Spent Days / Contract Days',
    accessor: 'spentDays',
    width: '15%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ({ row: { original, depth, index }, cell: { value } }) => {
      return (
        <ColorCodeSpentdayCell
          row={original}
          depth={depth}
          index={index}
          value={value}
        />
      );
    },
  },
  {
    Header: '',
    id: 'expander',
    width: '7%',
    Cell: ExpandButtonCell,
  },
];

export const mapRepProjProgressDetailsToTableData = (project) => {
  if (!project) return [];
  const { fullWeeksSinceProjectStart, projDurationInWeeks } = project;
  const memberProgress = project.Team.map((member) => {
    const {
      name,
      projectRole,
      totalSpentDays,
      memberContractDays,
      TimeEntries,
      SpentDays,
    } = member;
    const dayPerWeekInContract = memberContractDays / projDurationInWeeks;
    let theoreticalProgress =
      ((fullWeeksSinceProjectStart * dayPerWeekInContract) /
        memberContractDays) *
      100;
    if (fullWeeksSinceProjectStart < 0) {
      theoreticalProgress = 0;
    }
    if (theoreticalProgress > 100) {
      theoreticalProgress = 100;
    }
    let totalPreviouslySpentDays = 0;
    if (SpentDays.length !== 0) {
      totalPreviouslySpentDays = SpentDays.reduce(
        (acc, curr) => acc + curr.days,
        0,
      );
    }
    const totalSpentAndTimeEntryDays =
      totalSpentDays + totalPreviouslySpentDays;

    const memberTimeEntries = TimeEntries.map((timeEntry) => ({
      name: `- ${timeEntry.description || 'miscellaneous tasks'}`,
      theoreticalProgress: timeEntry.date,
      spentDays: timeEntry.duration,
    })).sort((a, b) => {
      return new Date(b.theoreticalProgress) - new Date(a.theoreticalProgress);
    });
    // .reduce((mergedData, next) => {
    //   const found = mergedData.find((item) => item.name === next.name);
    //   if (found) {
    //     found.spentDays += next.spentDays;
    //   } else {
    //     mergedData.push(next);
    //   }
    //   return mergedData;
    // }, []);

    const progressDetails = memberTimeEntries.map((entry) => ({
      ...entry,
      spentDays: convertDurationToHourAndMin(entry.spentDays),
    }));
    progressDetails.unshift({
      name: 'Detail',
      theoreticalProgress: 'Date',
      spentDays: 'Hour',
    });

    if (totalPreviouslySpentDays > 0) {
      const spentSubrow = {
        name: 'Time spent outside of timesheet',
        spentDays: convertDurationToHourAndMin(
          totalPreviouslySpentDays * 8 * 60,
        ),
      };
      progressDetails.push(spentSubrow);
    }
    let progressTotal = 'N/A';
    if (memberContractDays > 0) {
      progressTotal = `${(
        (totalSpentAndTimeEntryDays / memberContractDays) *
        100
      ).toFixed(2)}%`;
    }
    if (totalSpentAndTimeEntryDays > 0 && memberContractDays === 0) {
      progressTotal = '+100%';
    }
    if (totalSpentAndTimeEntryDays === 0 && memberContractDays === 0) {
      progressTotal = '0%';
      theoreticalProgress = 0;
    }
    return {
      name,
      projectRole,
      realProgress: progressTotal,
      theoreticalProgress:
        memberContractDays === 0 ? 'N/A' : `${theoreticalProgress.toFixed(2)}%`,
      spentDays: `${totalSpentAndTimeEntryDays.toFixed(
        2,
      )} / ${memberContractDays.toFixed(2)} `,
      subRows: progressDetails,
    };
  });
  return memberProgress.sort((a, b) => (a.name < b.name ? -1 : 1));
};

import { useMutation } from '@apollo/react-hooks';
import { LOGOUT } from '../../apollo/mutations';
import { getAccessToken, setAccessToken } from '../../utils/accessToken';
import { userRoles } from '../../constants/data';

const useAuth = (history) => {
  const [logout, { client }] = useMutation(LOGOUT);

  const revokeToken = async () => {
    await logout();
    localStorage.removeItem('user');
    setAccessToken('');
    await client.clearStore();
  };

  const logoutUser = async () => {
    await revokeToken();
    history.push('/login');
  };

  const isLoggedIn = () => {
    const token = getAccessToken();
    const userData = localStorage.getItem('user');
    return !!token && !!userData;
  };

  const saveAuthUser = (user) => {
    const userData = JSON.stringify(user);
    localStorage.setItem('user', userData);
  };

  const updateAuthUser = (key, value) => {
    const userData = localStorage.getItem('user');
    const parsed = JSON.parse(userData);
    const updated = {
      ...parsed,
      [key]: value,
    };
    localStorage.setItem('user', JSON.stringify(updated));
  };

  const getAuthUser = () => {
    const userData = localStorage.getItem('user');
    const parsed = { ...JSON.parse(userData) };

    if (parsed.team === 'DEV') {
      parsed.pastUnits = ['PROD', 'UX'];
    }

    if (parsed.team === 'DESIGN') {
      parsed.pastUnits = ['PROD', 'UX'];
    }

    if (parsed.team === 'DSI') {
      parsed.pastUnits = ['CX', 'DSI'];
      parsed.pastTeams = ['CX1', 'DSI'];
    }

    if (parsed.team === 'PM') {
      parsed.pastUnits = ['CX', 'UX'];
      parsed.pastTeams = ['CX2', 'PM'];
    }

    // if (parsed.team === 'CUSTOMERSOLUTION') {
    //   parsed.pastUnits = ['CUSTOMERSOLUTION'];
    //   parsed.pastTeams = ['CUSTOMERSOLUTION'];
    // }

    return parsed;
  };

  const isAdmin = () => {
    const { role } = getAuthUser();
    return role === userRoles.ADMIN;
  };

  const isUnitLeader = () => {
    const { role } = getAuthUser();
    return role === userRoles.UNIT_LEADER;
  };

  const isTeamLeader = (projectUnit) => {
    const { role, unit } = getAuthUser();
    return (
      projectUnit &&
      projectUnit.includes(unit) &&
      role === userRoles.TEAM_LEADER
    );
  };

  const isUser = () => {
    const { role } = getAuthUser();
    return role === userRoles.USER;
  };

  return {
    revokeToken,
    logoutUser,
    isLoggedIn,
    saveAuthUser,
    updateAuthUser,
    getAuthUser,
    isAdmin,
    isUnitLeader,
    isTeamLeader,
    isUser,
  };
};

export default useAuth;

import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import FormGroup from './FormGroup';
import Label from './Label';
import Error from './Error';
import useErrorHandler from '../../hooks/errorHandler';

const RadioLabel = styled.label`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  margin-right: ${({ inline }) => (inline ? '40px;' : '0')};
`;

const Radio = styled.input.attrs({
  type: 'radio',
})`
  border-radius: 0;
  display: none;
`;

const RadioContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
`;

const Icon = styled.div`
  &:after {
    content: '';
    background: white;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 6px;
    top: 6px;
    border-radius: 50%;
  }
`;

const StyledRadio = styled.div`
  margin-right: 7px;
  margin-top: 2px;
  width: 24px;
  height: 24px;
  border: ${({ theme }) => `1px solid ${theme.colors.bluish_light_gray}`};
  float: left;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  border-radius: 50%;
  background-color: ${({ radio, theme }) =>
    radio ? theme.colors.asiance_navy : 'white'};

  ${Icon} {
    visibility: ${({ radio }) => (radio ? 'visible' : 'hidden')};
  }
`;

const RadioGroup = ({
  name,
  label,
  register,
  rules,
  list,
  error,
  inline,
  ...rest
}) => {
  const { data: project } = useSelector((state) => state.project);
  const [radio, setRadio] = useState(project.basecamp);
  const { clearErrorState } = useErrorHandler();

  return (
    <FormGroup>
      <Label>{label}</Label>
      {list.map((item) => {
        return (
          <RadioLabel htmlFor={item.label} key={item.label} inline={inline}>
            <RadioContainer>
              <Radio
                id={item.label}
                name={name}
                value={item.value}
                ref={register(rules)}
                onChange={(e) => {
                  clearErrorState();
                  setRadio(e.target.value);
                }}
                {...rest}
              />
              {item.label}
            </RadioContainer>
            <StyledRadio radio={radio === item.value} {...rest}>
              <Icon />
            </StyledRadio>
          </RadioLabel>
        );
      })}
      {error && <Error>{error.message}</Error>}
    </FormGroup>
  );
};

export default RadioGroup;

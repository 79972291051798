import React from 'react';
import styled from 'styled-components/macro';
import InlineSelect from './InlineSelect';

const SelectField = styled(InlineSelect)`
  margin-left: ${({ isFirst }) => (isFirst ? 0 : '10px')};
  @media screen and (max-width: 991.98px) {
    margin-left: 0;
  }
  & > div:not(:last-child) {
    padding: 0 0 0 3px !important;
    @media screen and (max-width: 991.98px) {
      margin: 0 0 7px 0 !important;
    }
  }
`;

const MultiSelect = ({
  width = '15%',
  name,
  control,
  options,
  placeholder,
  ...rest
}) => {
  return (
    <SelectField
      width={width}
      name={name}
      isMulti
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      control={control}
      options={options}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default MultiSelect;

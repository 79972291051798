import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { useForm, Controller } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import enGb from 'date-fns/locale/en-GB';
import { Modal } from 'react-bootstrap';
import { useMutation } from 'react-apollo';
import { GET_TEAM_MEMBERS } from '../../apollo/queries';
import {
  DELETE_USER_INTERNSHIP,
  UPDATE_USER_INTERSHIP,
} from '../../apollo/mutations';
import { Button, Error } from '../Form';
import { InlineLoader } from '../Loader';
import { StyledModal } from './styled';
import { DoubleCheckModal } from '../index';
import calendar from '../../assets/images/calendar-new.svg';
import { stringifyDate } from '../../utils/datetime';
import editIcon from '../../assets/images/bl_edit.svg';

registerLocale('en-gb', enGb);

const FlexDiv = styled.div`
  display: flex;
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0px 40px;
  text-align: center;
  font-size: 18px;
  p {
    display: flex;
  }

  span {
    margin-left: 12px;
    display: flex;
    align-items: center;
  }
`;

const CenterDiv = styled.div`
  p {
    text-align: center;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const DatePickerContainer = styled.div`
  position: relative;
  padding: 0 10px;
  border: 1px solid ${({ theme }) => `${theme.colors.bluish_light_gray}`};
  margin: 20px 60px;

  &.flex-wrap {
    margin: 0;
    width: 48%;
  }

  .react-datepicker-wrapper {
    width: 100%;
    &:after {
      content: url(${calendar});
      position: absolute;
      right: 10px;
      top: 6px;
      cursor: pointer;
    }
  }
`;

const StyledDatePicker = styled(ReactDatePicker)`
  border: none;
  min-height: 38px;
  padding: 4px;
  width: calc(100% - 50px);
  &:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => `${theme.colors.bluish_gray}`};
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => `${theme.colors.bluish_gray}`};
  }
  &::placeholder {
    color: ${({ theme }) => `${theme.colors.bluish_gray}`};
  }
  &#startDate {
    margin-left: 0;
  }
  .leaveDate {
    width: 400px;
  }
`;

const Input = styled.input`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.bluish_light_gray}`};
  min-height: 38px;
  padding: 2px 13px;
  margin-bottom: 30px;
  outline: none;
  color: ${({ theme }) => theme.colors.asiance_navy};

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.bluish_gray};
  }
  &:focus {
    &::-webkit-input-placeholder {
      opacity: 0;
    }
  }
`;

const EditButton = styled.div`
  margin-right: 10px;
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  content: url(${editIcon});
  height: 20px;
  width: 20px;
  margin-left: 10px;
  color: ${({ theme, editable }) =>
    editable ? theme.colors.asiance_navy : theme.colors.bluish_gray};
  &:hover {
    color: ${({ theme }) => theme.colors.asiance_navy};
  }
`;

const DeleteButton = styled(FontAwesomeIcon)`
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  font-size: 20px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.bluish_gray : theme.colors.asiance_navy};
`;

const UserDataChangeModal = ({
  show,
  error,
  loading,
  handleClose,
  userId,
  userName,
  internshipPeriod,
}) => {
  const [startDate, setStartDate] = useState(
    internshipPeriod ? internshipPeriod.startDate : null,
  );
  const [endDate, setEndDate] = useState(
    internshipPeriod ? internshipPeriod.endDate : null,
  );
  const [notes, setNotes] = useState(
    internshipPeriod ? internshipPeriod.notes : null,
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [showEditDates, setShowEditDates] = useState(!internshipPeriod);

  const internStartDateRef = useRef();
  const internEndDateRef = useRef();
  const internNotesRef = useRef();

  const { control } = useForm();

  const [updateUserInternship] = useMutation(UPDATE_USER_INTERSHIP, {
    refetchQueries: [
      {
        query: GET_TEAM_MEMBERS,
      },
    ],
  });

  const [deleteUserInternshipPeriod, { loading: deleteLoading }] = useMutation(
    DELETE_USER_INTERNSHIP,
    {
      refetchQueries: [
        {
          query: GET_TEAM_MEMBERS,
        },
      ],
    },
  );

  const onClickEdit = () => {
    setShowEditDates(!showEditDates);
  };

  const onClickDelete = async () => {
    try {
      await deleteUserInternshipPeriod({
        variables: { userId },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const showConfirmModal = () => {
    setShowConfirm(true);
  };

  return (
    <StyledModal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Enter internship period</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FlexDiv>
          {internshipPeriod ? (
            // <p>
            <p>
              Internship period: {internshipPeriod.startDate} ~
              {internshipPeriod.endDate}{' '}
              <span>
                <EditButton onClick={onClickEdit} />
                <DeleteButton icon={faTimesCircle} onClick={showConfirmModal} />
              </span>
            </p>
          ) : (
            // </p>
            <p>No internship data for {userName}</p>
          )}

          <DoubleCheckModal
            show={showConfirm}
            text="Are you sure you want to delete it?"
            loading={deleteLoading}
            onClick={onClickDelete}
            handleClose={() => setShowConfirm(false)}
          />
        </FlexDiv>
        <CenterDiv>
          <p>
            <sm>
              *Ideal number contract days during internship period will be 0.
            </sm>
          </p>
        </CenterDiv>
        {showEditDates && (
          <>
            <FlexContainer>
              <DatePickerContainer className="flex-wrap">
                <Controller
                  as={<StyledDatePicker ref={internStartDateRef} />}
                  control={control}
                  id="internStartDate"
                  name="internStartDate"
                  locale="en-gb"
                  dateFormat="yyyy.MM.dd"
                  placeholderText="Start Date (YYYY.MM.DD)"
                  valueName="selected"
                  defaultValue={startDate ? new Date(startDate) : null}
                  onChange={([selected]) => {
                    setStartDate(stringifyDate(selected));
                    return selected;
                  }}
                  onFocus={() => internStartDateRef.current.input.focus()}
                  autoComplete="off"
                  showYearDropdown
                  showMonthDropdown
                />
              </DatePickerContainer>
              <DatePickerContainer className="flex-wrap">
                <Controller
                  as={<StyledDatePicker ref={internEndDateRef} />}
                  control={control}
                  id="internEndDate"
                  name="internEndDate"
                  locale="en-gb"
                  dateFormat="yyyy.MM.dd"
                  placeholderText="End Date (YYYY.MM.DD)"
                  valueName="selected"
                  defaultValue={endDate ? new Date(endDate) : null}
                  onChange={([selected]) => {
                    setEndDate(stringifyDate(selected));
                    return selected;
                  }}
                  onFocus={() => internEndDateRef.current.input.focus()}
                  autoComplete="off"
                  showYearDropdown
                  showMonthDropdown
                />
              </DatePickerContainer>
            </FlexContainer>
            <Input
              type="text"
              defaultValue={notes || null}
              placeholder="Enter notes on internship"
              ref={internNotesRef}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </>
        )}
      </Modal.Body>
      {error && <Error>{error.message}</Error>}
      <Modal.Footer>
        <Button
          type="redbtn"
          data-cy="statusUpdate"
          inline
          loading={loading}
          onClick={() => {
            const variables = {
              variables: {
                userInternshipInput: {
                  userId,
                  startDate,
                  endDate,
                  notes,
                },
              },
            };
            console.log(variables);
            if (startDate && endDate) {
              updateUserInternship(variables);
            }
            if (!startDate && !endDate) {
              deleteUserInternshipPeriod({ variables: { userId } });
            }
          }}
        >
          {loading ? <InlineLoader loading={loading} color="red" /> : 'Save'}
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

export default withRouter(UserDataChangeModal);

import React from 'react';
import { useTable, useExpanded } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { Container } from './styled';
import { DataLoader, CenteredDiv } from '../index';

const Table = ({
  columns,
  data,
  loading,
  sticky,
  footer,
  blank,
  className,
  pointer,
  rowProps = () => ({}),
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    footerGroups,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded,
    useSticky,
  );
  const Scroll = (e) => {
    const table = document.getElementsByClassName('table')[0];
    table.scrollTo({
      top: 0,
      left: table.scrollLeft + e.deltaY,
    });
  };

  return (
    <Container className={className} pointer={pointer}>
      <div
        {...getTableProps()}
        className={sticky ? 'table sticky' : 'table'}
        onWheel={sticky ? Scroll : ''}
      >
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => {
                const { style } = column.getHeaderProps();
                return (
                  <div
                    {...column.getHeaderProps()}
                    style={{
                      ...style,
                      ...column.extraStyles,
                      ...column.headerStyles,
                      width: column.width,
                      left: column.left,
                    }}
                    className="th"
                  >
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          ))}
        </div>

        {loading ? (
          <CenteredDiv height="200px">
            <DataLoader loading={loading} />
          </CenteredDiv>
        ) : (
          (data.length && (
            <div {...getTableBodyProps()} className="body">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <div
                    {...row.getRowProps(rowProps(row))}
                    className={`tr${
                      row.depth > 0 ? ` subRow sub-${row.index}` : ''
                    }${row.original.isTotal ? ` total` : ''}${
                      row.original.isSubTotal ? ` sub-total` : ''
                    }${row.original.isTeamTotal ? ` team-total` : ''}${
                      row.original.isUnitTotal ? ` unit-total` : ''
                    }${row.original.allUnitsTotal ? ` all-units-total` : ''}${
                      row.original.isUnitLeader ? ` unit-leader-row` : ''
                    }
                      `}
                  >
                    {row.cells.map((cell, index) => {
                      const { style } = cell.getCellProps();
                      const { cells } = row;
                      const rowHasColSpan = cells
                        .map((cellCheck, i) =>
                          cellCheck.column.colSpan
                            ? [i, cellCheck.column.colSpan]
                            : -1,
                        )
                        .filter((j) => j !== -1);
                      if (row.depth > 0 && rowHasColSpan.length > 0) {
                        const colSpanPosition = rowHasColSpan[0][0];
                        const colSpanToCheck = rowHasColSpan[0][1];
                        const spanDiff = colSpanPosition + colSpanToCheck;

                        if (colSpanPosition === index) {
                          let spanWidth = parseInt(
                            cell.column.width.replace('%', ''),
                            10,
                          );
                          let i = 1;
                          while (i <= colSpanToCheck) {
                            spanWidth += parseInt(
                              row.cells[index + i].column.width.replace(
                                '%',
                                '',
                              ),
                              10,
                            );
                            i += 1;
                          }

                          return (
                            <div
                              {...cell.getCellProps()}
                              style={{
                                ...style,
                                ...cell.column.extraStyles,
                                width: `${spanWidth}%`,
                                left: cell.column.left,
                              }}
                              className={`td${
                                cell.column.className
                                  ? ` ${cell.column.className}`
                                  : ''
                              }`}
                            >
                              {cell.render('Cell')}
                            </div>
                          );
                        }
                        if (index > colSpanPosition && index <= spanDiff) {
                          return (
                            <div
                              {...cell.getCellProps()}
                              style={{
                                ...style,
                                ...cell.column.extraStyles,
                                width: '0%',
                                left: cell.column.left,
                              }}
                              className={`td${
                                cell.column.className
                                  ? ` ${cell.column.className}`
                                  : ''
                              }`}
                            >
                              {cell.render('Cell')}
                            </div>
                          );
                        }

                        return (
                          <div
                            {...cell.getCellProps()}
                            style={{
                              ...style,
                              ...cell.column.extraStyles,
                              width: cell.column.width,
                              left: cell.column.left,
                            }}
                            className={`td${
                              cell.column.className
                                ? ` ${cell.column.className}`
                                : ''
                            }`}
                          >
                            {cell.render('Cell')}
                          </div>
                        );
                      }
                      return (
                        <div
                          {...cell.getCellProps()}
                          style={{
                            ...style,
                            ...cell.column.extraStyles,
                            width: cell.column.width,
                            left: cell.column.left,
                          }}
                          className={`td${
                            cell.column.className
                              ? ` ${cell.column.className}`
                              : ''
                          }`}
                        >
                          {cell.render('Cell')}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )) ||
          (!data.length && !blank && (
            <CenteredDiv height="200px">
              <div>No results found...</div>
            </CenteredDiv>
          ))
        )}

        {footer && (
          <div className="footer">
            {footerGroups.map((footerGroup) => (
              <div {...footerGroup.getHeaderGroupProps()} className="tr">
                {footerGroup.headers.map((column) => {
                  const { style } = column.getHeaderProps();
                  return (
                    <div
                      {...column.getHeaderProps()}
                      style={{
                        ...style,
                        ...column.extraStyles,
                        ...column.footerStyles,
                        width: column.width,
                        left: column.left,
                      }}
                      className="td"
                    >
                      {column.render('Footer')}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};

export default Table;

import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 60px;
  background-color: white;

  p {
    font-size: 1.125em;
  }

  .bold-text {
    font-weight: bold;
    margin: 0;
  }

  .graph-instructions {
    margin-bottom: 12px;
  }
`;

export const ToolbarWrap = styled.div`
  display: flex;
  margin-bottom: 30px;
  align-items: center;

  .button-wrap {
    margin-left: 30px;
  }
`;

export const DateSelectWrap = styled.div`
  display: flex;
  gap: 24px;

  .date-input-wrap {
    display: flex;
    align-items: center;

    .datePickWrap {
      padding: 10px 12px;
      border: 1px black solid;
      margin: 0;
      margin-left: 12px;
    }
  }
`;

export const GraphWrap = styled.div`
  display: flex;
  justify-content: center;
`;

import React from 'react';

const ReportPeopleForcastCell = (props) => {
  // console.log("props", props)
  const { value, row } = props;
  const fixedVal = parseFloat(value).toFixed(2);
  if (row.original.isUnitLeader) {
    if (value >= 5) return <div className="font-green">{fixedVal}</div>;
    if (value < 5) return <div>{fixedVal}</div>;
  }
  if (row.original.isTeamLeader) {
    if (value >= 10) return <div className="font-green">{fixedVal}</div>;
    if (value >= 5 && value < 10) return <div>{fixedVal}</div>;
    if (value < 5) return <div className="font-red">{fixedVal}</div>;
  }
  if (row.original.name === undefined) {
    return <div>{fixedVal}</div>;
  }
  if (value <= 10) return <div className="font-red">{fixedVal}</div>;
  if (value >= 15) return <div className="font-green">{fixedVal}</div>;
  return <div>{fixedVal}</div>;
};

export default ReportPeopleForcastCell;

import config from '../.config';

const gapiConfig = {
  apiKey: config.GOOGLE_API_KEY,
  discoveryDocs: config.GOOGLE_DISCOVERY_DOCS,
};

const fullGapiConfig = {
  ...gapiConfig,
  clientId: config.GOOGLE_CLIENT_ID,
  scope: config.GOOGLE_SCOPE,
};

export const fetchRefreshToken = async () => {
  const response = await fetch(`${config.API_URL}/refresh_token`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.status} ${response.statusText}`);
  }

  const { accessToken } = await response.json();
  return accessToken;
};

export const initGapiClient = async () => {
  const { gapi } = window;

  await new Promise((resolve, reject) => {
    gapi.load('client:auth2', async () => {
      const authInstance = await gapi.auth2.getAuthInstance();
      /**
       * gapi.client.init() promise with clientId/scope fails silently when already authenticated
       * */
      const clientConfig =
        !authInstance && !gapi.client.calendar ? fullGapiConfig : gapiConfig;
      const initGapi = () => {
        return gapi.client
          .init(clientConfig)
          .then(() => {
            resolve({ loaded: true, error: null });
          })
          .catch((err) => {
            const error = err.error.message;
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ loaded: false, error });
          });
      };
      setTimeout(initGapi, 10);
    });
  });
};

export const fetchCalendarList = async () => {
  const { gapi } = window;

  if (!gapi.client.calendar) {
    await gapi.client.init(fullGapiConfig);
  }

  const response = await gapi.client.calendar.calendarList.list({
    maxResults: 250,
  });

  if (response.status !== 200) {
    throw new Error(
      `Error: ${response.result.error.code} ${response.result.error.message}`,
    );
  }

  const calendarList = response.result.items;
  return calendarList;
};

export const fetchCalendarEvents = async (calendarId, startDate, endDate) => {
  const { gapi } = window;

  const response = await gapi.client.calendar.events.list({
    calendarId,
    timeMin: startDate.toISOString(),
    timeMax: endDate.toISOString(),
    singleEvents: true,
  });

  if (response.status !== 200) {
    throw new Error(
      `Error: ${response.result.error.code} ${response.result.error.message}`,
    );
  }

  const events = response.result.items;
  // console.log('fetchCalendarEvents', events)
  return events;
};

import {
  SET_PROJECT_FILTER,
  CLEAR_PROJECT_FILTER,
  CHANGE_PAGE,
  UPDATE_PAGINATION_OPTION,
} from '../constants/actionTypes';

export const setProjectFilter = (filter) => ({
  type: SET_PROJECT_FILTER,
  filter,
});

export const clearProjectFilter = () => ({
  type: CLEAR_PROJECT_FILTER,
});

export const changePage = (page) => ({
  type: CHANGE_PAGE,
  page,
});

export const updatePaginationOption = (option) => ({
  type: UPDATE_PAGINATION_OPTION,
  option,
});

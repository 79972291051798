import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import layout from './layout';
import project from './project';
import projectList from './projectList';
import timesheet from './timesheet';
import error from './error';
import leaveDatesToUpdate from './userLeaveDates';

export const rootPersistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['layout', 'projectList'],
};

const rootReducer = combineReducers({
  layout,
  project,
  projectList,
  timesheet,
  error,
  leaveDatesToUpdate,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedReducer;

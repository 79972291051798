import React from 'react';
import {
  InternalDaysCell,
  InfoHeader,
  ReportPeopleForcastCell,
  ReportPeopleSpentCell,
} from '../../components';
import { projectType as ProjectType, userRoles } from '../../constants/data';

export const getReportDetailsPeopleColumns = () => [
  {
    Header: 'Unit/Team',
    accessor: 'division',
    width: '28%',
  },
  {
    Header: 'Name',
    accessor: 'name',
    width: '24%',
  },
  {
    Header: InfoHeader,
    title: <>Client Contract Days Forecast</>,
    info: (
      <>
        <p>*For Team members only:</p>
        <p>
          <span className="font-red">Red Text</span>: if less than or equal to
          10 days
        </p>
        <p>
          <span className="font-green">Green Text</span>: Greater than or equal
          to 15 days
        </p>
        <p>
          <span>Black Text</span>: Between 10 to 15 days
        </p>
      </>
    ),
    accessor: 'contractDays',
    width: '18%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ReportPeopleForcastCell,
  },
  {
    Header: InfoHeader,
    title: <>Client Contract Days Spent</>,
    info: (
      <>
        <span className="font-red">Red Text</span>: if days spent &gt; forecast
        contract days
      </>
    ),
    accessor: 'realDays',
    width: '15%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ReportPeopleSpentCell,
  },
  {
    Header: (
      <div style={{ flexDirection: 'column' }}>
        Internal Projects Days Spent
      </div>
    ),
    accessor: 'internalDaysSpent',
    width: '15%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ({ cell: { value } }) => {
      return <InternalDaysCell value={value} />;
    },
  },
  {
    Header: (
      <div style={{ flexDirection: 'column' }}>
        Total Days Spent
        <div style={{ fontSize: '0.8rem' }}>(Internal + Client)</div>
      </div>
    ),
    accessor: 'totalDays',
    width: '15%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ({ cell: { value } }) => {
      return <>{parseFloat(value).toFixed(2)}</>;
    },
  },
];

export const mapReportDetailsPeopleToTableData = (
  data,
  user,
  division,
  selectedDate,
) => {
  const { teams, role } = user;

  const updatedData = data && [...data];

  if (
    updatedData &&
    new Date(selectedDate) >= new Date('2022-07-01') &&
    teams.includes('PM' && 'CX2')
  ) {
    const indexArr = [];

    for (let i = 0; i < updatedData.length; i += 1) {
      if (updatedData[i].team === 'CX2') {
        indexArr.push(i);
      }
    }

    if (indexArr.length > 0) {
      indexArr.forEach((index) => {
        updatedData[index].unit = 'UX';
        updatedData[index].team = 'PM';
      });
    }
  }

  if (
    updatedData &&
    new Date(selectedDate) >= new Date('2022-07-01') &&
    teams.includes('DSI' && 'CX1')
  ) {
    const indexArr = [];

    for (let i = 0; i < updatedData.length; i += 1) {
      if (updatedData[i].team === 'CX1') {
        indexArr.push(i);
      }
    }

    if (indexArr.length > 0) {
      indexArr.forEach((index) => {
        updatedData[index].unit = 'DSI';
        updatedData[index].team = 'DSI';
      });
    }
  }

  const rowsByTeam = teams.reduce((result, team) => {
    const teamMembers =
      data &&
      data
        .filter((member) => member.team === team)
        .map((member) => {
          const { id, name, AssignedProjects } = member;

          const contractDayTotal = AssignedProjects.flatMap((project) =>
            project.projectType === ProjectType.CLIENT
              ? project.ContractDays
              : [],
          )
            .filter((contract) => contract.userId === id)
            .reduce((sum, curr) => sum + curr.days, 0);

          const timesheetDurationClient = AssignedProjects.flatMap((project) =>
            project.projectType === ProjectType.CLIENT
              ? project.TimeEntries
              : [],
          )
            .filter((project) => project.userId === id)
            .reduce((sum, curr) => sum + curr.duration, 0);

          const timesheetDurationInternal = AssignedProjects.flatMap(
            (project) =>
              project.projectType === ProjectType.INTERNAL
                ? project.TimeEntries
                : [],
          )
            .filter((project) => project.userId === id)
            .reduce((sum, curr) => sum + curr.duration, 0);

          const timesheetDurationTotal = AssignedProjects.map(
            (project) => project.TimeEntries,
          )
            .reduce((arr, curr) => [...arr, ...curr], [])
            .filter((contract) => contract.userId === id)
            .reduce((sum, curr) => sum + curr.duration, 0);

          let isTeamLeader = false;
          if (member.role === 'TEAM_LEADER') {
            isTeamLeader = true;
          }

          return {
            name,
            contractDays: parseFloat(contractDayTotal.toFixed(2)),
            realDays: parseFloat((timesheetDurationClient / 60 / 8).toFixed(2)),
            totalDuration: parseFloat(timesheetDurationTotal.toFixed(2)),
            internalDaysSpent: parseFloat(timesheetDurationInternal / 60 / 8),
            totalDays: parseFloat((timesheetDurationTotal / 60 / 8).toFixed(2)),
            isTeamLeader,
          };
        });

    if (role === userRoles.USER) {
      return teamMembers ? [...result, ...teamMembers] : result;
    }

    const contractDays =
      teamMembers &&
      parseFloat(
        teamMembers
          .reduce((sum, curr) => sum + curr.contractDays, 0)
          .toFixed(2),
      );
    const realDays =
      teamMembers &&
      parseFloat(
        teamMembers.reduce((sum, curr) => sum + curr.realDays, 0).toFixed(2),
      );
    const totalDays =
      teamMembers &&
      parseFloat(
        (
          teamMembers.reduce((sum, curr) => sum + curr.totalDuration, 0) /
          60 /
          8
        ).toFixed(2),
      );

    const internalDaysSpent =
      teamMembers &&
      parseFloat(
        teamMembers
          .reduce((sum, curr) => sum + curr.internalDaysSpent, 0)
          .toFixed(2),
      );

    const teamTotal = {
      division: role === userRoles.TEAM_LEADER ? `Total` : team,
      contractDays,
      realDays,
      totalDays,
      internalDaysSpent,
      isSubTotal: role !== userRoles.TEAM_LEADER && true,
      isTotal: role === userRoles.TEAM_LEADER && true,
    };

    const rows =
      teamMembers && teamMembers.length > 0 ? [teamTotal, ...teamMembers] : [];

    return [...result, ...rows];
  }, []);

  const unitLeaderRow =
    data &&
    data
      .filter((member) => {
        return (
          member.role === 'UNIT_LEADER' &&
          (member.unit === division ||
            ((member.unit === 'MKTU2' || member.unit === 'MKTU1') &&
              division === 'MKT'))
        );
      })
      .map((member) => {
        const { id, name, AssignedProjects, unit: memberUnit } = member;

        const contractDayTotal = AssignedProjects.filter(
          (project) => project.projectType === ProjectType.CLIENT,
        )
          .map((project) => project.ContractDays)
          .reduce((arr, curr) => [...arr, ...curr], [])
          .filter((contract) => contract.userId === id)
          .reduce((sum, curr) => sum + curr.days, 0);

        const timesheetDurationClient = AssignedProjects.filter(
          (project) => project.projectType === ProjectType.CLIENT,
        )
          .map((project) => project.TimeEntries)
          .reduce((arr, curr) => [...arr, ...curr], [])
          .filter((contract) => contract.userId === id)
          .reduce((sum, curr) => sum + curr.duration, 0);

        const timesheetDurationInternal = AssignedProjects.flatMap((project) =>
          project.projectType === ProjectType.INTERNAL
            ? project.TimeEntries
            : [],
        )
          .filter((project) => project.userId === id)
          .reduce((sum, curr) => sum + curr.duration, 0);

        const timesheetDurationTotal = AssignedProjects.map(
          (project) => project.TimeEntries,
        )
          .reduce((arr, curr) => [...arr, ...curr], [])
          .filter((contract) => contract.userId === id)
          .reduce((sum, curr) => sum + curr.duration, 0);

        return {
          division: `${memberUnit} UNIT LEADER`,
          name,
          contractDays: parseFloat(contractDayTotal.toFixed(2)),
          realDays: parseFloat((timesheetDurationClient / 60 / 8).toFixed(2)),
          totalDuration: parseFloat(timesheetDurationTotal.toFixed(2)),
          internalDaysSpent: parseFloat(timesheetDurationInternal / 60 / 8),
          totalDays: parseFloat((timesheetDurationTotal / 60 / 8).toFixed(2)),
          isUnitLeader: true,
          unit: member.unit,
        };
      });

  if (role === userRoles.ADMIN || role === userRoles.UNIT_LEADER) {
    if (unitLeaderRow && unitLeaderRow.length > 0) {
      unitLeaderRow.forEach((row) => rowsByTeam.unshift(row));
      // rowsByTeam.unshift(unitLeaderRow[0]);
    }

    const total = rowsByTeam
      .filter((row) => row.isSubTotal || row.isUnitLeader)
      .reduce(
        (obj, curr) => {
          obj.contractDays += curr.contractDays;
          obj.internalDaysSpent += curr.internalDaysSpent;
          obj.realDays += curr.realDays;
          obj.totalDays += curr.totalDays;
          return obj;
        },
        {
          division: 'Total',
          internalDaysSpent: 0,
          contractDays: 0,
          realDays: 0,
          totalDays: 0,
          isTotal: true,
        },
      );
    total.totalDays = total.totalDays.toFixed(2);
    rowsByTeam.unshift(total);
  }

  return rowsByTeam;
};

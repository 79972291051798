export const errorMessage = {
  UNEXPECTED: 'An error occurs. Please contact to admin.',
  NO_REFRESH_TOKEN:
    'An error occurs while fetching refresh token. Please contact to admin.',
  LOGIN_FAIL: 'Login failed. Please try again.',
  USER_EMPTY: 'Please assign people.',
  CONTRACT_EMPTY: 'You need to assign people to proceed further.',
  TOTAL_DAYS_NOT_MATCH: 'Please review the total days again.',
  APPROVER_REQUIRED: 'You need to select a team leader.',
  STATUS_OPTION_REQUIRED: 'You need to select an status to update the project.',
  CLIENT_NAME_REQUIRED: 'You should enter client name.',
  NOTHING_TO_COPY: 'There is nothing to copy from the last week.',
  GOOGLE_UNAUTHENTICATED:
    'Your google authentication is invalid. Please login again.',
  NO_CALENDAR_LIST: 'There is no calendar list of your google account.',
  NO_CALENDAR_EVENTS:
    'There are no events to fetch from your google timesheet calendar for specified period.',
  PROJECTS_MISSING: 'You need to select project for each row.',
  DESCRIPTION_MISSING: 'You need to enter description for required field.',
  TIME_ENTRY_MISSING:
    'There are some rows missing time entry. You can delete a row if you want to proceed further without entering time for these projects.',
  TIME_ENTRIES_EMPTY: 'You need to fill out time entries.',
  MINIMUM_WORKING_HOURS:
    'You cannot proceed further if the daily working hours are less than 6 hours',
  SAVE_FIRST: 'You need to save first to proceed further.',
};

export const errorLocation = {
  LOGIN: 'LOGIN',
  CREATE_PROJECT: 'CREATE_PROJECT',
  EDIT_PROJECT: 'EDIT_PROJECT',
  CONTRACT_USER: 'CONTRACT_USER',
  CONTRACT_DAYS: 'CONTRACT_DAYS',
  CLIENT_FORM: 'CLIENT_FORM',
  CLIENT_DELETE: 'CLIENT_DELETE',
  CLIENT_UPDATE: 'CLIENT_UPDATE',
  STATUS_MODAL: 'STATUS_MODAL',
  SUBMIT_MODAL: 'SUBMIT_MODAL',
  SUBMIT_PROJECT: 'SUBMIT_PROJECT',
  DELETE_MODAL: 'DELETE_MODAL',
  NOTIFY_MODAL: 'NOTIFY_MODAL',
  GOOGLE_LOAD: 'GOOGLE_LOAD',
  TIMESHEET_LOAD: 'TIMESHEET_LOAD',
  TIMESHEET_SAVE: 'TIMESHEET_SAVE',
  TIMESHEET_DELETE: 'TIMESHEET_DELETE',
};

export const errorField = {
  PROJECT: 'PROJECT',
  DESCRIPTION: 'DESCRIPTION',
  DURATION: 'DURATION',
};

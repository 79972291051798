import {
  SAVE_PROJECT,
  SAVE_PROJECT_ID,
  CHANGE_STEP,
  SAVE_MEMBERS,
  SAVE_MEMBER,
  DELETE_MEMBER,
  CLEAR_MEMBERS_TO_DELETE,
  SAVE_SPENT_DAYS,
  ASSIGN_DAYS,
  UPDATE_DATERANGE,
  RESET_PROJECT,
} from '../constants/actionTypes';

export const saveProject = (data) => ({
  type: SAVE_PROJECT,
  data,
});

export const saveProjectId = (id) => ({
  type: SAVE_PROJECT_ID,
  id,
});

export const changeStep = (step) => ({
  type: CHANGE_STEP,
  step,
});

export const saveMembers = (members) => ({
  type: SAVE_MEMBERS,
  members,
});

export const clearMembersToDelete = () => ({
  type: CLEAR_MEMBERS_TO_DELETE,
});

export const saveMember = (member, role, months) => ({
  type: SAVE_MEMBER,
  member,
  role,
  months,
});

export const saveSpentDays = (memberId, days) => ({
  type: SAVE_SPENT_DAYS,
  memberId,
  days,
});

export const assignDays = (memberId, month, days) => ({
  type: ASSIGN_DAYS,
  memberId,
  month,
  days,
});

export const deleteMember = (memberId) => ({
  type: DELETE_MEMBER,
  memberId,
});

export const updateDateRange = (months) => ({
  type: UPDATE_DATERANGE,
  months,
});

export const resetProject = () => ({
  type: RESET_PROJECT,
});

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { GET_REPORT_PROJECTS, GET_FORM_DATA } from '../../apollo/queries';
import {
  DatePicker,
  ReactTableSorted,
  PageLoader,
  ErrorPage,
  ReportProjectToolbar,
} from '../../components';
import { projectStatus, statusList } from '../../constants/data';
import {
  setProjectFilter,
  clearProjectFilter,
} from '../../actions/projectList';
import { initialState } from '../../reducers/projectList';
import {
  reportProjectListColumns,
  mapReportProjectListToTableData,
} from '../../utils/tableData';
import {
  formatFormData,
  getReportProjectFilterInput,
  computeProjectFilterAsiancersOptions,
} from '../../utils/formData';
import { errorMessage } from '../../constants/error';
import { Container } from './styled';

const ReportProjects = () => {
  const dispatch = useDispatch();
  const { filter, page } = useSelector((state) => state.projectList);

  const [selectedYear, setSelectedYear] = useState(new Date());
  const [
    getProjects,
    { loading: prjLoading, data: prjData, error: prjError },
  ] = useLazyQuery(GET_REPORT_PROJECTS, {
    variables: {
      filter: getReportProjectFilterInput(filter, page),
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading: formLoading, data: formData, error: formError } = useQuery(
    GET_FORM_DATA,
  );

  const { register, control, handleSubmit, reset } = useForm({
    defaultValues: filter,
  });

  useEffect(() => {
    return () => {
      dispatch(clearProjectFilter());
    };
  }, []);

  useEffect(() => {
    getProjects();
    dispatch(
      setProjectFilter({
        filterStatus: [
          {
            label: statusList[projectStatus.ACTIVE],
            value: projectStatus.ACTIVE,
          },
        ],
        filterDate: selectedYear,
      }),
    );
  }, [selectedYear]);

  useEffect(() => {
    reset(filter);
  }, [filter]);

  if (formLoading) {
    return <PageLoader />;
  }
  if (prjError || formError) {
    return <ErrorPage text={errorMessage.UNEXPECTED} />;
  }

  const formattedFormData = formatFormData(formData.getFormData);
  const projectCount = prjData && prjData.getProjects.count;
  const projectList = mapReportProjectListToTableData(
    prjData && prjData.getProjects.projects,
  );

  const onSubmit = (filterInput) => {
    const computedFilter = computeProjectFilterAsiancersOptions(
      filterInput,
      formattedFormData,
    );
    const { filterDivision } = computedFilter;
    if (filterDivision && filterDivision.some((unit) => unit.value === 'UX')) {
      filterDivision.push({ value: 'PROD', label: 'PROD' });
    }

    if (filterDivision && filterDivision.some((unit) => unit.value === 'DSI')) {
      filterDivision.push({ value: 'CX', label: 'CX' });
    }

    computedFilter.filterDate = null;

    dispatch(setProjectFilter(computedFilter));
    getProjects();
  };

  const onClear = () => {
    reset(initialState.filter);
    dispatch(clearProjectFilter());
    getProjects();
  };

  return (
    <Container>
      <DatePicker
        selectedDate={selectedYear}
        changeDate={setSelectedYear}
        minDate={new Date('2020-01-01')}
        isYearPicker
      />
      <ReportProjectToolbar
        projectCount={projectCount}
        formData={formattedFormData}
        register={register}
        control={control}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        onClear={onClear}
      />
      <ReactTableSorted
        columns={reportProjectListColumns}
        data={projectList}
        loading={prjLoading}
      />
    </Container>
  );
};

export default ReportProjects;

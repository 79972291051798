import React from 'react';
import styled from 'styled-components/macro';
import ReactSelect from 'react-select';
import { ReactTable } from '../../components';

export const ContentWrap = styled.div`
  margin-bottom: 30px;
  background: white;
  padding: 40px 60px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonGroup = styled.div`
  display: flex;
`;

export const StyledReactTable = styled((props) => <ReactTable {...props} />)`
  color: blue;
  .table {
    .header {
      .th {
        font-weight: bold;
      }
      .th:first-child {
        padding-left: 30px !important;
      }
    }
    .body {
      .td {
        padding: 20px 30px;
      }
      .tr .td:first-child {
        padding-left: 30px;
        font-weight: bold;
      }
      .tr:last-child .td {
        font-weight: bold;
        background-color: ${({ theme }) => theme.colors.footer_gray};
        min-height: 72px;
      }
      .subRow {
        background-color: ${({ theme }) => theme.colors.row_gray};
        padding-left: 30px;
        padding-bottom: 20px;
        &.sub-0 {
          padding-top: 20px;
        }
        .td {
          border-bottom: ${({ theme }) => `1px solid ${theme.colors.row_gray}`};
          padding: 0;
        }
      }
    }
  }
`;

export const StyledSelect = styled(ReactSelect)`
  width: 25%;
  margin-bottom: 1rem;
  > div {
    background: white;
    border: 1px solid ${({ theme }) => `${theme.colors.bluish_light_gray}`};
    border-radius: 0;
    min-height: 40px;
    width: ${({ width }) => width || '100%'};
  }
`;

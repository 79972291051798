import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { theme } from '../../style/theme';

const InlineLoader = ({ loading, color }) => {
  return (
    <BeatLoader
      size={10}
      color={
        color === 'red' ? theme.colors.asiance_red : theme.colors.asiance_navy
      }
      css={{ display: 'inline-block', margin: 'auto' }}
      loading={loading}
    />
  );
};

export default InlineLoader;

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Container } from './styled';
import { MultiSelect, InlineInput, Button } from '../Form/index';

const ReportProjectToolbar = ({
  formData,
  register,
  control,
  handleSubmit,
  onSubmit,
  onClear,
}) => {
  return (
    <Container>
      <Row justify-content-between={1} style={{ alignItems: 'center' }}>
        <Col sm={12} xl={6} className="text-left">
          <MultiSelect
            width="33%"
            name="filterDivision"
            isMulti
            closeMenuOnSelect={false}
            control={control}
            options={formData.divisionList}
            placeholder="Division"
            isFirst
          />
          <MultiSelect
            width="33%"
            name="filterType"
            isMulti
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            control={control}
            options={formData.projectTypes}
            placeholder="Type"
          />
          <MultiSelect
            width="33%"
            isMulti
            name="filterStatus"
            control={control}
            options={formData.reportProjectStatusList}
            placeholder="Status"
          />
        </Col>
        <Col sm={12} xl={6} className="text-right">
          <InlineInput
            type="search"
            name="search"
            width="50%"
            register={register}
            placeholder="Search by Name/Hashtag/Client"
            standalone
          />
          <Button
            className="sub-button"
            type="submit"
            text="Search"
            inline
            onClick={handleSubmit(onSubmit)}
          />
          <Button type="button" className="icon clear" onClick={onClear} />
        </Col>
      </Row>
    </Container>
  );
};

export default ReportProjectToolbar;

import React from 'react';
import styled from 'styled-components/macro';
import useErrorHandler from '../../hooks/errorHandler';
import searchIcon from '../../assets/images/magnifier.svg';

const Input = styled.input`
  width: ${({ width }) => width || '100%'};
  border: ${({ theme }) => `1px solid ${theme.colors.bluish_light_gray}`};
  min-height: 38px;
  height: 52px;
  padding: 2px 13px;
  margin-right: ${({ standalone }) => (standalone ? 0 : '10px')};
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'initial')};
  color: ${({ theme }) => theme.colors.asiance_navy};
  &.search {
    height: 40px;
    background: url(${searchIcon}) no-repeat right 10px center;
    padding-right: 20px;
    &:focus {
      background: none;
    }
    @media screen and (max-width: 991.98px) {
      margin: 0 0 7px 0 !important;
    }
  }
  &[type='number'] {
    text-align: center;
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.bluish_gray};
  }
  &:focus {
    &::-webkit-input-placeholder {
      opacity: 0;
    }
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: ${({ disabled }) => disabled && 'none'};
    -webkit-appearance: ${({ disabled }) => disabled && 'none'};
  }
  &[type='number'] {
    -moz-appearance: ${({ disabled }) => disabled && 'textfield'};
  }
  @media screen and (max-width: 991.98px) {
    width: 100%;
  }
`;

const InlineInput = ({ type = 'text', register, ...rest }) => {
  const { clearErrorState } = useErrorHandler();

  return (
    <Input
      type={type}
      className={type === 'search' && 'search'}
      ref={register}
      onFocus={() => {
        clearErrorState();
      }}
      {...rest}
    />
  );
};

export default React.memo(InlineInput);

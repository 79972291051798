import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import FormGroup from './FormGroup';
import Label from './Label';
import Error from './Error';
import useErrorHandler from '../../hooks/errorHandler';

const CheckboxLabel = styled.label`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  margin-right: 40px;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
`;

const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  border-radius: 0;
  opacity: 0;
  margin-left: -10px;
`;

const Icon = styled.div`
  &:before,
  &:after {
    content: '';
    background: white;
    // background: ${({ theme }) => theme.colors.asiance_navy};
    width: 2px;
    position: absolute;
    left: 8px;
    bottom: 7px;
  }
  &:before {
    height: 7px;
    transform-origin: bottom right;
    transform: rotate(-45deg);
  }
  &:after {
    height: 12px;
    transform-origin: bottom left;
    transform: rotate(45deg);
  }
`;

const StyledCheckbox = styled.div`
  margin-right: 7px;
  margin-top: 2px;
  width: 24px;
  height: 24px;
  border: ${({ theme }) => `1px solid ${theme.colors.bluish_light_gray}`};
  float: left;
  position: relative;
  background: ${({ checked }) => (checked ? '#2A3642' : 'white')};

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxGroup = ({
  name,
  label,
  inline,
  list,
  register,
  rules,
  error,
  defaultChecked,
  getValues,
  type,
  setType,
  ...rest
}) => {
  const { data: project, step } = useSelector((state) => state.project);
  const [checked, setChecked] = useState(defaultChecked || []);
  const inputRef = useRef();
  const { clearErrorState } = useErrorHandler();

  // on project type change, state checked becomes empty array = unchecks boxes
  useEffect(() => {
    setChecked([]);
  }, [type]);

  useEffect(() => {
    setChecked(defaultChecked || []);
  }, [project, step]);

  return (
    <FormGroup {...rest}>
      <Label ref={inputRef}>{label}</Label>
      {list.map((item) => {
        return (
          <CheckboxLabel htmlFor={item.label} key={item.label} inline={inline}>
            <CheckboxContainer>
              <Checkbox
                id={item.label}
                name={name}
                value={item.value}
                ref={register(rules)}
                onFocus={() => (error ? inputRef.current.focus() : null)}
                onChange={(e) => {
                  clearErrorState();

                  let values = getValues(name);
                  if (!Array.isArray(values)) {
                    values = [values];
                  }

                  const checkedValues = defaultChecked
                    ? values
                    : values.map((value) => Number(value));

                  if (e.target.checked) {
                    setChecked(checkedValues);
                  } else {
                    setChecked(
                      checkedValues.filter((value) => value !== e.target.value),
                    );
                  }
                }}
                {...rest}
              />
              {item.label}
            </CheckboxContainer>
            <StyledCheckbox checked={checked.includes(item.value)}>
              <Icon />
            </StyledCheckbox>
          </CheckboxLabel>
        );
      })}
      {error && <Error>{error.message}</Error>}
    </FormGroup>
  );
};

export default CheckboxGroup;

import React from 'react';

const ReportRecapSpentCell = ({ value, row }) => {
  if (value > row.original.contractDays) {
    return (
      <div className="font-red">{parseFloat(value).toFixed(2) || '0.00'}</div>
    );
  }
  return <div>{parseFloat(value).toFixed(2) || '0.00'}</div>;
};

export default ReportRecapSpentCell;

import React, { useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import FormGroup from './FormGroup';
import Label from './Label';
import Description from './Description';
import Error from './Error';

const Input = styled.input`
  width: ${({ width }) => width || '100%'};
  background-color: white;
  border: 1px solid ${({ theme }) => `${theme.colors.bluish_light_gray}`};
  min-height: 40px;
  padding: 0 20px;
  font-size: 1rem;
  margin-right: 10px;
  &:focus {
    outline: none;
    &::-webkit-input-placeholder {
      opacity: 0;
    }
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.bluish_gray};
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.bluish_gray};
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.bluish_gray};
  }
  &.small {
    width: 50px;
    text-align: center;
    padding: 0;
  }
`;

const Flexbox = styled.div`
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .append {
    margin-left: 10px;
  }
`;

const InputGroup = ({
  width,
  name,
  label,
  details,
  register,
  rules,
  error,
  children,
  ...rest
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (error && error.isManual) {
      inputRef.current.focus();
    }
  }, [error]);

  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      <Description>{details}</Description>
      <Flexbox>
        <Input
          width={width}
          id={name}
          name={name}
          ref={(e) => {
            register(e, rules);
            inputRef.current = e;
          }}
          onFocus={() => inputRef.current.focus()}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              inputRef.current.blur();
            }
          }}
          {...rest}
        />
        {children}
        {error && <Error>{error.message}</Error>}
      </Flexbox>
    </FormGroup>
  );
};

export default InputGroup;

export const projectType = {
  INTERNAL: 'INTERNAL',
  CLIENT: 'CLIENT',
};

export const userRoles = {
  ADMIN: 'ADMIN',
  UNIT_LEADER: 'UNIT_LEADER',
  TEAM_LEADER: 'TEAM_LEADER',
  USER: 'USER',
};

export const editableProjectRoles = ['SUPERVISOR', 'AM', 'PM'];

export const statusList = [
  'Draft',
  'Pending',
  'Active',
  'Closed',
  'Discontinued',
];

export const projectStatus = {
  DRAFT: 0,
  PENDING: 1,
  ACTIVE: 2,
  CLOSED: 3,
  DISCONTINUED: 4,
};

export const reportStatusList = ['Active', 'Closed', 'Discontinued'];

export const reportProjectStatus = {
  ACTIVE: 2,
  CLOSED: 3,
  DISCONTINUED: 4,
};

export const requiredDescProjects = [
  { id: 1, hashtag: '#HR' },
  { id: 2, hashtag: '#INTERNAL' },
  { id: 3, hashtag: '#TRAINING' },
  { id: 4, hashtag: '#NEW-LEADS' },
  { id: 5, hashtag: '#GROWTH' },
];

export const noContractDaysProjects = [
  ...requiredDescProjects,
  { id: 6, hashtag: '#DAYOFF' },
];

export const timesheetStatus = {
  OPEN: 'OPEN',
  SUBMITTED: 'SUBMITTED',
  Submitted: 'Submitted',
  NotSubmitted: 'Not Submitted',
};

export const Unit = {
  MKT: 'MKT',
  MKTU1: 'MKT UNIT 1',
  MKTU2: 'MKT UNIT 2',
  CX: 'CX',
  PROD: 'PROD',
  DATATECH: 'DATATECH',
  JPN: 'JPN',
  ASIANCE: 'ASIANCE',
  PEOPLE: 'PEOPLE',
};

export const UnitNew = {
  MKT: 'MKT',
  MKTU1: 'MKT UNIT 1',
  MKTU2: 'MKT UNIT 2',
  UX: 'UX',
  DSI: 'DSI',
  DATATECH: 'DATATECH',
  JPN: 'JPN',
  ASIANCE: 'ASIANCE',
  PEOPLE: 'PEOPLE',
  CUSTOMERSOLUTION: 'CUSTOMERSOLUTION',
};

// past and new units all included
export const UnitAll = {
  MKT: 'MKT',
  MKTU1: 'MKT UNIT 1',
  MKTU2: 'MKT UNIT 2',
  CX: 'CX',
  PROD: 'PROD',
  UX: 'UX',
  DSI: 'DSI',
  DATATECH: 'DATATECH',
  JPN: 'JPN',
  ASIANCE: 'ASIANCE',
  PEOPLE: 'PEOPLE',
  CUSTOMERSOLUTION: 'CUSTOMERSOLUTION',
};

export const Team = {
  DEV: 'Development',
  DESIGN: 'Design',
  PM: 'PM',
  DSI: 'DSI',
  CX1: 'CX1',
  CX2: 'CX2',
  MKT1: 'MKT1',
  MKT2: 'MKT2',
  MKT3: 'MKT3',
  MKT4: 'MKT4',
  DATATECH: 'Data Tech',
  JPN: 'Japan',
  CUSTOMERSOLUTION: 'Customer Solution',
};

export const TeamNew = {
  DEV: 'Development',
  DESIGN: 'Design',
  PM: 'PM',
  DSI: 'DSI',
  MKT1: 'MKT1',
  MKT2: 'MKT2',
  MKT3: 'MKT3',
  MKT4: 'MKT4',
  DATATECH: 'DATATECH',
  JPN: 'Japan',
  PEOPLE: 'PEOPLE',
  CUSTOMERSOLUTION: 'Customer Solution',
};

export const TeamUnit = {
  DEV: 'UX',
  DESIGN: 'UX',
  PM: 'UX',
  DSI: 'DSI',
  MKT1: 'MKT UNIT 1',
  MKT2: 'MKT UNIT 1',
  MKT3: 'MKT UNIT 2',
  MKT4: 'MKT UNIT 2',
  DATATECH: 'DATATECH',
  JPN: 'JPN',
  PEOPLE: 'PEOPLE',
  CUSTOMERSOLUTION: 'Customer Solution',
};

// past and new teams all included
export const TeamAll = {
  DEV: 'Development',
  DESIGN: 'Design',
  PM: 'PM',
  DSI: 'DSI',
  CX1: 'CX1',
  CX2: 'CX2',
  MKT1: 'MKT1',
  MKT2: 'MKT2',
  MKT3: 'MKT3',
  MKT4: 'MKT4',
  DATATECH: 'DATATECH',
  JPN: 'Japan',
  PEOPLE: 'PEOPLE',
  CUSTOMERSOLUTION: 'Customer Solution',
};

export const TeamUnitAll = {
  DEV: 'UX',
  DESIGN: 'UX',
  PM: 'UX',
  DSI: 'DSI',
  CX1: 'CX',
  CX2: 'CX',
  MKT1: 'MKT UNIT 1',
  MKT2: 'MKT UNIT 1',
  MKT3: 'MKT UNIT 2',
  MKT4: 'MKT UNIT 2',
  DATATECH: 'DATATECH',
  JPN: 'JPN',
  PEOPLE: 'PEOPLE',
  CUSTOMERSOLUTION: 'Customer Solution',
};

// past and new UnitTeams all included
export const UnitTeam = {
  MKT: ['MKT1', 'MKT2', 'MKT3', 'MKT4'],
  MKTU1: ['MKT1', 'MKT2'],
  MKTU2: ['MKT3', 'MKT4'],
  UX: ['DEV', 'DESIGN', 'PM', 'CX2'],
  DSI: ['DSI', 'CX1'],
  CX: ['CX1', 'CX2'],
  PROD: ['DEV', 'DESIGN'],
  DATATECH: ['DATATECH'],
  JPN: ['JPN'],
  PEOPLE: ['PEOPLE'],
  CUSTOMERSOLUTION: ['CUSTOMERSOLUTION'],
};

// old UnitTeam
export const UnitTeamAll = {
  MKT: ['MKT1', 'MKT2', 'MKT3', 'MKT4'],
  MKTU1: ['MKT1', 'MKT2'],
  MKTU2: ['MKT3', 'MKT4'],
  PROD: ['DEV', 'DESIGN'],
  CX: ['CX1', 'CX2'],
  DATATECH: ['DATATECH'],
  JPN: ['JPN'],
  PEOPLE: ['PEOPLE'],
};

// new UnitTeams
export const UnitTeamAllNew = {
  MKT: ['MKT1', 'MKT2', 'MKT3', 'MKT4'],
  MKTU1: ['MKT1', 'MKT2'],
  MKTU2: ['MKT3', 'MKT4'],
  UX: ['DEV', 'DESIGN', 'PM', 'CX2'],
  DSI: ['DSI', 'CX1'],
  DATATECH: ['DATATECH'],
  JPN: ['JPN'],
  PEOPLE: ['PEOPLE'],
  CUSTOMERSOLUTION: ['CUSTOMER SOLUTION'],
};

export const fakeUserList = [
  'timesheet@asiance.com',
  'exasiancer@asiance.com',
  'freelancer@asiance.com',
  'dev.seoul@asiance.com',
];

export const timesheetStartDate = '2021-01-04';

import React, { useMemo, useEffect, useState } from 'react';
import { differenceInDays, differenceInWeeks, format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import {
  PageLoader,
  ErrorPage,
  Subtitle,
  ReportProjectInfoText,
  ReportProjectOverview,
  Button,
  LinkButton,
  ExportCSVBtn,
} from '../../components';
import {
  getReportProjectProgressDetailColumns,
  mapRepProjProgressDetailsToTableData,
} from '../../utils/tableData';
import { GET_PROJECT } from '../../apollo/queries';
import { updateTitle } from '../../actions/layout';
import { errorMessage } from '../../constants/error';
import { Container, StyledReactTable, ButtonWrap } from './styled';
import { convertProjectProgressForExcel } from './ProjectProgressExportUtils';

const ReportProjectDetails = ({ match, history }) => {
  const dispatch = useDispatch();
  const { loading: prjLoading, data: prjData, error: prjError } = useQuery(
    GET_PROJECT,
    {
      variables: {
        id: Number(match.params.id),
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ getProject }) => {
        dispatch(updateTitle(getProject.hashtag));
      },
    },
  );

  const [project, setProject] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (prjData && prjData.getProject) {
      const projectData = prjData.getProject;
      const team = projectData.Team;
      const teamTotalSpentDaysArr = team.map((member) => {
        const timeEntryDays =
          member.TimeEntries.reduce((acc, curr) => acc + curr.duration, 0) /
          60 /
          8;
        member.totalSpentDays = timeEntryDays;
        const memberContractDays = member.ContractDays.reduce(
          (acc, curr) => acc + curr.days,
          0,
        );
        member.memberContractDays = memberContractDays;
        if (member.SpentDays[0]) {
          const spentDays = member.SpentDays[0].days;
          return timeEntryDays + spentDays;
        }
        return timeEntryDays;
      });
      const totalSpentDays = teamTotalSpentDaysArr.reduce(
        (acc, curr) => acc + curr,
      );
      projectData.totalSpentDays = totalSpentDays;
      const today = new Date();
      const projDurationInDays = differenceInDays(
        new Date(projectData.endDate),
        new Date(projectData.startDate),
      );
      const projDurationInWeeks = projDurationInDays / 7;
      const fullWeeksSinceProjectStart = -differenceInWeeks(
        new Date(projectData.startDate),
        today,
      );
      const dayPerWeekInContract = projectData.dayForcast / projDurationInWeeks;

      projectData.projDurationInWeeks = projDurationInWeeks;
      projectData.fullWeeksSinceProjectStart = fullWeeksSinceProjectStart;
      projectData.dayPerWeekInContract = dayPerWeekInContract;
      setProject(projectData);
    }
  }, [prjData]);

  const handleRowSelection = (row, isChecked) => {
    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        return [...prevSelectedRows, row];
      }
      return [
        ...prevSelectedRows.filter((selectedRow) => selectedRow.id !== row.id),
      ];
    });
  };

  const columns = useMemo(() => {
    return getReportProjectProgressDetailColumns(handleRowSelection);
  }, []);

  const tableData = useMemo(
    () => mapRepProjProgressDetailsToTableData(project),
    [project],
  );

  if (prjLoading) return <PageLoader />;
  if (prjError) return <ErrorPage text={errorMessage.UNEXPECTED} />;
  if (!prjData.getProject) return <ErrorPage text="Not Found" />;

  const rawExcelData =
    project && convertProjectProgressForExcel(project, tableData, selectedRows);

  return (
    project && (
      <>
        <Container>
          <Subtitle>Overview</Subtitle>
          <div className="wrap">
            <ReportProjectOverview defaultValues={project} />
          </div>
        </Container>

        <Container>
          <Subtitle>Project Information</Subtitle>
          <div className="wrap">
            <ReportProjectInfoText defaultValues={project} />
          </div>
        </Container>

        <Container>
          <div className="flex-wrap">
            <Subtitle>Progress Detail</Subtitle>

            <ExportCSVBtn
              fileName={`${project.hashtag}_${format(
                new Date(),
                'yyyy-MM-dd',
              )}`}
              loading={prjLoading}
              rawData={rawExcelData}
              className="export-btn-no-margin"
              text="Export Progress Report"
            />
          </div>
          <div className="wrap">
            <span className="small">
              * To export progress reports of specific project members only,
              click the checkmark next to their name(s) before exporting the
              report.
            </span>
            <br />
            <span className="small">
              * All members are included in the report by default (if no names
              are selected).
            </span>
            <StyledReactTable
              selectableRows
              onSelectionChange={handleRowSelection}
              columns={columns}
              data={tableData}
              loading={prjLoading}
            />
          </div>
        </Container>
        <ButtonWrap>
          <Button
            className="backtolistbtn"
            type="button"
            text="Go back to list"
            style={{ marginRight: '20px', display: 'block' }}
            inline
            onClick={() => {
              history.push('/report/projects');
            }}
          />
          <LinkButton
            url={`/projects/${project.id}`}
            text="more details"
            uppercase="uppercase"
          />
        </ButtonWrap>
      </>
    )
  );
};

export default ReportProjectDetails;

import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  width: auto;
  display: inline-block;
  padding: 10px 40px;
  background: transparent;
  border: ${({ theme }) => `1px solid ${theme.colors.asiance_navy}`};
  color: ${({ theme }) => theme.colors.asiance_navy};
  font-family: 'futura-pt';
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'initial')};
  transition: all 0.2s ease;
  letter-spacing: 0.5px;
  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
    color: white;
    background: ${({ theme }) => theme.colors.asiance_navy};
  }
  &.arrow-link {
    border: none;
    font-size: 1rem;
    font-weight: 400;
    .arrow {
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      margin-left: 5px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
      background: none;
      color: ${({ theme }) => theme.colors.asiance_navy};
    }
  }
`;

const LinkButton = ({ text, url, arrow, children, ...rest }) => {
  return (
    <StyledLink to={url} className={arrow ? 'arrow-link' : ''} {...rest}>
      {text || children}
      {arrow && <i className="arrow" />}
    </StyledLink>
  );
};

export default LinkButton;

import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_USER } from '../../../apollo/queries';
import { Button, UserLeaveConfirmModal } from '../../index';

const DeactivateUserCell = ({ id, leaveDate, name }) => {
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(null);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [getUserData, { data }] = useLazyQuery(GET_USER, {
    onCompleted: () => {
      setUserData(data);
      handleShow();
    },
  });

  return (
    <>
      <Button
        className="deactivateBtn"
        type="button"
        text="Set Leave Dates"
        onClick={() => {
          getUserData({
            variables: { id },
            fetchPolicy: 'network-only',
          });
          setShowModal(true);
        }}
      />
      {data && (
        <UserLeaveConfirmModal
          show={showModal}
          handleClose={() => handleClose()}
          userId={id}
          userName={name}
          initialLeaveDate={leaveDate}
          longTermLeavesArr={userData ? userData.getUser.LongTermLeaves : []}
        />
      )}
    </>
  );
};

export default DeactivateUserCell;

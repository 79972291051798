import React, { useState } from 'react';

import { Button, UserDataChangeModal } from '../../index';

const UserDataChangeCell = ({ userId, internshipPeriod, name }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <Button
        className="deactivateBtn"
        type="button"
        onClick={() => handleShow()}
      >
        {internshipPeriod
          ? `${internshipPeriod.startDate} ~ ${internshipPeriod.endDate}`
          : `Enter Internship Period`}
      </Button>

      <UserDataChangeModal
        show={showModal}
        handleClose={() => handleClose()}
        userId={userId}
        userName={name}
        internshipPeriod={internshipPeriod}
      />
    </>
  );
};

export default UserDataChangeCell;

import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { Controller } from 'react-hook-form';
import enGb from 'date-fns/locale/en-GB';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FormGroup from './FormGroup';
import Label from './Label';
import Error from './Error';
import calendar from '../../assets/images/calendar-new.svg';

registerLocale('en-gb', enGb);

const DatePickerContainer = styled.div`
  position: relative;
  width: 330px;
  border: 1px solid ${({ theme }) => `${theme.colors.bluish_light_gray}`};
  padding: 0 20px;

  .react-datepicker-wrapper {
    &:after {
      content: url(${calendar});
      position: absolute;
      right: 20px;
      top: 6px;
      cursor: pointer;
    }
  }
`;

const StyledDatePicker = styled(ReactDatePicker)`
  border: none;
  min-height: 38px;
  padding: 2px 0;
  width: calc(100% - 50px);
  &:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => `${theme.colors.bluish_gray}`};
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => `${theme.colors.bluish_gray}`};
  }
  &::placeholder {
    color: ${({ theme }) => `${theme.colors.bluish_gray}`};
  }
  &#startDate {
    margin-left: 0;
  }
`;

const Flexbox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  label {
    flex-grow: 1;
    position: relative;
  }
  .react-datepicker-wrapper {
    display: block;
  }
  > span {
    width: 40px;
    text-align: center;
  }
`;

const DateRangePicker = ({
  name,
  label,
  control,
  rules,
  error,
  children,
  ...rest
}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const startRef = useRef();
  const endRef = useRef();

  return (
    <FormGroup>
      <Label htmlFor={name}>{label}</Label>
      <Flexbox>
        <DatePickerContainer>
          <Controller
            as={<StyledDatePicker ref={startRef} />}
            control={control}
            id="startDate"
            name="startDate"
            locale="en-gb"
            dateFormat="yyyy.MM.dd"
            placeholderText="Project start date (YYYY.MM.DD)"
            valueName="selected"
            onChange={([selected]) => {
              setStartDate(selected);
              return selected;
            }}
            onFocus={() => startRef.current.input.focus()}
            rules={rules}
            maxDate={endDate}
            autoComplete="off"
            showDisabledMonthNavigation
            showYearDropdown
            showMonthDropdown
            {...rest}
          />
        </DatePickerContainer>
        <span>—</span>
        <DatePickerContainer>
          <Controller
            as={<StyledDatePicker ref={endRef} />}
            control={control}
            id="endDate"
            name="endDate"
            locale="en-gb"
            dateFormat="yyyy.MM.dd"
            placeholderText="Project end date (YYYY.MM.DD)"
            valueName="selected"
            onChange={([selected]) => {
              setEndDate(selected);
              return selected;
            }}
            onFocus={() => endRef.current.input.focus()}
            rules={rules}
            minDate={startDate}
            autoComplete="off"
            showDisabledMonthNavigation
            showYearDropdown
            showMonthDropdown
            {...rest}
          />
        </DatePickerContainer>
        {children}
        {error && <Error>{error.message}</Error>}
      </Flexbox>
    </FormGroup>
  );
};

export default DateRangePicker;

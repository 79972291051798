import React from 'react';
import { useSelector } from 'react-redux';

const ContractFooterCell = ({ rows, data }) => {
  const editable = data && data[0] ? data[0].editable : true;
  const total = parseFloat(
    rows.reduce((sum, row) => row.values.total + sum, 0).toFixed(3),
  );
  const { data: project } = useSelector((state) => state.project);
  const totalContractDays = parseFloat(
    parseFloat(
      rows.length ? rows[0].original.totalContractDays : project.contractDays,
    ).toFixed(3),
  );

  return (
    <div style={{ flexDirection: 'column', textAlign: 'center' }}>
      <div>{total}</div>
      {totalContractDays &&
      total !== 0 &&
      total !== totalContractDays &&
      editable ? (
        <div style={{ fontSize: '0.7rem', color: 'red' }}>
          Total days does not match.
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ContractFooterCell;

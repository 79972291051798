import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { Modal } from 'react-bootstrap';
import { StyledModal } from './styled';
import { SelectGroup, Button, Error } from '../Form';
import { InlineLoader } from '../Loader';
import { GET_REVIEWERS } from '../../apollo/queries';
import useAuth from '../../hooks/auth';
import { userRoles, fakeUserList } from '../../constants/data';

const SubmitModal = ({
  show,
  unit,
  control,
  error,
  loading,
  onSubmit,
  handleClose,
  noClose,
  current,
  update,
  history,
}) => {
  const { getAuthUser } = useAuth();
  const { id: userId } = getAuthUser();
  const {
    loading: reviewerLoading,
    data: reviewerData,
    error: reviewerError,
  } = useQuery(GET_REVIEWERS, {
    variables: {
      filter: {
        unit,
        role: [userRoles.ADMIN, userRoles.UNIT_LEADER, userRoles.TEAM_LEADER],
      },
    },
  });

  if (reviewerLoading) {
    return (
      <StyledModal show={show} onHide={handleClose}>
        <InlineLoader loading={reviewerLoading} />
      </StyledModal>
    );
  }

  if (reviewerError)
    return (
      <StyledModal show={show} onHide={handleClose}>
        An Error occurs. Please contact admin.
      </StyledModal>
    );

  const reviewerList = reviewerData.getUsers
    .filter((user) => !user.leaveDate)
    .filter((user) => user.id !== userId)
    .filter((user) => !fakeUserList.includes(user.email))
    .map((user) => ({
      value: user.id,
      label: `${user.name}\n<${user.email}>`,
    }))
    .filter((item) => (current ? item.value !== current.value : true));

  return (
    <StyledModal show={show} onHide={handleClose} noclose={noClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {update
            ? 'The project is waiting for approval!'
            : 'The project is saved as draft!'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectGroup
          name="approver"
          label={
            update
              ? 'Please select a team leader to validate the project.'
              : 'Please select a team leader to validate the project. *'
          }
          details="The team leader will be notified with an email."
          control={control}
          options={reviewerList}
          placeholder="Select a team leader"
          rules={{ required: 'This field is required.' }}
          marginEven
        />
        {error && <Error>{error.message}</Error>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          data-cy="requestApproval"
          inline
          loading={loading}
          onClick={onSubmit}
        >
          {loading ? (
            <InlineLoader loading={loading} color="red" />
          ) : (
            (update && 'Update') || (!update && 'Submit')
          )}
        </Button>
        {!update && (
          <Button
            type="button"
            text="Go to project List"
            inline
            onClick={() => {
              history.push('/project-list');
            }}
          />
        )}
      </Modal.Footer>
    </StyledModal>
  );
};

export default withRouter(SubmitModal);

import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { ScrolledModal } from './styled';
import { RadioGroup, Button, Error, CenteredDiv, InlineLoader } from '../index';

const CalendarListModal = ({
  show,
  calendarList,
  setGoogleCalendarId,
  loading,
  onClick,
  handleClose,
  error,
}) => {
  const { register, errors, handleSubmit, watch } = useForm();
  const selectedCalendar = watch('calendarList');
  const btnRef = useRef(null);

  const handleKeypress = (e) => {
    if (show && e.key === 'Enter') {
      handleSubmit(onClick)();
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', handleKeypress, true);
    return () => {
      document.removeEventListener('keypress', handleKeypress, true);
    };
  }, []);

  useEffect(() => {
    setGoogleCalendarId(selectedCalendar);
  }, [selectedCalendar]);

  return (
    <ScrolledModal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Google Calendar List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <RadioGroup
            name="calendarList"
            label="Please select your timesheet calendar. *"
            register={register}
            list={calendarList}
            rules={{ required: 'You need to select calendar.' }}
            error={errors.calendarList}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CenteredDiv columnDirection>
          {error && <Error centered>{error.message}</Error>}
          <Button
            type="button"
            ref={btnRef}
            inline
            loading={loading}
            onClick={error ? handleClose : handleSubmit(onClick)}
          >
            {loading ? <InlineLoader loading={loading} color="red" /> : 'OK'}
          </Button>
        </CenteredDiv>
      </Modal.Footer>
    </ScrolledModal>
  );
};

export default CalendarListModal;

import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import Header from './Header';

const Container = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.lighter_gray};
`;

const Content = styled.div`
  font-family: 'Open Sans';
  width: 100%;
  height: calc(100% - 84px);
  padding: ${({ theme }) => theme.mixins.getvw('80')};
  padding-left: ${({ sidebar, theme }) =>
    sidebar ? theme.mixins.getvw('80') : '160px'};
  @media screen and (max-width: 991.98px) {
    padding: 20px;
  }
`;

const Main = ({ title, children }) => {
  const { sidebar } = useSelector((state) => state.layout);

  return (
    <Container>
      {sidebar && <Header title={title} />}
      <Content sidebar={sidebar}>{children}</Content>
    </Container>
  );
};

export default Main;

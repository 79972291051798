/* eslint-disable no-nested-ternary */
import React from 'react';
import { format, subMonths } from 'date-fns';
import styled from 'styled-components/macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { getTeamColumns, getTeamColumnsNoEdit } from '../../utils/tableData';
import {
  ReactTableSorted,
  ErrorPage,
  Button,
  PageLoader,
} from '../../components';
import { GET_TEAM_MEMBERS, GET_LAST_CLOSING_DATE } from '../../apollo/queries';
import { APPROVE_TIMESHEET } from '../../apollo/mutations';
import { timesheetStatus } from '../../constants/data';
import useAuth from '../../hooks/auth';
import useQueryFilter from '../../hooks/queryFilter';

const StyledReactTable = styled((props) => <ReactTableSorted {...props} />)`
  .table {
    .body {
      .tr {
        .td {
          font-weight: bold;
          .submitted {
            color: ${({ theme }) => theme.colors.green};
          }
          .open {
            color: ${({ theme }) => theme.colors.asiance_red};
          }
        }
      }
    }
  }
`;

export const ContentWrap = styled.div`
  margin-bottom: 30px;
  background: white;
  padding: 40px 60px;
`;

const Team = () => {
  const { isAdmin, isUnitLeader } = useAuth();
  const { roleFilter: filter } = useQueryFilter();

  const { loading, data, error } = useQuery(GET_TEAM_MEMBERS, {
    variables: { filter },
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: loadingClosingDate,
    data: dataClosingDate,
    error: errorClosingDate,
  } = useQuery(GET_LAST_CLOSING_DATE, {
    fetchPolicy: 'cache-and-network',
  });

  const [approveTimesheet] = useMutation(APPROVE_TIMESHEET, {
    refetchQueries: [
      {
        query: GET_TEAM_MEMBERS,
      },
      {
        query: GET_LAST_CLOSING_DATE,
      },
    ],
  });

  const onSubmit = async () => {
    approveTimesheet();
  };

  if (loading || loadingClosingDate) return <PageLoader />;
  const members = data.getUsers;

  if (error || errorClosingDate) {
    return (
      <ErrorPage text="An error occurs. Please try again or contact admin." />
    );
  }

  const isTimesheetReset =
    !members.filter((member) => member.timesheetStatus !== timesheetStatus.OPEN)
      .length > 0;

  const lastClosingDate = dataClosingDate.getLastClosingDate;
  const tableData = members;

  return (
    <ContentWrap>
      {isAdmin() || isUnitLeader() ? (
        <StyledReactTable columns={getTeamColumns()} data={tableData} />
      ) : (
        <StyledReactTable columns={getTeamColumnsNoEdit()} data={tableData} />
      )}
      <p>
        Last validation date: {format(new Date(lastClosingDate), 'MM/dd/yyyy')}
      </p>
      {!isTimesheetReset ? (
        <>
          {isAdmin() && (
            <Button
              onClick={onSubmit}
              text="Validate Timesheet"
              inline
              className="teambtns"
            />
          )}
        </>
      ) : (
        <Button disabled className="teambtns">
          {format(subMonths(new Date(lastClosingDate), '1'), 'MMMM')} Timesheet
          has been completed and validated
        </Button>
      )}
    </ContentWrap>
  );
};

export default Team;

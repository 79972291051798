import React from 'react';
import styled from 'styled-components/macro';
import logo from '../../assets/images/logowhite.svg';

const Container = styled.div`
  z-index: 99;
  border-right: ${({ theme }) => `1px solid ${theme.colors.light_gray}`};
  position: relative;
  width: ${({ theme, width }) => theme.mixins.getvw(width)};
  transition: all 0.2s ease;
  overflow: hidden;
  max-width: ${(props) => props.width}px;
  min-width: ${(props) => props.minwidth}px;
  background: #2a3642;
  .logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 14px 24px;
  }
  .logo {
    width: 140px;
    margin: 0 auto;
  }
`;

const Sidebar = ({ width, minwidth, children }) => {
  return (
    <Container width={width} minwidth={minwidth}>
      <div className="logo-wrapper">
        <img src={logo} className="logo" alt="" />
      </div>
      {children}
    </Container>
  );
};

export default Sidebar;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

const ProjectInfo = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: disc;
  margin: 0 0 0 30px;
  @media screen and (max-width: 1199.98px) {
    flex-direction: column;
  }
`;

const TextGroup = styled.li`
  margin: 10px 0;
  font-size: 1.125rem;
  width: 50%;
  padding-right: 20px;
  @media screen and (max-width: 1199.98px) {
    display: block;
    width: 100%;
  }
`;

const Title = styled.span`
  font-weight: 700;
  display: inline-block;
  width: 45%;
  @media screen and (max-width: 1199.98px) {
    width: 100%;
  }
`;

const Content = styled.span`
  word-wrap: break-word;
  width: 50%;
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'none')};
`;

const InfoWrap = styled.div`
  display: flex;
  @media screen and (max-width: 1199.98px) {
    display: block;
  }
`;

const Info = ({ title, capitalize, children }) => (
  <TextGroup>
    <InfoWrap>
      <Title>{title}</Title>
      <Content capitalize={capitalize}>{children}</Content>
    </InfoWrap>
  </TextGroup>
);

const ReportProjectInfoText = ({ defaultValues }) => {
  const { unit, contractUrl, status, comments } = defaultValues;
  const [statusName, setStatusName] = useState('');

  useEffect(() => {
    if (status === 0) {
      setStatusName('Draft');
    }
    if (status === 1) {
      setStatusName('Pending');
    }
    if (status === 2) {
      setStatusName('Active');
    }
    if (status === 3) {
      setStatusName('Closed');
    }
    if (status === 4) {
      setStatusName('Discontinued');
    }
  }, []);

  return (
    <ProjectInfo>
      <Info title="Division(s)">{unit.join(', ')}</Info>
      <Info title="Status">{statusName}</Info>
      <Info title="Contract Path">{contractUrl}</Info>
      <Info title="Additional Information">{comments || 'N/A'}</Info>
    </ProjectInfo>
  );
};

export default ReportProjectInfoText;

import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .percentage {
    width: 30%;
    text-align: right;
  }
  .progressBarWrap {
    background-color: #d0d0d0;
    width: 70%;
    height: 10px;
    border-radius: 16px;
    margin-left: 10px;
    position: relative;

    .progressBar {
      position: absolute;
      left: 0;
      background-color: ${({ theme }) => theme.colors.green};
      width: ${({ realProgress }) => `${realProgress}%` || 0};
      height: 10px;
      border-radius: 16px;
    }
  }
  ${(props) =>
    props.orange &&
    css`
      .percentage {
        color: ${({ theme }) => theme.colors.orange};
      }
      .progressBarWrap {
        .progressBar {
          background-color: ${({ theme }) => theme.colors.orange};
        }
      }
    `};
  ${(props) =>
    props.red &&
    css`
      .percentage {
        color: ${({ theme }) => theme.colors.red};
      }
      .progressBarWrap {
        .progressBar {
          background-color: ${({ theme }) => theme.colors.red};
        }
      }
    `};
`;

const ProgressCell = ({ value, row }) => {
  let realProgress = parseFloat(value);
  const theoreticalProgress = parseFloat(row.theoreticalProgress);
  const [red, setRed] = useState(false);
  const [orange, setOrange] = useState(false);

  if (realProgress > 100) {
    realProgress = 100;
  }

  useEffect(() => {
    if (theoreticalProgress < 100 && realProgress > theoreticalProgress) {
      setOrange(true);
    }
    if (realProgress >= 100) {
      setRed(true);
    }
  }, []);

  if (value === 'N/A' || !value) {
    return <span>{value}</span>;
  }

  return (
    <Cell realProgress={realProgress} red={red} orange={orange}>
      <div className="percentage">{value}</div>
      <div className="progressBarWrap">
        <div className="progressBar" />
      </div>
    </Cell>
  );
};

export default ProgressCell;

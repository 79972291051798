import styled from 'styled-components/macro';

export const Container = styled.div`
  .table {
    overscroll-behavior: contain;
    width: 100%;
    border-spacing: 0;
    border-top: ${({ theme }) => `2px solid ${theme.colors.asiance_navy}`};
    border-bottom: ${({ theme }) =>
      `1px solid ${theme.colors.bluish_light_gray}`};
    color: ${({ theme }) => theme.colors.dart_gray};
    font-weight: 600;

    .header {
      min-height: 60px;
      color: ${({ theme }) => theme.colors.asiance_navy};
      text-align: center;
      .th {
        background: #ffffff !important;
        position: relative;
      }
    }
    .footer {
      .td {
        background: ${({ theme }) => theme.colors.footer_gray} !important;
        &:first-child {
          padding-left: 15px !important;
        }
      }
    }
    .header,
    .tr {
      width: 100%;
      display: flex;
      .td:first-child {
        padding-left: 15px;
      }
    }
    .body {
      .tr {
        cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};

        &.total {
          background-color: ${({ theme }) => theme.colors.footer_gray};

          .td {
            font-size: 1rem;
            font-weight: bold;
          }
        }

        &.unit-leader-row {
          height: 80px;
          background-color: ${({ theme }) => theme.colors.row_gray};
          border-top: ${({ theme }) =>
            `1px solid ${theme.colors.bluish_light_gray}`};

          .td {
            font-weight: bold;
          }
        }

        &.sub-total {
          height: 80px;
          background-color: ${({ theme }) => theme.colors.row_gray};
          border-top: ${({ theme }) =>
            `1px solid ${theme.colors.bluish_light_gray}`};

          .td {
            font-weight: bold;
          }
        }

        &.unit-total {
          background-color: #ffffff;
          .td {
            font-weight: bold;
          }
        }

        &.team-total {
          background-color: #f6f6f6;

          .td {
            font-size: 1rem;
          }
        }

        &.all-units-total {
          background-color: #ebedf0;

          .td {
            font-size: 1rem;
            font-weight: bold;
          }
        }
      }
    }
    .th,
    .td {
      padding: 0 20px;
      border-bottom: ${({ theme }) =>
        `1px solid ${theme.colors.bluish_light_gray}`};
      /* overflow: hidden; */
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child,
      &:nth-child(2) {
        justify-content: flex-start;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
      }
      &[data-sticky-td] {
        justify-content: flex-start;
      }
      &[data-sticky-last-left-td] {
        justify-content: center;
      }
      &[data-sticky-first-right-td] {
        justify-content: center;
        right: 60px;
      }
    }
    .th {
      font-size: 0.938em;
      font-family: Open Sans, sans-serif;
      font-weight: 700;

      &:first-child {
        padding-left: 15px !important;
      }

      &[title] {
        cursor: pointer;
      }
    }
    .td {
      padding: 26px 10px;
      font-size: 1rem;
      font-family: Open Sans, sans-serif;
      font-weight: 400;

      .draft {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.red};
      }
      .pending {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.yellow};
      }
      .active {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.green};
      }
      .closed {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.asiance_navy};
      }
      .discontinued {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.bluish_gray};
      }
    }

    .hcarhp {
      border-radius: 0;
      text-align: center;
      border-color: ${({ theme }) => theme.colors.bluish_light_gray};
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .bold-text {
      font-size: 1.5rem;
      font-weight: 600;
      @media screen and (max-width: 1440px) {
        font-size: 1.25rem;
      }
    }
    .green-text {
      font-size: 2rem;
      color: ${({ theme }) => theme.colors.green};
    }
    .red-text {
      font-size: 2rem;
      color: ${({ theme }) => theme.colors.red};
    }
    .gray-bg {
      font-size: 2rem;
      background-color: #f9f9f9;
    }

    &.sticky {
      overflow-x: scroll;
      .td {
        font-size: 1rem;
        padding: 20px 10px;
      }
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        .th {
          font-weight: bold;
          color: ${({ theme }) => theme.colors.asiance_navy};
        }
      }

      .body {
        position: relative;
        z-index: 0;
        width: fit-content;

        .tr {
          display: inline-flex;
          &:last-child {
            .td {
              border-bottom: ${({ theme }) =>
                `1px solid ${theme.colors.bluish_light_gray}`};
            }
          }
        }
      }
    }
  }
`;

import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { assignDays } from '../../../actions/project';
import { InlineInput } from '../../Form';
import { validateDayInput } from '../../../utils/datetime';

const ContractInputCell = ({ value, column, row: { original } }) => {
  const { memberId: assignedUserId, editable } = original;
  const month = column.id;
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [displayedVal, setDisplayedVal] = useState(value);

  useEffect(() => {
    setDisplayedVal(value);
  }, [value]);

  return (
    <InlineInput
      type="number"
      min={0}
      step="any"
      register={inputRef}
      value={displayedVal || ''}
      onChange={(e) => {
        const { value: inputVal } = e.target;
        if (!inputVal || inputVal === '0') {
          setDisplayedVal(inputVal);
        }
        const days = parseFloat(parseFloat(inputVal).toFixed(3)) || 0;
        dispatch(assignDays(assignedUserId, month, days));
      }}
      onBlur={(e) => {
        const { value: inputVal } = e.target;
        const days = parseFloat(
          parseFloat(validateDayInput(inputVal)).toFixed(3),
        );
        dispatch(assignDays(assignedUserId, month, days));
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          inputRef.current.blur();
        }
      }}
      disabled={!editable}
      standalone
    />
  );
};

export default ContractInputCell;

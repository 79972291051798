import {
  Login,
  Dashboard,
  CreateProject,
  ProjectSubmitted,
  ProjectList,
  ProjectDetails,
  AdminPanel,
  ClientList,
  NotFound,
  Timesheet,
  Team,
  MonthlyReport,
  Report,
  ReportProjects,
  ReportRecap,
  ReportDetailsPeople,
  ReportProjectDetails,
  // ReportDateRange,
} from '../pages';
import { userRoles } from '../constants/data';

const routes = [
  {
    name: 'Login',
    title: 'Login',
    path: '/login',
    exact: true,
    component: Login,
    private: false,
    hidden: true,
  },
  {
    name: 'Dashboard',
    title: 'Dashboard',
    path: '/',
    exact: true,
    component: Dashboard,
    private: true,
    hidden: false,
  },
  {
    name: 'Timesheet',
    title: 'Timesheet',
    path: '/timesheet',
    exact: true,
    component: Timesheet,
    private: true,
    hidden: false,
  },
  {
    name: 'Monthly Report',
    title: 'Monthly Report',
    path: '/monthly-report',
    exact: true,
    component: MonthlyReport,
    private: true,
    restricted: false,
    hidden: false,
  },
  {
    name: 'Create Project',
    title: 'Create Project',
    path: '/create-project',
    exact: true,
    component: CreateProject,
    private: true,
    hidden: false,
    subRoute: [
      {
        name: 'Project Submitted',
        path: '/project-submitted',
        hidden: true,
      },
    ],
  },
  {
    name: 'Project Submitted',
    title: 'Create Project',
    path: '/project-submitted',
    exact: true,
    component: ProjectSubmitted,
    private: true,
    hidden: true,
  },
  {
    name: 'Project List',
    title: 'Project List',
    path: '/project-list',
    exact: true,
    component: ProjectList,
    private: true,
    hidden: false,
  },
  {
    name: 'Project Details',
    title: '',
    path: '/projects/:id',
    exact: true,
    component: ProjectDetails,
    private: true,
    hidden: true,
  },
  {
    name: 'Report',
    title: 'Report',
    path: '/report',
    exact: true,
    component: Report,
    private: true,
    hidden: false,
    subRoute: [
      {
        name: 'Projects',
        path: '/report/projects',
        hidden: false,
      },
      {
        name: 'Recap Unit/Team',
        path: '/report/recap',
        hidden: false,
      },
      {
        name: 'Details People',
        path: '/report/details',
        hidden: false,
      },
      // {
      //   name: 'Report by Date Range',
      //   path: '/report/daterange',
      //   hidden: false,
      // },
    ],
  },
  {
    name: 'Projects',
    title: 'Report - Projects',
    path: '/report/projects',
    exact: true,
    component: ReportProjects,
    private: true,
    hidden: true,
  },
  {
    name: 'Project Details',
    title: '',
    path: '/report/projects/:id',
    exact: true,
    component: ReportProjectDetails,
    private: true,
    hidden: true,
  },
  {
    name: 'Recap Unit/Team',
    title: 'Report - Recap Unit/Team',
    path: '/report/recap',
    exact: true,
    component: ReportRecap,
    private: true,
    hidden: true,
  },
  {
    name: 'Details People',
    title: 'Report - Details People',
    path: '/report/details',
    exact: true,
    component: ReportDetailsPeople,
    private: true,
    hidden: true,
  },
  // {
  //   name: 'Report by Date Range',
  //   title: 'Report - Monthly Report by Date Range',
  //   path: '/report/daterange',
  //   exact: true,
  //   component: ReportDateRange,
  //   private: true,
  //   hidden: true,
  // },
  {
    name: 'Admin Panel',
    title: 'Admin Panel',
    path: '/admin',
    exact: true,
    component: AdminPanel,
    private: true,
    restricted: [userRoles.ADMIN, userRoles.UNIT_LEADER, userRoles.TEAM_LEADER],
    hidden: false,
    subRoute: [
      {
        name: 'Team',
        path: '/admin/team',
        restricted: [
          userRoles.ADMIN,
          userRoles.UNIT_LEADER,
          userRoles.TEAM_LEADER,
        ],
        hidden: false,
      },
      {
        name: 'Client List',
        path: '/admin/client-list',
        restricted: [
          userRoles.ADMIN,
          userRoles.UNIT_LEADER,
          userRoles.TEAM_LEADER,
        ],
        hidden: false,
      },
    ],
  },
  {
    name: 'Client List',
    title: 'Client List',
    path: '/admin/client-list',
    exact: true,
    component: ClientList,
    private: true,
    hidden: true,
    restricted: [userRoles.ADMIN, userRoles.UNIT_LEADER, userRoles.TEAM_LEADER],
  },
  {
    name: 'Team',
    title: 'Team',
    path: '/admin/team',
    exact: true,
    component: Team,
    private: true,
    hidden: true,
    restricted: [userRoles.ADMIN, userRoles.UNIT_LEADER, userRoles.TEAM_LEADER],
  },
  {
    name: 'Not Found',
    title: 'Not Found',
    component: NotFound,
    private: true,
    hidden: true,
  },
];

export const menu = routes
  .filter((route) => !route.hidden)
  .map(({ name, path, restricted, subRoute }) => ({
    name,
    path,
    restricted,
    submenu:
      subRoute &&
      subRoute.map((sub) => ({
        name: sub.name,
        path: sub.path,
        restricted: sub.restricted,
        hidden: sub.hidden,
      })),
  }));

export default routes;

import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import routes from '../../routes';

const Routes = () => (
  <Router>
    <Switch>
      {routes.map((route) => {
        return route.private ? (
          <PrivateRoute
            key={route.name}
            path={route.path}
            exact={route.exact}
            component={route.component}
            restricted={route.restricted}
          />
        ) : (
          <PublicRoute
            key={route.name}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      })}
    </Switch>
  </Router>
);

export default Routes;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDateRange } from '../../actions/project';
import {
  InputGroup,
  SelectGroup,
  RadioGroup,
  CheckboxGroup,
  DateRangePickerGroup,
  InlineSelect,
  TextareaGroup,
  Button,
} from '../index';
import { getListOfDateTime, validateDayInput } from '../../utils/datetime';
import { formatUnitSelectOptions } from '../../utils/formData';
import { projectType, projectStatus, UnitNew } from '../../constants/data';
import { yesOrNo } from '../../constants/formOptions';

const ProjectInfo = ({
  type,
  setType,
  defaultChecked,
  formData,
  control,
  register,
  errors,
  setValue,
  getValues,
  editable,
  update,
}) => {
  const { projectTypes, clientList, currencyList } = formData;
  const filteredDivisionList = formatUnitSelectOptions(
    Object.keys(UnitNew),
  ).filter(
    (unit) =>
      unit.value !== 'MKTU1' &&
      unit.value !== 'MKTU2' &&
      unit.value !== 'ASIANCE',
  );
  const dispatch = useDispatch();
  const { data: project } = useSelector((state) => state.project);

  return (
    <>
      <SelectGroup
        name="type"
        width="420px"
        label="What is the type of the project? *"
        control={control}
        options={projectTypes}
        placeholder="Select Type"
        rules={{ required: 'The project type is required.' }}
        error={{ ...errors.type, focus: true }}
        onChange={([selected]) => {
          if (selected) {
            setType(selected.value);
          }
        }}
        isDisabled={!editable || project.status >= projectStatus.ACTIVE}
      />
      {type !== projectType.INTERNAL && (
        <SelectGroup
          width="420px"
          name="client"
          label="Who is the client? *"
          details="If you cannot find the client from the dropdown list, please ask your line manager to add the new client."
          control={control}
          options={clientList}
          placeholder="Select Client"
          rules={{ required: 'The client name is required.' }}
          error={
            errors.type
              ? { ...errors.client, focus: false }
              : errors.client && { ...errors.client, focus: true }
          }
          isDisabled={!editable}
        />
      )}
      <CheckboxGroup
        inline
        name="division"
        label="Division *"
        list={filteredDivisionList}
        register={register}
        rules={{ required: 'The division is required.' }}
        error={errors.division}
        defaultChecked={defaultChecked}
        getValues={getValues}
        disabled={!editable}
        type={type}
      />
      <InputGroup
        width="420px"
        type="text"
        name="name"
        label="What is the name of this project? *"
        register={register}
        rules={{ required: 'The project name is required.' }}
        error={errors.name}
        placeholder="Please Fill the name of this project"
        disabled={!editable}
      />
      <InputGroup
        width="420px"
        type="text"
        name="hashtag"
        label='Please enter a "#" for this project. *'
        placeholder="Please Fill Hashtag"
        details={
          <>
            <p>
              Example: #(Client Name)-(Project Type)-(Year if it’s a yearly
              management or campaign contract)
              <br />
              #LV-TICKETING-2ND, #RALPHLAUREN-E-APPT, #PFIZER-MAINTENANCE20
            </p>
          </>
        }
        register={register}
        rules={{
          required: 'The project hashtag is required.',
          pattern: {
            value: /^#[A-Z0-9]+(-[A-Z0-9]+)*$/,
            message:
              'The hashtag should include only capital letters, numbers, and hyphens with prefix "#".',
          },
        }}
        error={errors.hashtag}
        onFocus={() => {
          if (!getValues('hashtag')) {
            setValue('hashtag', '#');
          }
        }}
        onChange={(e) => {
          setValue('hashtag', e.target.value.toUpperCase());
        }}
        disabled={!editable}
      />
      {type !== projectType.INTERNAL && (
        <InputGroup
          width="420px"
          type="text"
          name="budget"
          label="How much is the budget for this project? (Please, don't forget the currency) *"
          details={
            <p>
              If the currency is not available in the list, choose KRW currency
              and convert the amount into KRW.
            </p>
          }
          placeholder="Enter Budget"
          register={register}
          rules={{ required: 'The budget is required.' }}
          error={errors.budget || errors.currency}
          onChange={(e) => {
            setValue('budget', e.target.value.replace(/[^0-9.]/g, ''));
          }}
          onFocus={(e) => {
            setValue('budget', e.target.value.split(',').join(''));
          }}
          onBlur={(e) => {
            const { value } = e.target;
            setValue('budget', value ? parseFloat(value).toLocaleString() : '');
          }}
          disabled={!editable}
        >
          <InlineSelect
            width="28%"
            name="currency"
            control={control}
            options={currencyList}
            placeholder="Select Currency"
            rules={{ required: 'The currency is required.' }}
            noClear
            isDisabled={!editable}
          />
        </InputGroup>
      )}
      <DateRangePickerGroup
        name="period"
        label={
          type === projectType.INTERNAL
            ? 'Please define the project period'
            : 'Please define the project period *'
        }
        control={control}
        rules={
          type !== projectType.INTERNAL && {
            required: 'The project period is required.',
          }
        }
        error={errors.startDate || errors.endDate}
        disabled={!editable}
      >
        {editable && update && (
          <Button
            className="sm-navybtn ml-3"
            type="button"
            text="Apply"
            inline
            onClick={() => {
              const startDate = getValues('startDate');
              const endDate = getValues('endDate');
              const months = getListOfDateTime(
                startDate,
                endDate,
                'yyyyMM',
                'months',
              );
              dispatch(updateDateRange(months));
            }}
          />
        )}
      </DateRangePickerGroup>
      <InputGroup
        type="text"
        className="small"
        name="contractDays"
        placeholder="0"
        label={
          type === projectType.INTERNAL
            ? 'How many working days are forecast in total in the contract?'
            : 'How many working days are forecast in total in the contract? *'
        }
        register={register}
        rules={
          type !== projectType.INTERNAL && {
            required: 'The contract days is required.',
          }
        }
        error={errors.contractDays}
        onChange={(e) => {
          const days = e.target.value.replace(/[^0-9.]/g, '');
          setValue('contractDays', days);
        }}
        onBlur={(e) => {
          const { value: inputVal } = e.target;
          const days = validateDayInput(inputVal);
          const contractDays = !days || days === '0' ? '' : days;
          setValue('contractDays', contractDays);
        }}
        disabled={!editable}
      >
        <span className="append">Days</span>
      </InputGroup>
      {type !== projectType.INTERNAL && (
        <RadioGroup
          name="basecamp"
          label="Do you need to create a Basecamp page for this project? *"
          register={register}
          rules={{ required: 'This field is required.' }}
          list={yesOrNo}
          error={errors.basecamp}
          disabled={!editable || project.status >= projectStatus.ACTIVE}
          inline
        />
      )}
      {type !== projectType.INTERNAL && (
        <InputGroup
          width="48%"
          type="text"
          name="contractUrl"
          label="Please, indicate here the contract URL on the A drive *"
          placeholder="Please fill the contract URL on the A drive"
          register={register}
          rules={{ required: 'The contract URL is required.' }}
          error={errors.contractUrl}
          disabled={!editable}
        />
      )}
      <TextareaGroup
        width="48%"
        name="comments"
        label="Any additional information that could be useful (ex: WBS, Media Mix, cost estimation URL, invoices' schedule, etc.)"
        placeholder="Please fill this form"
        register={register}
        disabled={!editable}
      />
    </>
  );
};

export default ProjectInfo;

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const TOGGLE_SUBMENU = 'TOGGLE_SUBMENU';

export const UPDATE_TITLE = 'UPDATE_TITLE';

export const SAVE_PROJECT = 'SAVE_PROJECT';

export const SAVE_PROJECT_ID = 'SAVE_PROJECT_ID';

export const CHANGE_STEP = 'CHANGE_STEP';

export const SAVE_MEMBERS = 'SAVE_MEMBERS';

export const SAVE_MEMBER = 'SAVE_MEMBER';

export const DELETE_MEMBER = 'DELETE_MEMBER';

export const CLEAR_MEMBERS_TO_DELETE = 'CLEAR_MEMBERS_TO_DELETE';

export const SAVE_SPENT_DAYS = 'SAVE_SPENT_DAYS';

export const ASSIGN_DAYS = 'ASSIGN_DAYS';

export const UPDATE_DATERANGE = 'UPDATE_DATERANGE';

export const RESET_PROJECT = 'RESET_PROJECT';

export const SET_ERROR = 'SET_ERROR';

export const SET_PROJECT_FILTER = 'SET_PROJECT_FILTER';

export const CLEAR_PROJECT_FILTER = 'CLEAR_PROJECT_FILTER';

export const CHANGE_PAGE = 'CHANGE_PAGE';

export const UPDATE_PAGINATION_OPTION = 'UPDATE_PAGINATION_OPTION';

export const SELECT_WEEK = 'SELECT_WEEK';

export const SAVE_TIME_ENTRIES = 'SAVE_TIME_ENTRIES';

export const SAVE_HASHTAG = 'SAVE_HASHTAG';

export const SAVE_DESCRIPTION = 'SAVE_DESCRIPTION';

export const SAVE_DURATION = 'SAVE_DURATION';

export const REMOVE_DURATION = 'REMOVE_DURATION';

export const DELETE_TIMESHEET_ROW = 'DELETE_TIMESHEET_ROW';

export const UPDATE_USER_LEAVEDATES = 'UPDATE_USER_LEAVEDATES';

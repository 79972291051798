import React from 'react';

const ReportPeopleSpentCell = ({
  value,
  row: {
    values: { contractDays },
  },
}) => {
  const fixedVal = parseFloat(value).toFixed(2);
  if (value > contractDays) return <div className="font-red">{fixedVal}</div>;
  return <div>{fixedVal}</div>;
};

export default ReportPeopleSpentCell;

export const unitOptions = [
  { value: 'MKT', label: 'MKT' },
  { value: 'CX', label: 'CX' },
  { value: 'PROD', label: 'PROD' },
  { value: 'DATATECH', label: 'DATATECH' },
  { value: 'JPN', label: 'JPN' },
];

export const unitTeamOptions = {
  PROD: [
    { value: 'DEV', label: 'Development' },
    { value: 'DESIGN', label: 'Design' },
  ],
  CX: [
    { value: 'CX1', label: 'CX1' },
    { value: 'CX2', label: 'CX2' },
    // { value: 'CX3', label: 'CX3' },
  ],
  MKT: [
    { value: 'MKT1', label: 'MKT1' },
    { value: 'MKT2', label: 'MKT2' },
    { value: 'MKT3', label: 'MKT3' },
    { value: 'MKT4', label: 'MKT4' },
  ],
  DATATECH: [{ value: 'DATATECH', label: 'DATATECH' }],
  JPN: [{ value: 'JPN', label: 'Japan' }],
  CUSTOMERSOLUTION: [{ value: 'CUSTOMERSOLUTION', label: 'Customer Solution' }],
};

export const yesOrNo = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export const statusOptions = [
  { value: 2, label: 'Re-open Project' },
  { value: 3, label: 'Close Project' },
  { value: 4, label: 'Discontinue Project' },
];

export const durationOptions = [
  { value: 30, label: '0:30' },
  { value: 60, label: '1:00' },
  { value: 90, label: '1:30' },
  { value: 120, label: '2:00' },
  { value: 150, label: '2:30' },
  { value: 180, label: '3:00' },
  { value: 210, label: '3:30' },
  { value: 240, label: '4:00' },
  { value: 270, label: '4:30' },
  { value: 300, label: '5:00' },
  { value: 330, label: '5:30' },
  { value: 360, label: '6:00' },
  { value: 390, label: '6:30' },
  { value: 420, label: '7:00' },
  { value: 450, label: '7:30' },
  { value: 480, label: '8:00' },
];

import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InlineSelect } from '../../index';
import { saveHashtag } from '../../../actions/timesheet';
import { errorLocation, errorField } from '../../../constants/error';

const Cell = styled.div`
  width: 100%;
  border: ${({ isError }) => (isError ? '1px solid red' : 'none')};
  border-radius: 4px;
  .__react_component_tooltip {
    display: ${({ isError }) => (isError ? 'inline-block' : 'none')};
  }
`;

const ProjectDropdown = styled(InlineSelect)`
  > div {
    padding: 0 0 0 0 !important;
    margin-right: 0 !important;
  }
`;

const DropdownCell = ({ value, column, row }) => {
  const { hashtagOptions } = column;
  const {
    original: { id, projectId, hashtagError },
  } = row;

  const activeHashtagOptions = hashtagOptions.filter(
    (option) => option.status === 2,
  );
  const closedHashtagOptions = hashtagOptions.filter(
    (option) => option.status === 3,
  );

  const groupedHashtagOptions = [
    {
      label: 'Active',
      options: activeHashtagOptions,
    },
    {
      label: 'Closed',
      options: closedHashtagOptions,
    },
  ];

  const defaultOption = hashtagOptions.find(
    (option) => option.label === value || option.value === projectId,
  );

  const dispatch = useDispatch();
  const { control, setValue, watch } = useForm();
  const selectedHashtag = watch('hashtagOption');

  useEffect(() => {
    const selectedOption = defaultOption || '';
    setValue('hashtagOption', selectedOption);
  }, [value, projectId]);

  const { error } = useSelector((state) => state);
  const isError = useMemo(
    () =>
      (error &&
        error.location === errorLocation.TIMESHEET_SAVE &&
        error.field === errorField.PROJECT &&
        error.args.includes(id) &&
        !projectId) ||
      (hashtagError && !selectedHashtag),
    [error, hashtagError, selectedHashtag],
  );

  return (
    <Cell isError={isError}>
      <ProjectDropdown
        name="hashtagOption"
        control={control}
        options={groupedHashtagOptions}
        defaultValue={defaultOption}
        value={defaultOption}
        closeMenuOnSelect
        isOverflow
        placeholder="Select Hashtag"
        onChange={([selected]) => {
          if (selected) {
            dispatch(saveHashtag(id, selected.value, selected.label));
          } else {
            dispatch(saveHashtag(id, null, null));
          }
          return selected;
        }}
      />
    </Cell>
  );
};

export default DropdownCell;

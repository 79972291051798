import styled from 'styled-components/macro';
import pinwheel from '../../assets/images/loginpinwheel.svg';
import google from '../../assets/images/google-logo.png';

export const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) =>
    `${theme.colors.asiance_navy} url(${pinwheel}) no-repeat right bottom`};
  background-size: auto 120%;
  display: flex;
  align-items: center;
  color: white;

  .loginpagelogo {
    position: absolute;
    left: 30px;
    top: 20px;
  }

  .container {
    max-width: 75%;
  }

  h1 {
    font-size: 5rem;
    font-weight: 600;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      font-size: 2.5rem;
    }
  }
  p {
    font-size: 2.5rem;
    font-weight: 300;
    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
  .googlelogin {
    margin-top: 3.75rem;
    border: ${({ theme }) => `1px solid ${theme.colors.bluish_light_gray}`};
    border-radius: 10px;
    background: none;
    color: white;
    padding: 18px 60px;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.2;
    box-shadow: 0px 4px 4px rgba(0 0 0 0.25);
    &:before {
      content: url(${google});
      float: left;
      width: 27px;
      height: 27px;
      margin-right: 13px;
    }
    &:focus {
      outline: none;
    }
    @media screen and (max-width: 768px) {
      font-size: 1.125rem;
      line-height: 27px;
    }
  }
`;

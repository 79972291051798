import React from 'react';
import styled from 'styled-components/macro';
// import * as d3 from 'd3'
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { htmlLegendPlugin, htmlDaysLegendPlugin } from './Legend';

const LegendContainer = styled.div`
  position: relative;
  z-index: 99;
  margin-top: 40px;
  ul {
    list-style: none;
    flex-wrap: wrap;
    gap: 15px 30px;
    li {
      cursor: pointer;
      white-space: nowrap;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: 0 10px;
      span {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .switcher {
      width: 40px;
      height: 12px;
      border-radius: 20px;
      box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: white;
        box-shadow: 0px -2px 5px 0px rgba(153, 153, 153, 0.2),
          0px 3px 1px 0px rgba(153, 153, 153, 0.2);
      }
      &.on {
        &:after {
          left: auto;
          right: 0;
        }
      }
    }
  }
  &#dayslegend {
    ul li {
      flex-direction: row;
    }
    ul li span {
      font-weight: normal;
      color: #666666;
      font-size: 12px;
    }
    hr.line {
      border: none;
      width: 32px;
    }

    hr.plain-line {
      border-top: 3px solid #666666;
    }

    hr.dashed-line {
      border-top: 3px dashed #666666;
    }

    hr.dotted-line {
      border-top: 3px dotted #666666;
    }

    .checkbox {
      cursor: pointer;
      border: 1px solid ${({ theme }) => theme.colors.asiance_navy};
      width: 20px;
      height: 20px;
      &.on {
        background-color: ${({ theme }) => theme.colors.asiance_navy};
      }
      accent-color: black;
    }
  }
`;

// const LegendWrap = styled.div`
//   display: flex;
//   justify-content: 20px;
//   margin-top: 20px;
//   gap: 20px;

//   .filter-wrap {
//     border: 1px black solid;
//     padding: 12px;
//     display: flex;
//   }
// `;

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

const LineChart = ({ chartData }) => {
  const options = {
    scales: {
      x: {
        ticks: {
          color: '#000',
          font: {
            family: 'futura-pt',
            size: 12,
          },
        },
      },
      y: {
        ticks: {
          color: '#000',
          font: {
            family: 'futura-pt',
            size: 12,
          },
        },
      },
    },
    plugins: {
      htmlLegend: {
        // ID of the container to put the legend in
        containerID: 'legend-container',
      },
      htmlDaysLegend: {
        // ID of the container to put the legend in
        containerID: 'days-legend-container',
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <ChartContainer className="chart-container">
      <LegendContainer id="dayslegend" />
      <Chart
        type="line"
        data={chartData}
        options={options}
        plugins={[htmlLegendPlugin, htmlDaysLegendPlugin]}
      />
      <LegendContainer id="legend" />
    </ChartContainer>
  );
};
export default LineChart;

import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  margin: 0 0 10px;
  color: ${({ theme }) => theme.colors.asiance_navy};
  font-size: 1rem;
`;

const Description = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Description;

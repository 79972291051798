import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import Error from './Error';
import useErrorHandler from '../../hooks/errorHandler';
import checkicon from '../../assets/images/checkmark.svg';

const Container = styled.div`
  width: ${({ width }) => width || '100%'};
  text-align: left;
  display: inline-block;
  @media screen and (max-width: 991.98px) {
    width: 100%;
  }

  > div {
    position: ${({ isOverflow }) => (isOverflow ? 'static' : 'relative')};
  }
  div[class*='menu'] {
    top: auto;
    left: auto;
    width: ${({ isOverflow }) => (isOverflow ? '250px' : '150px')};
    min-width: ${({ isOverflow }) => (isOverflow ? '0' : '100%')};
  }

  > div > div:not(:last-child) {
    background: white;
    border: 1px solid
      ${({ dark, theme }) =>
        dark ? theme.colors.asiance_navy : theme.colors.bluish_light_gray};
    border-radius: 0;
    min-height: 40px;
    cursor: pointer;
  }
  > div > div > div {
    flex-wrap: nowrap;
  }

  div[class*='ValueContainer'] {
    div[class*='multiValue'] {
      flex-shrink: 0;
    }
  }

  > div > div > div > div {
    font-weight: ${({ dark }) => (dark ? 600 : 400)};
    color: ${({ theme }) => theme.colors.asiance_navy};
    &[class*='placeholder'] {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.asiance_navy : theme.colors.bluish_gray};
    }
  }
`;

const InlineSelect = ({
  width,
  name,
  control,
  options,
  isMulti,
  noClear,
  closeMenuOnSelect,
  placeholder,
  dark,
  isOverflow,
  error,
  ...rest
}) => {
  const inputRef = useRef();
  const { clearErrorState } = useErrorHandler();

  return (
    <Container width={width} dark={dark} isOverflow={isOverflow}>
      <Controller
        as={
          <ReactSelect
            ref={inputRef}
            isMulti={isMulti}
            closeMenuOnSelect={closeMenuOnSelect}
            // menuPortalTarget={document.querySelector('body')}
            onFocus={() => {
              clearErrorState();
            }}
          />
        }
        components={{
          IndicatorSeparator: () => null,
        }}
        control={control}
        onFocus={() => inputRef.current.focus()}
        id={name}
        name={name}
        options={options}
        placeholder={placeholder}
        styles={{
          control: (styles) => ({
            ...styles,
            flexWrap: 'nowrap',
            cursor: 'pointer',
          }),
          option: (styles, state) => ({
            ...styles,
            backgroundColor: state.isSelected ? '#EEE' : 'none',
            backgroundImage: state.isSelected ? `url(${checkicon})` : 'none',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '12px auto',
            backgroundPosition: 'center right 10px',
            color: 'inherit',
            cursor: 'pointer',
          }),
          menu: (styles) => ({ ...styles, zIndex: 99 }),
        }}
        // menuIsOpen // TODO: toggle this to style the dropdown
        menuPlacement="bottom"
        isClearable={!noClear}
        {...rest}
      />
      {error && <Error>{error.message}</Error>}
    </Container>
  );
};

export default React.memo(InlineSelect);

import styled from 'styled-components/macro';
import { Modal } from 'react-bootstrap';

export const StyledModal = styled(Modal)`
  font-family: 'Open Sans';

  .modal-dialog {
    max-width: 700px;
  }

  .modal-header {
    padding-top: 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.asiance_navy};

    .close {
      position: absolute;
      top: 10px;
      right: 20px;
      display: ${({ noclose }) => (noclose ? 'none' : 'inline-block')};
    }
  }

  .modal-content {
    padding: 50px 80px;
  }
  .modal-body {
    padding: 1em 0 0;
  }
  .modal-title {
    margin: auto;
    width: 80%;
    text-align: center;
  }

  .modal-footer {
    border: none;
    button {
      margin: auto;
    }
  }
`;

export const ScrolledModal = styled(Modal)`
  .modal-body {
    padding: 1rem 2rem;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.bluish_light_gray};
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.asiance_navy};
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.dark_gray};
    }
  }
`;

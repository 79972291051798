/* eslint-disable no-console */
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
// import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_USER_LONGTERM_LEAVEDATES } from '../../../apollo/mutations';
import { GET_USER } from '../../../apollo/queries';
// import useErrorHandler from '../../../hooks/errorHandler';
// import { errorLocation } from '../../../constants/error';
import { DoubleCheckModal } from '../../Modal';
import editIcon from '../../../assets/images/bl_edit.svg';

const Cell = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .__react_component_tooltip {
    display: ${({ disabled }) => (disabled ? 'inline-block' : 'none')};
  }
`;

const DeleteButton = styled(FontAwesomeIcon)`
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  font-size: 1.5rem;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.bluish_gray : theme.colors.asiance_navy};
`;

const EditButton = styled.div`
  margin-right: 20px;
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  content: url(${editIcon});
  height: 24px;
  width: 24px;
  margin-left: 10px;
  color: ${({ theme, editable }) =>
    editable ? theme.colors.asiance_navy : theme.colors.bluish_gray};
  &:hover {
    color: ${({ theme }) => theme.colors.asiance_navy};
  }
`;

const LongTermLeaveDateEditCell = ({
  row: { original, index },
  column: { info },
}) => {
  // console.log('original: ', original);
  // console.log(info);
  const { editingRow, setEditingRow } = info;
  const [show, setShow] = useState(null);
  // const { handleAPIError, clearErrorState } = useErrorHandler();
  const [
    updateUserLongTermLeaveDates,
    { loading: deleteLoading },
  ] = useMutation(UPDATE_USER_LONGTERM_LEAVEDATES, {
    refetchQueries: [
      {
        query: GET_USER,
        variables: {
          id: info.userId,
        },
      },
    ],
  });

  const onClickDelete = () => {
    // console.log('first delete click');
    setShow('delete');
    // clearErrorState();
  };

  // const deleteRow = () => {
  //   console.log('delete on click');
  // };
  const deleteRow = async () => {
    try {
      await updateUserLongTermLeaveDates({
        variables: {
          longTermLeaveDataToUpdate: [
            {
              id: original.id,
              deleteDates: true,
            },
          ],
        },
      });
    } catch (err) {
      console.log(err);
      // handleAPIError(err, errorLocation.CLIENT_DELETE, { args: name });
    }
  };

  const onClickEdit = () => {
    setEditingRow(index);
  };

  return (
    <Cell>
      <EditButton editable={editingRow === index} onClick={onClickEdit} />
      <DeleteButton icon={faTimesCircle} onClick={onClickDelete} />

      <DoubleCheckModal
        show={show === 'delete'}
        text="Are you sure you want to delete it?"
        loading={deleteLoading}
        onClick={deleteRow}
        handleClose={() => setShow(false)}
      />
    </Cell>
  );
};

export default LongTermLeaveDateEditCell;

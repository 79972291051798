import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';
import { Routes, ErrorBoundary, PageLoader, ErrorPage } from './components';
import GlobalStyle from './style/globalStyle';
import { theme } from './style/theme';
import { fetchRefreshToken } from './utils/api';
import { setAccessToken } from './utils/accessToken';
import { clearProjectFilter } from './actions/projectList';
import { errorMessage } from './constants/error';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const APP_VERSION = '1.1';

  const handleStorage = (e) => {
    if (e.key === 'version' && !e.newValue && e.newValue !== APP_VERSION) {
      localStorage.clear();
      localStorage.setItem('version', APP_VERSION);
      dispatch(clearProjectFilter());
    }
  };

  useEffect(() => {
    const prevVersion = localStorage.getItem('version');
    if (!prevVersion || prevVersion !== APP_VERSION) {
      localStorage.clear();
      localStorage.setItem('version', APP_VERSION);
      dispatch(clearProjectFilter());
    }

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  useEffect(() => {
    const refreshToken = async () => {
      try {
        const accessToken = await fetchRefreshToken();
        setAccessToken(accessToken);
        setLoading(false);
      } catch (err) {
        setAccessToken('');
        setLoading(false);
        setError({
          message: errorMessage.NO_REFRESH_TOKEN,
        });
      }
    };
    refreshToken();
  }, []);

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <PageLoader />
      </div>
    );
  }

  if (error) {
    return <ErrorPage text={error.message} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <GlobalStyle />
        <Routes />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;

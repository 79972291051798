import React from 'react';
import styled from 'styled-components/macro';
import { CenteredDiv } from '../index';

const StyledCenteredDiv = styled(CenteredDiv)`
  flex-direction: column;

  h1 {
    font-size: 3em;
  }

  p {
    font-size: 1.2em;
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   logErrorToMyService(error, errorInfo);
  // }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <StyledCenteredDiv>
          <h1>Looks like something went wrong!</h1>
          <p>If the problem persists, please contact admin.</p>
          <p>
            In the meantime, try refreshing or entering domain name in the
            browser address bar.
          </p>
        </StyledCenteredDiv>
      );
    }

    return children;
  }
}

export default ErrorBoundary;

import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';
import infoIcon from '../../assets/images/info-icon.svg';

const Icon = styled.img`
  cursor: pointer;
  /* margin-left: 6px; */
  position: absolute;
  right: 0px;
`;

const StyledTooltip = styled(ReactTooltip)`
  max-width: 250px;
  white-space: normal;
  padding: 20px 10px;
  &.place-top {
    &:after {
      bottom: -5px !important;
    }
  }
  &.place-bottom {
    &:before {
      border-bottom: 8px solid #dee2e6 !important;
    }
  }
`;

const InfoHeader = ({ column }) => {
  const { title, info, id } = column;
  const tooltipRef = useRef(null);

  return (
    <>
      {title}
      <Icon
        src={infoIcon}
        alt="info icon"
        ref={tooltipRef}
        data-type="light"
        data-tip
        data-for={`header-info-${id}`}
      />
      <StyledTooltip
        id={`header-info-${id}`}
        border
        backgroundColor="#fff"
        effect="solid"
        multiline
      >
        {info}
      </StyledTooltip>
    </>
  );
};

export default InfoHeader;

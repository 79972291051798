import useAuth from '../auth';
import { userRoles } from '../../constants/data';

const useQueryFilter = (defaultFilter = {}) => {
  const {
    getAuthUser,
    isTeamLeader,
    isUnitLeader,
    isAdmin,
    isUser,
  } = useAuth();
  const { unit, team, pastUnits, pastTeams } = getAuthUser();

  const roleFilter = { ...defaultFilter };
  if (isTeamLeader(unit)) {
    roleFilter.team = team;
    roleFilter.role = userRoles.USER;
  }
  if (isUnitLeader()) {
    roleFilter.unit = unit;
    roleFilter.role = [userRoles.TEAM_LEADER, userRoles.USER];
  }
  if (isAdmin()) {
    roleFilter.role = [
      userRoles.UNIT_LEADER,
      userRoles.TEAM_LEADER,
      userRoles.USER,
    ];
  }

  const roleFilterIncludingSelf = { ...roleFilter };
  if (isTeamLeader(unit)) {
    roleFilterIncludingSelf.role = [userRoles.TEAM_LEADER, userRoles.USER];
  }
  if (isUnitLeader()) {
    roleFilterIncludingSelf.role = [
      userRoles.UNIT_LEADER,
      userRoles.TEAM_LEADER,
      userRoles.USER,
    ];
  }
  if (team === 'DEV' || team === 'DESIGN' || team === 'DSI' || team === 'PM') {
    roleFilterIncludingSelf.unit = pastUnits;
    if (team === 'DSI' || team === 'PM') {
      roleFilterIncludingSelf.team = pastTeams;
    }
  }

  const roleFilterForRecap = { ...roleFilter };
  if (isTeamLeader(unit) || isUser()) {
    roleFilterForRecap.team = team;
    roleFilterForRecap.role = [userRoles.TEAM_LEADER, userRoles.USER];
    roleFilterForRecap.unit = unit;
  }
  if (isUnitLeader()) {
    roleFilterForRecap.role = [
      userRoles.UNIT_LEADER,
      userRoles.TEAM_LEADER,
      userRoles.USER,
    ];
  }
  if (team === 'DEV' || team === 'DESIGN' || team === 'DSI' || team === 'PM') {
    roleFilterForRecap.unit = pastUnits;
    if (team === 'DSI' || team === 'PM') {
      roleFilterForRecap.team = pastTeams;
    }
  }

  if (unit === 'MKT') {
    roleFilterForRecap.unit = ['MKT', 'MKTU1', 'MKTU2'];
  }

  // if (isAdmin()) {
  //   roleFilterForRecap.role = [
  //     userRoles.UNIT_LEADER,
  //     userRoles.TEAM_LEADER,
  //     userRoles.USER,
  //   ];
  // }

  const divisionFilter = { ...defaultFilter };
  if (isTeamLeader(unit)) {
    divisionFilter.team = team;
  }
  if (isUnitLeader()) {
    divisionFilter.unit = unit;
  }
  if (
    (isTeamLeader || isUser) &&
    divisionFilter.team &&
    divisionFilter.team.includes('PM')
  ) {
    divisionFilter.team = [divisionFilter.team, 'CX2'];
  }

  if (
    (isTeamLeader || isUser) &&
    divisionFilter.team &&
    divisionFilter.team.includes('DSI')
  ) {
    divisionFilter.team = [divisionFilter.team, 'CX1'];
  }

  return {
    roleFilter,
    roleFilterIncludingSelf,
    divisionFilter,
    roleFilterForRecap,
  };
};

export default useQueryFilter;

import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InlineInput } from '../../Form';
import { saveDescription } from '../../../actions/timesheet';
import { requiredDescProjects } from '../../../constants/data';
import { errorLocation, errorField } from '../../../constants/error';

const Cell = styled.div`
  width: 100%;
  border: ${({ isError }) => (isError ? '1px solid red' : 'none')};
  border-radius: 4px;
  .__react_component_tooltip {
    display: ${({ disabled, isError }) =>
      disabled || (!disabled && isError) ? 'inline-block' : 'none'};
  }
`;

const StyledInlineInput = styled(InlineInput)`
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'initial')};
  border: 1px solid ${({ theme }) => theme.colors.bluish_light_gray};
  outline: none;
  min-height: 0;
  height: 38px;
  padding: 0 10px;
`;

const TextInputCell = ({ value, row }) => {
  const {
    original: { id, projectId },
  } = row;
  const dispatch = useDispatch();
  const disabled = useMemo(() => !projectId, [projectId]);
  const required = useMemo(
    () => requiredDescProjects.some((project) => project.id === projectId),
    [projectId],
  );
  const { register, watch, setValue } = useForm();
  const description = watch('description');
  const { error } = useSelector((state) => state);
  const tooltipRef = useRef(null);
  const isError = useMemo(
    () =>
      error &&
      error.location === errorLocation.TIMESHEET_SAVE &&
      error.field === errorField.DESCRIPTION &&
      error.args.includes(id) &&
      !description,
    [error],
  );

  useEffect(() => {
    if (value !== undefined) {
      setValue('description', value);
    }
  }, [value]);

  useEffect(() => {
    if (description) {
      ReactTooltip.hide(tooltipRef.current);
    }
  }, [description]);

  return (
    <Cell disabled={disabled} isError={isError}>
      <span
        ref={tooltipRef}
        data-tip={(disabled && 'Select project first.') || ''}
        data-for={`description-cell-${id}`}
      >
        <StyledInlineInput
          type="text"
          name="description"
          defaultValue={value}
          placeholder={required ? 'Enter task (Required)' : 'Enter task'}
          register={register}
          disabled={disabled}
          value={description}
          standalone
          onBlur={() => {
            dispatch(saveDescription(id, description));
          }}
        />
      </span>
      <ReactTooltip id={`description-cell-${id}`} />
    </Cell>
  );
};

export default TextInputCell;

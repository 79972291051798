import styled from 'styled-components/macro';

const Label = styled.label`
  display: block;
  margin: 20px 0;
  font-weight: 700;
  font-size: 1.125rem;
`;

export default Label;

import React, { useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import FormGroup from './FormGroup';
import Label from './Label';
import Description from './Description';
import Error from './Error';
import useErrorHandler from '../../hooks/errorHandler';

const Textarea = styled.textarea`
  width: ${({ width }) => width || '100%'};
  min-height: 100px;
  padding: 16px;
  background-color: white;
  border: 1px solid ${({ theme }) => `${theme.colors.bluish_light_gray}`};
  font-size: 1rem;
  &:focus {
    outline: none;
  }
`;

const Flexbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: center;
`;

const InputGroup = ({
  width,
  name,
  label,
  details,
  register,
  rules,
  error,
  children,
  justify,
  ...rest
}) => {
  const inputRef = useRef();
  const { clearErrorState } = useErrorHandler();

  useEffect(() => {
    if (error && error.isManual) {
      inputRef.current.focus();
    }
  }, [error]);

  return (
    <FormGroup {...rest}>
      <Label htmlFor={name}>{label}</Label>
      <Description>{details}</Description>
      <Flexbox justify={justify}>
        <Textarea
          width={width}
          id={name}
          name={name}
          ref={(e) => {
            register(e, rules);
            inputRef.current = e;
          }}
          onFocus={() => {
            clearErrorState();
            inputRef.current.focus();
          }}
          {...rest}
        />
        {children}
        {error && <Error>{error.message}</Error>}
      </Flexbox>
    </FormGroup>
  );
};

export default InputGroup;

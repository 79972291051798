import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_CLIENT } from '../../../apollo/mutations';
import { GET_CLIENTS, GET_FORM_DATA } from '../../../apollo/queries';
import { DoubleCheckModal } from '../../Modal';
import useErrorHandler from '../../../hooks/errorHandler';
import { errorLocation } from '../../../constants/error';
import editIcon from '../../../assets/images/bl_edit.svg';

const Cell = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .__react_component_tooltip {
    display: ${({ disabled }) => (disabled ? 'inline-block' : 'none')};
  }
`;

const DeleteButton = styled(FontAwesomeIcon)`
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  font-size: 1.5rem;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.bluish_gray : theme.colors.asiance_navy};
`;

const EditButton = styled.div`
  margin-right: 20px;
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  content: url(${editIcon});
  height: 24px;
  width: 24px;
  margin-left: 10px;
  color: ${({ theme, editable }) =>
    editable ? theme.colors.asiance_navy : theme.colors.bluish_gray};
  &:hover {
    color: ${({ theme }) => theme.colors.asiance_navy};
  }
`;

const ClientButtonCell = ({ row: { original, index }, column: { info } }) => {
  const { id, name, hasProjects } = original;
  const { editingRow, setEditingRow } = info;
  const tooltipRef = useRef(null);
  const [show, setShow] = useState(null);
  const { error } = useSelector((state) => state);
  const { handleAPIError, clearErrorState } = useErrorHandler();
  const [deleteClient, { loading: deleteLoading }] = useMutation(
    DELETE_CLIENT,
    {
      refetchQueries: [
        {
          query: GET_CLIENTS,
        },
        {
          query: GET_FORM_DATA,
        },
      ],
    },
  );

  const onClickDelete = () => {
    if (hasProjects) {
      return;
    }
    setShow('delete');
    clearErrorState();
  };

  const deleteClientOnClick = async () => {
    try {
      const response = await deleteClient({
        variables: {
          id,
        },
      });

      if (response && response.data && response.data.deleteClient) {
        setShow(null);
      }
    } catch (err) {
      handleAPIError(err, errorLocation.CLIENT_DELETE, { args: name });
    }
  };

  const onClickEdit = () => {
    setEditingRow(index);
  };

  return (
    <Cell disabled={hasProjects}>
      <EditButton editable={editingRow === index} onClick={onClickEdit} />
      <span
        ref={tooltipRef}
        data-tip="This client is referenced in projects"
        data-for={`button-cell-${id}`}
      >
        <DeleteButton
          icon={faTimesCircle}
          disabled={hasProjects}
          onClick={onClickDelete}
        />
      </span>
      <ReactTooltip id={`button-cell-${id}`} />
      <DoubleCheckModal
        show={show === 'delete'}
        title={name}
        text="Are you sure you want to delete it?"
        loading={deleteLoading}
        error={
          error && error.location === errorLocation.CLIENT_DELETE ? error : null
        }
        onClick={deleteClientOnClick}
        handleClose={() => setShow(false)}
      />
    </Cell>
  );
};

export default ClientButtonCell;

/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';

const Container = styled.div`
  font-size: 1.125rem;

  .title {
    font-weight: 700;
  }
  .border-right {
    border-right: 1px solid #e1e3e4;
  }
  @media screen and (max-width: 1199.98px) {
    .border-right {
      border: 0 !important;
    }
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: 1199.98px) {
    display: block;
  }
`;

const Col = styled.div`
  width: 50%;
  @media screen and (max-width: 1199.98px) {
    display: block;
    width: 100%;
  }
`;

const ProjectSummary = styled.div`
  font-weight: 700;
  border-top: 2px solid ${({ theme }) => theme.colors.asiance_navy};
  border-bottom: 1px solid #e1e3e4;
  margin-top: 30px;
  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
  }
`;

const BudgetSummary = styled.div`
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  background-color: #f9f9f9;
  padding: 30px;
  justify-content: space-between;

  p {
    margin: 0;
  }
`;

const ProjectPeriod = styled.div`
  font-size: 1rem;
  display: flex;
  background-color: #f9f9f9;
  padding: 30px;
  justify-content: space-between;
  p {
    margin: 0;
  }
`;

const ProgressSummary = styled.div`
  display: flex;
  p {
    margin: 0;
  }
  .progresswrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    padding: 30px;
    width: 50%;
    .title {
      width: 55%;
    }
    .value {
      width: 45%;
      text-align: right;
    }
  }
  ${(props) =>
    props.orange &&
    css`
      .progressColor {
        color: #ffa51e;
      }
    `};
  ${(props) =>
    props.red &&
    css`
      .progressColor {
        color: #f53154;
      }
    `};
`;

const ReportProjectOverview = ({ defaultValues }) => {
  const {
    Client,
    name,
    budgetAmount,
    startDate,
    endDate,
    dayForcast,
    currencyCode,
    totalSpentDays,
    fullWeeksSinceProjectStart,
    dayPerWeekInContract,
  } = defaultValues;

  const [realProgress, setRealProgress] = useState(null);
  const [theoreticalProgress, setTheoreticalProgress] = useState(null);
  const [red, setRed] = useState(false);
  const [orange, setOrange] = useState(false);

  useEffect(() => {
    if (dayForcast == null) {
      setRealProgress('N/A');
      setTheoreticalProgress('N/A');
    }

    if (dayForcast !== null) {
      setRealProgress(((totalSpentDays / dayForcast) * 100).toFixed(2));

      const theoreticalDaysSpent =
        fullWeeksSinceProjectStart * dayPerWeekInContract;
      if (((theoreticalDaysSpent / dayForcast) * 100).toFixed(2) > 100) {
        setTheoreticalProgress(100);
      }
      setTheoreticalProgress(
        ((theoreticalDaysSpent / dayForcast) * 100).toFixed(2),
      );
      if (fullWeeksSinceProjectStart < 0) {
        setTheoreticalProgress(0);
      }
      if ((theoreticalDaysSpent / dayForcast) * 100 > 100) {
        setTheoreticalProgress(100);
      }
      if (theoreticalProgress < 100 && realProgress > theoreticalProgress) {
        setOrange(true);
      }
      if (realProgress >= 100) {
        setRed(true);
      }
    }
  }, [theoreticalProgress]);

  let projectPeriod;
  if (startDate && endDate) {
    projectPeriod = `${startDate.replaceAll('-', '.')} - ${endDate.replaceAll(
      '-',
      '.',
    )}`;
  }

  if (startDate && !endDate) {
    projectPeriod = `${startDate.replaceAll('-', '.')} - N/A`;
  }

  if (!startDate && endDate) {
    projectPeriod = `N/A - ${endDate.replaceAll('-', '.')}`;
  }
  if (!startDate && !endDate) {
    projectPeriod = 'N/A';
  }

  return (
    <Container>
      <p className="title">{Client ? Client.name : 'Asiance'}</p>
      <p>{name}</p>
      <ProjectSummary>
        <Row>
          <Col className="border-right">
            <BudgetSummary>
              <p>Budget</p>
              <h4>
                {budgetAmount
                  ? `${currencyCode} ${parseFloat(budgetAmount).toLocaleString(
                      'en',
                    )}`
                  : 'N/A'}
              </h4>
            </BudgetSummary>
          </Col>
          <Col>
            <ProjectPeriod>
              <p>Project Period</p>
              <h4>{projectPeriod}</h4>
            </ProjectPeriod>
          </Col>
        </Row>
        <Row>
          <Col className="border-right">
            <ProgressSummary red={red} orange={orange}>
              <div className="progresswrap">
                <p>Spent Days</p>
                <h4 className="progressColor">{totalSpentDays.toFixed(2)}</h4>
              </div>
              <div className="progresswrap">
                <p>Contract Days</p>
                <h4>{dayForcast !== null ? dayForcast : 'N/A'}</h4>
              </div>
            </ProgressSummary>
          </Col>
          <Col>
            <ProgressSummary red={red} orange={orange}>
              <div className="progresswrap">
                <div className="title">
                  <p>Real progress %</p>
                </div>
                <div className="value">
                  <h4 className="progressColor">
                    {isNaN(realProgress) ? realProgress : `${realProgress}%`}
                  </h4>
                </div>
              </div>
              <div className="progresswrap">
                <div className="title">
                  <p>Theoretical progress %</p>
                </div>
                <div className="value">
                  <h4>
                    {isNaN(theoreticalProgress)
                      ? theoreticalProgress
                      : `${theoreticalProgress}%`}
                  </h4>
                </div>
              </div>
            </ProgressSummary>
          </Col>
        </Row>
      </ProjectSummary>
    </Container>
  );
};
export default ReportProjectOverview;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { EditProject, PageLoader, ErrorPage } from '../../components';
import { GET_PROJECT, GET_FORM_DATA } from '../../apollo/queries';
import { resetProject } from '../../actions/project';
import { updateTitle } from '../../actions/layout';
import { formatFormData } from '../../utils/formData';
import { editableProjectRoles } from '../../constants/data';
import { errorMessage } from '../../constants/error';
import useAuth from '../../hooks/auth';

const ProjectDetails = ({ match }) => {
  const dispatch = useDispatch();
  const { getAuthUser } = useAuth();
  const { id: userId } = getAuthUser();

  const { loading: prjLoading, data: prjData, error: prjError } = useQuery(
    GET_PROJECT,
    {
      variables: {
        id: Number(match.params.id),
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ getProject }) => {
        dispatch(updateTitle(getProject.hashtag));
      },
    },
  );
  const { loading: formLoading, data: formData, error: formError } = useQuery(
    GET_FORM_DATA,
  );

  useEffect(() => {
    dispatch(resetProject());

    return () => {
      dispatch(updateTitle(''));
    };
  }, []);

  if (prjLoading || formLoading) return <PageLoader />;
  if (prjError || formError)
    return <ErrorPage text={errorMessage.UNEXPECTED} />;
  if (!prjData.getProject) return <ErrorPage text="Not Found" />;

  const formattedFormData = formatFormData(formData.getFormData);
  const project = prjData.getProject;
  const isOwner = userId === Number(project.Owner.id);
  const isReviewer = project.Reviewer
    ? userId === Number(project.Reviewer.id)
    : false;
  const isTeamMember = project.Team.some(
    (member) =>
      member.id === userId && editableProjectRoles.includes(member.projectRole),
  );

  return (
    <EditProject
      formData={formattedFormData}
      projectId={project.id}
      projectData={project}
      isOwner={isOwner}
      isReviewer={isReviewer}
      isTeamMember={isTeamMember}
    />
  );
};

export default ProjectDetails;

import React from 'react';
import styled from 'styled-components/macro';
import BootstrapPagination from 'react-bootstrap/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { changePage } from '../../actions/projectList';
import chevron from '../../assets/images/chevron.svg';

const PaginationContainer = styled(BootstrapPagination)`
  justify-content: flex-end;
  margin-bottom: 0;

  .page-link {
    border: none;
    span:not(.sr-only) {
      display: none;
    }
  }
  .page-item {
    .page-link {
      color: ${({ theme }) => theme.colors.asiance_navy};
      opacity: 0.5;
    }
    &:first-child,
    &:last-child {
      .page-link {
        position: relative;
        top: 2px;
        opacity: 1;
        &:after {
          display: inline-block;
          content: url(${chevron});
        }
      }
    }
    &:first-child {
      .page-link {
        &:after {
          transform: scaleX(-1);
        }
      }
    }
  }
  .page-item.active .page-link {
    background: none;
    border: none;
    font-weight: 700;
    opacity: 1;
  }

  .page-link:hover {
    background: none;
  }

  .page-link:focus {
    box-shadow: none;
  }
`;

const Pagination = ({ pageCount, refetch }) => {
  const dispatch = useDispatch();
  const { page } = useSelector((state) => state.projectList);

  const pageNumCount =
    // eslint-disable-next-line no-nested-ternary
    pageCount <= 7 ? pageCount : page <= 3 || page >= pageCount - 2 ? 6 : 7;

  const onPageChanged = (pageNum) => {
    let num = pageNum;
    if (pageNum < 1) num = 1;
    if (pageNum > pageCount) num = pageCount;
    dispatch(changePage(num));
    refetch();
  };

  return (
    <PaginationContainer>
      {/* <BootstrapPagination.First onClick={() => onPageChanged(1)} /> */}
      <BootstrapPagination.Prev onClick={() => onPageChanged(page - 1)} />
      {Array.from(Array(pageNumCount), (_, i) => {
        if (pageCount < 7 || page <= 3) {
          return i + 1;
        }
        if (page >= pageCount - 2) {
          return i + (pageCount - 5);
        }
        return i + (page - 3);
      }).map((num, i) => {
        if (
          pageCount > 7 &&
          ((page <= 3 && i === pageNumCount - 1) ||
            (page >= pageCount - 2 && i === 0) ||
            (page > 3 &&
              page < pageCount - 2 &&
              (i === 0 || i === pageNumCount - 1)))
        ) {
          return <BootstrapPagination.Ellipsis />;
        }
        return (
          <BootstrapPagination.Item
            key={num}
            active={page === num}
            onClick={() => onPageChanged(num)}
          >
            {num}
          </BootstrapPagination.Item>
        );
      })}
      <BootstrapPagination.Next onClick={() => onPageChanged(page + 1)} />
      {/* <BootstrapPagination.Last onClick={() => onPageChanged(pageCount)} /> */}
    </PaginationContainer>
  );
};

export default Pagination;

import { format } from 'date-fns';
import {
  projectType,
  statusList,
  projectStatus as prjStatus,
  Unit,
  UnitAll,
  Team,
  UnitNew,
  TeamNew,
} from '../constants/data';
import { statusOptions } from '../constants/formOptions';
import { getListOfDateTime } from './datetime';

export const formatUnitSelectOptions = (data) => {
  return data.map((unit) => ({
    value: unit,
    label: UnitAll[unit],
  }));
};

export const formatFormData = (data) => {
  const projectTypes = data.projectTypes.map((type) => ({
    value: type,
    label: type,
  }));

  const clientList = data.clients.map((client) => ({
    value: client.id,
    label: client.name,
  }));

  const divisionList = data.units.map((unit) => ({
    value: unit,
    label: UnitAll[unit],
  }));

  const currencyList = data.currencyCodes.map((code) => ({
    value: code,
    label: code,
  }));

  const roleList = data.projectRoles.map((role) => ({
    value: role,
    label: role,
  }));

  const userList =
    data.users &&
    data.users
      .filter((user) => user.email !== 'timesheet@asiance.com')
      .map((user) => {
        const { id, name, email, team, unit } = user;
        return {
          value: id,
          label: name,
          name,
          email,
          team,
          unit,
        };
      });

  const allUserList =
    data.allUsers &&
    data.allUsers
      .filter((user) => user.email !== 'timesheet@asiance.com')
      .map((user) => {
        const { id, name, email, team, unit } = user;
        return {
          value: id,
          label: name,
          name,
          email,
          team,
          unit,
        };
      });

  const groupUsersSelect = [
    {
      label: 'Unit',
      options: Object.keys(UnitNew).map((key) => ({ label: key, value: key })),
    },
    {
      label: 'Team',
      options: Object.keys(TeamNew).map((key) => ({
        label: Team[key],
        value: key,
      })),
    },
    { label: 'User', options: allUserList },
  ];

  const projectStatus = data.projectStatus.map((value) => ({
    value,
    label: statusList[value],
  }));

  const reportProjectStatusList = [
    {
      value: 2,
      label: 'ACTIVE',
    },
    {
      value: 3,
      label: 'CLOSED',
    },
    {
      value: 4,
      label: 'DISCONTINUED',
    },
  ];

  return {
    projectTypes,
    clientList,
    divisionList,
    currencyList,
    roleList,
    userList,
    groupUsersSelect,
    projectStatus,
    reportProjectStatusList,
  };
};

export const mapFormDataToProjectInput = (data, userId, projectId) => {
  const {
    type,
    client,
    name,
    division,
    hashtag,
    startDate,
    endDate,
    budget,
    currency,
    contractDays,
    basecamp,
    contractUrl,
    comments,
  } = data;

  let projectInput = {
    projectType: type.value,
    unit: division,
    name,
    hashtag,
    startDate: startDate ? format(startDate, 'yyyy-MM-dd') : null,
    endDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
    dayForcast: contractDays ? parseFloat(contractDays) : null,
    comments,
    createdBy: Number(userId),
  };

  if (projectId) {
    projectInput.id = Number(projectId);
  }

  if (type.value === projectType.INTERNAL) {
    return projectInput;
  }

  projectInput = {
    ...projectInput,
    clientId: client.value,
    budgetAmount: parseFloat(budget.split(',').join('')),
    currencyCode: currency.value,
    isOnBasecamp: basecamp === 'yes',
    contractUrl,
  };

  return projectInput;
};

export const mapMemberStateToProjectUserInput = (projectId, members) => {
  return members.map((member) => ({
    projectId,
    userId: member.id,
    projectRole: member.role,
  }));
};

export const mapMemberStateToContractInput = (projectId, members) => {
  const contractDays = [];

  members.forEach((member) => {
    contractDays.push(
      ...member.contractDays.map((contract) => {
        const monthYear = `${contract.month.slice(0, 4)}-${contract.month.slice(
          4,
        )}`;
        return {
          id: contract.id,
          userId: member.id,
          projectId,
          days: contract.days,
          monthYear: format(new Date(`${monthYear}-01`), 'yyyy-MM-dd'),
        };
      }),
    );
  });

  return contractDays;
};

export const mapMemberStateToSpentDayInput = (projectId, members) => {
  const spentDays = [];

  members.forEach((member) => {
    if (member.spentDays) {
      const { id, days } = member.spentDays;
      spentDays.push({
        id,
        userId: member.id,
        projectId,
        days,
      });
    }
  });

  return spentDays;
};

export const addUpContractDays = (members) => {
  let totalDays = 0;

  members.forEach((member) => {
    totalDays += member.contractDays.reduce(
      (acc, curr) => parseFloat(acc) + parseFloat(curr.days),
      0,
    );
  });

  return totalDays;
};

export const getDefaultStatusOption = (status) => {
  // eslint-disable-next-line no-nested-ternary
  return status === prjStatus.ACTIVE
    ? { value: prjStatus.CLOSED, label: 'Close Project' }
    : status >= prjStatus.CLOSED
    ? { value: prjStatus.ACTIVE, label: 'Re-open Project' }
    : '';
};

export const mapProjectDataToState = (data) => {
  const {
    projectType: type,
    Client,
    unit: division,
    name,
    hashtag,
    budgetAmount,
    currencyCode,
    startDate,
    endDate,
    dayForcast,
    isOnBasecamp,
    contractUrl,
    comments,
    Owner,
    Reviewer,
    LastModifiedBy,
    status,
    createdAt,
    updatedAt,
    StatusHistory,
  } = data;
  return {
    type: { value: type, label: type },
    client: Client ? { value: Client.id, label: Client.name } : '',
    division,
    name,
    hashtag,
    budget: budgetAmount ? budgetAmount.toLocaleString() : '',
    currency: {
      value: currencyCode || '',
      label: currencyCode || '',
    },
    startDate: startDate ? new Date(startDate) : '',
    endDate: endDate ? new Date(endDate) : '',
    contractDays: dayForcast || '',
    basecamp: isOnBasecamp ? 'yes' : 'no',
    contractUrl: contractUrl || '',
    comments: comments || '',
    months: getListOfDateTime(startDate, endDate, 'yyyyMM', 'months'),
    owner: { value: Owner.id, label: Owner.name, email: Owner.email },
    reviewer: Reviewer && {
      value: Reviewer.id,
      label: Reviewer.name,
      email: Reviewer.email,
    },
    lastEditor: LastModifiedBy && LastModifiedBy.name,
    status,
    statusOptions: getDefaultStatusOption(status),
    statusHistory:
      status === prjStatus.ACTIVE && StatusHistory.length === 1
        ? null
        : status >= prjStatus.ACTIVE &&
          StatusHistory.length && {
            status: StatusHistory.find((history) => history.status === status)
              .status,
            updatedAt: StatusHistory.find(
              (history) => history.status === status,
            ).updatedAt,
          },
    createdAt,
    updatedAt,
  };
};

export const mapTeamDataToMemberState = (team, months) => {
  const result = [];

  team.forEach((teamMember) => {
    const { id, name, projectRole: role, ContractDays, SpentDays } = teamMember;

    const member = {
      id,
      name: `${name}`,
      role,
      contractDays: [],
      spentDays: SpentDays.length
        ? { id: SpentDays[0].id, days: SpentDays[0].days }
        : null,
    };

    if (!ContractDays.length) {
      member.contractDays = months.map((month) => ({
        month,
        days: 0,
      }));
    } else {
      member.contractDays = ContractDays.map((contract) => ({
        id: contract.id,
        month: contract.monthYear.split('-').slice(0, 2).join(''),
        days: contract.days,
      }));
    }

    result.push(member);
  });

  return result;
};

export const mapContractDaysToMemberState = (members, contractDays) => {
  const updatedMembers = members.map((member) => {
    member.contractDays.forEach((contract) => {
      const savedContract = contractDays.find(
        (contractDay) =>
          contractDay.userId === member.id &&
          contractDay.monthYear.split('-').slice(0, 2).join('') ===
            contract.month,
      );
      contract.id = savedContract.id;
    });
    return member;
  });

  return updatedMembers;
};

export const mapSpentDaysToMemberState = (members, spentDays) => {
  const updatedMembers = members.map((member) => {
    if (member.spentDays) {
      const savedSpentDays = spentDays.find(
        (spentDay) => spentDay.userId === member.id,
      );
      member.spentDays.id = savedSpentDays && savedSpentDays.id;
      member.spentDays.days = savedSpentDays && savedSpentDays.days;
    }
    return member;
  });
  return updatedMembers;
};

export const getStatusOptions = (status) => {
  if (status === prjStatus.ACTIVE) {
    return statusOptions.filter((option) => option.value !== prjStatus.ACTIVE);
  }
  return statusOptions.filter((option) => option.value === prjStatus.ACTIVE);
};

export const mapTimesheetStateToTimeEntriesInput = (timesheet, userId) => {
  const timeEntries = [];
  timesheet.forEach((project) => {
    const { projectId, description, entries } = project;
    entries.forEach((entry) => {
      const { id, date, duration } = entry;
      if (!id && !duration) {
        return;
      }
      const entryInput = {
        userId,
        projectId,
        description,
        date,
        duration,
      };
      if (id) {
        entryInput.id = id;
      }
      timeEntries.push(entryInput);
    });
  });
  return timeEntries;
};

export const getProjectFilterInput = (filter) => {
  const {
    search,
    filterDivision,
    filterType,
    filterStatus,
    filterUnit,
    filterTeam,
    filterMember,
  } = filter;

  const filterInput = {
    ...(search && { text: search }),
    ...(filterType &&
      filterType.length && {
        projectType: filterType.map((item) => item.value),
      }),
    ...(filterDivision &&
      filterDivision.length && {
        division: filterDivision.map((item) => item.value),
      }),
    ...(filterStatus &&
      filterStatus.length && {
        projectStatus: filterStatus.map((item) => item.value),
      }),
    ...(filterUnit && filterUnit.length && { unit: filterUnit }),
    ...(filterTeam && filterTeam.length && { team: filterTeam }),
    ...(filterMember && filterMember.length && { members: filterMember }),
  };

  return Object.keys(filterInput).length === 0 ? null : filterInput;
};

export const computeProjectFilterAsiancersOptions = (filter) => {
  const projectFilter = filter;
  const selectedOptions = projectFilter.asiancersOptions || [];

  const [unitTeamSelected, userSelected] = selectedOptions
    .map((option) => option.value)
    .reduce(
      ([unitTeams, users], selected) =>
        typeof selected === 'string'
          ? [[...unitTeams, selected], users]
          : [unitTeams, [...users, selected]],
      [[], []],
    );

  const units =
    unitTeamSelected && unitTeamSelected.filter((key) => key in Unit);
  const teams =
    unitTeamSelected && unitTeamSelected.filter((key) => key in Team);

  projectFilter.filterUnit = units;
  projectFilter.filterTeam = teams;
  projectFilter.filterMember = [...new Set(userSelected)];

  return projectFilter;
};

export const getReportProjectFilterInput = (filter) => {
  const {
    search,
    filterDate,
    filterDivision,
    filterType,
    filterStatus,
  } = filter;

  const filterInput = {
    ...(search && { text: search }),
    ...(filterDate && { date: filterDate }),
    ...(filterType &&
      filterType.length && {
        projectType: filterType.map((item) => item.value),
      }),
    ...(filterDivision &&
      filterDivision.length && {
        division: filterDivision.map((item) => item.value),
      }),
    ...(filterStatus &&
      filterStatus.length && {
        projectStatus: filterStatus.map((item) => item.value),
      }),
  };
  return Object.keys(filterInput).length === 0 ? null : filterInput;
};

import styled from 'styled-components/macro';

export const Form = styled.div`
  background-color: white;
  padding: 40px 60px;
  margin: 80px 0;
  width: 100%;

  .button-group {
    display: flex;
    justify-content: space-between;
    margin: '24px 0';
  }
  .save-button-group {
    display: inline;
    button {
      margin-left: 20px;
    }
  }
  p {
    margin: 0;
  }

  .teamleader-select {
    width: 440px;
    label {
      width: 110%;
    }
  }
`;

import styled from 'styled-components/macro';

const FormGroup = styled.div`
  margin-top: 18px;
  margin-bottom: ${({ marginEven }) => (marginEven ? '18px' : '56px')};
  > .react-datepicker__tab-loop {
    display: inline-block;
  }
`;

export default FormGroup;

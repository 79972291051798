import React from 'react';
import styled from 'styled-components/macro';
import { CenteredDiv } from '../PageElements';
import pinwheel from '../../assets/images/pinwheel.svg';

const Spinner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 99999;
  justify-content: center;
  align-items: center;

  .spinner {
    animation: spin 1600ms ease-in-out infinite;
    box-shadow: none;
    height: 110px;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    transform: scale(5);
  }

  @keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
      transform-origin: center;
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform-origin: center;
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
      transform-origin: center;
    }
  }
`;

const PageLoader = () => {
  return (
    <CenteredDiv>
      <Spinner>
        <div className="spinner" data-cy="spinner">
          <img src={pinwheel} alt="Loading..." />
        </div>
      </Spinner>
    </CenteredDiv>
  );
};

export default PageLoader;

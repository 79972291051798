import React from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProjectFilter } from '../../../actions/projectList';

const StyledDiv = styled.div`
  &:hover,
  &:focus {
    outline: none;
  }
`;

const LinkToProjectsCell = ({ value, row: { original }, history }) => {
  const { filter } = original;
  const dispatch = useDispatch();

  const redirectToProjectList = () => {
    dispatch(setProjectFilter(filter));
    history.push('/project-list');
  };

  return (
    <StyledDiv
      role="button"
      tabIndex="0"
      onClick={redirectToProjectList}
      onKeyPress={redirectToProjectList}
    >
      {value || '-'}
    </StyledDiv>
  );
};

export default withRouter(LinkToProjectsCell);

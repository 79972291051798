import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { Error } from '../../Form';
import { UPDATE_CLIENT } from '../../../apollo/mutations';
import { GET_CLIENTS, GET_FORM_DATA } from '../../../apollo/queries';
import useErrorHandler from '../../../hooks/errorHandler';
import { errorLocation } from '../../../constants/error';

const StyledInlineInput = styled.input`
  border: none;
  outline: none;
  background: initial;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  border-bottom: ${({ disabled, theme }) =>
    disabled ? 'none' : `1px solid ${theme.colors.input_border}`};
  padding: 10px 4px;
`;

const ClientInputCell = ({
  value,
  row: { original, index },
  column: { info },
}) => {
  const { id } = original;
  const { editingRow, setEditingRow } = info;
  const [editable, setEditable] = useState(false);
  const [clientValue, setClientValue] = useState(value);
  const inputRef = useRef(null);
  const { error } = useSelector((state) => state);
  const { register, setValue, watch } = useForm();
  const clientName = watch('clientName');
  const { handleAPIError } = useErrorHandler();
  const [updateClient] = useMutation(UPDATE_CLIENT, {
    refetchQueries: [
      {
        query: GET_CLIENTS,
      },
      {
        query: GET_FORM_DATA,
      },
    ],
  });

  const client = {
    id,
    name: clientName,
  };

  const handleClickOutside = (e) => {
    if (e.target !== document.activeElement) {
      setClientValue(value);
      setEditingRow(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    setValue('clientName', value);
    setClientValue(value);
    setEditable(editingRow === index);
  }, [editingRow]);

  useEffect(() => {
    if (editable) {
      inputRef.current.focus();
    }
  }, [editable]);

  useEffect(() => {
    setValue('clientName', value);
    setClientValue(value);
  }, [value]);

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      try {
        const response = await updateClient({
          variables: {
            client,
          },
        });

        if (response && response.data && response.data.updateClient) {
          setEditingRow(null);
        }
      } catch (err) {
        handleAPIError(err, errorLocation.CLIENT_UPDATE, { args: id });
      }
    }
  };

  return (
    <>
      {editable ? (
        <StyledInlineInput
          ref={(e) => {
            register(e);
            inputRef.current = e;
          }}
          type="text"
          name="clientName"
          defaultValue={clientValue}
          onChange={(e) => {
            setClientValue(e.target.value);
            return e.target.value;
          }}
          onKeyPress={handleKeyPress}
          standalone
        />
      ) : (
        value
      )}
      {error &&
        error.location === errorLocation.CLIENT_UPDATE &&
        error.args === id && <Error>{error.message}</Error>}
    </>
  );
};

export default ClientInputCell;

import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuth from '../../hooks/auth';
import Layout from '../Layout';

const PrivateRoute = ({ component: Component, restricted, ...rest }) => {
  const { isLoggedIn, getAuthUser } = useAuth();
  const { role } = getAuthUser() || '';

  useEffect(() => {
    const handleStorage = (e) => {
      if (e.key === 'user' && e.newValue) {
        window.location.reload();
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        // eslint-disable-next-line no-nested-ternary
        isLoggedIn() ? (
          restricted && !restricted.includes(role) ? (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          ) : (
            <Layout {...props}>
              <Component {...props} />
            </Layout>
          )
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;

import React from 'react';
import styled from 'styled-components/macro';

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  text-align: center;

  font-weight: ${({ isHeader }) => (isHeader ? 'bold' : 'normal')};
  font-size: ${({ isSubRow, isHeader }) =>
    isSubRow && isHeader ? '0.875rem' : '1rem'};
`;

const SubHeaderCell = ({ value, depth, index }) => {
  return (
    <Cell isHeader={depth === 1 && index === 0} isSubRow={depth === 1}>
      {value}
    </Cell>
  );
};

export default SubHeaderCell;

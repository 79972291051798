import React, { useState } from 'react';
import styled from 'styled-components/macro';

const CheckboxContainer = styled.div`
  position: relative;
  display: ${({ depth }) => (depth > 0 ? 'none' : '')};
`;

const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  border-radius: 0;
  opacity: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Icon = styled.div`
  &:before,
  &:after {
    content: '';
    background: white;
    width: 2px;
    position: absolute;
    left: 8px;
    bottom: 7px;
  }
  &:before {
    height: 7px;
    transform-origin: bottom right;
    transform: rotate(-45deg);
  }
  &:after {
    height: 12px;
    transform-origin: bottom left;
    transform: rotate(45deg);
  }
`;

const StyledCheckbox = styled.div`
  width: 24px;
  height: 24px;
  border: ${({ theme }) => `1px solid ${theme.colors.bluish_light_gray}`};
  position: absolute;
  background: ${({ checked }) => (checked ? '#2A3642' : 'white')};

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxCell = ({ row, handleCheck }) => {
  const [checked, setChecked] = useState(false);
  return (
    <CheckboxContainer depth={row.depth}>
      <StyledCheckbox checked={checked}>
        <Icon />
      </StyledCheckbox>
      <Checkbox
        onChange={(e) => {
          setChecked(e.target.checked);
          handleCheck(row, e.target.checked);
        }}
      />
    </CheckboxContainer>
  );
};

export default CheckboxCell;

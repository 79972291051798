import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Container, SelectField } from './styled';
import { UnitAll } from '../../constants/data';
import useAuth from '../../hooks/auth';

const ReportPeopleToolbar = ({ formData, control, onChange, selectedDate }) => {
  const { getAuthUser, isAdmin, isUnitLeader } = useAuth();
  const { unit } = getAuthUser();

  const options = formData.filter((division) => {
    if (isAdmin()) {
      return division.label !== UnitAll.ASIANCE;
    }
    if (
      selectedDate < new Date('2022-03-01') &&
      isUnitLeader() &&
      (unit === 'MKTU1' || unit === 'MKTU2' || unit === 'MKT')
    ) {
      const { setValue } = control;
      setValue('MKT');
      return division.label === 'MKT';
    }

    if (
      selectedDate >= new Date('2022-03-01') &&
      selectedDate < new Date('2022-02-01') &&
      isUnitLeader() &&
      (unit === 'MKTU1' || unit === 'MKTU2' || unit === 'MKT')
    ) {
      return division.value === 'MKTU1' || division.value === 'MKTU2';
    }
    return division.label === UnitAll[unit];
  });

  return (
    <Container>
      <Row justify-content-between={1} style={{ alignItems: 'center' }}>
        <Col sm={12} xl={6} className="text-left">
          <SelectField
            width="50%"
            name="filterDivision"
            control={control}
            options={options}
            placeholder="Division"
            onChange={onChange}
            noClear
            defaultValue={options}
          />
          {/* <Button
            className="sub-button"
            type="submit"
            text="Search"
            inline
            onClick={handleSubmit(onSubmit)}
          /> */}
        </Col>
      </Row>
    </Container>
  );
};

export default ReportPeopleToolbar;

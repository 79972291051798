import React from 'react';
import styled from 'styled-components/macro';
import calendar from '../../assets/images/calendar-new.svg';
import { ReactTable } from '../../components';

export const ToolBar = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: center;
  flex-wrap: wrap;
  cursor: ${({ cursor }) => cursor || 'default'};
  .month {
    font-size: ${({ theme }) => theme.mixins.getvw('28')};
    font-weight: 700;
    text-transform: uppercase;
    margin-right: ${({ theme }) => theme.mixins.getvw('20')};
  }
`;

export const DateRange = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  &:focus {
    outline: none;
  }
  &:after {
    content: url(${calendar});
    margin-left: 12px;
  }
`;

export const DatePickerContainer = styled.div`
  > div {
    visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  }

  .react-datepicker {
    position: absolute;
    left: 0;
    z-index: 1;
    margin-top: 16px;
  }
`;

export const Message = styled.div`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  color: ${({ theme }) => theme.colors.green};
  font-size: 0.938em;
  margin: ${({ inline }) => (inline ? '0 8px' : '8px 0')};
  text-align: ${({ centered }) => (centered ? 'center' : 'initial')};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const PlusIcon = styled.div`
  margin-right: 16px;
  position: relative;
  top: 3px;
  &:before {
    content: '+';
    color: ${({ theme }) => theme.colors.asiance_navy};
    font-size: 1.5rem;
  }
`;

export const StyledReactTable = styled((props) => <ReactTable {...props} />)`
  .table {
    overflow-y: auto;
    .th,
    .td {
      padding: 10px 7px !important;
    }
    .th {
      border-top: ${({ theme }) =>
        `1px solid ${theme.colors.bluish_light_gray}`};
    }
  }
`;

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Container } from './styled';
import { InlineInput, MultiSelect, Button } from '../Form';

const ProjectListToolbar = ({
  projectCount,
  formData,
  register,
  control,
  handleSubmit,
  onSubmit,
  onClear,
}) => {
  return (
    <Container>
      <Row style={{ marginBottom: '20px' }}>
        <Col className="text-right">
          <MultiSelect
            width="20%"
            name="asiancersOptions"
            control={control}
            options={formData.groupUsersSelect}
            placeholder="Asiancers"
            dark
          />
        </Col>
      </Row>
      <Row justify-content-between={1} style={{ alignItems: 'center' }}>
        <Col sm={12} xl={2} className="total">
          <strong>{projectCount} Total</strong>
        </Col>
        <Col sm={12} xl={10} className="text-right">
          <InlineInput
            type="search"
            name="search"
            width="31%"
            register={register}
            placeholder="Search by Name or Hashtag"
            standalone
          />
          <MultiSelect
            name="filterDivision"
            control={control}
            options={formData.divisionList}
            placeholder="Division"
          />
          <MultiSelect
            name="filterType"
            control={control}
            options={formData.projectTypes}
            placeholder="Type"
          />
          <MultiSelect
            name="filterStatus"
            control={control}
            options={formData.projectStatus}
            placeholder="Status"
          />
          <Button
            type="submit"
            className="sub-button"
            text="Search"
            inline
            onClick={handleSubmit(onSubmit)}
          />
          <Button type="button" className="icon clear" onClick={onClear} />
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectListToolbar;

import {
  SELECT_WEEK,
  SAVE_TIME_ENTRIES,
  SAVE_HASHTAG,
  SAVE_DESCRIPTION,
  SAVE_DURATION,
  REMOVE_DURATION,
  DELETE_TIMESHEET_ROW,
} from '../constants/actionTypes';

export const selectWeek = (startDate, endDate) => ({
  type: SELECT_WEEK,
  startDate,
  endDate,
});

export const saveTimeEntries = (timeEntries) => ({
  type: SAVE_TIME_ENTRIES,
  timeEntries,
});

export const saveHashtag = (id, projectId, hashtag) => ({
  type: SAVE_HASHTAG,
  id,
  projectId,
  hashtag,
});

export const saveDescription = (id, description) => ({
  type: SAVE_DESCRIPTION,
  id,
  description,
});

export const saveDuration = (id, date, duration) => ({
  type: SAVE_DURATION,
  id,
  date,
  duration,
});

export const removeDuration = (id, date) => ({
  type: REMOVE_DURATION,
  id,
  date,
});

export const deleteTimesheetRow = (id) => ({
  type: DELETE_TIMESHEET_ROW,
  id,
});

import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { Button } from '../../Form';
import { DoubleCheckModal } from '../../Modal';
import {
  deleteTimesheetRow,
  saveTimeEntries,
} from '../../../actions/timesheet';
import { DELETE_TIMESHEET_ROW } from '../../../apollo/mutations';
import { GET_USER_TIMESHEET } from '../../../apollo/queries';
import useAuth from '../../../hooks/auth';
import useErrorHandler from '../../../hooks/errorHandler';
import { getListOfDateTime } from '../../../utils/datetime';
import { getEmptyEntry } from '../../../utils/tableData';
import { errorLocation } from '../../../constants/error';

const Cell = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .__react_component_tooltip {
    display: ${({ isError }) => (isError ? 'inline-block' : 'none')};
  }
`;

const TimesheetButtonCell = ({ row: { original } }) => {
  const { id, projectId, hashtag, description, entries } = original;
  const [show, setShow] = useState(false);
  const savedInDatabase = entries.some((entry) => !!entry.id);
  const {
    timesheet: { selectedWeek, timeEntries },
  } = useSelector((state) => state);
  const disabled = timeEntries.length === 1 && !timeEntries[0].projectId;
  const dispatch = useDispatch();
  const { getAuthUser } = useAuth();
  const { id: userId } = getAuthUser();
  const { handleAPIError, clearErrorState } = useErrorHandler();
  const [deleteRow, { loading: deleteLoading }] = useMutation(
    DELETE_TIMESHEET_ROW,
    {
      refetchQueries: [
        {
          query: GET_USER_TIMESHEET,
          variables: {
            id: userId,
            assignedProjects: {
              startDate: selectedWeek.startDate,
              endDate: selectedWeek.endDate,
            },
          },
        },
      ],
    },
  );

  const { error } = useSelector((state) => state);
  const tooltipRef = useRef(null);
  const isError = useMemo(
    () =>
      error &&
      error.location === errorLocation.TIMESHEET_DELETE &&
      error.args === id,
    [error],
  );

  useEffect(() => {
    if (isError) {
      ReactTooltip.show(tooltipRef.current);
    }
  }, [isError]);

  const deleteRowFromState = () => {
    if (timeEntries.length === 1) {
      dispatch(deleteTimesheetRow(id));
      const days = getListOfDateTime(
        selectedWeek.startDate,
        selectedWeek.endDate,
        'yyyy-MM-dd',
        'days',
      );
      const initialEntry = getEmptyEntry(days);
      dispatch(saveTimeEntries(initialEntry));
    } else {
      dispatch(deleteTimesheetRow(id));
    }
  };

  const deleteRowFromDatabase = async () => {
    const ids = entries.filter((entry) => !!entry.id).map((entry) => entry.id);
    if (ids.length) {
      try {
        const response = await deleteRow({
          variables: {
            ids,
          },
        });

        if (response && response.data && response.data.deleteTimeEntriesByIds) {
          deleteRowFromState();
          setShow(false);
        }
      } catch (err) {
        handleAPIError(err, errorLocation.TIMESHEET_DELETE, { args: id });
      }
    } else {
      dispatch(deleteTimesheetRow(id));
    }
  };

  const copyRow = () => {
    const currentIndex = timeEntries.findIndex((row) => row.id === id);
    const copiedRow = [
      {
        projectId,
        hashtag,
        description: '',
        entries: [],
      },
    ];
    const prevPart = timeEntries.slice(0, currentIndex + 1);
    const nextPart = timeEntries.slice(currentIndex + 1);
    const newTimeEntries = prevPart
      .concat(copiedRow)
      .concat(nextPart)
      .map((row, index) => {
        row.id = index;
        return row;
      });
    dispatch(saveTimeEntries(newTimeEntries));
  };

  return (
    <Cell disabled={!projectId} isError={isError}>
      <Button className="icon copy" title="copy" onClick={copyRow} />
      <>
        <span
          ref={tooltipRef}
          data-tip={error && error.message}
          data-for={`button-cell-${id}`}
          data-event="none"
        >
          <Button
            className="icon remove"
            title="delete"
            disabled={disabled}
            onClick={() => {
              clearErrorState();

              if (disabled) {
                return;
              }

              if (savedInDatabase) {
                setShow(true);
              } else {
                deleteRowFromState();
              }
            }}
          />
        </span>
        <ReactTooltip id={`button-cell-${id}`} />
        <DoubleCheckModal
          show={show}
          title={`${hashtag} ${description}`}
          text="This row has been saved in the database. Are you sure you want to delete it?"
          loading={deleteLoading}
          error={
            error && error.location === errorLocation.TIMESHEET_DELETE
              ? error
              : null
          }
          onClick={deleteRowFromDatabase}
          handleClose={() => setShow(false)}
        />
      </>
    </Cell>
  );
};

export default TimesheetButtonCell;

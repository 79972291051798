import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation tokenLogin($token: String!) {
    tokenLogin(token: $token) {
      accessToken
      user {
        id
        name
        role
        unit
        team
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject($project: ProjectInput!) {
    createProject(project: $project) {
      id
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject($project: ProjectInput!) {
    updateProject(project: $project) {
      id
      projectType
      unit
      name
      hashtag
      startDate
      endDate
      budgetAmount
      currencyCode
      contractUrl
      dayForcast
      isOnBasecamp
      Client {
        id
        name
      }
      Owner {
        id
        name
        email
      }
      Reviewer {
        id
        name
        email
      }
      LastModifiedBy {
        id
        name
      }
      comments
      status
      Team {
        id
        name
        email
        ContractDays {
          id
          userId
          projectId
          days
          monthYear
        }
      }
      createdAt
      updatedAt
      StatusHistory {
        status
        updatedAt
      }
    }
  }
`;

export const ADD_MEMBER = gql`
  mutation($projectId: Int!, $userId: Int!, $projectRole: ProjectRole!) {
    addUserToProject(
      projectId: $projectId
      userId: $userId
      projectRole: $projectRole
    )
  }
`;

export const REMOVE_MEMBER = gql`
  mutation($projectId: Int!, $userId: Int!) {
    removeUserFromProject(projectId: $projectId, userId: $userId)
  }
`;

export const ASSIGN_MEMBERS = gql`
  mutation($projectUsers: [ProjectUserInput!]!) {
    addUsersToProject(projectUsers: $projectUsers)
  }
`;

export const REMOVE_MEMBERS = gql`
  mutation($projectId: Int!, $userIds: [Int!]!) {
    removeUsersFromProject(projectId: $projectId, userIds: $userIds)
  }
`;

export const ADD_OR_UPDATE_CONTRACT_DAYS = gql`
  mutation($contracts: [ContractDayInput!]!) {
    addOrUpdateContractDays(contracts: $contracts) {
      id
      userId
      projectId
      monthYear
      days
    }
  }
`;

export const ADD_OR_UPDATE_SPENT_DAYS = gql`
  mutation($spentDays: [SpentDayInput!]!) {
    addOrUpdateSpentDays(spentDays: $spentDays) {
      id
      userId
      projectId
      days
    }
  }
`;

export const REQUEST_PROJECT_APPROVAL = gql`
  mutation($projectId: Int!, $reviewerId: Int!) {
    requestProjectApproval(projectId: $projectId, reviewerId: $reviewerId)
  }
`;

export const APPROVE_PROJECT = gql`
  mutation($projectId: Int!) {
    approveProject(projectId: $projectId)
  }
`;

export const DELETE_PROJECT = gql`
  mutation($projectId: Int!) {
    deleteProject(projectId: $projectId)
  }
`;

export const NOTIFY_PROJECT_UPDATED = gql`
  mutation($userIds: [Int!]!, $projectId: Int!, $comment: String) {
    notifyProjectUpdated(
      userIds: $userIds
      projectId: $projectId
      comment: $comment
    )
  }
`;

export const CREATE_CLIENT = gql`
  mutation($name: String!) {
    createClient(name: $name) {
      id
      name
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation($id: Int!) {
    deleteClient(id: $id)
  }
`;

export const UPDATE_CLIENT = gql`
  mutation($client: ClientInput!) {
    updateClient(client: $client) {
      id
      name
    }
  }
`;

export const ADD_OR_UPDATE_TIME = gql`
  mutation($entries: [TimeEntryInput!]!) {
    addOrUpdateTimeEntries(entries: $entries) {
      id
      userId
      projectId
      description
      date
      duration
    }
  }
`;

export const DELETE_TIME_ENTRIES = gql`
  mutation($startDate: DateTime!, $endDate: DateTime!) {
    deleteTimeEntries(startDate: $startDate, endDate: $endDate)
  }
`;

export const DELETE_TIMESHEET_ROW = gql`
  mutation($ids: [Int!]!) {
    deleteTimeEntriesByIds(ids: $ids)
  }
`;

export const SUBMIT_TIMESHEET = gql`
  mutation {
    submitTimesheet
  }
`;

export const APPROVE_TIMESHEET = gql`
  mutation {
    approveTimesheet
  }
`;

export const UPDATE_USER_LEAVEDATE = gql`
  mutation(
    $userId: Int!
    $userLeaveDate: String
    $newLongTermLeaveStartDate: String
    $newLongTermLeaveEndDate: String
    $newLongTermLeaveNotes: String
  ) {
    updateUserLeaveDate(
      userId: $userId
      userLeaveDate: $userLeaveDate
      newLongTermLeaveStartDate: $newLongTermLeaveStartDate
      newLongTermLeaveEndDate: $newLongTermLeaveEndDate
      newLongTermLeaveNotes: $newLongTermLeaveNotes
    ) {
      id
      leaveDate
      LongTermLeaves {
        id
        startDate
        endDate
        notes
      }
    }
  }
`;

export const UPDATE_USER_LONGTERM_LEAVEDATES = gql`
  mutation($longTermLeaveDataToUpdate: [LongTermLeaveToUpdateInput!]!) {
    updateUserLongTermLeaveDates(
      longTermLeaveDataToUpdate: $longTermLeaveDataToUpdate
    ) {
      id
      leaveDate
      LongTermLeaves {
        id
        startDate
        endDate
        notes
      }
    }
  }
`;

export const UPDATE_USER_DATA = gql`
  mutation($userId: Int!, $userTeam: String!, $userUnit: String!) {
    updateUserTeamUnit(
      userId: $userId
      userTeam: $userTeam
      userUnit: $userUnit
    ) {
      id
      team
      unit
    }
  }
`;

export const UPDATE_USER_INTERSHIP = gql`
  mutation($userInternshipInput: UserInternshipPeriodInput!) {
    updateUserInternshipPeriod(userInternshipInput: $userInternshipInput) {
      userId
      startDate
      endDate
      notes
    }
  }
`;

export const DELETE_USER_INTERNSHIP = gql`
  mutation($userId: Int!) {
    deleteUserInternshipPeriod(userId: $userId)
  }
`;

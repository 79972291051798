import React from 'react';
import styled from 'styled-components/macro';
import useErrorHandler from '../../hooks/errorHandler';

const Select = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 78px;
  border: ${({ theme }) => `1px solid ${theme.colors.input_border}`};
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
  margin: 4px 0;
  padding: 4px 0;
  position: absolute;
  z-index: 99;
  left: ${({ left }) => left || 'auto'};
  overscroll-behavior: contain;
`;

const Option = styled.option`
  cursor: pointer;
  padding: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.dropdown_hover};
  }

  &.selected {
    color: white;
    background-color: ${({ theme }) => theme.colors.dropdown_selected};
  }
`;

const CustomSelect = ({
  options,
  selected,
  show,
  setShow,
  setDisplayedVal,
  left,
}) => {
  const { clearErrorState } = useErrorHandler();

  return (
    <Select show={show} left={left}>
      {options.map((option) => (
        <Option
          key={option.label}
          className={selected === option.label && 'selected'}
          value={option.label}
          onClick={(e) => {
            clearErrorState();

            const { value } = e.target;
            setShow(false);
            setDisplayedVal(value);
          }}
        >
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelect;

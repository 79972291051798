import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const Item = styled(Link)`
  display: block;
  text-decoration: none;
  padding: 5% 0 7% 10%;
  font-weight: 600;
  letter-spacing: 0.045em;
  color: white;
  opacity: 0.7;
  transition: color 0.15s linear;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.colors.asiance_red};
    text-decoration: none;
  }
  &.selected {
    color: white;
    opacity: 1;
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background: ${({ theme }) => theme.colors.asiance_red};
      float: left;
      position: absolute;
      margin-top: 12px;
      margin-left: 40px;
      animation: showSelected linear 0.5s;
      animation-iteration-count: 1;
    }
  }
  &.submenu {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    text-transform: none;
    color: #5f676f;
    padding: 5% 10%;
    font-weight: 500;
    background-color: #1b2631;
    transition: all 0.5s ease;
    &:before {
      content: '•';
    }
    &.selected {
      color: white;
      opacity: 1;
      &:before {
        content: '•';
        color: ${({ theme }) => theme.colors.asiance_red};
      }
      &:after {
        content: '';
        width: 0%;
        height: 0px;
      }
    }
  }
  @keyframes showSelected {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`;

const MenuItem = ({ path, name, className, onClick }) => {
  return (
    <Item to={path} key={name} className={className} onClick={onClick}>
      {name}
    </Item>
  );
};

export default MenuItem;

import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import jwtDecode from 'jwt-decode';
import { getAccessToken, setAccessToken } from '../utils/accessToken';
import config from '../.config';

const cache = new InMemoryCache({});

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: () => {
    const token = getAccessToken();

    if (!token) {
      return true;
    }

    try {
      const { exp } = jwtDecode(token);
      if (Date.now() >= exp * 1000) {
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  },
  fetchAccessToken: () => {
    return fetch(`${config.API_URL}/refresh_token`, {
      method: 'POST',
      credentials: 'include',
    });
  },
  handleFetch: (accessToken) => {
    setAccessToken(accessToken);
  },
  handleError: (err) => {
    console.warn('Your refresh token is invalid. Try to relogin');
    console.error(err);
    // user.logout();
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // sendToLoggingService(graphQLErrors);
    graphQLErrors.map(({ extensions, message }) =>
      console.log(
        `[GraphQL error] Code: ${extensions.code}, Message: ${message}`,
      ),
    );
  }
  if (networkError) {
    // logoutUser();
    // redirect to /network-error
    console.log(`[Network error]: ${networkError}`);
  }
});

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => {
          const accessToken = getAccessToken();
          if (accessToken) {
            oper.setContext({
              headers: { authorization: `Bearer ${accessToken}` },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const httpLink = new HttpLink({
  uri: `${config.API_URL}/graphql`,
  credentials: 'include',
});

const client = new ApolloClient({
  link: ApolloLink.from([tokenRefreshLink, errorLink, requestLink, httpLink]),
  cache,
});

export default client;

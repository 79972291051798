import React from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAuth from '../../hooks/auth';
import logo from '../../assets/images/asiance-logo.svg';

const Container = styled.div`
  display: flex;
  background-color: white;
  .logo-wrapper {
    padding-top: 12px;
    padding-bottom: 24px;
    padding-left: 36px;
    padding-right: 36px;
    img {
      width: 140px;
    }
  }
  .header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: ${({ sidebar, theme }) =>
      sidebar ? theme.mixins.getvw('80') : '0'};
    padding-right: 60px;
    h1 {
      font-weight: 600;
      font-size: 2.5rem;
      margin: 0;
    }
  }
  .profile {
    font-family: Open Sans;
    padding: 18px 0;
    background: none;
    font-weight: 450;
    display: flex;
    align-items: center;
    button {
      background: none;
      border: none;
      margin-right: 10px;
    }
    p {
      margin-right: 10px;
      margin-bottom: 0;
    }
    .logoutbtn {
      cursor: pointer;
      opacity: 0.4;
    }
    .avatar {
      border-radius: 50%;
      width: 48px;
      height: 48px;
    }
  }
`;

const Header = ({ history, title }) => {
  const { logoutUser, getAuthUser } = useAuth(history);
  const { name, imageUrl } = getAuthUser();
  const { sidebar } = useSelector((state) => state.layout);

  return (
    <Container sidebar={sidebar}>
      {!sidebar && (
        <div className="logo-wrapper">
          <img src={logo} alt="Asiance" />
        </div>
      )}
      <div className="header-wrapper">
        <h1>{title}</h1>
        <div className="profile">
          <button type="button" onClick={logoutUser} className="logoutbtn">
            Log out
          </button>
          <p>|</p>
          <p>{name}</p>
          <img src={imageUrl} alt={name} className="avatar" />
        </div>
      </div>
    </Container>
  );
};

export default withRouter(Header);

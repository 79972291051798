import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { stringifyDate } from '../../utils/datetime';

const useFormInput = (inputs) => {
  const [isInputChanged, setIsInputChanged] = useState(false);
  const {
    project: { data: project },
  } = useSelector((state) => state);

  useEffect(() => {
    if (
      inputs.type &&
      project.type &&
      (inputs.type.value !== project.type.value ||
        inputs.division.length !== project.division.length ||
        !(
          inputs.division.length === project.division.length &&
          inputs.division.every((d) => project.division.includes(d)) &&
          project.division.every((d) => inputs.division.includes(d))
        ) ||
        inputs.name !== project.name ||
        inputs.hashtag !== project.hashtag ||
        (inputs.startDate ? stringifyDate(inputs.startDate) : null) !==
          (project.startDate ? stringifyDate(project.startDate) : null) ||
        (inputs.endDate ? stringifyDate(inputs.endDate) : null) !==
          (project.endDate ? stringifyDate(project.endDate) : null) ||
        (inputs.contractDays &&
          parseFloat(inputs.contractDays) !== project.contractDays) ||
        (inputs.client && inputs.client.value !== project.client.value) ||
        (inputs.budget && inputs.budget !== project.budget) ||
        (inputs.currency && inputs.currency.value !== project.currency.value) ||
        (inputs.basecamp && inputs.basecamp !== project.basecamp) ||
        (inputs.contractUrl && inputs.contractUrl !== project.contractUrl) ||
        inputs.comments !== project.comments)
    ) {
      setIsInputChanged(true);
    } else {
      setIsInputChanged(false);
    }
  }, [inputs]);

  return {
    isInputChanged,
  };
};

export default useFormInput;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Header from './Header';
import Sidebar from './Sidebar';
import ToggleButton from './ToggleButton';
import Main from './Main';
import Menu from './Menu';
import { toggleSidebar } from '../../actions/layout';
import routes from '../../routes';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Layout = ({ children, match }) => {
  const { sidebar, title } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const currentPage = routes.find((item) => item.path === match.path);
  const headerTitle = currentPage.title || title;

  return (
    <>
      {!sidebar && <Header title={headerTitle} />}
      <Container>
        <Sidebar minwidth={sidebar ? '220' : '0'} width={sidebar ? '272' : '0'}>
          {sidebar && <Menu />}
        </Sidebar>
        <ToggleButton
          direction={sidebar ? '1' : '-1'}
          className="toggle"
          type="button"
          onClick={() => dispatch(toggleSidebar())}
        >
          ‹
        </ToggleButton>
        <Main title={headerTitle}>{children}</Main>
      </Container>
    </>
  );
};

export default Layout;

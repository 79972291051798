import { createStore } from 'redux';
import { persistStore } from 'redux-persist';
import persistedReducer from '../reducers';

const store = createStore(
  persistedReducer,
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
const persistor = persistStore(store);

export { store, persistor };

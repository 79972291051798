import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Row, Col } from 'react-bootstrap';
import {
  GET_FORM_DATA,
  GET_PROJECTS,
  GET_REVIEWERS,
} from '../../apollo/queries';
import {
  CREATE_PROJECT,
  UPDATE_PROJECT,
  ADD_OR_UPDATE_CONTRACT_DAYS,
  ADD_OR_UPDATE_SPENT_DAYS,
  REQUEST_PROJECT_APPROVAL,
  ASSIGN_MEMBERS,
  REMOVE_MEMBERS,
} from '../../apollo/mutations';
import {
  Subtitle,
  Button,
  Error,
  ProjectInfo,
  ContractDays,
  PageLoader,
  InlineLoader,
  ErrorPage,
  SelectGroup,
} from '../../components';
import {
  saveProject,
  saveProjectId,
  changeStep,
  updateDateRange,
  resetProject,
  clearMembersToDelete,
  saveMembers,
} from '../../actions/project';
import {
  formatFormData,
  mapFormDataToProjectInput,
  mapMemberStateToProjectUserInput,
  mapMemberStateToContractInput,
  mapMemberStateToSpentDayInput,
  addUpContractDays,
  mapContractDaysToMemberState,
  mapSpentDaysToMemberState,
} from '../../utils/formData';
import { Form } from './styled';
import { getListOfDateTime, stringifyDate } from '../../utils/datetime';
import { errorLocation, errorMessage } from '../../constants/error';
import { projectType, userRoles, fakeUserList } from '../../constants/data';
import useAuth from '../../hooks/auth';
import useErrorHandler from '../../hooks/errorHandler';

const CreateProject = ({ history }) => {
  const dispatch = useDispatch();
  const {
    error,
    project: { id: projectId, data: project, step, members, membersToDelete },
  } = useSelector((state) => state);
  const { getAuthUser } = useAuth();
  const { id: userId, unit } = getAuthUser();
  const {
    handleAPIError,
    handleValidationError,
    clearErrorState,
  } = useErrorHandler();

  const [type, setType] = useState(null);
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    triggerValidation,
    setError,
    errors,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({ defaultValues: project });
  const totalContractDays = watch('contractDays');

  useEffect(() => {
    if (step === 0) {
      dispatch(resetProject());
    }

    return () => {
      dispatch(resetProject());
    };
  }, []);

  useEffect(() => {
    if (type) {
      reset({ ...project, type: { value: type, label: type } });
    } else {
      reset(project);
    }
  }, [type, project, step, members]);

  useEffect(() => {
    clearErrorState();
    if (step !== 2) {
      setSaved(false);
    }
  }, [step]);

  useEffect(() => {
    if (error && error.args === 'hashtag') {
      setError('hashtag', '', error.message);
    }
  }, [error]);

  useEffect(() => {
    setSaved(false);
  }, [members]);

  const [createProject, { loading: createLoading }] = useMutation(
    CREATE_PROJECT,
  );
  const [updateProject, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT,
  );
  const [assignMembers] = useMutation(ASSIGN_MEMBERS, {
    refetchQueries: [{ query: GET_PROJECTS }],
  });
  const [removeMembers] = useMutation(REMOVE_MEMBERS, {
    refetchQueries: [{ query: GET_PROJECTS }],
  });
  const [addOrUpdateContractDays] = useMutation(ADD_OR_UPDATE_CONTRACT_DAYS, {
    refetchQueries: [{ query: GET_PROJECTS }],
  });
  const [addOrUpdateSpentDays] = useMutation(ADD_OR_UPDATE_SPENT_DAYS, {
    refetchQueries: [{ query: GET_PROJECTS }],
  });
  const [requestProjectApproval] = useMutation(REQUEST_PROJECT_APPROVAL, {
    refetchQueries: [{ query: GET_PROJECTS }],
  });
  const { loading: formLoading, data: formData, error: formError } = useQuery(
    GET_FORM_DATA,
  );
  const {
    loading: reviewerLoading,
    data: reviewerData,
    error: reviewerError,
  } = useQuery(GET_REVIEWERS, {
    variables: {
      filter: {
        unit:
          unit && project.division.indexOf(unit) === -1
            ? [...project.division, unit]
            : project.division,
        role: [userRoles.ADMIN, userRoles.UNIT_LEADER, userRoles.TEAM_LEADER],
      },
    },
  });

  if (formLoading || reviewerLoading) return <PageLoader />;
  if (formError || reviewerError)
    return <ErrorPage text={errorMessage.UNEXPECTED} />;

  const formattedFormData = formatFormData(formData.getFormData);
  const reviewerList =
    reviewerData &&
    reviewerData.getUsers
      .filter((user) => !user.leaveDate)
      .filter((user) => user.id !== userId)
      .filter((user) => !fakeUserList.includes(user.email))
      .map((user) => ({
        value: user.id,
        label: `${user.name}\n<${user.email}>`,
      }));

  const onSubmitNext = async (formInputData) => {
    triggerValidation('type');
    const [typeOption] = formattedFormData.projectTypes.filter(
      (option) => option.value === type,
    );
    formInputData.type = typeOption;
    formInputData.contractDays =
      formInputData.contractDays && parseFloat(formInputData.contractDays);
    const months = getListOfDateTime(
      formInputData.startDate,
      formInputData.endDate,
      'yyyyMM',
      'months',
    );
    formInputData.months = months;
    dispatch(saveProject(formInputData));
    const projectInput = mapFormDataToProjectInput(formInputData, userId);

    if (step === 0) {
      try {
        const response = await createProject({
          variables: {
            project: projectInput,
          },
        });

        if (response && response.data) {
          const { id } = response.data.createProject;
          dispatch(saveProjectId(id));
          dispatch(changeStep(2));
        }
      } catch (err) {
        handleAPIError(err, errorLocation.CREATE_PROJECT);
      }
    } else {
      projectInput.id = projectId;

      try {
        const response = await updateProject({
          variables: {
            project: projectInput,
          },
        });

        if (response && response.data) {
          dispatch(updateDateRange(months));
          dispatch(changeStep(2));
        }
      } catch (err) {
        handleAPIError(err, errorLocation.CREATE_PROJECT);
      }
    }
  };

  const removeMembersFromProject = async () => {
    if (membersToDelete.length) {
      const response = await removeMembers({
        variables: {
          projectId,
          userIds: membersToDelete,
        },
      });

      if (response && response.data && response.data.removeUsersFromProject) {
        dispatch(clearMembersToDelete());
      }
    }
  };

  const updateMembers = async () => {
    removeMembersFromProject();

    const projectUsers = mapMemberStateToProjectUserInput(projectId, members);
    const response = await assignMembers({
      variables: {
        projectUsers,
      },
    });

    return response;
  };

  const saveSpentDays = async (updatedMembers) => {
    const spentDays = mapMemberStateToSpentDayInput(projectId, members);

    if (spentDays.length) {
      const spentResponse = await addOrUpdateSpentDays({
        variables: {
          spentDays,
        },
      });

      if (spentResponse && spentResponse.data) {
        const updatedSpentDays = spentResponse.data.addOrUpdateSpentDays;
        const finalMembersData = mapSpentDaysToMemberState(
          updatedMembers,
          updatedSpentDays,
        );
        dispatch(saveMembers(finalMembersData));
      }
    }
    setLoading(null);
    setSaved(true);
  };

  const saveContractDays = async () => {
    const contracts = mapMemberStateToContractInput(projectId, members);

    const response = await addOrUpdateContractDays({
      variables: {
        contracts,
      },
    });

    if (response && response.data) {
      const updatedContractDays = response.data.addOrUpdateContractDays;
      const updatedMembers = mapContractDaysToMemberState(
        members,
        updatedContractDays,
      );
      // dispatch(saveMembers(updatedMembers));
      saveSpentDays(updatedMembers);
    }
  };

  const onSaveAsDraft = async () => {
    setLoading('save');
    clearErrorState();

    const totalDays = parseFloat(addUpContractDays(members).toFixed(3));
    if (!totalDays && !members.length) {
      setLoading(null);
      setSaved(true);
      return;
    }

    try {
      if (type === projectType.INTERNAL && !totalContractDays && !totalDays) {
        updateMembers();
        saveSpentDays(members);
        return;
      }

      const result = await updateMembers();
      if (result && result.data && result.data.addUsersToProject) {
        saveContractDays();
      }
    } catch (err) {
      handleAPIError(err, errorLocation.CREATE_PROJECT);
    }
  };

  const validateContractDays = (totalDays) => {
    if (!members.length) {
      handleValidationError(errorLocation.CREATE_PROJECT, {
        message: errorMessage.CONTRACT_EMPTY,
      });
      return false;
    }

    if (totalContractDays && totalDays !== parseFloat(totalContractDays)) {
      handleValidationError(errorLocation.CREATE_PROJECT, {
        message: errorMessage.TOTAL_DAYS_NOT_MATCH,
      });
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    setLoading('submit');
    clearErrorState();

    const totalDays = addUpContractDays(members);
    const validated = validateContractDays(totalDays);
    if (!validated) {
      setLoading(null);
      return;
    }

    if (!getValues('approver')) {
      handleValidationError(errorLocation.SUBMIT_PROJECT, {
        message: errorMessage.APPROVER_REQUIRED,
      });
      setLoading(null);
      return;
    }

    const reviewerId = getValues('approver').value;

    try {
      if (!saved) {
        if (
          !(
            type === projectType.INTERNAL &&
            members.length &&
            !totalContractDays &&
            !totalDays
          )
        ) {
          const result = await updateMembers();
          if (result && result.data && result.data.addUsersToProject) {
            saveContractDays();
          }
        }
      }

      const response = await requestProjectApproval({
        variables: {
          projectId,
          reviewerId,
        },
      });

      if (response && response.data && response.data.requestProjectApproval) {
        history.push('/project-submitted');
        setTimeout(() => {
          dispatch(resetProject());
          setLoading(null);
          setSaved(false);
        }, 1000);
      }
    } catch (err) {
      handleAPIError(err, errorLocation.SUBMIT_PROJECT);
    }
  };

  return (
    <>
      {(step === 0 || step === 1) && (
        <>
          <Subtitle>Project Information</Subtitle>
          <div>
            Dear all, <br />
            Here is a form to help us gather all the information on each new
            project. <br />
            Please, fill it in every time you win a project so we can create the
            right hashtag and allow you to work in the best conditions!
            <br />
            <br />
            If you have any question, do not hesitate to see directly with
            Gerard. Thanks for your help!
          </div>
          <Row>
            <Form>
              <form>
                <ProjectInfo
                  type={type}
                  setType={setType}
                  defaultChecked={project.division}
                  formData={formattedFormData}
                  control={control}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  editable
                />
                {error &&
                  error.location === errorLocation.CREATE_PROJECT &&
                  !error.args && <Error>{error.message}</Error>}
                <Button
                  type="button"
                  className="nextbtn"
                  loading={createLoading || updateLoading}
                  onClick={handleSubmit(onSubmitNext)}
                >
                  {createLoading || updateLoading ? (
                    <InlineLoader loading={createLoading || updateLoading} />
                  ) : (
                    'Next'
                  )}
                </Button>
              </form>
            </Form>
          </Row>
        </>
      )}
      {step === 2 && (
        <>
          <Subtitle>Days Repartition</Subtitle>
          <div>
            This section will help forecast the number of days assigned per team
            member to this project. <br />
            Try to fill it in with every information you have so it can be
            accurate. If you don&apos;t have the information, <br />
            please indicate if the information will come soon or not, and if
            someone else than you might have them. <br />
            <br />
            Do not forget to validate the form at the end!
          </div>
          <Row>
            <Col xs="12">
              <Form>
                <ContractDays
                  defaultValues={project}
                  contractDays={members}
                  totalContractDays={totalContractDays}
                  formData={formattedFormData}
                  editable
                  startDate={stringifyDate(project.startDate)}
                />
                {error &&
                  error.location === errorLocation.CREATE_PROJECT &&
                  !error.args && <Error>{error.message}</Error>}
                <SelectGroup
                  className="teamleader-select"
                  name="approver"
                  label="Please select a team leader to validate the project."
                  control={control}
                  options={reviewerList}
                  placeholder="Select a team leader"
                  rules={{ required: 'This field is required.' }}
                  marginEven
                />
                {error && error.location === errorLocation.SUBMIT_PROJECT && (
                  <Error>{error.message}</Error>
                )}
                <div className="button-group">
                  <Button
                    className="prevbtn"
                    type="button"
                    text="Prev"
                    inline
                    onClick={() => dispatch(changeStep(1))}
                  />
                  <div className="save-button-group">
                    <Button
                      className="navybtn"
                      type="button"
                      data-cy="saveProject"
                      disabled={saved}
                      loading={loading === 'save'}
                      inline
                      onClick={onSaveAsDraft}
                    >
                      {loading === 'save' ? (
                        <InlineLoader loading={loading === 'save'} />
                      ) : (
                        (saved && 'Saved!') || (!saved && 'Save as draft')
                      )}
                    </Button>
                    <Button
                      className="redbtn"
                      type="button"
                      data-cy="requestApproval"
                      loading={loading === 'submit'}
                      inline
                      onClick={onSubmit}
                    >
                      {loading === 'submit' ? (
                        <InlineLoader
                          loading={loading === 'submit'}
                          color="red"
                        />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CreateProject;

import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormGroup,
  InlineSelect,
  Button,
  Label,
  Error,
  ReactTable,
  BoldText,
} from '../index';
import { saveMember } from '../../actions/project';
import {
  getContractDaysColumns,
  mapMemberStateToTableData,
} from '../../utils/tableData';
import { errorLocation } from '../../constants/error';

const Container = styled.form`
  label {
    margin-top: 0;
  }
  .prepend {
    color: ${({ theme }) => theme.colors.bluish_gray};
    font-size: 1.875rem;
    display: inline-block;
    vertical-align: middle;
    padding-right: 10px;
    position: absolute;
    left: 15px;
  }
  .small-select {
    > div {
      padding: 2px 4px;
    }
  }
`;

const ContractDays = ({
  defaultValues,
  contractDays,
  totalContractDays,
  formData,
  editable,
  status,
  startDate,
}) => {
  const { roleList, userList } = formData;
  const dispatch = useDispatch();
  const { data: project, members } = useSelector((state) => state.project);
  const { error } = useSelector((state) => state);
  const contractData = !editable ? contractDays : members;
  const months = !editable ? defaultValues.months : project.months;
  const columns = useMemo(() => getContractDaysColumns(months, startDate), [
    months,
  ]);
  const tableData = useMemo(
    () =>
      mapMemberStateToTableData(
        contractData,
        totalContractDays,
        editable,
        status,
      ),
    [contractData, totalContractDays, editable, status],
  );

  const { control, handleSubmit, errors, reset } = useForm();

  useEffect(() => {
    if (
      editable ||
      parseFloat(totalContractDays) !== parseFloat(project.contractDays)
    ) {
      tableData.current = mapMemberStateToTableData(
        contractData,
        parseFloat(totalContractDays),
        editable,
        status,
      );
    }
  }, [totalContractDays, editable, contractData]);

  const addMemberOnClick = (inputValue) => {
    const { user, role } = inputValue;
    dispatch(saveMember(user, role.label, months));
    reset({ ...project, user: '', role: '' });
  };

  return (
    <Container>
      {totalContractDays ? (
        <>
          <Label className="firstlabel">
            Contract Total Days: <BoldText>{totalContractDays} </BoldText>Days
          </Label>
        </>
      ) : (
        ''
      )}
      {editable && (
        <Label>
          Who will be working on the project? How many days are forecast for
          each of them and per month? *
        </Label>
      )}
      {editable && (
        <FormGroup>
          <Row className="justify-content-sm-between justify-content-md-start">
            <Col xs="8" lg="4" xl="3" className="mb-3 mb-lg-0">
              <InlineSelect
                className="small-select"
                name="user"
                control={control}
                options={userList
                  .filter(
                    (item) =>
                      !members.map((member) => member.id).includes(item.value),
                  )
                  .sort((a, b) => a.label.localeCompare(b.label))}
                placeholder="Select Assignee"
                rules={{ required: 'You should select assignee.' }}
              />
            </Col>
            <Col xs="8" lg="4" xl="3">
              <InlineSelect
                className="small-select"
                name="role"
                control={control}
                options={roleList}
                placeholder="Select Role"
                rules={{ required: 'You should select role.' }}
              />
            </Col>
            <Button
              className="sm-navybtn"
              type="button"
              data-cy="addUser"
              text="Add"
              inline
              onClick={handleSubmit(addMemberOnClick)}
            />
            {errors.user && (
              <Error padding="0 15px">{errors.user.message}</Error>
            )}
            {!errors.user && errors.role && (
              <Error padding="0 15px">{errors.role.message}</Error>
            )}
            {error && error.location === errorLocation.CONTRACT_USER && (
              <Error padding="0 15px">{error.message}</Error>
            )}
          </Row>
        </FormGroup>
      )}
      <ReactTable columns={columns} data={tableData} sticky footer blank />
      {error && error.location === errorLocation.CONTRACT_DAYS && (
        <Error>{error.message}</Error>
      )}
    </Container>
  );
};

export default ContractDays;

import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { theme } from './theme';
import icomoonEot from '../assets/fonts/icomoon.eot';
import icomoonWoff from '../assets/fonts/icomoon.woff';
import icomoonSvg from '../assets/fonts/icomoon.svg';
import icomoonTtf from '../assets/fonts/icomoon.ttf';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
    margin:0;
    padding: 0;
  }

  html, body {
    width: 100%;
    height: 100%;
    font-family: 'futura-pt', 'Open sans';
    font-weight: 400;
    color: ${theme.colors.bluish_dark_gray};
    font-size:15px;
  }

  div#root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @import url('https://use.typekit.net/tph4shv.css');

  @font-face {
    font-family: 'icomoon';
    src:  url(${icomoonEot});
    src:  url(${icomoonEot}) format('embedded-opentype'),
      url(${icomoonTtf}) format('truetype'),
      url(${icomoonWoff}) format('woff'),
      url(${icomoonSvg}) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-toggle-arrow:before {
    content: "\\e900";
    color: ${theme.colors.asiance_red};
  }

  .datePickWrap {
    display: flex;
    margin-bottom: 1rem;
  }

  .react-datepicker__day {
    &:hover {
      background-color: ${theme.colors.bluish_gray};
      color: #fff;
    }
  }

  .react-datepicker__day--selected {
    &:hover {
      background-color: ${theme.colors.datepicker_selected};
      color: #fff;
    }
  }

  .react-datepicker__day--highlighted {
    background-color: ${theme.colors.datepicker_selected};
    color: #fff;
  }

  .content{
    background-color: #fff;
    padding: ${theme.mixins.getvw('60')};
    @media screen and (max-width: 991.98px) {
    padding: 40px;
    }
  }

  .font-red {
    color: ${theme.colors.red};
  }

  .font-orange {
    color: ${theme.colors.orange};
  }

  .font-green {
    color: ${theme.colors.green};
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export default GlobalStyle;

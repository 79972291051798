import React from 'react';
import {
  startOfMonth,
  endOfMonth,
  differenceInMonths,
  getDate,
  sub,
} from 'date-fns';
import {
  InternalDaysCell,
  InfoHeader,
  ReportRecapForecastCell,
  ReportRecapSpentCell,
  ReportRecapAvgMMCell,
} from '../../components';
import {
  projectType as ProjectType,
  Unit,
  UnitTeam,
  UnitAll,
} from '../../constants/data';
import { mapReportDetailsPeopleToTableData } from '../ReportDetailPeople/reportDetailPeopleTableData';

export const getReportRecapColumns = () => [
  {
    Header: 'Unit/Team',
    accessor: 'division',
    width: '16%',
    extraStyles: {
      fontWeight: 'bold',
    },
  },
  {
    Header: InfoHeader,
    title: <>Client Contract Days Forecast</>,
    info: (
      <>
        <span className="font-red">Red Text</span>: If contract days spent &lt;
        ideal number contract days
      </>
    ),
    accessor: 'contractDays',
    width: '14%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ReportRecapForecastCell,
  },
  {
    Header: InfoHeader,
    title: <>Client Contract Days Spent</>,
    info: (
      <>
        <span className="font-red">Red Text</span>: If days spent &gt; forecast
        contract days
      </>
    ),
    accessor: 'realDays',
    width: '14%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ReportRecapSpentCell,
  },
  {
    Header: (
      <div style={{ flexDirection: 'column' }}>
        Internal Projects Days Spent
      </div>
    ),
    accessor: 'internalDaysSpent',
    width: '15%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ({ cell: { value } }) => {
      return <InternalDaysCell value={value} />;
    },
  },
  {
    Header: 'Ideal Number Contract Days',
    accessor: 'idealNumContractDays',
    width: '14%',
  },
  {
    Header: 'Average Contract Days Forecast',
    accessor: 'avgContractDaysForecast',
    width: '14%',
  },
  {
    Header: InfoHeader,
    title: 'Average Contract % of 1MM',
    info: (
      <>
        If the average contract days forecast per team and unit is
        <br />
        <span className="font-green">Green Text</span>: 70% or above
        <br />
        Black Text: Between 50% and 70%
        <span className="font-red">Red Text</span>: Below 50%
      </>
    ),
    accessor: 'avgContractOf1MM',
    width: '14%',
    Cell: ReportRecapAvgMMCell,
  },
  {
    Header: (
      <div style={{ flexDirection: 'column' }}>
        Total Days Spent
        <div style={{ fontSize: '0.8rem' }}>(Internal + Client)</div>
      </div>
    ),
    accessor: 'totalDays',
    width: '14%',
    extraStyles: {
      justifyContent: 'center',
    },
    Cell: ({ cell: { value } }) => {
      return <>{parseFloat(value).toFixed(2)}</>;
    },
  },
];

const combineTeams = (arr, teamOne, teamTwo) => {
  return arr.reduce((curr, acc) => {
    if (curr.length >= 1) {
      const oldTeam = curr.findIndex((team) => team.division === teamOne);
      const newTeam = curr.findIndex((team) => team.division === teamTwo);
      if (oldTeam >= 0 && acc.division === teamTwo) {
        const combined = { ...curr[oldTeam] };
        const keysArr = Object.keys(combined);

        keysArr.forEach((key) => {
          combined[key] = curr[oldTeam][key] + acc[key];
        });
        combined.division = teamTwo;

        combined.avgContractDaysForecast = parseFloat(
          parseInt(curr[oldTeam].avgContractDaysForecast, 10) +
            parseInt(acc.avgContractDaysForecast, 10),
        ).toFixed(2);

        combined.avgContractOf1MM = `${
          parseInt(curr[oldTeam].avgContractOf1MM.slice(0, -1), 10) +
          parseInt(acc.avgContractOf1MM.slice(0, -1), 10)
        }%`;
        curr.push(combined);
        curr.splice(oldTeam, 1);
        return [...curr];
      }

      if (newTeam >= 0 && acc.division === teamOne) {
        const combined = { ...curr[newTeam] };
        const keysArr = Object.keys(combined);

        keysArr.forEach((key) => {
          combined[key] = curr[newTeam][key] + acc[key];
        });
        combined.division = teamTwo;
        combined.avgContractDaysForecast = parseFloat(
          parseInt(curr[newTeam].avgContractDaysForecast, 10) +
            parseInt(acc.avgContractDaysForecast, 10),
        ).toFixed(2);
        combined.avgContractOf1MM = `${
          parseInt(curr[newTeam].avgContractOf1MM.slice(0, -1), 10) +
          parseInt(acc.avgContractOf1MM.slice(0, -1), 10)
        }%`;
        curr.push(combined);

        curr.splice(newTeam, 1);

        return [...curr];
      }

      if (oldTeam >= 0 && newTeam >= 0) {
        const combined = { ...curr[oldTeam] };
        const keysArr = Object.keys(combined);

        keysArr.forEach((key) => {
          combined[key] = curr[oldTeam][key] + curr[newTeam][key];
        });
        combined.division = teamTwo;
        combined.avgContractDaysForecast = parseFloat(
          parseInt(curr[newTeam].avgContractDaysForecast, 10) +
            parseInt(curr[oldTeam].avgContractDaysForecast, 10),
        ).toFixed(2);
        combined.avgContractOf1MM = `${
          parseInt(curr[newTeam].avgContractOf1MM.slice(0, -1), 10) +
          parseInt(curr[oldTeam].avgContractOf1MM.slice(0, -1), 10)
        }%`;

        curr.push(combined);

        curr.splice(oldTeam, 1);
        curr.splice(newTeam, 1);

        return [...curr, acc];
      }
    }
    return [...curr, acc];
  }, []);
};

export const extractAssignedProjects = (
  filterDate,
  member,
  division,
  isTeamTotal,
) => {
  const { id, AssignedProjects } = member;
  const AssignedProjectsFiltered = AssignedProjects.filter(
    (project) =>
      (project.ContractDays && project.ContractDays.length > 0) ||
      (project.TimeEntries && project.TimeEntries.length > 0),
  );

  let userStartDate = new Date(member.createdAt);
  if (getDate(new Date(member.createdAt)) < 15) {
    userStartDate = sub(new Date(member.createdAt), { months: 1 });
  }

  const endOfFilterMonth = endOfMonth(filterDate);
  const monthsAsNewcomer = differenceInMonths(endOfFilterMonth, userStartDate);

  const contractDayTotal = AssignedProjectsFiltered.filter(
    (project) =>
      project.projectType === ProjectType.CLIENT &&
      project.ContractDays &&
      project.ContractDays.length > 0,
  )
    .map((project) => project.ContractDays)
    .reduce((acc, curr) => {
      const userContractDays = curr.filter((obj) => obj.userId === id);
      let sum = acc;
      userContractDays.forEach((obj) => {
        sum = parseFloat(sum + obj.days);
      });

      return sum;
    }, 0);

  const timesheetDurationClient = AssignedProjectsFiltered.flatMap((project) =>
    project.projectType === ProjectType.CLIENT ? project.TimeEntries : [],
  )
    .filter((contract) => contract.userId === id)
    .reduce((sum, curr) => sum + curr.duration, 0);

  const timesheetDurationInternal = AssignedProjectsFiltered.flatMap(
    (project) =>
      project.projectType === ProjectType.INTERNAL ? project.TimeEntries : [],
  )
    .filter((project) => project.userId === id)
    .reduce((sum, curr) => sum + curr.duration, 0);

  let idealNumContractDays = 0;
  if (member.role === 'USER') {
    idealNumContractDays = 15;
    if (monthsAsNewcomer <= 3 && monthsAsNewcomer !== 0) {
      idealNumContractDays = 12;
    }
  }
  if (
    member.role === 'TEAM_LEADER' &&
    (member.unit === 'MKT' ||
      member.unit === 'MKTU1' ||
      member.unit === 'MKTU2')
  ) {
    idealNumContractDays = 8;
    if (monthsAsNewcomer <= 3 && monthsAsNewcomer !== 0) {
      idealNumContractDays = 5;
    }
  }
  if (
    member.role === 'TEAM_LEADER' &&
    (member.unit !== 'MKT' ||
      member.unit !== 'MKTU1' ||
      member.unit !== 'MKTU2')
  ) {
    idealNumContractDays = 10;
    if (monthsAsNewcomer <= 3) {
      idealNumContractDays = 7;
    }
  }
  if (monthsAsNewcomer === 0) {
    idealNumContractDays = 0;
  }

  if (member.role === 'UNIT_LEADER') {
    idealNumContractDays = 5;
    if (monthsAsNewcomer <= 3) {
      idealNumContractDays = 2;
    }
  }

  if (
    member.InternshipPeriod &&
    startOfMonth(new Date(member.InternshipPeriod.startDate)) <=
      endOfFilterMonth &&
    endOfMonth(new Date(member.InternshipPeriod.endDate)) >= endOfFilterMonth
  ) {
    idealNumContractDays = 0;
  }

  const memberTotal = {
    division,
    contractDays: contractDayTotal,
    contractDaysSpentMinutes: timesheetDurationClient,
    internalDaysSpentMinutes: timesheetDurationInternal,
    idealNumContractDays,
    isTeamTotal,
  };

  return memberTotal;
};

export const getGroupTotal = (team, teamMembers) => {
  const contractDays =
    teamMembers &&
    teamMembers.reduce((sum, curr) => sum + curr.contractDays, 0);

  const contractDaysSpentMinutes =
    teamMembers &&
    teamMembers.reduce((sum, curr) => sum + curr.contractDaysSpentMinutes, 0);

  const internalDaysSpentMinutes =
    teamMembers &&
    teamMembers.reduce((sum, curr) => sum + curr.internalDaysSpentMinutes, 0);

  const idealNumContractDays =
    teamMembers &&
    parseFloat(
      teamMembers.reduce((sum, curr) => sum + curr.idealNumContractDays, 0),
    );

  const avgContractDaysForecast =
    teamMembers && teamMembers.length > 0
      ? parseFloat(
          teamMembers.reduce((sum, curr) => sum + curr.contractDays, 0) /
            teamMembers.length,
        ).toFixed(2)
      : 0;

  const avgContractOf1MM =
    teamMembers && teamMembers.length > 0
      ? parseFloat(
          (teamMembers.reduce((sum, curr) => sum + curr.contractDays, 0) /
            teamMembers.length /
            21) *
            100,
        ).toFixed(2)
      : 0;

  const teamTotal = {
    division: ` - ${team}`,
    contractDays,
    realDays: Number((contractDaysSpentMinutes / 60 / 8).toFixed(2)),
    internalDaysSpent: Number((internalDaysSpentMinutes / 60 / 8).toFixed(2)),
    idealNumContractDays,
    avgContractDaysForecast,
    avgContractOf1MM: `${avgContractOf1MM}%`,
    totalDays:
      Number((internalDaysSpentMinutes / 60 / 8).toFixed(2)) +
      Number((contractDaysSpentMinutes / 60 / 8).toFixed(2)),
    isTeamTotal: true,
  };

  return teamTotal;
};

export const mapReportRecapToTableData = (
  filterDate,
  data,
  teams,
  unit,
  role,
) => {
  let rowsByUnits = teams.reduce((result, team) => {
    const teamMembers =
      data &&
      filterDate &&
      data
        .filter(
          (member) => member.team === team && member.role !== 'UNIT_LEADER',
        )
        .map((member) => {
          return extractAssignedProjects(
            filterDate,
            member,
            member.name,
            false,
          );
        });
    // end of team member

    const teamTotal = getGroupTotal(team, teamMembers);

    const rows = teamMembers && teamMembers.length > 0 ? [teamTotal] : [];
    return [...result, ...rows];
  }, []);
  // end of const rowsByUnits

  rowsByUnits = combineTeams(rowsByUnits, ' - CX1', ' - DSI');
  rowsByUnits = combineTeams(rowsByUnits, ' - CX2', ' - PM');

  // Get Unit Total here and add to top of rowsByUnits
  const total = rowsByUnits
    .filter((row) => row.isTeamTotal)
    .reduce(
      (obj, curr) => {
        obj.contractDays += curr.contractDays;
        obj.realDays += curr.realDays;
        obj.internalDaysSpent += curr.internalDaysSpent;
        obj.idealNumContractDays += curr.idealNumContractDays;
        obj.totalDays += curr.totalDays;
        return obj;
      },
      {
        division: UnitAll[unit],
        contractDays: 0.0,
        realDays: 0.0,
        idealNumContractDays: 0.0,
        internalDaysSpent: 0.0,
        totalDays: 0.0,
        isUnitTotal: true,
      },
    );

  const unitLeaderData =
    data &&
    data
      .filter((member) => {
        return (
          member.role === 'UNIT_LEADER' &&
          (member.unit === unit ||
            ((member.unit === 'MKTU2' || member.unit === 'MKTU1') &&
              unit === 'MKT'))
        );
      })
      .map((member) => {
        return extractAssignedProjects(
          filterDate,
          member,
          '- UNIT LEADER',
          true,
        );
      });

  const unitLeaderDataCombined = getGroupTotal('- UNIT LEADER', unitLeaderData);

  if (role !== 'TEAM_LEADER' && role !== 'USER') {
    if (data && data.length && unitLeaderDataCombined.length > 0) {
      unitLeaderDataCombined.forEach((row) => {
        rowsByUnits.unshift(row);
        total.contractDays = parseFloat(
          (total.contractDays += row.contractDays).toFixed(2),
        );
        total.realDays = parseFloat(
          (total.realDays += row.realDays).toFixed(2),
        );
        total.internalDaysSpent = parseFloat(
          (total.internalDaysSpent += row.internalDaysSpent).toFixed(2),
        );
        total.idealNumContractDays += row.idealNumContractDays;
        total.totalDays = parseFloat(
          (total.totalDays += row.totalDays),
        ).toFixed(2);
      });
    }
    rowsByUnits.unshift(total);
  }
  return rowsByUnits;
};

export const mapReportRecapToTableDataAdmin = (filterDate, data, user) => {
  const { unit } = user;
  let { teams } = user;

  let unitArr = Object.keys(unit).filter((unitName) => {
    if (
      filterDate < new Date('2022-03-01') ||
      filterDate >= new Date('2023-02-01')
    ) {
      return (
        unitName !== 'ASIANCE' && unitName !== 'MKTU1' && unitName !== 'MKTU2'
      );
    }
    if (filterDate > new Date('2022-03-01')) {
      return unitName !== 'ASIANCE' && unitName !== 'MKT';
    }
    return unitName !== 'ASIANCE';
  });

  if (
    (unit === 'MKTU1' || unit === 'MKTU2' || unit === 'MKT') &&
    filterDate >= new Date('2022-03-01') &&
    filterDate < new Date('2023-02-01')
  ) {
    unitArr = ['MKTU1', 'MKTU2'];
    teams = {
      MKTU1: ['MKT1', 'MKT2'],
      MKTU2: ['MKT3', 'MKT4'],
    };
  }

  const allUnits =
    data &&
    unitArr.flatMap((eachUnit) => {
      return mapReportRecapToTableData(
        filterDate,
        data,
        teams[eachUnit],
        eachUnit,
      );
    });

  if (data && allUnits.length > 0) {
    const isUnitTotal = allUnits.filter((row) => row.isUnitTotal);
    const allUnitsTotal = isUnitTotal.reduce(
      (obj, curr) => {
        obj.contractDays =
          parseFloat(obj.contractDays) + parseFloat(curr.contractDays);
        obj.realDays = parseFloat(obj.realDays) + parseFloat(curr.realDays);
        obj.internalDaysSpent =
          parseFloat(obj.internalDaysSpent) +
          parseFloat(curr.internalDaysSpent);
        obj.idealNumContractDays += curr.idealNumContractDays;
        return obj;
      },
      {
        division: 'Total',
        contractDays: 0,
        realDays: 0,
        internalDaysSpent: 0,
        idealNumContractDays: 0,
        allUnitsTotal: true,
      },
    );
    allUnitsTotal.contractDays = parseFloat(allUnitsTotal.contractDays).toFixed(
      2,
    );
    allUnitsTotal.realDays = parseFloat(allUnitsTotal.realDays);
    allUnitsTotal.internalDaysSpent = parseFloat(
      allUnitsTotal.internalDaysSpent,
    );
    allUnitsTotal.totalDays =
      parseFloat(allUnitsTotal.realDays) +
      parseFloat(allUnitsTotal.internalDaysSpent);
    allUnits.unshift(allUnitsTotal);

    return allUnits;
  }
  return [];
};

export const mapReportDetailsPeopleToTableDataForExport = (data, user) => {
  delete Unit.ASIANCE;

  return Object.keys(Unit).flatMap((unit) => {
    const newUser = user;
    newUser.teams = UnitTeam[unit];

    const tableData = mapReportDetailsPeopleToTableData(data, newUser, unit);
    tableData.unshift({
      division: unit,
    });
    return tableData;
  });
};

import React from 'react';
import styled from 'styled-components/macro';
import { format } from 'date-fns';

const ProjectInfo = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: disc;
  margin: 0;
  @media screen and (max-width: 1199.98px) {
    flex-direction: column;
  }
`;
const TextGroup = styled.li`
  margin: 10px 0;
  font-size: 1.125rem;
  width: 50%;
  padding-right: 20px;
  @media screen and (max-width: 1199.98px) {
    display: block;
    width: 100%;
  }
`;

const InfoWrap = styled.div`
  display: flex;
  @media screen and (max-width: 1199.98px) {
    display: block;
  }
`;

const Title = styled.span`
  font-weight: 700;
  display: inline-block;
  width: 45%;
  @media screen and (max-width: 1199.98px) {
    width: 100%;
  }
`;

const Content = styled.span`
  word-wrap: break-word;
  width: 50%;
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'none')};
`;

const Container = styled.div`
  background: white;
  padding: 60px;
  margin: 40px 0 60px;
`;

const Info = ({ title, capitalize, children }) => (
  <TextGroup>
    <InfoWrap>
      <Title>{title}</Title>
      <Content capitalize={capitalize}>{children}</Content>
    </InfoWrap>
  </TextGroup>
);

const ProjectInfoText = ({ defaultValues }) => {
  const {
    type,
    client,
    division,
    name,
    hashtag,
    budget,
    currency,
    startDate,
    endDate,
    contractDays,
    contractUrl,
    basecamp,
    comments,
  } = defaultValues;
  return (
    <Container>
      <ProjectInfo>
        <Info title="Project Type">{type.label}</Info>
        {client && <Info title="Client">{client.label}</Info>}
        <Info title="Division(s)">{division.join(', ')}</Info>
        <Info title="Project Name">{name}</Info>
        <Info title="Project Hashtag">{hashtag}</Info>
        {budget && (
          <Info title="Budget">
            {budget} {currency.label}
          </Info>
        )}
        {startDate && endDate && (
          <Info title="Project Period">{`${format(
            startDate,
            'yyyy.MM.dd',
          )} - ${format(endDate, 'yyyy.MM.dd')}`}</Info>
        )}
        {contractDays && (
          <Info title="Total Contract Days">{contractDays} Days</Info>
        )}
        {basecamp === 'yes' && (
          <Info title="Basecamp Creation Needed" capitalize>
            {basecamp}
          </Info>
        )}
        {contractUrl && <Info title="Contract Path">{contractUrl}</Info>}
        <Info title="Additional Information">{comments || '-'}</Info>
      </ProjectInfo>
    </Container>
  );
};

export default ProjectInfoText;

import gql from 'graphql-tag';

// export const GET_USERS = gql`
//   query {
//     getUsers {
//       id
//       name
//       email
//       unit
//       team
//       role
//     }
//   }
// `;

export const GET_FORM_DATA = gql`
  query {
    getFormData {
      currencyCodes
      projectRoles
      projectTypes
      projectStatus
      units
      clients {
        id
        name
      }
      users {
        id
        name
        email
        unit
        team
      }
      allUsers {
        id
        name
        email
        unit
        team
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  query ($filter: ProjectFilterInput, $pagination: PaginationInput) {
    getProjects(filter: $filter, pagination: $pagination) {
      count
      projects {
        id
        projectType
        name
        hashtag
        unit
        startDate
        endDate
        status
        Owner {
          id
          name
        }
        Reviewer {
          id
          name
        }
        Team {
          id
          name
          unit
          team
        }
      }
    }
  }
`;

export const GET_PROJECT = gql`
  query ($id: Int!) {
    getProject(id: $id) {
      id
      projectType
      name
      hashtag
      unit
      startDate
      endDate
      budgetAmount
      currencyCode
      contractUrl
      dayForcast
      isOnBasecamp
      Client {
        id
        name
      }
      Owner {
        id
        name
        email
      }
      Reviewer {
        id
        name
        email
      }
      LastModifiedBy {
        id
        name
      }
      comments
      status
      Team {
        id
        name
        email
        projectRole
        SpentDays {
          id
          userId
          projectId
          days
        }
        ContractDays {
          id
          userId
          projectId
          days
          monthYear
        }
        TimeEntries {
          id
          projectId
          description
          date
          duration
        }
      }
      createdAt
      updatedAt
      StatusHistory {
        status
        updatedAt
      }
    }
  }
`;

export const GET_REPORT_PROJECTS = gql`
  query ($filter: ProjectFilterInput, $pagination: PaginationInput) {
    getProjects(filter: $filter, pagination: $pagination) {
      count
      projects {
        id
        projectType
        name
        hashtag
        unit
        Client {
          id
          name
        }
        startDate
        endDate
        dayForcast
        status
        Owner {
          id
          name
        }
        Reviewer {
          id
          name
        }
        Team {
          id
          name
          email
          projectRole
        }
        ContractDays {
          id
          userId
          projectId
          days
          monthYear
        }
        TimeEntries {
          id
          projectId
          description
          date
          duration
        }
        SpentDays {
          id
          userId
          projectId
          days
        }
      }
    }
  }
`;

export const GET_REVIEWERS = gql`
  query ($filter: UserFilterInput) {
    getUsers(filter: $filter) {
      id
      name
      email
      leaveDate
    }
  }
`;

export const GET_CLIENTS = gql`
  query {
    getClients {
      id
      name
      hasProjects
    }
  }
`;

export const GET_USER = gql`
  query ($id: Int!) {
    getUser(id: $id) {
      createdAt
      LongTermLeaves {
        id
        startDate
        endDate
        notes
      }
    }
  }
`;

export const GET_USER_TIMESHEET = gql`
  query ($id: Int!, $assignedProjects: ProjectPeriodFilterInput) {
    getUser(id: $id, assignedProjects: $assignedProjects) {
      createdAt
      timesheetStatus
      AssignedProjects {
        id
        hashtag
        status
        TimeEntries {
          id
          projectId
          description
          date
          duration
        }
      }
      LongTermLeaves {
        id
        startDate
        endDate
        notes
      }
    }
  }
`;

export const GET_LAST_CLOSING_DATE = gql`
  query {
    getLastClosingDate
  }
`;

export const GET_TEAM_MEMBERS = gql`
  query ($filter: UserFilterInput) {
    getUsers(filter: $filter) {
      id
      name
      email
      role
      unit
      team
      timesheetStatus
      leaveDate
      LongTermLeaves {
        id
        startDate
        endDate
        notes
      }
      InternshipPeriod {
        startDate
        endDate
        notes
      }
    }
  }
`;

export const GET_TIMESHEET_MONTHLY_REPORT = gql`
  query ($userId: Int!, $monthYear: String!) {
    getTimesheetMonthReport(userId: $userId, monthYear: $monthYear) {
      AssignedProjects {
        id
        hashtag
        projectType
        ContractDays {
          days
        }
        TimeEntries {
          description
          duration
          date
        }
      }
    }
  }
`;

export const GET_DETAILS_PEOPLE = gql`
  query ($filter: UserFilterInput) {
    getReportUsers(filter: $filter) {
      id
      name
      unit
      team
      role
      leaveDate
      AssignedProjects {
        hashtag
        projectType
        ContractDays {
          userId
          days
        }
        TimeEntries {
          userId
          duration
        }
      }
      InternshipPeriod {
        startDate
        endDate
      }
      createdAt
    }
  }
`;

export const GET_REPORT_RECAPS = gql`
  query ($filter: UserFilterInput) {
    getReportUsers(filter: $filter) {
      id
      name
      unit
      team
      role
      leaveDate
      AssignedProjects {
        hashtag
        projectType
        ContractDays {
          userId
          days
        }
        TimeEntries {
          userId
          duration
        }
      }
      InternshipPeriod {
        startDate
        endDate
      }
      createdAt
    }
  }
`;

export const GET_REPORT_DATERANGE = gql`
  query ($filter: UserFilterInput) {
    getReportUserByDateRange(filter: $filter) {
      monthYear
      teams {
        team
        clientDays
        internalDays
        idealDays
        contractDays
      }
    }
  }
`;

import React from 'react';
import styled from 'styled-components/macro';

const Title = styled.h3`
  margin-bottom: 20px;
  font-weight: 700;
`;

const Subtitle = ({ children }) => {
  return <Title>{children}</Title>;
};

export default Subtitle;

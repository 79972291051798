import React, { useEffect } from 'react';
// import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { GET_PROJECTS, GET_FORM_DATA } from '../../apollo/queries';
import {
  PageLoader,
  ErrorPage,
  ProjectListToolbar,
  ReactTableSorted,
  // Pagination,
  // InlineSelect,
} from '../../components';
import {
  setProjectFilter,
  clearProjectFilter,
  // updatePaginationOption,
} from '../../actions/projectList';
import { initialState } from '../../reducers/projectList';
import {
  projectListColumns,
  mapProjectListToTableData,
} from '../../utils/tableData';
import {
  formatFormData,
  getProjectFilterInput,
  computeProjectFilterAsiancersOptions,
} from '../../utils/formData';
import { errorMessage } from '../../constants/error';
import useAuth from '../../hooks/auth';

const ProjectList = ({ history }) => {
  const dispatch = useDispatch();
  const { getAuthUser } = useAuth();
  const { id: userId } = getAuthUser();
  const { filter, pagination } = useSelector((state) => state.projectList);
  // const { limit } = pagination; // TODO: Handle pagination

  const [
    getProjects,
    { loading: prjLoading, data: prjData, error: prjError },
  ] = useLazyQuery(GET_PROJECTS, {
    variables: {
      filter: getProjectFilterInput(filter),
      pagination,
    },
    fetchPolicy: 'network-only',
  });

  const {
    loading: formLoading,
    data: formData,
    error: formError,
  } = useQuery(GET_FORM_DATA, { fetchPolicy: 'network-only' });

  const { register, control, handleSubmit, reset } = useForm({
    defaultValues: filter,
  });

  useEffect(() => {
    getProjects();

    // return () => {
    //   dispatch(clearProjectFilter());
    // };
  }, []);

  useEffect(() => {
    reset(filter);
  }, [filter]);

  if (formLoading) {
    return <PageLoader />;
  }
  if (prjError || formError) {
    return <ErrorPage text={errorMessage.UNEXPECTED} />;
  }

  const formattedFormData = formatFormData(formData.getFormData);
  const projectCount = prjData && prjData.getProjects.count;
  // const pageCount = Math.ceil(projectCount / limit); // TODO: Handle pagination
  const projectList = mapProjectListToTableData(
    prjData && prjData.getProjects.projects,
    userId,
  );

  const onSubmit = (filterInput) => {
    const computedFilter = computeProjectFilterAsiancersOptions(filterInput);
    // const { filterDivision } = computedFilter;
    // if (filterDivision && filterDivision.some((unit) => unit.value === 'UX')) {
    //   filterDivision.push({ value: 'PROD', label: 'PROD' });
    // }

    // if (filterDivision && filterDivision.some((unit) => unit.value === 'DSI')) {
    //   filterDivision.push({ value: 'CX', label: 'CX' });
    // }

    dispatch(setProjectFilter(computedFilter));
    getProjects();
  };

  const onClear = () => {
    reset(initialState.filter);
    dispatch(clearProjectFilter());
    getProjects();
  };

  // TODO: Handle pagination
  // const options = [
  //   { value: 10, label: '10' },
  //   { value: 50, label: '50' },
  //   { value: 100, label: '100' },
  // ];
  // const defaultOption = options.find((option) => option.value === limit);

  return (
    <div className="content">
      <ProjectListToolbar
        projectCount={projectCount}
        formData={formattedFormData}
        register={register}
        control={control}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        onClear={onClear}
      />
      <ReactTableSorted
        columns={projectListColumns}
        data={projectList}
        loading={prjLoading}
        pointer
        rowProps={(row) => ({
          onClick: () => history.push(`/projects/${row.original.id}`),
        })}
      />
      {/* // TODO: Handle pagination */}
      {/* <Row style={{ alignItems: 'center' }}>
        <Col xs={12} md={3}>
          <span style={{ paddingRight: '10px' }}>Show rows:</span>
          <InlineSelect
            width="100px"
            name="showRows"
            isClearable={false}
            control={control}
            options={options}
            defaultValue={defaultOption}
            placeholder="10"
            onChange={([selected]) => {
              dispatch(
                updatePaginationOption({
                  limit: selected.value,
                  offset: 0,
                }),
              );
            }}
          />
        </Col>
        <Col xs={12} md={9}>
          <Pagination pageCount={pageCount} refetch={getProjects} />
        </Col>
      </Row> */}
    </div>
  );
};

export default ProjectList;

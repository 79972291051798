import { useDispatch } from 'react-redux';
import { setErrorState } from '../../actions/error';
import { errorMessage } from '../../constants/error';

const useErrorHandler = () => {
  const dispatch = useDispatch();

  const handleAPIError = (errors, location, extra = {}) => {
    const { graphQLErrors } = errors;
    const { args, message: customMessage } = extra;
    if (graphQLErrors && graphQLErrors.length) {
      const { extensions, message } = graphQLErrors[0];
      switch (extensions.code) {
        case 'BAD_USER_INPUT':
          dispatch(
            setErrorState({
              type: extensions.code,
              location,
              args: args || extensions.invalidArgs,
              message: customMessage || message,
            }),
          );
          break;
        case 'INTERNAL_SERVER_ERROR':
          dispatch(
            setErrorState({
              type: extensions.code,
              location,
              args,
              message: errorMessage.UNEXPECTED,
            }),
          );
          break;
        default:
          dispatch(
            setErrorState({
              type: extensions.code,
              location,
              args,
              message: customMessage || message,
            }),
          );
      }
    } else {
      dispatch(
        setErrorState({
          type: 'unexpected',
          location,
          args,
          message: customMessage || errorMessage.UNEXPECTED,
        }),
      );
    }
  };

  const handleGoogleError = (error, location) => {
    if (error.status === 401) {
      dispatch(
        setErrorState({
          type: 'unauthenticated',
          location,
          message: errorMessage.GOOGLE_UNAUTHENTICATED,
        }),
      );
    } else {
      dispatch(
        setErrorState({
          type: 'unexpected',
          location,
          message: errorMessage.UNEXPECTED,
        }),
      );
    }
  };

  const handleValidationError = (location, extra = {}) => {
    const { message, args, field } = extra;
    dispatch(
      setErrorState({
        type: 'input_validation',
        location,
        args,
        field,
        message: message || errorMessage.UNEXPECTED,
      }),
    );
  };

  const clearErrorState = () => {
    dispatch(setErrorState(null));
  };

  return {
    handleAPIError,
    handleGoogleError,
    handleValidationError,
    clearErrorState,
  };
};

export default useErrorHandler;

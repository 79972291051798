import React from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { theme } from '../../style/theme';

const DataLoader = ({ loading }) => {
  return (
    <BarLoader
      size={10}
      color={theme.colors.asiance_red}
      css={{ display: 'inline-block' }}
      loading={loading}
    />
  );
};

export default DataLoader;

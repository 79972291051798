import React from 'react';
import { Button } from '../..';

const ExpandButtonCell = ({ row }) => {
  return row.canExpand ? (
    <span
      {...row.getToggleRowExpandedProps({
        style: {
          paddingLeft: `${row.depth * 2}rem`,
        },
      })}
    >
      {row.isExpanded ? (
        <Button className="minus" />
      ) : (
        <Button className="plus" />
      )}
    </span>
  ) : null;
};

export default ExpandButtonCell;

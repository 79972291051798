import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery } from 'react-apollo';
import useAuth from '../../hooks/auth';
import { PageLoader, DatePicker, Button, LinkButton } from '../../components';
import {
  GET_TIMESHEET_MONTHLY_REPORT,
  GET_TEAM_MEMBERS,
  GET_LAST_CLOSING_DATE,
} from '../../apollo/queries';
import {
  getTimesheetMonthReportColumns,
  mapMonthlyReportToTableData,
} from '../../utils/tableData';
import { userRoles } from '../../constants/data';
import {
  Container,
  ContentWrap,
  ButtonGroup,
  StyledReactTable,
  StyledSelect,
} from './styled';
import useQueryFilter from '../../hooks/queryFilter';

const MonthlyReport = ({ history, location: { state } }) => {
  const { getAuthUser, isTeamLeader, isUnitLeader, isAdmin } = useAuth();
  const { id, role, unit } = getAuthUser();
  const { roleFilter: filter } = useQueryFilter();
  const displaySelect = role !== userRoles.USER;
  const [startDate, setStartDate] = useState(new Date());
  const [userId, setUserId] = useState(state ? state.userId : id);
  const { loading: reportLoading, data: reportData } = useQuery(
    GET_TIMESHEET_MONTHLY_REPORT,
    {
      variables: {
        userId,
        monthYear: startDate,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const [getLastClosingMonth, { loading: lastLoading }] = useLazyQuery(
    GET_LAST_CLOSING_DATE,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ getLastClosingDate }) => {
        const lastClosingDate = new Date(getLastClosingDate);
        setStartDate(lastClosingDate);
      },
    },
  );
  useEffect(() => {
    getLastClosingMonth();
  }, []);

  const { loading: userLoading, data: userData } = useQuery(GET_TEAM_MEMBERS, {
    variables: { ...(Object.keys(filter).length !== 0 && { filter }) },
    skip: !displaySelect,
  });

  if (userLoading || lastLoading) return <PageLoader />;
  const userList = (displaySelect && userData.getUsers) || [];

  let subGroupOptionaLabel;
  if (isAdmin()) {
    subGroupOptionaLabel = 'Asiancers';
  } else if (isUnitLeader()) {
    subGroupOptionaLabel = 'My Unit';
  } else if (isTeamLeader(unit)) {
    subGroupOptionaLabel = 'My Team';
  }

  const options = [{ value: id, label: 'My Report' }];
  options.push({
    label: subGroupOptionaLabel,
    options: userList.map((user) => {
      return { value: user.id, label: user.name };
    }),
  });

  const timesheet =
    (!reportLoading && reportData.getTimesheetMonthReport) || {};
  const tableData = mapMonthlyReportToTableData(timesheet);

  return (
    <>
      <ContentWrap>
        <Container>
          <DatePicker
            selectedDate={startDate}
            changeDate={setStartDate}
            isMonthPicker
          />
          {displaySelect && (
            <StyledSelect
              name="user"
              components={{
                IndicatorSeparator: () => null,
              }}
              value={[options[0], ...options[1].options].filter(
                (option) => option.value === userId,
              )}
              options={options}
              onChange={(selectedOption) => setUserId(selectedOption.value)}
              placeholder="Select Team Member"
            />
          )}
        </Container>
        <StyledReactTable
          columns={getTimesheetMonthReportColumns()}
          data={tableData}
          loading={reportLoading}
        />
      </ContentWrap>
      <ButtonGroup>
        <LinkButton url="/timesheet" uppercase={1}>
          Go to Timesheet Page
        </LinkButton>
        {state && (
          <Button
            text="Previous"
            onClick={() => history.goBack()}
            className="redbtn btnmargin"
          />
        )}
      </ButtonGroup>
    </>
  );
};

export default MonthlyReport;

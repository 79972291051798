import React from 'react';
import styled from 'styled-components/macro';
import { CenteredDiv } from '../PageElements';

const Container = styled(CenteredDiv)`
  font-size: 1.2em;
`;

const ErrorPage = ({ text }) => {
  return <Container>{text}</Container>;
};

export default ErrorPage;

import React, { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import {
  toDate,
  subMonths,
  addMonths,
  subYears,
  addYears,
  format,
} from 'date-fns';
import enGb from 'date-fns/locale/en-GB';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../Form';

registerLocale('en-gb', enGb);

export const MonthPicker = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.asiance_navy};
  font-size: 1.2em;
  margin: 0 1rem;
  font-weight: 600;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export const DatePickerContainer = styled.div`
  > div {
    display: ${({ show }) => (show ? 'inline-block' : 'none')};
  }

  .react-datepicker {
    position: absolute;
    z-index: 1;
    margin-top: 16px;
  }
`;

const DatePicker = ({
  selectedDate,
  changeDate,
  isMonthPicker,
  isYearPicker,
  minDate,
  maxDate,
  hide,
}) => {
  const dateFormat = useMemo(
    () => (isMonthPicker && 'yyyy.MM') || (isYearPicker && 'yyyy'),
    [isMonthPicker, isYearPicker],
  );
  const disabled = useMemo(
    () =>
      (isMonthPicker && toDate(subMonths(selectedDate, 1)) < minDate) ||
      (isYearPicker && toDate(subYears(selectedDate, 1)) < minDate),
    [selectedDate],
  );
  const [calendarShow, setCalendarShow] = useState(false);

  const goPrev = () => {
    if (isMonthPicker) {
      changeDate(toDate(subMonths(selectedDate, 1)));
    } else if (isYearPicker) {
      changeDate(toDate(subYears(selectedDate, 1)));
    }
  };

  const goNext = () => {
    if (isMonthPicker) {
      changeDate(toDate(addMonths(selectedDate, 1)));
    }
    if (isYearPicker) {
      changeDate(toDate(addYears(selectedDate, 1)));
    }
  };

  const onMonthPickerClick = () => {
    if (calendarShow) {
      setCalendarShow(false);
    } else {
      setCalendarShow(true);
    }
  };

  const onDatechange = (date) => {
    setCalendarShow(false);
    changeDate(date);
  };

  return (
    <div className="datePickWrap">
      <Button
        className="angle left"
        onClick={goPrev}
        disabled={disabled}
        hide={hide}
      />
      <DatePickerContainer show={calendarShow}>
        <ReactDatePicker
          selected={selectedDate}
          selectsStart
          onChange={(date) => onDatechange(date)}
          dateFormat={dateFormat}
          showMonthYearPicker={isMonthPicker}
          showYearPicker={isYearPicker}
          minDate={minDate}
          maxDate={maxDate}
          inline
        />
      </DatePickerContainer>
      <MonthPicker onClick={onMonthPickerClick} onKeyPress={onMonthPickerClick}>
        {format(selectedDate, dateFormat)}
      </MonthPicker>
      <Button className="angle right" onClick={goNext} hide={hide} />
    </div>
  );
};

export default DatePicker;

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { Modal } from 'react-bootstrap';
import { StyledModal } from './styled';
import { InlineLoader } from '../Loader';
import { Button, CheckboxGroup, TextareaGroup, Error } from '../Form';
import { NOTIFY_PROJECT_UPDATED } from '../../apollo/mutations';
import useAuth from '../../hooks/auth';
import useErrorHandler from '../../hooks/errorHandler';
import { errorLocation } from '../../constants/error';

export const CustomStyledModal = styled(StyledModal)`
  .modal-title {
    width: 65%;
    font-weight: 700;
    font-size: 1.125rem;
  }

  .modal-footer {
    button {
      margin: 0 5px;
    }
  }
`;

export const Message = styled.div`
  color: ${({ theme }) => theme.colors.asiance_red};
  margin-bottom: 18px;
`;

const NotifyModal = ({ show, members, handleClose, history }) => {
  const [sent, setSent] = useState(false);
  const {
    error,
    project: {
      id: projectId,
      data: { reviewer },
    },
  } = useSelector((state) => state);
  const { getAuthUser } = useAuth(history);
  const { id: userId } = getAuthUser();
  const { handleAPIError } = useErrorHandler();
  const { register, errors, getValues, handleSubmit } = useForm();
  const [notifyProjectUpdated, { loading }] = useMutation(
    NOTIFY_PROJECT_UPDATED,
  );

  let memberList = members
    .map((member) => ({
      value: member.id,
      label: member.name,
    }))
    .filter((member) => member.value !== userId);

  if (
    reviewer &&
    !memberList.filter((member) => member.value === reviewer.value).length
  ) {
    const { value, label, email } = reviewer;
    memberList = memberList.concat([{ value, label: `${label}\n<${email}>` }]);
  }

  const onNotify = async ({ members: values, comment }) => {
    let selected = values;
    if (!Array.isArray(values)) {
      selected = [values];
    }
    const userIds = selected.map((id) => Number(id));

    try {
      const response = await notifyProjectUpdated({
        variables: {
          userIds,
          projectId,
          comment,
        },
      });

      if (response && response.data && response.data.notifyProjectUpdated) {
        setSent(true);
      }
    } catch (err) {
      handleAPIError(err, errorLocation.NOTIFY_MODAL);
    }
  };

  return (
    <CustomStyledModal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Please select people who you want to notify about this project update
          *
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <CheckboxGroup
            name="members"
            list={memberList}
            register={register}
            rules={{ required: 'You need to select members to notify' }}
            error={errors.members}
            getValues={getValues}
            marginEven
          />
          <TextareaGroup
            name="comment"
            placeholder="Please write any additional comments here"
            register={register}
            marginEven
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{ justifyContent: 'center', flexDirection: 'column' }}
      >
        {error && error.location === errorLocation.NOTIFY_MODAL && (
          <Error centered>{error.message}</Error>
        )}
        {!sent && (
          <div style={{ display: 'flex' }}>
            <Button
              type="button"
              text="Send"
              inline
              loading={loading}
              onClick={handleSubmit(onNotify)}
              className="redbtn"
            >
              {loading ? (
                <InlineLoader loading={loading} color="red" />
              ) : (
                'Send'
              )}
            </Button>
            <Button
              type="button"
              text="Don't Send"
              inline
              onClick={handleClose}
              className="greybtn"
            />
          </div>
        )}
        {sent && (
          <>
            <Message>Emails have been sent successfully!</Message>
            <Button
              className="redbtn"
              type="button"
              text="OK"
              inline
              onClick={() => {
                history.push('/project-list');
              }}
            />
          </>
        )}
      </Modal.Footer>
    </CustomStyledModal>
  );
};

export default withRouter(NotifyModal);

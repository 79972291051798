import React from 'react';
import styled from 'styled-components/macro';
import { Modal } from 'react-bootstrap';
import { StyledModal } from './styled';
import { Button, Error } from '../Form';
import { CenteredDiv } from '../PageElements';
import { InlineLoader } from '../Loader';

const CustomStyledModal = styled(StyledModal)`
  .modal-content {
    padding: 50px 80px;
  }

  .modal-title {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`;

const MarginDiv = styled(CenteredDiv)`
  margin: 20px 0;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const DoubleCheckModal = ({
  show,
  title,
  text,
  loading,
  error,
  onClick,
  handleClose,
}) => {
  return (
    <CustomStyledModal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MarginDiv>{text}</MarginDiv>
        <CenteredDiv>
          <Button
            type="button"
            loading={loading}
            inline
            onClick={onClick}
            className="redbtn"
          >
            {loading ? <InlineLoader loading={loading} color="red" /> : 'Yes'}
          </Button>
          <Button
            type="button"
            inline
            onClick={handleClose}
            className="navybtn"
          >
            No
          </Button>
        </CenteredDiv>
        {error && <Error centered>{error.message}</Error>}
      </Modal.Body>
    </CustomStyledModal>
  );
};

export default DoubleCheckModal;

import React from 'react';
import styled from 'styled-components/macro';
import { projectType } from '../../../constants/data';
import { theme } from '../../../style/theme';

const Text = styled.span`
  color: ${({ prjType }) =>
    prjType === projectType.INTERNAL
      ? theme.colors.dropdown_selected
      : 'black'};

  font-weight: ${({ isHeader }) => (isHeader ? 'bold' : 'normal')};
  font-size: ${({ isSubRow, isHeader }) =>
    isSubRow && isHeader ? '0.875rem' : '1rem'};

  &.blue {
    color: ${theme.colors.dropdown_selected};
  }
`;

const ContractDaysCell = ({ value, row }) => {
  const { original, depth, index } = row;
  const { projectType: type, hashtag } = original;

  if (hashtag === 'Total') {
    const splitVal = value.split(' | ');

    return (
      <div>
        <Text>{splitVal[0]}</Text>
        <br />
        <Text className="blue">{splitVal[1]}</Text>
      </div>
    );
  }

  return (
    <Text
      prjType={type}
      isHeader={depth === 0 || (depth === 1 && index === 0)}
      isSubRow={depth === 1}
    >
      {value}
    </Text>
  );
};

export default ContractDaysCell;

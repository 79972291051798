import React from 'react';
import GoogleLogin from 'react-google-login';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { Container, Row, Col } from 'react-bootstrap';
import { PageContainer } from './styled';
import { PageLoader, Error } from '../../components';
import { LOGIN } from '../../apollo/mutations';
import { setAccessToken } from '../../utils/accessToken';
import config from '../../.config';
import useAuth from '../../hooks/auth';
import useErrorHandler from '../../hooks/errorHandler';
import { errorLocation, errorMessage } from '../../constants/error';
import logo from '../../assets/images/logowhite.svg';

const Login = ({ history }) => {
  const [login, { loading }] = useMutation(LOGIN);
  const { saveAuthUser } = useAuth();
  const { handleAPIError } = useErrorHandler();
  const { error } = useSelector((state) => state);

  if (loading) {
    return <PageLoader />;
  }

  const onSuccess = async (res) => {
    try {
      const response = await login({
        variables: {
          token: res.tokenId,
        },
      });

      if (response && response.data) {
        const { id, name, role, unit, team } = response.data.tokenLogin.user;
        const user = {
          id,
          name,
          role,
          unit,
          team,
          imageUrl: res.profileObj.imageUrl,
        };
        saveAuthUser(user);
        setAccessToken(response.data.tokenLogin.accessToken);
        history.push('/');
      }
    } catch (err) {
      handleAPIError(err, errorLocation.LOGIN);
    }
  };

  const onFailure = (err) => {
    handleAPIError(err, errorLocation.LOGIN, {
      message: errorMessage.LOGIN_FAIL,
    });
  };

  return (
    <PageContainer>
      <img src={logo} className="loginpagelogo" alt="" />
      <Container>
        <Row>
          <Col md="6">
            <h1>ASIANCE TIMESHEET</h1>
            <p>Please login to your account.</p>
            <GoogleLogin
              clientId={config.GOOGLE_CLIENT_ID}
              scope="profile email https://www.googleapis.com/auth/calendar.readonly"
              render={(renderProps) => (
                <button
                  type="button"
                  data-cy="loginSubmit"
                  className="googlelogin"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  Sign in with Google
                </button>
              )}
              onSuccess={onSuccess}
              onFailure={onFailure}
            />
            {error && error.location === errorLocation.LOGIN && (
              <Error>{error.message}</Error>
            )}
          </Col>
        </Row>
      </Container>
    </PageContainer>
  );
};

export default Login;

import {
  SELECT_WEEK,
  SAVE_TIME_ENTRIES,
  SAVE_HASHTAG,
  SAVE_DESCRIPTION,
  SAVE_DURATION,
  REMOVE_DURATION,
  DELETE_TIMESHEET_ROW,
} from '../constants/actionTypes';

const initialState = {
  selectedWeek: {
    startDate: null,
    endDate: null,
  },
  timeEntries: [],
};

const timesheet = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_WEEK:
      return {
        ...state,
        selectedWeek: {
          startDate: action.startDate,
          endDate: action.endDate,
        },
        timeEntries: [],
      };
    case SAVE_TIME_ENTRIES:
      return {
        ...state,
        timeEntries: action.timeEntries,
      };
    case SAVE_HASHTAG:
      return {
        ...state,
        timeEntries: state.timeEntries.map((row) => {
          if (row.id === action.id) {
            row.hashtag = action.hashtag;
            row.projectId = action.projectId;
          }
          return row;
        }),
      };
    case SAVE_DESCRIPTION:
      return {
        ...state,
        timeEntries: state.timeEntries.map((row) => {
          if (row.id === action.id) {
            row.description = action.description;
          }
          return row;
        }),
      };
    case SAVE_DURATION:
      return {
        ...state,
        timeEntries: state.timeEntries.map((row) => {
          if (row.id === action.id) {
            const found = row.entries.find(
              (entry) => entry.date === action.date,
            );
            if (found) {
              found.duration = action.duration;
            } else {
              const { date, duration } = action;
              row.entries.push({
                date,
                duration,
              });
            }
          }
          return row;
        }),
      };
    case REMOVE_DURATION:
      return {
        ...state,
        timeEntries: state.timeEntries.map((row) => {
          if (row.id === action.id) {
            row.entries = row.entries.filter(
              (entry) => entry.date !== action.date,
            );
          }
          return row;
        }),
      };
    case DELETE_TIMESHEET_ROW:
      return {
        ...state,
        timeEntries: state.timeEntries.filter((row) => row.id !== action.id),
      };
    default:
      return state;
  }
};

export default timesheet;

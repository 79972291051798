const config = {
  API_URL: process.env.REACT_APP_API_URL,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  GOOGLE_DISCOVERY_DOCS: [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ],
  GOOGLE_SCOPE: 'https://www.googleapis.com/auth/calendar.readonly',
};

if (!config.API_URL) {
  throw 'API URL needs to be set in .env file.';
}

if (!config.GOOGLE_CLIENT_ID) {
  throw 'GOOGLE CLIENT ID needs to be set in .env file.';
}

if (!config.GOOGLE_API_KEY) {
  throw 'GOOGLE API KEY needs to be set in .env file.';
}

console.log(`[+] Environment env=${process.env.NODE_ENV}`);

export default config;

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { format } from 'date-fns';
import {
  UPDATE_PROJECT,
  ADD_OR_UPDATE_SPENT_DAYS,
  ADD_OR_UPDATE_CONTRACT_DAYS,
  REQUEST_PROJECT_APPROVAL,
  APPROVE_PROJECT,
  DELETE_PROJECT,
  ASSIGN_MEMBERS,
  REMOVE_MEMBERS,
} from '../../apollo/mutations';
import { GET_PROJECTS, GET_PROJECT } from '../../apollo/queries';
import {
  saveProjectId,
  saveProject,
  saveMembers,
  clearMembersToDelete,
  updateDateRange,
} from '../../actions/project';
import { updateTitle } from '../../actions/layout';
import {
  ProjectInfoText,
  ProjectInfo,
  ContractDays,
  SubmitModal,
  StatusChangeModal,
  ConfirmModal,
  NotifyModal,
  DoubleCheckModal,
  Subtitle,
  Button,
  Error,
  InlineLoader,
} from '../index';
import {
  mapFormDataToProjectInput,
  mapMemberStateToProjectUserInput,
  mapMemberStateToContractInput,
  mapMemberStateToSpentDayInput,
  mapProjectDataToState,
  mapTeamDataToMemberState,
  mapContractDaysToMemberState,
  mapSpentDaysToMemberState,
  addUpContractDays,
  getDefaultStatusOption,
  getStatusOptions,
} from '../../utils/formData';
import { getListOfDateTime, stringifyDate } from '../../utils/datetime';
import { projectStatus, projectType, Unit } from '../../constants/data';
import { errorLocation, errorMessage } from '../../constants/error';
import useAuth from '../../hooks/auth';
import useErrorHandler from '../../hooks/errorHandler';
import useFormInput from '../../hooks/form';

const Container = styled.div`
  background: white;
  padding: 60px;
  margin: 40px 0;

  .bar {
    font-size: 1.25rm;
    color: ${({ theme }) => `${theme.colors.blue_gray}`};
    margin: 0px 10px;
  }
`;

const ButtonsDiv = styled.div`
  margin: 60px 0px;
  display: flex;
  justify-content: space-between;
  .editable-btns {
    display: flex;
    button {
      margin-left: 10px;
    }
  }
`;

const EditProject = ({
  history,
  formData,
  projectId,
  projectData,
  isOwner,
  isReviewer,
  isTeamMember,
}) => {
  const defaultValues = mapProjectDataToState(projectData);
  const months = getListOfDateTime(
    projectData.startDate,
    projectData.endDate,
    'yyyyMM',
    'months',
  );
  const contractDays = mapTeamDataToMemberState(projectData.Team, months);
  const dispatch = useDispatch();
  const {
    error,
    project: { data: project, members, membersToDelete },
  } = useSelector((state) => state);
  const status = project.status || defaultValues.status;
  const projectUnit = project.division.length
    ? project.division
    : defaultValues.division;
  const { isAdmin, isUnitLeader, isTeamLeader, getAuthUser } = useAuth();
  const { id: userId, unit } = getAuthUser();
  const {
    handleAPIError,
    handleValidationError,
    clearErrorState,
  } = useErrorHandler();
  const [type, setType] = useState(defaultValues.type.value);
  const [editable, setEditable] = useState(false);
  const [editStatus, setEditStatus] = useState(null);

  const [updateProject, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT,
    {
      refetchQueries: [
        {
          query: GET_PROJECT,
          variables: {
            id: projectId,
          },
        },
      ],
    },
  );
  const [assignMembers, { loading: assignLoading }] = useMutation(
    ASSIGN_MEMBERS,
    {
      refetchQueries: [
        {
          query: GET_PROJECT,
          variables: {
            id: projectId,
          },
        },
      ],
    },
  );
  const [removeMembers, { loading: removeLoading }] = useMutation(
    REMOVE_MEMBERS,
    {
      refetchQueries: [
        {
          query: GET_PROJECT,
          variables: {
            id: projectId,
          },
        },
      ],
    },
  );
  const [addOrUpdateContractDays, { loading: contractLoading }] = useMutation(
    ADD_OR_UPDATE_CONTRACT_DAYS,
    {
      refetchQueries: [
        {
          query: GET_PROJECT,
          variables: {
            id: projectId,
          },
        },
      ],
    },
  );
  const [addOrUpdateSpentDays, { loading: spentLoading }] = useMutation(
    ADD_OR_UPDATE_SPENT_DAYS,
    {
      refetchQueries: [
        {
          query: GET_PROJECT,
          variables: {
            id: projectId,
          },
        },
      ],
    },
  );
  const [requestProjectApproval, { loading: submitLoading }] = useMutation(
    REQUEST_PROJECT_APPROVAL,
    {
      refetchQueries: [{ query: GET_PROJECTS }],
    },
  );
  const [approveProject, { loading: approveLoading }] = useMutation(
    APPROVE_PROJECT,
    {
      refetchQueries: [
        { query: GET_PROJECTS },
        {
          query: GET_PROJECT,
          variables: {
            id: projectId,
          },
        },
      ],
    },
  );
  const [deleteProject, { loading: deleteLoading }] = useMutation(
    DELETE_PROJECT,
    {
      refetchQueries: [{ query: GET_PROJECTS }],
    },
  );

  const {
    control,
    register,
    setError,
    clearError,
    errors,
    setValue,
    getValues,
    reset,
    watch,
    handleSubmit,
  } = useForm({ defaultValues });
  const totalContractDays = watch('contractDays');
  const inputs = watch();
  const { isInputChanged } = useFormInput(inputs);

  useEffect(() => {
    if (isInputChanged) {
      setEditStatus(null);
    }
  }, [isInputChanged]);

  useEffect(() => {
    return () => {
      clearErrorState();
    };
  }, []);

  useEffect(() => {
    reset(defaultValues);
  }, [type]);

  useEffect(() => {
    clearError();
  }, [totalContractDays, members]);

  useEffect(() => {
    if (!(!members.length && !membersToDelete.length)) {
      setEditStatus(null);
    }
  }, [members, membersToDelete]);

  useEffect(() => {
    if (error && error.args === 'hashtag') {
      setError('hashtag', '', error.message);
    }
  }, [error]);

  const validateContractDays = () => {
    clearErrorState();

    if (!members.length) {
      if (project.status !== projectStatus.DRAFT) {
        handleValidationError(errorLocation.EDIT_PROJECT, {
          message: errorMessage.USER_EMPTY,
        });
      }
      return false;
    }

    const totalDays = parseFloat(addUpContractDays(members).toFixed(3));
    if (
      members.length &&
      totalContractDays &&
      totalDays !== parseFloat(totalContractDays || 0)
    ) {
      if (project.status !== projectStatus.DRAFT) {
        handleValidationError(errorLocation.EDIT_PROJECT, {
          message: errorMessage.TOTAL_DAYS_NOT_MATCH,
        });
      }
      return false;
    }

    return true;
  };

  const updateProjectPeriod = () => {
    if (isInputChanged) {
      const startDate = getValues('startDate');
      const endDate = getValues('endDate');
      if (
        stringifyDate(startDate) !== stringifyDate(project.startDate) ||
        stringifyDate(endDate) !== stringifyDate(project.endDate)
      ) {
        const startDateInput = getValues('startDate');
        const endDateInput = getValues('endDate');
        const updatedMonths = getListOfDateTime(
          startDateInput,
          endDateInput,
          'yyyyMM',
          'months',
        );
        dispatch(updateDateRange(updatedMonths));
      }
    }
  };

  const updateProjectData = async (projectInput) => {
    if (isInputChanged) {
      const response = await updateProject({
        variables: {
          project: projectInput,
        },
      });

      if (response && response.data) {
        const updatedProject = mapProjectDataToState(
          response.data.updateProject,
        );
        dispatch(saveProject(updatedProject));
        return true;
      }
    }
    return true;
  };

  const removeMembersFromProject = async () => {
    if (membersToDelete.length) {
      const response = await removeMembers({
        variables: {
          projectId,
          userIds: membersToDelete,
        },
      });

      if (response && response.data && response.data.removeUsersFromProject) {
        dispatch(clearMembersToDelete());
      }
    }
  };

  const updateSpentDays = async (updatedMembers, nextAction) => {
    const spentDays = mapMemberStateToSpentDayInput(projectId, members);

    if (spentDays.length) {
      const spentResponse = await addOrUpdateSpentDays({
        variables: {
          spentDays,
        },
      });

      if (spentResponse && spentResponse.data) {
        const updatedSpentDays = spentResponse.data.addOrUpdateSpentDays;
        const finalMembersData = mapSpentDaysToMemberState(
          updatedMembers,
          updatedSpentDays,
        );
        dispatch(saveMembers(finalMembersData));
        nextAction();
      }
    } else {
      dispatch(saveMembers(updatedMembers));
      nextAction();
    }
  };

  const updateMembers = async (nextAction) => {
    await removeMembersFromProject();

    const projectUsers = mapMemberStateToProjectUserInput(projectId, members);
    const response = await assignMembers({
      variables: {
        projectUsers,
      },
    });

    if (response && response.data && response.data.addUsersToProject) {
      const contracts = mapMemberStateToContractInput(projectId, members);
      if (contracts.length) {
        const contractResponse = await addOrUpdateContractDays({
          variables: {
            contracts,
          },
        });

        if (contractResponse && contractResponse.data) {
          const updatedContractDays =
            contractResponse.data.addOrUpdateContractDays;
          const updatedMembers = mapContractDaysToMemberState(
            members,
            updatedContractDays,
          );
          await updateSpentDays(updatedMembers, nextAction);
        }
      } else {
        await updateSpentDays(members, nextAction);
      }
    }
  };

  const updateAll = async (projectInput, nextAction) => {
    const result = await updateProjectData(projectInput);
    if (result) {
      await updateMembers(nextAction);
    }
  };

  const onSaveAsDraft = async (formInputData) => {
    const nextAction = () => {
      const validated = validateContractDays();
      if (validated) {
        setEditStatus('validated');
      } else {
        setEditStatus('saved');
      }
    };

    updateProjectPeriod();

    const projectInput = mapFormDataToProjectInput(
      formInputData,
      project.owner.value,
      projectId,
    );

    try {
      if (!members.length) {
        await removeMembersFromProject();
        const result = await updateProjectData(projectInput);
        if (result) nextAction();
        return;
      }

      await updateAll(projectInput, nextAction);
    } catch (err) {
      handleAPIError(err, errorLocation.EDIT_PROJECT);
    }
  };

  const onSubmit = async () => {
    if (!getValues('approver')) {
      handleValidationError(errorLocation.SUBMIT_MODAL, {
        message: errorMessage.APPROVER_REQUIRED,
      });
      return;
    }

    const reviewerId = getValues('approver').value;

    try {
      const response = await requestProjectApproval({
        variables: {
          projectId,
          reviewerId,
        },
      });

      if (response && response.data && response.data.requestProjectApproval) {
        if (editStatus === 'approverUpdated') {
          setEditStatus(null);
          return;
        }
        history.push('/project-submitted');
      }
    } catch (err) {
      handleAPIError(err, errorLocation.SUBMIT_MODAL);
    }
  };

  const onUpdateStatus = async () => {
    const selectedOption = getValues('statusOptions');
    if (!selectedOption) {
      handleValidationError(errorLocation.STATUS_MODAL, {
        message: errorMessage.STATUS_OPTION_REQUIRED,
      });
      return;
    }

    const selectedStatus = selectedOption.value;

    try {
      const response = await updateProject({
        variables: {
          project: {
            id: projectId,
            status: selectedStatus,
          },
        },
      });
      if (response && response.data) {
        const updatedProject = response.data.updateProject;
        const { status: updatedStatus } = updatedProject;
        reset({
          ...defaultValues,
          statusOptions: getDefaultStatusOption(updatedStatus),
        });

        dispatch(saveProject(mapProjectDataToState(updatedProject)));
        setEditStatus(null);
      }
    } catch (err) {
      handleAPIError(err, errorLocation.EDIT_PROJECT);
    }
  };

  const onUpdateProject = async (formInputData) => {
    updateProjectPeriod();

    const validated = validateContractDays();
    if (!validated) {
      return;
    }

    const projectInput = mapFormDataToProjectInput(
      formInputData,
      project.owner.value,
      projectId,
    );

    const nextAction = () => {
      if (
        status === projectStatus.ACTIVE &&
        members.length &&
        !(members.length === 1 && members[0].id === userId)
      ) {
        setEditStatus('notify');
        return;
      }
      setEditStatus('updated');
    };

    try {
      if (!members.length) {
        if (type !== projectType.INTERNAL) {
          handleValidationError(errorLocation.EDIT_PROJECT, {
            message: errorMessage.USER_EMPTY,
          });
        } else {
          const result = await updateProjectData(projectInput);
          if (result) nextAction();
        }
        return;
      }

      await updateAll(projectInput, nextAction);
    } catch (err) {
      handleAPIError(err, errorLocation.EDIT_PROJECT);
    }
  };

  const onApprove = async () => {
    try {
      const response = await approveProject({
        variables: {
          projectId,
        },
      });

      if (response && response.data && response.data.approveProject) {
        setEditStatus('approved');
        reset({
          ...defaultValues,
          statusOptions: getDefaultStatusOption(projectStatus.ACTIVE),
        });
      }
    } catch (err) {
      handleAPIError(err, errorLocation.EDIT_PROJECT);
    }
  };

  const onRequestApproval = async () => {
    clearErrorState();

    if (!contractDays.length) {
      handleValidationError(errorLocation.EDIT_PROJECT, {
        message: errorMessage.USER_EMPTY,
      });
      return;
    }

    const totalDays = parseFloat(addUpContractDays(contractDays).toFixed(3));
    if (totalContractDays && totalDays !== parseFloat(totalContractDays)) {
      handleValidationError(errorLocation.EDIT_PROJECT, {
        message: errorMessage.TOTAL_DAYS_NOT_MATCH,
      });
      return;
    }

    setEditStatus('validated');
  };

  const onPrevious = () => {
    const defaultContractDays = mapTeamDataToMemberState(
      projectData.Team,
      months,
    );
    reset(defaultValues);
    dispatch(updateTitle(defaultValues.hashtag));
    dispatch(saveProject(defaultValues));
    dispatch(saveMembers(defaultContractDays));
    dispatch(clearMembersToDelete());
    setEditStatus(null);
    setEditable(false);
    clearError();
    clearErrorState();
  };

  const onEdit = () => {
    dispatch(updateTitle('Edit Project'));
    reset(defaultValues);
    dispatch(saveProjectId(projectId));
    dispatch(saveProject(defaultValues));
    dispatch(saveMembers(contractDays));
    setEditStatus(null);
    setEditable(true);
    clearError();
    clearErrorState();
  };

  const onDelete = async () => {
    try {
      const response = await deleteProject({
        variables: {
          projectId,
        },
      });

      if (response && response.data && response.data.deleteProject) {
        setEditStatus('deleted');
      }
    } catch (err) {
      handleAPIError(err, errorLocation.DELETE_MODAL);
    }
  };

  const handleModalClose = (route) => {
    setEditable(false);
    setEditStatus(null);
    // dispatch(saveMembers([]));
    history.push(route);
  };

  return (
    <>
      <Subtitle>Project Information</Subtitle>
      {editable ? (
        <div style={{ background: 'white', padding: '60px', margin: '40px 0' }}>
          <ProjectInfo
            type={type}
            setType={setType}
            defaultChecked={project.division || defaultValues.division}
            formData={formData}
            control={control}
            register={register}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            editable={editable}
            update
          />
        </div>
      ) : (
        <ProjectInfoText defaultValues={defaultValues} />
      )}
      <Subtitle>Days Repartition</Subtitle>
      <Container>
        {!editable &&
          (!contractDays.length && !members.length ? (
            <div>No details..</div>
          ) : (
            <ContractDays
              defaultValues={defaultValues}
              contractDays={members.length ? members : contractDays}
              totalContractDays={totalContractDays}
              formData={formData}
              editable={editable}
              status={status}
              startDate={projectData.startDate}
            />
          ))}
        {editable && (
          <ContractDays
            defaultValues={defaultValues}
            contractDays={members.length ? members : contractDays}
            totalContractDays={totalContractDays}
            formData={formData}
            editable={editable}
            status={status}
            startDate={projectData.startDate}
          />
        )}
        <div>
          {status === projectStatus.PENDING && (
            <>
              <span>
                Project Approver: <b>{defaultValues.reviewer.label}</b>
              </span>
              <span className="bar">|</span>
              <Button
                className="changebtn"
                type="button"
                text="Change Approver"
                inline
                onClick={() => setEditStatus('approverUpdated')}
              />
            </>
          )}
        </div>
        {status >= projectStatus.ACTIVE && (
          <>
            <span>
              Last Modified By: <b>{defaultValues.lastEditor}</b>
            </span>
          </>
        )}
        {status >= projectStatus.ACTIVE &&
          (isAdmin() ||
            isUnitLeader() ||
            isOwner ||
            isReviewer ||
            isTeamMember) && (
            <>
              {status >= projectStatus.ACTIVE &&
                (project.statusHistory || defaultValues.statusHistory ? (
                  <>
                    <span className="bar">|</span>
                    <span>
                      {(status === projectStatus.ACTIVE && 'Re-opened') ||
                        (status === projectStatus.CLOSED && 'Closed') ||
                        (status === projectStatus.DISCONTINUED &&
                          'Discontinued')}{' '}
                      Project:{' '}
                      {format(
                        (project.statusHistory &&
                          new Date(project.statusHistory.updatedAt)) ||
                          (defaultValues.statusHistory &&
                            new Date(defaultValues.statusHistory.updatedAt)),
                        'yyyy.MM.dd',
                      )}
                    </span>
                  </>
                ) : (
                  ''
                ))}
            </>
          )}
        {status >= projectStatus.ACTIVE &&
          (((isAdmin() || isUnitLeader()) &&
            !defaultValues.division.includes(Unit.ASIANCE) &&
            !project.division.includes(Unit.ASIANCE)) ||
            isOwner ||
            isReviewer ||
            isTeamMember) && (
            <>
              <span className="bar">|</span>
              <Button
                className="changebtn"
                type="button"
                text="Update Project Status"
                inline
                onClick={() => setEditStatus('projectStatusUpdated')}
              />
            </>
          )}
        {error &&
          error.location === errorLocation.EDIT_PROJECT &&
          !error.args && <Error>{error.message}</Error>}
        <ButtonsDiv>
          {!editable && (
            <>
              <Button
                className="backtolistbtn"
                type="button"
                text="Go back to list"
                style={{ marginRight: '20px' }}
                inline
                onClick={() => {
                  history.push('/project-list');
                }}
              />
              <div className="editable-btns">
                {isReviewer && status === projectStatus.PENDING && (
                  <Button
                    type="button"
                    inline
                    loading={approveLoading}
                    onClick={onApprove}
                    className="navybtn"
                  >
                    {approveLoading ? (
                      <InlineLoader loading={approveLoading} />
                    ) : (
                      'Approve'
                    )}{' '}
                  </Button>
                )}
                {isOwner && status === projectStatus.DRAFT && (
                  <Button
                    className="greybtn"
                    type="button"
                    text="Delete"
                    inline
                    onClick={() => {
                      clearError();
                      setEditStatus('doubleCheck');
                    }}
                  />
                )}
                {isOwner && status === projectStatus.DRAFT && (
                  <Button
                    className="navybtn"
                    type="button"
                    text="Request Approval"
                    inline
                    onClick={onRequestApproval}
                  />
                )}
                {(isAdmin() ||
                  isUnitLeader() ||
                  isTeamLeader(projectUnit) ||
                  isOwner ||
                  isReviewer ||
                  isTeamMember) &&
                  status <= 2 && (
                    <Button
                      className="redbtn"
                      type="button"
                      text="Edit"
                      disabled={
                        !(
                          isAdmin() ||
                          isUnitLeader() ||
                          isTeamLeader(projectUnit) ||
                          isOwner ||
                          isReviewer ||
                          isTeamMember
                        )
                      }
                      inline
                      onClick={onEdit}
                    />
                  )}
              </div>
            </>
          )}
          {editable && status === projectStatus.DRAFT && (
            <>
              <Button
                className="backtolistbtn"
                type="button"
                text="BACK"
                style={{ marginRight: '20px' }}
                disabled={
                  updateLoading ||
                  assignLoading ||
                  removeLoading ||
                  contractLoading ||
                  spentLoading
                }
                inline
                onClick={onPrevious}
              />
              <Button
                className="redbtn"
                type="button"
                inline
                loading={
                  updateLoading ||
                  assignLoading ||
                  removeLoading ||
                  contractLoading ||
                  spentLoading
                }
                disabled={
                  editStatus === 'saved' ||
                  updateLoading ||
                  assignLoading ||
                  removeLoading ||
                  contractLoading ||
                  spentLoading
                }
                onClick={handleSubmit(onSaveAsDraft)}
              >
                {updateLoading ||
                assignLoading ||
                removeLoading ||
                contractLoading ||
                spentLoading ? (
                  <InlineLoader
                    loading={
                      updateLoading ||
                      assignLoading ||
                      removeLoading ||
                      contractLoading ||
                      spentLoading
                    }
                    color="red"
                  />
                ) : (
                  (editStatus === 'saved' && 'Saved!') ||
                  (editStatus !== 'saved' && 'Save')
                )}
              </Button>
            </>
          )}
          {editable &&
            (status === projectStatus.PENDING ||
              status === projectStatus.ACTIVE) && (
              <>
                <Button
                  className="backtolistbtn"
                  type="button"
                  text="Back"
                  style={{ marginRight: '20px' }}
                  inline
                  onClick={onPrevious}
                />
                <Button
                  className="redbtn"
                  type="submit"
                  inline
                  loading={
                    updateLoading ||
                    assignLoading ||
                    removeLoading ||
                    contractLoading ||
                    spentLoading
                  }
                  disabled={
                    editStatus === 'updated' ||
                    updateLoading ||
                    assignLoading ||
                    removeLoading ||
                    contractLoading ||
                    spentLoading
                  }
                  onClick={handleSubmit(onUpdateProject)}
                >
                  {updateLoading ||
                  assignLoading ||
                  removeLoading ||
                  contractLoading ||
                  spentLoading ? (
                    <InlineLoader
                      loading={
                        updateLoading ||
                        assignLoading ||
                        removeLoading ||
                        contractLoading ||
                        spentLoading
                      }
                      color="red"
                    />
                  ) : (
                    (editStatus === 'updated' && 'Updated!') ||
                    (editStatus !== 'updated' && 'Update')
                  )}
                </Button>
              </>
            )}
        </ButtonsDiv>
      </Container>
      <SubmitModal
        show={editStatus === 'validated' || editStatus === 'approverUpdated'}
        unit={
          unit &&
          projectData.unit.length &&
          projectData.unit.indexOf(unit) === -1
            ? [...projectData.unit, unit]
            : projectData.unit
          // project.division.length ? project.division : defaultValues.division
        }
        control={control}
        error={
          error && error.location === errorLocation.SUBMIT_MODAL ? error : null
        }
        loading={submitLoading}
        onSubmit={onSubmit}
        handleClose={handleModalClose}
        current={project.reviewer || defaultValues.reviewer}
        update={editStatus === 'approverUpdated'}
      />
      <StatusChangeModal
        show={editStatus === 'projectStatusUpdated'}
        control={control}
        error={
          error && error.location === errorLocation.STATUS_MODAL ? error : null
        }
        loading={updateLoading}
        statusOptions={getStatusOptions(status)}
        onUpdate={onUpdateStatus}
        handleClose={handleModalClose}
      />
      <ConfirmModal
        show={editStatus === 'updated'}
        text="Project has been updated!"
        handleClose={handleModalClose}
      />
      <ConfirmModal
        show={editStatus === 'approved'}
        text="Project has been approved!"
        handleClose={() => handleModalClose('/project-list')}
      />
      <ConfirmModal
        show={editStatus === 'deleted'}
        text="Project has been deleted!"
        handleClose={() => handleModalClose('/project-list')}
      />
      <DoubleCheckModal
        show={editStatus === 'doubleCheck'}
        title={defaultValues.hashtag}
        text="Are you sure you want to delete it?"
        loading={deleteLoading}
        error={
          error && error.location === errorLocation.DELETE_MODAL ? error : null
        }
        onClick={onDelete}
        handleClose={handleModalClose}
      />
      <NotifyModal
        show={editStatus === 'notify'}
        members={members}
        handleClose={handleModalClose}
      />
    </>
  );
};

export default withRouter(EditProject);

import React from 'react';
import styled from 'styled-components/macro';

const Text = styled.span`
  font-weight: bold;
`;

const BoldText = ({ children }) => {
  return <Text>{children}</Text>;
};

export default BoldText;

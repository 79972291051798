import React from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { StyledModal } from './styled';
import { SelectGroup, Button, Error } from '../Form';
import { InlineLoader } from '../Loader';

const StatusChangeModal = ({
  show,
  control,
  error,
  loading,
  statusOptions,
  onUpdate,
  handleClose,
}) => {
  return (
    <StyledModal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change project status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectGroup
          name="statusOptions"
          label="Please select a status to update the project."
          control={control}
          options={statusOptions}
          placeholder="Select a project status"
          rules={{ required: 'This field is required.' }}
          marginEven
        />
      </Modal.Body>
      {error && <Error>{error.message}</Error>}
      <Modal.Footer>
        <Button
          type="redbtn"
          data-cy="statusUpdate"
          inline
          loading={loading}
          onClick={onUpdate}
        >
          {loading ? <InlineLoader loading={loading} color="red" /> : 'Update'}
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

export default withRouter(StatusChangeModal);

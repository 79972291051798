import React from 'react';
import styled from 'styled-components/macro';
import { ReactTable } from '../../components';

export const Container = styled.div`
  .wrap {
    background: white;
    padding: 50px;
    margin: 40px 0;
    margin-bottom: 60px;
  }

  .flex-wrap {
    display: flex;
    justify-content: space-between;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledReactTable = styled((props) => <ReactTable {...props} />)`
  color: blue;
  .table {
    .header {
      .th {
        font-weight: bold;
      }
    }
    .body {
      .td {
        padding: 20px 15px;
      }
      .tr .td:first-child {
        font-weight: bold;
      }
      .subRow {
        background-color: ${({ theme }) => theme.colors.row_gray};
        padding-left: 30px;
        padding-bottom: 20px;
        &.sub-0 {
          padding-top: 20px;
        }
        .td {
          border-bottom: ${({ theme }) => `1px solid ${theme.colors.row_gray}`};
          padding: 0;
        }
      }
    }
  }
`;

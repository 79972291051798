import React from 'react';
import { Modal } from 'react-bootstrap';
import { StyledModal } from './styled';
import { Button } from '../Form';
import { CenteredDiv } from '../PageElements';

const ConfirmModal = ({ show, text, handleClose }) => {
  return (
    <StyledModal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{text}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CenteredDiv>
          <Button
            type="button"
            text="OK"
            inline
            onClick={handleClose}
            className="redbtn"
          />
        </CenteredDiv>
      </Modal.Body>
    </StyledModal>
  );
};

export default ConfirmModal;

import {
  SET_PROJECT_FILTER,
  CLEAR_PROJECT_FILTER,
  CHANGE_PAGE,
  UPDATE_PAGINATION_OPTION,
} from '../constants/actionTypes';

export const initialState = {
  filter: {
    search: '',
    filterDate: new Date(),
    filterDivision: null,
    filterType: null,
    filterStatus: null,
    filterUnit: null,
    filterTeam: null,
    filterMember: null,
    asiancersOptions: null,
  },
  pagination: {
    limit: 10,
    offset: 0,
  },
  page: 1,
  pageNum: 1,
};

const projectList = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_FILTER:
      return {
        ...state,
        filter: {
          ...initialState.filter,
          ...action.filter,
        },
        pagination: {
          limit: state.pagination.limit,
          offset: 0,
        },
        page: 1,
        pageNum: 1,
      };
    case CLEAR_PROJECT_FILTER:
      return {
        ...initialState,
        pagination: {
          limit: state.pagination.limit,
          offset: 0,
        },
      };
    case CHANGE_PAGE: {
      const { page } = action;
      const pageNum = !page || page < 1 ? 1 : page;
      const { limit } = state.pagination;
      return {
        ...state,
        page,
        pageNum,
        pagination: {
          ...state.pagination,
          offset: pageNum * limit - limit,
        },
      };
    }
    case UPDATE_PAGINATION_OPTION:
      return {
        ...state,
        pagination: action.option,
        page: 1,
        pageNum: 1,
      };
    default:
      return state;
  }
};

export default projectList;

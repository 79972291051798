import React from 'react';
import styled from 'styled-components/macro';
import { LinkButton } from '../../components';
import projectIcon from '../../assets/images/projecticon.svg';

const Container = styled.div`
  background-color: white;
  position: relative;
  text-align: center;
  padding: 100px;

  h1 {
    font-family: 'futura-pt';
  }
  p {
    margin-bottom: 40px;
  }
`;

const ProjectSubmitted = () => {
  return (
    <Container>
      <img src={projectIcon} className="project icon" alt="" />
      <h1>Project Submitted!</h1>
      <p>
        Project has been submitted,
        <br />
        Project will be created once team leader validates the project.
      </p>
      <LinkButton
        url="/project-list"
        text="go to project page"
        uppercase="uppercase"
      />
    </Container>
  );
};

export default ProjectSubmitted;

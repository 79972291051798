import React, { useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import FormGroup from './FormGroup';
import Label from './Label';
import Description from './Description';
import Error from './Error';
import useErrorHandler from '../../hooks/errorHandler';

const StyledSelect = styled(Select)`
  > div {
    background: white;
    border: 1px solid ${({ theme }) => `${theme.colors.bluish_light_gray}`};
    border-radius: 0;
    min-height: 40px;
    width: ${({ width }) => width || '100%'};
  }
`;

const SelectGroup = ({
  name,
  label,
  details,
  control,
  options,
  placeholder,
  rules,
  error,
  children,
  ...rest
}) => {
  const inputRef = useRef();
  const { clearErrorState } = useErrorHandler();

  useEffect(() => {
    if (error && error.type && error.focus) {
      inputRef.current.focus();
    }
  }, [error]);

  return (
    <FormGroup {...rest}>
      <Label htmlFor={name}>{label}</Label>
      <Description>{details}</Description>
      <Controller
        as={
          <StyledSelect
            ref={inputRef}
            onFocus={() => {
              clearErrorState();
            }}
          />
        }
        components={{
          IndicatorSeparator: () => null,
        }}
        control={control}
        id={name}
        name={name}
        options={options}
        placeholder={placeholder}
        rules={rules}
        isClearable
        {...rest}
      />
      {children}
      {error && <Error>{error.message}</Error>}
    </FormGroup>
  );
};

export default SelectGroup;

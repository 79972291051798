import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '../Form';

/*  This is where typescript would be good..lol
rawData = {
  sheetNameString: [...sheetData],
  sheetNameString: [...sheetData],
}
*/
const ExportCSVBtn = ({ fileName, text, loading, rawData, className }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = () => {
    const wb = { Sheets: {}, SheetNames: [] };

    Object.entries(rawData).forEach(([key, value]) => {
      wb.SheetNames.push(key);
      wb.Sheets[key] = XLSX.utils.json_to_sheet(value);
    });

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      className={className || ''}
      variant="warning"
      onClick={() => exportToCSV()}
      loading={loading}
    >
      {text}
    </Button>
  );
};

export default ExportCSVBtn;

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_DETAILS_PEOPLE } from '../../apollo/queries';
import { DatePicker, ErrorPage, ReportPeopleToolbar } from '../../components';
import { StyledReactTable } from './styled';
import { formatUnitSelectOptions } from '../../utils/formData';
import {
  getReportDetailsPeopleColumns,
  mapReportDetailsPeopleToTableData,
} from './reportDetailPeopleTableData';
import {
  Unit,
  UnitNew,
  UnitAll,
  UnitTeam,
  UnitTeamAllNew,
} from '../../constants/data';
import { errorMessage } from '../../constants/error';
import useAuth from '../../hooks/auth';
import useQueryFilter from '../../hooks/queryFilter';

const ReportDetailsPeople = () => {
  const { getAuthUser, isAdmin, isUnitLeader, isUser } = useAuth();
  const { id, role, unit, team } = getAuthUser();
  const [startDate, setStartDate] = useState(new Date());
  const [division, setDivision] = useState(unit || 'MKT');
  const defaultFilter = isUser()
    ? { date: startDate, userId: id }
    : { date: startDate };

  if (isAdmin()) {
    defaultFilter.unit = division;
  }

  const { roleFilterIncludingSelf: filter } = useQueryFilter(defaultFilter);

  if (isAdmin() || isUnitLeader()) {
    if (division === 'UX') {
      filter.unit = ['UX', 'CX'];
    }
    if (division === 'DSI') {
      filter.unit = ['DSI', 'CX'];
    }
  }

  if (
    startDate < new Date('2022-03-01') &&
    isUnitLeader() &&
    (unit === 'MKTU1' || unit === 'MKTU2' || unit === 'MKT') &&
    division === 'MKT'
  ) {
    filter.unit = ['MKT', 'MKTU1', 'MKTU2'];
  }

  if (
    startDate > new Date('2022-03-01') &&
    startDate < new Date('2023-02-01') &&
    isUnitLeader() &&
    (unit === 'MKTU1' || unit === 'MKTU2' || unit === 'MKT')
  ) {
    filter.unit = ['MKTU1', 'MKTU2'];
  }

  const [
    getReportUsers,
    { loading: userLoading, data: userData, error: userError },
  ] = useLazyQuery(GET_DETAILS_PEOPLE, {
    variables: {
      filter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { control } = useForm({
    defaultValues: {
      filterDivision: { label: UnitAll[division], value: division },
    },
  });

  const onChange = ([selected]) => {
    setDivision(selected.value);
  };

  useEffect(() => {
    if (
      startDate < new Date('2022-03-01') &&
      (division === 'MKTU1' || division === 'MKTU2')
    ) {
      setDivision('MKT');
    }
    getReportUsers();
  }, [startDate, division]);

  let unitSelectOptions = formatUnitSelectOptions(Object.keys(UnitNew));

  if (startDate < new Date('2022-07-01')) {
    unitSelectOptions = formatUnitSelectOptions(Object.keys(Unit));
  }

  const user =
    isAdmin() || isUnitLeader()
      ? {
          unit: division,
          teams: UnitTeam[division],
          role,
        }
      : { unit: division, teams: [team], role };

  if (user.unit === 'UX') {
    user.teams = UnitTeamAllNew.UX;
  }

  if (user.unit === 'DSI') {
    user.teams = UnitTeamAllNew.DSI;
  }

  const tableData = mapReportDetailsPeopleToTableData(
    userData && userData.getReportUsers,
    user,
    division,
    startDate,
  );

  if (userError) {
    return <ErrorPage text={errorMessage.UNEXPECTED} />;
  }

  return (
    <div className="content">
      <DatePicker
        selectedDate={startDate}
        changeDate={setStartDate}
        minDate={new Date('2021-01-01')}
        isMonthPicker
      />
      {(isAdmin() || isUnitLeader()) && (
        <ReportPeopleToolbar
          formData={unitSelectOptions}
          control={control}
          onChange={onChange}
          selectedDate={startDate}
        />
      )}
      <StyledReactTable
        columns={getReportDetailsPeopleColumns()}
        data={tableData}
        loading={userLoading}
      />
    </div>
  );
};

export default ReportDetailsPeople;

import React, { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  InlineInput,
  Button,
  ReactTable,
  PageLoader,
  ErrorPage,
  Error,
  InlineLoader,
} from '../../components';
import { CREATE_CLIENT } from '../../apollo/mutations';
import { GET_CLIENTS, GET_FORM_DATA } from '../../apollo/queries';
import { getClientListColumns } from '../../utils/tableData';
import { errorLocation, errorMessage } from '../../constants/error';
import useErrorHandler from '../../hooks/errorHandler';

const Container = styled.div`
  margin-bottom: 60px;
  background: white;
  padding: 40px 60px;
`;

const ClientList = () => {
  const { error: formError } = useSelector((state) => state);
  const {
    handleAPIError,
    handleValidationError,
    clearErrorState,
  } = useErrorHandler();
  const [filterValue, setFilterValue] = useState('');
  const [editingRow, setEditingRow] = useState(null);
  const { register, handleSubmit, reset } = useForm();
  const [createClient, { loading: createLoading }] = useMutation(
    CREATE_CLIENT,
    {
      refetchQueries: [
        {
          query: GET_CLIENTS,
        },
        {
          query: GET_FORM_DATA,
        },
      ],
    },
  );
  const { loading, data, error: clientError } = useQuery(GET_CLIENTS, {
    fetchPolicy: 'network-only',
  });

  const columns = useMemo(
    () => getClientListColumns({ editingRow, setEditingRow }),
    [editingRow],
  );

  const clientListData = useMemo(() => {
    let filteredList = [];
    if (data && data.getClients) {
      filteredList = data.getClients
        .filter((client) => {
          return client.name.toLowerCase().includes(filterValue.toLowerCase());
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    return filteredList;
  }, [filterValue, data]);

  if (loading) return <PageLoader />;
  if (clientError) {
    return <ErrorPage text={errorMessage.UNEXPECTED} />;
  }

  const onSubmit = async ({ client }) => {
    clearErrorState();

    if (!client) {
      handleValidationError(errorLocation.CLIENT_FORM, {
        message: errorMessage.CLIENT_NAME_REQUIRED,
      });
      return;
    }

    try {
      const response = await createClient({
        variables: {
          name: client,
        },
      });

      if (response && response.data && response.data.createClient) {
        setFilterValue('');
        reset();
      }
    } catch (err) {
      handleAPIError(err, errorLocation.CLIENT_FORM);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: '20px' }}>
        <InlineInput
          width="30%"
          type="text"
          name="client"
          register={register}
          placeholder="Filter or Add Client Name"
          onChange={(e) => {
            clearErrorState();
            const { value } = e.target;
            setFilterValue(value);
            return value;
          }}
        />
        <Button type="submit" inline loading={createLoading}>
          {createLoading ? (
            <InlineLoader loading={createLoading} color="red" />
          ) : (
            'Add'
          )}
        </Button>
        {formError && formError.location === errorLocation.CLIENT_FORM && (
          <Error>{formError.message}</Error>
        )}
      </form>
      <ReactTable columns={columns} data={clientListData} />
    </Container>
  );
};

export default ClientList;

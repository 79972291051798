import {
  SAVE_PROJECT,
  SAVE_PROJECT_ID,
  CHANGE_STEP,
  SAVE_MEMBERS,
  SAVE_MEMBER,
  DELETE_MEMBER,
  CLEAR_MEMBERS_TO_DELETE,
  SAVE_SPENT_DAYS,
  ASSIGN_DAYS,
  UPDATE_DATERANGE,
  RESET_PROJECT,
} from '../constants/actionTypes';

const initialState = {
  id: null,
  data: {
    type: null,
    client: null,
    division: [],
    name: '',
    hashtag: '',
    budget: '',
    currency: { value: 'KRW', label: 'KRW' },
    startDate: null,
    endDate: null,
    contractDays: '',
    basecamp: 'yes',
    contractUrl: '',
    comments: '',
    months: [],
    owner: null,
    reviewer: null,
    lastEditor: null,
    status: null,
    statusOptions: null,
    statusHistory: null,
    createdAt: null,
    updatedAt: null,
  },
  members: [],
  membersToDelete: [],
  step: 0,
  error: null,
};

const project = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PROJECT:
      return {
        ...state,
        data: action.data,
      };
    case SAVE_PROJECT_ID:
      return {
        ...state,
        id: action.id,
      };
    case CHANGE_STEP:
      return {
        ...state,
        step: action.step,
      };
    case SAVE_MEMBERS:
      return {
        ...state,
        members: action.members,
      };
    case SAVE_MEMBER:
      return {
        ...state,
        members: [
          ...state.members,
          {
            id: action.member.value,
            name: action.member.label,
            role: action.role,
            contractDays: action.months.map((month) => ({
              id: null,
              month,
              days: 0,
            })),
            spentDays: null, // { id, days }
          },
        ],
      };
    case SAVE_SPENT_DAYS:
      return {
        ...state,
        members: state.members.map((member) => {
          if (member.id === action.memberId) {
            if (member.spentDays) {
              member.spentDays.days = action.days;
            } else {
              member.spentDays = { id: null, days: action.days };
            }
          }
          return member;
        }),
      };
    case ASSIGN_DAYS:
      return {
        ...state,
        members: state.members.map((member) => {
          if (member.id === action.memberId) {
            member.contractDays = member.contractDays.map((contract) => {
              if (contract.month === action.month) {
                contract.days = action.days;
              }
              return contract;
            });
          }
          return member;
        }),
      };
    case DELETE_MEMBER:
      return {
        ...state,
        members: state.members.filter(
          (member) => member.id !== action.memberId,
        ),
        membersToDelete: [...state.membersToDelete, action.memberId],
      };
    case CLEAR_MEMBERS_TO_DELETE:
      return {
        ...state,
        membersToDelete: [],
      };
    case UPDATE_DATERANGE:
      return {
        ...state,
        data: {
          ...state.data,
          months: action.months,
        },
        members: state.members.map((member) => {
          const removedContractDays = member.contractDays
            .filter((contract) => !action.months.includes(contract.month))
            .map((contract) => {
              contract.days = 0;
              return contract;
            });

          const updatedContractDays = action.months.map((month) => {
            const existingContract = member.contractDays.find(
              (contract) => contract.month === month,
            );
            if (existingContract) {
              const { id, days } = existingContract;
              return {
                id,
                month,
                days,
              };
            }
            return {
              id: null,
              month,
              days: 0,
            };
          });

          member.contractDays = updatedContractDays.concat(
            ...removedContractDays,
          );
          return member;
        }),
      };
    case RESET_PROJECT:
      return initialState;
    default:
      return state;
  }
};

export default project;

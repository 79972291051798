import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import {
  PageLoader,
  ErrorPage,
  LinkButton,
  Button,
  CenteredDiv,
  ReactTable,
} from '../../components';
import {
  GET_USER_TIMESHEET,
  GET_LAST_CLOSING_DATE,
  GET_PROJECTS,
  GET_TEAM_MEMBERS,
} from '../../apollo/queries';
import useAuth from '../../hooks/auth';
import { setProjectFilter } from '../../actions/projectList';
import {
  projectStatus,
  statusList,
  timesheetStatus,
  Unit,
} from '../../constants/data';
import { errorMessage } from '../../constants/error';
import {
  getDashboardTableColumns,
  mapTeamMembersToTableData,
} from '../../utils/tableData';
import { Container } from './styled';
import useQueryFilter from '../../hooks/queryFilter';

const Dashboard = ({ history }) => {
  const dispatch = useDispatch();
  const { getAuthUser, isTeamLeader, isUnitLeader, isAdmin } = useAuth();
  const { id: userId, unit, name } = getAuthUser();
  const { roleFilter: filter, divisionFilter } = useQueryFilter();
  const { loading: userLoading, data: userData, error: userError } = useQuery(
    GET_USER_TIMESHEET,
    {
      variables: {
        id: userId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  const { loading: dateLoading, data: dateData, error: dateError } = useQuery(
    GET_LAST_CLOSING_DATE,
  );
  const {
    loading: myPrjLoading,
    data: myPrjData,
    error: myPrjError,
  } = useQuery(GET_PROJECTS, {
    fetchPolicy: 'cache-and-network',
  });

  const { loading: teamLoading, data: teamData, error: teamError } = useQuery(
    GET_TEAM_MEMBERS,
    {
      variables: { filter },
      fetchPolicy: 'cache-and-network',
    },
  );

  const { loading: prjLoading, data: prjData, error: prjError } = useQuery(
    GET_PROJECTS,
    {
      variables: {
        filter: { ...divisionFilter, projectStatus: projectStatus.ACTIVE },
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  if (userLoading || dateLoading || myPrjLoading || prjLoading || teamLoading) {
    return <PageLoader />;
  }
  if (userError || dateError || myPrjError || prjError || teamError)
    return <ErrorPage text={errorMessage.UNEXPECTED} />;

  const lastClosingDate = new Date(dateData.getLastClosingDate);
  const currentMonthYear = format(lastClosingDate, 'MMMM yyyy');
  const myStatus =
    userData.getUser.timesheetStatus === timesheetStatus.SUBMITTED
      ? timesheetStatus.Submitted
      : timesheetStatus.NotSubmitted;

  const draft = myPrjData.getProjects.projects.filter(
    (prj) =>
      prj.status === projectStatus.DRAFT &&
      ((prj.Team && prj.Team.some((member) => member.id === userId)) ||
        (prj.Reviewer && prj.Reviewer.id === userId) ||
        prj.Owner.id === userId),
  );

  const pending = myPrjData.getProjects.projects.filter(
    (prj) =>
      prj.status === projectStatus.PENDING &&
      (prj.Team.some((member) => member.id === userId) ||
        prj.Reviewer.id === userId ||
        prj.Owner.id === userId),
  );
  const ongoing = myPrjData.getProjects.projects.filter(
    (prj) =>
      prj.status === projectStatus.ACTIVE &&
      !prj.unit.includes(Unit.ASIANCE) &&
      prj.Team.some((member) => member.id === userId),
  );
  const myProjectList = [
    { heading: 'Draft', count: draft.length },
    { heading: 'Pending', count: pending.length },
    { heading: 'On-going', count: ongoing.length },
  ];

  const tableData = mapTeamMembersToTableData(
    teamData.getUsers,
    prjData.getProjects.projects,
  );

  const viewList = () => {
    dispatch(
      setProjectFilter({
        filterStatus: [
          {
            label: statusList[projectStatus.DRAFT],
            value: projectStatus.DRAFT,
          },
          {
            label: statusList[projectStatus.PENDING],
            value: projectStatus.PENDING,
          },
          {
            label: statusList[projectStatus.ACTIVE],
            value: projectStatus.ACTIVE,
          },
        ],
        filterMember: [userId],
        asiancersOptions: [
          {
            value: userId,
            label: name,
          },
        ],
      }),
    );
    history.push('/project-list');
  };

  return (
    <Container submitted={myStatus === timesheetStatus.Submitted}>
      <div>
        <h3>Welcome back!</h3>
        <p>Please review your status and fill out the timesheet.</p>
        <LinkButton url="/timesheet" uppercase={1}>
          Go to Timesheet Page
        </LinkButton>
      </div>
      <div className="status-container">
        <div>
          <h4>My Status</h4>
          <div>
            <div className="month-year">{currentMonthYear}</div>
            <div className="timesheet-status big-font">{myStatus}</div>
          </div>
        </div>
        <div>
          <div className="heading">
            <h4>My Project List</h4>
            <Button className="arrow-btn" onClick={viewList} arrow>
              View List
            </Button>
          </div>
          <hr />
          <div className="project-status">
            {myProjectList.map((item) => (
              <CenteredDiv key={item.heading} columnDirection>
                <div>{item.heading}</div>
                <div className="big-font">{item.count}</div>
              </CenteredDiv>
            ))}
          </div>
        </div>
      </div>
      {(isTeamLeader(unit) || isUnitLeader() || isAdmin()) && (
        <div className="table-container content">
          <ReactTable columns={getDashboardTableColumns()} data={tableData} />
        </div>
      )}
    </Container>
  );
};

export default Dashboard;

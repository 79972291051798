import styled from 'styled-components/macro';

const CenteredDiv = styled.div`
  width: 100%;
  height: ${({ height }) => height || '100%'};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ columnDirection }) =>
    columnDirection ? 'column' : 'initial'};
`;

export default CenteredDiv;

import React from 'react';
import styled from 'styled-components/macro';

const Text = styled.span`
  font-weight: ${({ isHeader }) => (isHeader ? 'bold' : 'normal')};
  font-size: ${({ isExpanded, isSubRow, isHeader }) =>
    (isExpanded && '1.25rem') || (isSubRow && isHeader ? '0.875rem' : '1rem')};
`;

const HashtagCell = ({ value, row }) => {
  const { isExpanded, depth, index } = row;

  return (
    <Text
      isExpanded={isExpanded}
      isHeader={depth === 0 || (depth === 1 && index === 0)}
      isSubRow={depth === 1}
    >
      {value}
    </Text>
  );
};

export default HashtagCell;

import { format } from 'date-fns';
import { statusList } from '../../constants/data';

const camelToTitle = (str) => {
  const formattedStr = str.replace(/([a-z])([A-Z])/g, '$1 $2');
  if (formattedStr === 'Owner') {
    return formattedStr;
  }
  return formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);
};

const convertProjectOverview = (projectData) => {
  const projectOverview = {
    Overview: format(new Date(), 'yyyy.MM.dd'),
    Details: `https://timesheet.asiance.com/report/projects/${projectData.id}`,
  };

  const projectDataConverted = [];

  let totalSpentDays;
  let dayForcast;
  let fullWeeksSinceProjectStart;
  let dayPerWeekInContract;

  Object.entries(projectData).forEach(([key, value]) => {
    if (key === 'Client' && value) {
      projectDataConverted.push({
        Overview: key,
        Details: value.name,
      });
    } else if (key === 'unit' && value) {
      projectDataConverted.push({
        Overview: 'Unit',
        Details: value.join(', '),
      });
    } else if (key === 'Team' && value) {
      const teamMembers = value.map((member) =>
        member.projectRole
          ? `${member.name}(${member.projectRole})`
          : member.name,
      );
      projectDataConverted.push({
        Overview: 'Project Members',
        Details: teamMembers.join(', '),
      });
    } else if (
      key === 'Owner' ||
      key === 'Reviewer' ||
      key === 'LastModifiedBy'
    ) {
      if (value) {
        projectDataConverted.push({
          Overview: camelToTitle(key),
          Details: value.email ? `${value.name} - ${value.email}` : value.name,
        });
      }
    } else if (key === 'projDurationInWeeks' && value) {
      if (projectData.startDate) {
        projectDataConverted.push({
          Overview: camelToTitle(key),
          Details: +value.toFixed(1, 10),
        });
      }
    } else if (key === 'dayPerWeekInContract' && value) {
      if (value > 0) {
        dayPerWeekInContract = value;
        projectDataConverted.push({
          Overview: camelToTitle(key),
          Details: +value.toFixed(1, 10),
        });
      }
    } else if (key === 'fullWeeksSinceProjectStart' && value) {
      if (projectData.startDate) {
        fullWeeksSinceProjectStart = value;
        projectDataConverted.push({
          Overview: camelToTitle(key),
          Details: value,
        });
      }
    } else if (key === 'createdAt' || key === 'updatedAt') {
      if (value) {
        projectDataConverted.push({
          Overview: camelToTitle(key),
          Details: format(new Date(value), 'yyyy-MM-dd HH:mm:ss OOOO'),
        });
      }
    } else if (key === 'dayForcast' && value) {
      dayForcast = value;
      projectDataConverted.push({
        Overview: 'Contract Days',
        Details: value,
      });
    } else if (key === 'totalSpentDays' && value) {
      totalSpentDays = value;
      projectDataConverted.push({
        Overview: 'Total Contract Days Spent',
        Details: value,
      });
    } else if (key === 'status' && value) {
      projectDataConverted.push({
        Overview: camelToTitle(key),
        Details: statusList[value],
      });
    } else if (
      key !== '__typename' &&
      key !== 'StatusHistory' &&
      key !== 'id'
    ) {
      if (value) {
        projectDataConverted.push({
          Overview: camelToTitle(key),
          Details: value,
        });
      }
    }
  });

  if (dayForcast && totalSpentDays) {
    projectDataConverted.push({
      Overview: 'Real Progress',
      Details: `${((totalSpentDays / dayForcast) * 100).toFixed(2)}%`,
    });

    if (dayPerWeekInContract) {
      const theoreticalDaysSpent =
        fullWeeksSinceProjectStart * dayPerWeekInContract;

      const theoreticalProgress =
        +((theoreticalDaysSpent / dayForcast) * 100).toFixed(2) > 100
          ? '100%'
          : `${((theoreticalDaysSpent / dayForcast) * 100).toFixed(2)}%`;

      projectDataConverted.push({
        Overview: 'Theoretical Progress',
        Details: theoreticalProgress,
      });
    }
  }

  projectDataConverted.unshift(projectOverview);

  return { ProjectOverView: projectDataConverted };
};

const convertProgressByMember = (progressData) => {
  const memberDataConverted = {};

  progressData.forEach((member) => {
    const memberSubArr = [];
    memberSubArr.push({
      name: member.name,
      role: member.projectRole,
      realProgress: member.realProgress,
      theoreticalProgress: member.theoreticalProgress,
      spentDays: member.spentDays,
    });

    member.subRows.forEach((row) => {
      if (row.spentDays) {
        memberSubArr.push({
          name: row.name,
          theoreticalProgress: row.theoreticalProgress,
          spentDays: row.spentDays,
        });
      }
    });

    memberDataConverted[
      member.projectRole ? `${member.name}(${member.projectRole})` : member.name
    ] = memberSubArr;
  });

  return memberDataConverted;
};

export const convertProjectProgressForExcel = (
  projectData,
  progressData,
  selectedRows,
) => {
  let progressByMember;
  if (selectedRows.length > 0) {
    const selectedMembers = selectedRows.map((row) => row.original.name);
    const filteredMembers = progressData.filter((member) =>
      selectedMembers.includes(member.name),
    );
    progressByMember = convertProgressByMember(filteredMembers);
  } else {
    progressByMember = convertProgressByMember(progressData);
  }
  const projectOverview = convertProjectOverview(projectData);

  return {
    ...projectOverview,
    ...progressByMember,
  };
};

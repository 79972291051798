import styled from 'styled-components/macro';

export const Container = styled.div`
  > div {
    margin-bottom: 40px;
  }

  h3 {
    font-weight: 600;
    font-family: 'futura-pt', sans-serif;
  }

  .status-container {
    display: flex;
    width: 100%;
    font-size: ${({ theme }) => theme.mixins.getvw('24')};

    > div {
      background: white;
      padding: ${({ theme }) => theme.mixins.getvw('50')};
      flex-grow: 2.5;
      &:first-child {
        flex-grow: 0.5;
        margin-right: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    h4 {
      font-weight: 600;
      font-family: 'futura-pt', sans-serif;
    }
    .big-font {
      font-size: 3.25rem;
      @media screen and (max-width: 1668px) {
        font-size: 1.875rem;
      }
      @media screen and (max-width: 1433px) {
        font-size: 130%;
      }
    }
    .month-year {
      font-family: 'futura-pt', sans-serif;
      padding-top: 16px;
      text-align: center;
    }
    .timesheet-status {
      color: ${({ theme, submitted }) =>
        submitted ? theme.colors.green : theme.colors.red};
      text-align: center;
    }
    .project-status {
      font-family: 'futura-pt', sans-serif;
      display: flex;
    }
    .heading {
      display: flex;
      justify-content: space-between;
    }
  }
`;

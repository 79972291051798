import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  font-size: 2rem;
  color: ${({ submitted, theme }) =>
    submitted ? theme.colors.green : theme.colors.red};

  &:hover,
  &:focus {
    color: ${({ submitted, theme }) =>
      submitted ? theme.colors.green : theme.colors.red};
    text-decoration: none;
  }
`;

const LinkToTeamCell = ({ column: { id }, row: { original } }) => {
  const { submitted, notSubmitted } = original;
  return (
    <StyledLink to="/admin/team" submitted={id === 'submitted'}>
      {(id === 'submitted' ? submitted : notSubmitted) || '-'}
    </StyledLink>
  );
};

export default LinkToTeamCell;

import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MenuItem from './MenuItem';
import { menu } from '../../routes';
import { toggleSidebar } from '../../actions/layout';
import useAuth from '../../hooks/auth';

import Xbtn from '../../assets/images/X.svg';

const Container = styled.div`
  text-transform: uppercase;
  font-family: 'futura-pt', sans-serif;
  .sideMenuItems {
    button {
      width: 100%;
      border: none;
    }
    .sideMenuCloseBtn {
      cursor: pointer;
      text-transform: none;
      color: white;
      opacity: 1;
      background-color: #1e2730;
      padding: 0 10%;
      margin-bottom: 10%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      height: 48px;
      &:focus {
        outline: none;
      }
      img {
        height: 10px;
      }
    }
  }
`;

const Menu = ({ location: { pathname } }) => {
  const dispatch = useDispatch();
  const { getAuthUser } = useAuth();
  const { role } = getAuthUser();

  return (
    <Container>
      <div className="sideMenuItems">
        <button
          type="button"
          className="sideMenuCloseBtn"
          onClick={() => dispatch(toggleSidebar())}
        >
          Close Menu
          <img src={Xbtn} alt="" />
        </button>
        {menu.map(({ path, name, restricted, submenu }) =>
          restricted && !restricted.includes(role) ? null : (
            <div key={path}>
              <MenuItem
                path={path}
                key={name}
                name={name}
                className={
                  (pathname === path ||
                    (submenu &&
                      submenu.some((sub) => sub.path === pathname))) &&
                  'selected'
                }
              />
              {submenu &&
                submenu
                  .filter((sub) => !sub.hidden)
                  .map(
                    ({
                      path: subPath,
                      name: subName,
                      restricted: subRestricted,
                    }) =>
                      subRestricted && !subRestricted.includes(role) ? null : (
                        <MenuItem
                          path={subPath}
                          key={subName}
                          name={subName}
                          className={
                            pathname === subPath
                              ? 'submenu selected'
                              : 'submenu'
                          }
                        >
                          {subName}
                        </MenuItem>
                      ),
                  )}
            </div>
          ),
        )}
      </div>
    </Container>
  );
};

export default withRouter(Menu);

import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteMember } from '../../../actions/project';
import { Button } from '../../Form';
import { projectStatus } from '../../../constants/data';

const ContractButtonCell = ({ row: { original } }) => {
  const { memberId, editable, status } = original;
  const assignedUserId = Number(memberId);
  const dispatch = useDispatch();

  const removeMemberOnClick = () => {
    dispatch(deleteMember(assignedUserId));
  };

  return status >= projectStatus.ACTIVE || !editable ? null : (
    <Button
      className="icon remove"
      type="button"
      text=""
      disabled={!editable || status >= projectStatus.ACTIVE}
      onClick={removeMemberOnClick}
    />
  );
};

export default ContractButtonCell;

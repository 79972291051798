import {
  TOGGLE_SIDEBAR,
  TOGGLE_SUBMENU,
  UPDATE_TITLE,
} from '../constants/actionTypes';

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

export const toggleSubmenu = () => ({
  type: TOGGLE_SUBMENU,
});

export const updateTitle = (title) => ({
  type: UPDATE_TITLE,
  title,
});

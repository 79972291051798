import styled from 'styled-components/macro';
import { InlineSelect } from '../Form';

export const Container = styled.div`
  padding: 10px 0 30px 0;
  .total {
    strong {
      padding-right: 5px;
    }
  }
  .InlineSelect__Container-bqvfc8-0 {
    @media screen and (max-width: 1199.98px) {
      width: 18%;
    }
    @media screen and (max-width: 991.98px) {
      width: 100%;
    }
  }
  .row {
    .sub-button {
      height: 40px;
      margin-left: 10px;
      margin-right: 0;
      text-transform: none;
      padding: 0 20px;
      @media screen and (max-width: 991.98px) {
        margin-left: 0px;
        margin-top: 10px;
        width: 100%;
      }
    }
  }
`;

export const SelectField = styled(InlineSelect)`
  margin-left: 0;
  & > div:not(:last-child) {
    padding: 0 0 0 3px !important;
    @media screen and (max-width: 991.98px) {
      margin: 0 0 7px 0 !important;
    }
  }
`;

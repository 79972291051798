import {
  TOGGLE_SIDEBAR,
  TOGGLE_SUBMENU,
  UPDATE_TITLE,
} from '../constants/actionTypes';

const initialState = {
  title: '',
  sidebar: true,
  submenu: {
    show: true,
  },
};

const layout = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case TOGGLE_SUBMENU:
      return { ...state, submenu: { show: !state.submenu.show } };
    case UPDATE_TITLE:
      return { ...state, title: action.title };
    default:
      return state;
  }
};

export default layout;

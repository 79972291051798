import { UPDATE_USER_LEAVEDATES } from '../constants/actionTypes';

const initialState = {
  leaveDatesToUpdateArr: [],
};

const leaveDatesToUpdate = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_LEAVEDATES: {
      const ogArr = [...state.leaveDatesToUpdateArr];
      const incomingObj = action.leaveDatesDataToUpdate;
      let newArr = [];

      if (ogArr.length > 0) {
        const idExists = ogArr.some((obj) => obj.id === incomingObj.id);
        if (idExists) {
          ogArr.forEach((obj) => {
            if (obj.id === incomingObj.id) {
              const mergedObj = { ...obj, ...incomingObj };
              newArr.push(mergedObj);
            } else {
              newArr.push(obj);
            }
          });
        }
        if (!idExists) {
          newArr = [...state.leaveDatesToUpdateArr, incomingObj];
        }
      } else {
        newArr.push(incomingObj);
      }

      return {
        ...state,
        leaveDatesToUpdateArr: [...newArr],
      };
    }
    default:
      return state;
  }
};

export default leaveDatesToUpdate;

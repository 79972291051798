import React, { useEffect, useState, useRef, useMemo } from 'react';
import styled from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { InlineInput, CustomSelect } from '../../Form';
import { saveDuration, removeDuration } from '../../../actions/timesheet';
import {
  convertDurationToHourAndMin,
  convertHourAndMinToDuration,
  validateTimeinput,
} from '../../../utils/datetime';
import { durationOptions } from '../../../constants/formOptions';
import { errorLocation, errorField } from '../../../constants/error';
import useErrorHandler from '../../../hooks/errorHandler';

const Cell = styled.div`
  position: static;
  border: ${({ isError }) => (isError ? '1px solid red' : 'none')};
  .__react_component_tooltip {
    display: ${({ disabled, isError }) =>
      disabled || (!disabled && isError) ? 'inline-block' : 'none'};
  }
`;

const Duration = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'initial')};
`;

const DropdownButton = styled.a`
  position: absolute;
  right: 0px;
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  height: 100%;
  width: 30px;
  &:before,
  &:after {
    content: '';
    border: ${({ theme }) => `1px solid ${theme.colors.asiance_navy}`};
    position: absolute;
    top: 50%;
  }
  &:before {
    width: 10px;
    height: 1px;
    transform: rotate(45deg);
    right: 14px;
  }
  &:after {
    width: 10px;
    height: 1px;
    transform: rotate(-45deg);
    right: 8px;
  }
`;

const DropdownList = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'initial')};
`;

const TimeInput = styled(InlineInput)`
  min-height: 0;
  height: 36px;
  border-radius: 0;
  ${({ isWeekend, isDuringLeaveDate }) =>
    (isWeekend || isDuringLeaveDate) && `background: #EBEDF0`};
`;

const HourInputCell = ({ value, row, column }) => {
  const convertedVal = value ? convertDurationToHourAndMin(value) : '';
  const {
    original: { id, projectId, entries },
  } = row;
  const { id: date, disabled, isWeekend, isDuringLeaveDate } = column;
  const { clearErrorState } = useErrorHandler();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state);
  const { startDate } = useSelector((state) => state.timesheet.selectedWeek);
  const isFirstDateColumn = format(startDate, 'yyyy-MM-dd') === date;
  const [inputVal, setInputVal] = useState(null);
  const [displayedVal, setDisplayedVal] = useState(convertedVal);
  const [dropdownBtnShow, setDropdownBtnShow] = useState(false);
  const [dropdownListShow, setDropdownListShow] = useState(false);
  const [cellPosition, setCellPosition] = useState(null);
  const inputRef = useRef();
  const dropdownBtnRef = useRef(null);
  const dropdownListRef = useRef(null);
  const tooltipRef = useRef(null);
  const noHashtag = useMemo(() => !projectId, [projectId]);
  const isError = useMemo(
    () =>
      error &&
      error.location === errorLocation.TIMESHEET_SAVE &&
      error.field === errorField.DURATION &&
      error.args.includes(id) &&
      !(inputVal || displayedVal),
    [error],
  );

  const handleClickOutside = (e) => {
    if (
      dropdownListRef.current &&
      !dropdownListRef.current.contains(e.target)
    ) {
      dropdownListShow &&
      dropdownBtnRef.current &&
      dropdownBtnRef.current.contains(e.target)
        ? setDropdownListShow(true)
        : setDropdownListShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (dropdownListShow) {
      setDropdownBtnShow(true);
      return;
    }
    if (inputRef.current === document.activeElement) {
      setDropdownBtnShow(true);
      return;
    }
    setDropdownBtnShow(false);
  }, [dropdownListShow]);

  useEffect(() => {
    if (isError) {
      ReactTooltip.show(tooltipRef.current);
    }
  }, [isError]);

  useEffect(() => {
    const converted = value ? convertDurationToHourAndMin(value) : '';
    setDisplayedVal(converted);
  }, [value]);

  useEffect(() => {
    if (displayedVal) {
      const convertedInput = convertHourAndMinToDuration(displayedVal);
      dispatch(saveDuration(id, date, convertedInput));
    } else {
      const sameDateEntry = entries.find((entry) => entry.date === date);
      const isSavedEntry = sameDateEntry && !!sameDateEntry.id;
      if (isSavedEntry) {
        dispatch(saveDuration(id, date, 0));
      } else {
        dispatch(removeDuration(id, date));
      }
    }
  }, [displayedVal]);

  return (
    <Cell disabled={noHashtag} isError={isError}>
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <Duration
        ref={isFirstDateColumn ? tooltipRef : null}
        disabled={disabled || noHashtag}
        data-tip={(!disabled && noHashtag && 'Select project first.') || ''}
        data-for={`date-cell-${date}-${id}`}
        onMouseOver={() => {
          if (!disabled) {
            setDropdownBtnShow(true);
          }
        }}
        onFocus={() => {
          if (!disabled) {
            setDropdownBtnShow(true);
          }
        }}
        onMouseOut={() => {
          if (!dropdownListShow) {
            setDropdownBtnShow(false);
          }
        }}
      >
        <TimeInput
          type="text"
          name="duration"
          register={inputRef}
          value={inputVal || displayedVal}
          isWeekend={isWeekend}
          isDuringLeaveDate={isDuringLeaveDate}
          disabled={disabled || noHashtag}
          onChange={(e) => {
            const { value: rawValue } = e.target;
            setInputVal(rawValue);
            setDisplayedVal('');
          }}
          onBlur={() => {
            if (inputVal) {
              const formatted = validateTimeinput(inputVal);
              setDisplayedVal(formatted);
              setInputVal(null);
            } else {
              setDisplayedVal(convertedVal);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              inputRef.current.blur();
            }
          }}
          autoComplete="off"
          standalone
        />
        {!noHashtag && dropdownBtnShow && (
          <DropdownButton
            ref={dropdownBtnRef}
            disabled={noHashtag}
            onClick={(e) => {
              clearErrorState();
              if (!noHashtag) {
                setDropdownListShow(!dropdownListShow);
                setCellPosition(
                  `${e.target.parentElement.getBoundingClientRect().left}px`,
                );
              }
            }}
            onMouseEnter={() => {
              setDropdownBtnShow(true);
            }}
            onMouseLeave={() => {
              if (!dropdownListShow) {
                setDropdownBtnShow(false);
              }
            }}
          />
        )}
      </Duration>
      <ReactTooltip id={`date-cell-${date}-${id}`} />
      <DropdownList ref={dropdownListRef} disabled={disabled || noHashtag}>
        <CustomSelect
          options={durationOptions}
          selected={displayedVal}
          show={dropdownListShow}
          left={cellPosition}
          setShow={setDropdownListShow}
          setDisplayedVal={setDisplayedVal}
        />
      </DropdownList>
    </Cell>
  );
};

export default HourInputCell;

/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addLeaveDatesToUpdate } from '../../../actions/userLeaveDates';

const Input = styled.input`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.bluish_light_gray}`};
  min-height: 38px;
  padding: 2px 13px;
  outline: none;
  color: ${({ theme }) => theme.colors.asiance_navy};

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.bluish_gray};
  }
  &:focus {
    &::-webkit-input-placeholder {
      opacity: 0;
    }
  }
`;

const LongTermLeaveDateNotesCell = ({
  value,
  row: { original, index },
  column: { info },
}) => {
  const { editingRow, setEditingRow } = info;
  const [editable, setEditable] = useState(false);
  const [notes, setNotes] = useState(value);
  const { register, setValue, watch } = useForm();
  const notesToUpdate = watch('notes');
  const dispatch = useDispatch();
  const notesRef = useRef(null);

  useEffect(() => {
    setValue('notes', value);
    setEditable(editingRow === index);
  }, [editingRow]);

  useEffect(() => {
    setValue('notes', value);
  }, [value]);

  const handleClickOutside = async (e) => {
    if (e.target !== document.activeElement) {
      setEditingRow(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (notesToUpdate && !editable) {
      dispatch(
        addLeaveDatesToUpdate({
          id: original.id,
          notes,
          deleteDates: false,
        }),
      );
    }
  }, [notesToUpdate]);

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      dispatch(
        addLeaveDatesToUpdate({
          id: original.id,
          notes,
          deleteDates: false,
        }),
      );
      setEditingRow(null);
    }
  };

  return (
    <>
      {editable ? (
        <Input
          name="notes"
          type="text"
          defaultValue={notes}
          ref={(e) => {
            register(e);
            notesRef.current = e;
          }}
          onChange={(e) => {
            setNotes(e.target.value);
            return e.target.value;
          }}
          onKeyPress={handleKeyPress}
        />
      ) : (
        notes
      )}
    </>
  );
};

export default LongTermLeaveDateNotesCell;

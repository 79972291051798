import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { format } from 'date-fns';
import { GET_REPORT_RECAPS } from '../../apollo/queries';
import { DatePicker, ErrorPage, ExportCSVBtn } from '../../components';
import { StyledReactTable } from './styled';
import {
  getReportRecapColumns,
  mapReportRecapToTableData,
  mapReportRecapToTableDataAdmin,
  mapReportDetailsPeopleToTableDataForExport,
} from './reportRecapTableData';

import {
  Unit,
  UnitNew,
  UnitTeamAll,
  UnitTeamAllNew,
} from '../../constants/data';
import { errorMessage } from '../../constants/error';
import useAuth from '../../hooks/auth';
import useQueryFilter from '../../hooks/queryFilter';

const ReportRecap = () => {
  const { getAuthUser, isAdmin, isUnitLeader } = useAuth();
  const { role, unit, team, pastUnits, pastTeams } = getAuthUser();
  const [startDate, setStartDate] = useState(new Date('2024-01-01'));
  const [dateFormatted, setDateFormatted] = useState(
    format(startDate, 'yyyy.MM'),
  );
  const defaultFilter = { date: startDate };
  const { roleFilterForRecap: filter } = useQueryFilter(defaultFilter);

  if (
    startDate < new Date('2023-02-01') &&
    isUnitLeader &&
    (unit === 'MKTU1' || unit === 'MKTU2' || unit === 'MKT')
  ) {
    filter.unit = ['MKTU1', 'MKTU2', 'MKT'];
  }

  const [
    getReportUsers,
    { loading: userLoading, data: userData, error: userError },
  ] = useLazyQuery(GET_REPORT_RECAPS, {
    variables: {
      filter,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    getReportUsers();
    setDateFormatted(format(startDate, 'yyyy.MM'));
  }, [startDate]);

  if (userError) {
    return <ErrorPage text={errorMessage.UNEXPECTED} />;
  }

  let user = { unit, teams: [team], role };

  if (team === 'DEV' || team === 'DESIGN' || team === 'DSI' || team === 'PM') {
    user.unit = pastUnits;

    if (team === 'DSI' || team === 'PM') {
      user.teams = pastTeams;
    }
  }

  if (isAdmin()) {
    if (startDate < new Date('2022-07-01')) {
      user = {
        unit: Unit,
        teams: UnitTeamAll,
        role,
      };
    } else if (startDate >= new Date('2022-07-01')) {
      user = {
        unit: UnitNew,
        teams: UnitTeamAllNew,
        role,
      };
    }
  }

  if (isUnitLeader()) {
    if (
      startDate < new Date('2022-03-01') &&
      (unit === 'MKTU1' || unit === 'MKTU2')
    ) {
      user = {
        unit: 'MKT',
        teams: UnitTeamAll.MKT,
        role,
      };
    } else {
      user = {
        unit,
        teams: UnitTeamAllNew[unit],
        role,
      };
    }
  }

  let tableData = [];

  if (!isAdmin()) {
    tableData = mapReportRecapToTableData(
      startDate,
      userData && userData.getReportUsers,
      user.teams,
      user.unit,
      role,
    );
  }

  if (
    isAdmin() ||
    (isUnitLeader() &&
      (user.unit === 'MKTU1' || user.unit === 'MKTU2') &&
      startDate >= new Date('2022-03-01')) ||
    (isUnitLeader() &&
      user.unit === 'MKT' &&
      startDate < new Date('2023-02-01') &&
      startDate >= new Date('2022-03-01'))
  ) {
    tableData = mapReportRecapToTableDataAdmin(
      startDate,
      userData && userData.getReportUsers,
      user,
    );
  }

  // for Excel Export
  const reportRecapDataSorted = tableData.map((row) => {
    const obj = {
      Unit_division: row.division,
      Contract_Days_Forecast: row.contractDays,
      Contract_Days_Spent: row.idealNumContractDays,
      Average_Contract_Days_Forecast: row.avgContractDaysForecast,
      Avg_Contract_Of_1MM: row.avgContractOf1MM,
      Internal_Client_Total_Days_Spent: row.totalDays,
    };
    return obj;
  });

  const anotherTableData = mapReportDetailsPeopleToTableDataForExport(
    userData && userData.getReportUsers,
    user,
  );

  const reportDetailsPeopleDataSorted = anotherTableData.map((row) => {
    const obj = {
      Unit_Team: row.division,
      Name: row.name,
      Contract_Days_Forecast: row.contractDays,
      Contract_Days_Spent: row.realDays,
      Internal_Client_Total_Days_Spent: row.totalDays,
    };
    return obj;
  });

  const excelRawData = {
    Recap_Unit_Team: reportRecapDataSorted,
    Details_People: reportDetailsPeopleDataSorted,
  };

  return (
    <div className="content">
      <DatePicker
        selectedDate={startDate}
        changeDate={setStartDate}
        minDate={new Date('2021-01-01')}
        isMonthPicker
      />
      <StyledReactTable
        columns={getReportRecapColumns()}
        data={tableData}
        loading={userLoading}
      />
      {(isAdmin() || isUnitLeader()) && (
        <ExportCSVBtn
          className="export-btn-margin-top"
          fileName={dateFormatted}
          loading={userLoading}
          rawData={excelRawData}
          text={`Export report for ${format(startDate, 'yyyy.MM')}`}
        />
      )}
    </div>
  );
};

export default ReportRecap;

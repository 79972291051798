import React, { useState, useRef, useMemo } from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { Modal } from 'react-bootstrap';
import enGb from 'date-fns/locale/en-GB';
import {
  UPDATE_USER_LEAVEDATE,
  UPDATE_USER_LONGTERM_LEAVEDATES,
} from '../../apollo/mutations';
import { GET_TEAM_MEMBERS, GET_USER } from '../../apollo/queries';
import { ReactTable } from '..';
import { getLongTermLeavesColumns } from '../../utils/tableData';
import { Button, Error } from '../Form';
import { InlineLoader } from '../Loader';
import { StyledModal } from './styled';
import calendar from '../../assets/images/calendar-new.svg';
import { stringifyDate } from '../../utils/datetime';

registerLocale('en-gb', enGb);

const WrapDiv = styled.div`
  margin-bottom: 40px;

  p {
    margin: 0;
    line-height: 1.5;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const DatePickerContainer = styled.div`
  position: relative;
  padding: 0 10px;
  border: 1px solid ${({ theme }) => `${theme.colors.bluish_light_gray}`};
  margin: 20px 60px;

  &.flex-wrap {
    margin: 0;
    width: 48%;
  }

  .react-datepicker-wrapper {
    width: 100%;
    &:after {
      content: url(${calendar});
      position: absolute;
      right: 10px;
      top: 6px;
      cursor: pointer;
    }
  }
`;

const StyledDatePicker = styled(ReactDatePicker)`
  border: none;
  min-height: 38px;
  padding: 4px;
  width: calc(100% - 50px);
  &:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => `${theme.colors.bluish_gray}`};
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => `${theme.colors.bluish_gray}`};
  }
  &::placeholder {
    color: ${({ theme }) => `${theme.colors.bluish_gray}`};
  }
  &#startDate {
    margin-left: 0;
  }
  .leaveDate {
    width: 400px;
  }
`;

const Input = styled.input`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.bluish_light_gray}`};
  min-height: 38px;
  padding: 2px 13px;
  margin-bottom: 30px;
  outline: none;
  color: ${({ theme }) => theme.colors.asiance_navy};

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.bluish_gray};
  }
  &:focus {
    &::-webkit-input-placeholder {
      opacity: 0;
    }
  }
`;

const UserLeaveConfirmModal = ({
  show,
  error,
  loading,
  handleClose,
  userId,
  initialLeaveDate,
  userName,
  longTermLeavesArr,
}) => {
  const [userLeaveDate, setUserLeaveDate] = useState(initialLeaveDate);
  const [longTermLeaveStartDate, setLongTermLeaveStartDate] = useState(null);
  const [longTermLeaveEndDate, setLongTermLeaveEndDate] = useState(null);
  const [longTermLeaveNotes, setLongTermLeaveNotes] = useState(null);

  const [editingRow, setEditingRow] = useState(null);

  const leaveDateRef = useRef();
  const longTermLeaveStartDateRef = useRef();
  const longTermLeaveEndDateRef = useRef();
  const longTermLeaveNotesRef = useRef();

  const { control } = useForm();

  const columns = useMemo(
    () => getLongTermLeavesColumns({ userId, editingRow, setEditingRow }),
    [editingRow],
  );

  const [updateUserLongTermLeaveDates] = useMutation(
    UPDATE_USER_LONGTERM_LEAVEDATES,
  );

  const [updateLeaveDate] = useMutation(UPDATE_USER_LEAVEDATE, {
    refetchQueries: [
      {
        query: GET_TEAM_MEMBERS,
      },
      {
        query: GET_USER,
        variables: {
          id: userId,
        },
      },
    ],
  });

  const { leaveDatesToUpdateArr } = useSelector(
    (state) => state.leaveDatesToUpdate,
  );

  return (
    <StyledModal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Set leave dates for {userName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WrapDiv>
          <b>
            <p>
              To enter long term leave dates for {userName}, please select the
              dates and add necessary notes below.
            </p>
          </b>
          <FlexContainer>
            <DatePickerContainer className="flex-wrap">
              <Controller
                as={<StyledDatePicker ref={longTermLeaveStartDateRef} />}
                control={control}
                id="longTermLeaveStartDate"
                name="longTermLeaveStartDate"
                locale="en-gb"
                dateFormat="yyyy.MM.dd"
                placeholderText="Start Date (YYYY.MM.DD)"
                valueName="selected"
                defaultValue={null}
                onChange={([selected]) => {
                  setLongTermLeaveStartDate(selected);
                  return selected;
                }}
                onFocus={() => longTermLeaveStartDateRef.current.input.focus()}
                autoComplete="off"
                showYearDropdown
                showMonthDropdown
              />
            </DatePickerContainer>
            <DatePickerContainer className="flex-wrap">
              <Controller
                as={<StyledDatePicker ref={longTermLeaveEndDateRef} />}
                control={control}
                id="longTermLeaveEndDate"
                name="longTermLeaveEndDate"
                locale="en-gb"
                dateFormat="yyyy.MM.dd"
                placeholderText="End Date (YYYY.MM.DD)"
                valueName="selected"
                defaultValue={null}
                onChange={([selected]) => {
                  setLongTermLeaveEndDate(selected);
                  return selected;
                }}
                onFocus={() => longTermLeaveEndDateRef.current.input.focus()}
                autoComplete="off"
                showYearDropdown
                showMonthDropdown
              />
            </DatePickerContainer>
          </FlexContainer>
          <Input
            type="text"
            placeholder={`Enter notes on leave (i.e. "Maternity Leave, etc.")`}
            ref={longTermLeaveNotesRef}
            onChange={(e) => {
              setLongTermLeaveNotes(e.target.value);
            }}
          />
          {longTermLeavesArr.length > 0 && getLongTermLeavesColumns() && (
            <ReactTable columns={columns} data={longTermLeavesArr} />
          )}
        </WrapDiv>
        <WrapDiv>
          <b>
            <p>
              To set a resignation date for {userName}, please select a date
              below.
            </p>
            <p>
              *The user will be hidden from the following month of their
              selected resignation date.
            </p>
          </b>
          <DatePickerContainer>
            <Controller
              as={<StyledDatePicker ref={leaveDateRef} />}
              control={control}
              id="leaveDate"
              name="leaveDate"
              locale="en-gb"
              dateFormat="yyyy.MM.dd"
              placeholderText="Leave Date (YYYY.MM.DD)"
              valueName="selected"
              defaultValue={userLeaveDate ? new Date(userLeaveDate) : null}
              onChange={([selected]) => {
                setUserLeaveDate(selected);
                return selected;
              }}
              onFocus={() => leaveDateRef.current.input.focus()}
              autoComplete="off"
              showYearDropdown
              showMonthDropdown
            />
          </DatePickerContainer>
        </WrapDiv>
      </Modal.Body>
      {error && <Error>{error.message}</Error>}
      <Modal.Footer>
        <Button
          type="redbtn"
          data-cy="statusUpdate"
          inline
          loading={loading}
          onClick={() => {
            const newLeaveDate = stringifyDate(userLeaveDate);

            if (
              longTermLeaveStartDate ||
              longTermLeaveEndDate ||
              longTermLeaveNotes ||
              userLeaveDate !== initialLeaveDate
            ) {
              const variables = {
                variables: {
                  userId,
                  userLeaveDate: newLeaveDate,
                  newLongTermLeaveStartDate: longTermLeaveStartDate,
                  newLongTermLeaveEndDate: longTermLeaveEndDate,
                  newLongTermLeaveNotes: longTermLeaveNotes,
                },
              };
              updateLeaveDate(variables);
            }

            const variablesForUpdate = {
              variables: {
                longTermLeaveDataToUpdate: leaveDatesToUpdateArr,
              },
            };
            if (leaveDatesToUpdateArr.length > 0) {
              updateUserLongTermLeaveDates(variablesForUpdate);
            }
          }}
        >
          {loading ? <InlineLoader loading={loading} color="red" /> : 'Save'}
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

export default withRouter(UserLeaveConfirmModal);

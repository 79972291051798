import React from 'react';
import styled from 'styled-components/macro';

const Message = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.asiance_red};
  font-size: 12px;
  margin: 8px 0;
  padding: ${({ padding }) => padding};
  width: 100%;
  text-align: ${({ centered, right }) =>
    (centered && 'center') || (right && 'right') || 'initial'};
`;

const Error = ({ children, centered, right, ...rest }) => {
  return (
    <Message centered={centered} right={right} {...rest}>
      {children}
    </Message>
  );
};

export default Error;

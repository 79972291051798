import React from 'react';
import styled from 'styled-components/macro';
import { ReactTable } from '../../components';

export const StyledReactTable = styled((props) => <ReactTable {...props} />)`
  .table {
    border-bottom: ${({ theme }) =>
      `1px solid ${theme.colors.asiance_navy} !important`};

    .header {
      .th {
        min-height: 68px;
        font-weight: bold;

        &:first-child {
          padding-left: 30px !important;
        }
      }
    }

    .body {
      .td {
        padding: 0 20px;
        border-bottom: none;
      }

      .tr {
        height: 60px;

        .td:first-child {
          padding-left: 30px;
          font-size: 1.25rem;
        }
      }
    }
  }
`;

import React from 'react';
import styled from 'styled-components/macro';

const Text = styled.span`
  margin-left: 10px;
`;

const AppendText = ({ children }) => {
  return <Text>{children}</Text>;
};

export default AppendText;

import {
  startOfMonth,
  isBefore,
  isEqual,
  format,
  add,
  getDay,
  addDays,
  eachMonthOfInterval,
  sub,
} from 'date-fns';

export const getListOfDateTime = (startDate, endDate, pattern, key) => {
  if (!startDate || !endDate) {
    return [];
  }

  let start = new Date(startDate);
  const end = new Date(endDate);

  start = key === 'months' ? startOfMonth(start) : start;

  const list = [];

  while (isBefore(start, end) || isEqual(start, end)) {
    list.push(format(start, pattern));
    start = add(start, { [key]: 1 });
  }

  return list;
};

export const convertDurationToHourAndMin = (time) => {
  if (typeof time !== 'number') {
    return time;
  }
  const hours = Math.floor(time / 60);
  const minutes = Math.floor(time % 60);
  const hh = (hours === 0 && '0') || hours;
  const mm = (minutes === 0 && '00') || minutes < 10 ? `0${minutes}` : minutes;
  const converted = time ? `${hh}:${mm}` : '';
  return converted;
};

export const convertHourAndMinToDuration = (time) => {
  const hours = Number(time.split(':')[0]);
  const minutes = Number(time.split(':')[1]);
  const hoursToMinutes = hours * 60;
  return hoursToMinutes + minutes;
};

export const convertMinToDays = (minutes) => {
  const days = Math.round((minutes / (60 * 8)) * 100) / 100;
  return days;
};

export const validateDayInput = (input) => {
  const notNumber = /^(?!([0-9]*\.?[0-9]*$))/;
  let formatted = input;
  if (notNumber.test(input)) {
    formatted = '';
  }
  if (input[0] === '.') {
    formatted = 0 + input;
  }
  return formatted || 0;
};

export const validateTimeinput = (input) => {
  const notNumber = /^(?!(^[0-9]*\.?[0-9]*$))/;
  let formatted = null;
  if (input.includes(':')) {
    const hours = input.split(':')[0].replace(notNumber, '');
    const minutes = input.split(':')[1].replace(notNumber, '');
    let mm = null;
    if (minutes.length < 1) {
      mm = '00';
    } else if (minutes.length === 1) {
      mm = `0${minutes}`;
    } else if (minutes.length === 2) {
      mm = minutes;
    } else {
      mm = minutes.slice(0, 2);
    }
    formatted = `${hours}:${mm}`;
  } else {
    if (notNumber.test(input)) {
      return '';
    }
    if (input.includes('.')) {
      const hours = input.split('.')[0];
      const minutes = input.split('.')[1];
      const formattedMinutes = `0.${minutes}`;
      const hh = hours || 0;
      const mm = minutes ? parseFloat(formattedMinutes) * 60 : '00';
      formatted = `${hh}:${mm}`;
    } else {
      const hh = input.slice(0, -2);
      const mm = input.slice(-2);
      if (input.length <= 2) {
        formatted = input.length >= 2 ? `0:${input}` : `0:0${input}`;
        return formatted;
      }
      formatted = `${hh}:${mm}`;
    }
  }
  return formatted;
};

export const getCurrentWeek = (startDate) => {
  let startOfWeek = startDate;
  let day = getDay(startOfWeek);
  const dates = [];
  while (day <= 7) {
    dates.push(new Date(startOfWeek));
    startOfWeek = addDays(startOfWeek, 1);
    day += 1;
  }
  return dates;
};

export const stringifyDate = (date) => {
  return date ? format(new Date(date), 'yyyy-MM-dd') : null;
};

export const formatMonthYear = (monthYear) => {
  const strMonthYear = `${monthYear}`;
  const year = strMonthYear.slice(0, 4);
  const month = strMonthYear.slice(-2);
  return `${year}-${month}-01`;
};

export const getMonthsArr = (startDate, endDate) => {
  return eachMonthOfInterval({
    start: startDate,
    end: endDate,
  }).map((month) => format(month, 'yyyy-MM'));
};

export const getLastMonth = (startDate) => {
  const date = startDate || new Date();
  return sub(date, { months: 1 });
};

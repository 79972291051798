import React from 'react';
import styled from 'styled-components/macro';
import { theme } from '../../../style/theme';

const BlueText = styled.span`
  color: ${theme.colors.dropdown_selected};
`;

const InternalDaysCell = ({ value }) => {
  const fixedVal = parseFloat(value).toFixed(2);

  return <BlueText>{fixedVal}</BlueText>;
};

export default InternalDaysCell;

import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  text-align: center;

  font-weight: ${({ isHeader }) => (isHeader ? 'bold' : 'normal')};
  font-size: ${({ isSubRow, isHeader }) =>
    isSubRow && isHeader ? '0.875rem' : '1rem'};

  .spentDaysColor {
    font-weight: bold;
  }

  ${(props) =>
    props.orange &&
    css`
      .spentDaysColor {
        color: ${({ theme }) => theme.colors.orange};
      }
    `};
  ${(props) =>
    props.red &&
    css`
      .spentDaysColor {
        color: ${({ theme }) => theme.colors.red};
      }
    `};
`;

const ColorCodeSpentdayCell = ({ value, row, depth, index }) => {
  const realProgress = parseFloat(row.realProgress);
  const theoreticalProgress = parseFloat(row.theoreticalProgress);
  const [red, setRed] = useState(false);
  const [orange, setOrange] = useState(false);
  const spentDaysContractDays = value.split(' / ');

  let spentDays;
  let contractDays;
  if (spentDaysContractDays.length === 2) {
    [spentDays, contractDays] = spentDaysContractDays;
  }

  useEffect(() => {
    if (theoreticalProgress < 100 && realProgress > theoreticalProgress) {
      setOrange(true);
    }
    if (realProgress >= 100) {
      setRed(true);
    }
  }, []);

  if (spentDaysContractDays.length < 2) {
    return (
      <Cell isHeader={depth === 1 && index === 0} isSubRow={depth === 1}>
        <div>{value}</div>
      </Cell>
    );
  }
  return (
    <Cell
      red={red}
      orange={orange}
      isHeader={depth === 1 && index === 0}
      isSubRow={depth === 1}
    >
      <div>
        <span className="spentDaysColor">{spentDays}</span> / {contractDays}
      </div>
    </Cell>
  );
};

export default ColorCodeSpentdayCell;

/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import enGb from 'date-fns/locale/en-GB';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { addLeaveDatesToUpdate } from '../../../actions/userLeaveDates';
import { stringifyDate } from '../../../utils/datetime';

registerLocale('en-gb', enGb);

const DatePickerContainer = styled.div`
  border: 1px solid ${({ theme }) => `${theme.colors.bluish_light_gray}`};

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const StyledDatePicker = styled(ReactDatePicker)`
  border: none;
  min-height: 38px;
  padding: 4px;
  width: 100%;

  #longTermLeaveStartDate {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => `${theme.colors.bluish_gray}`};
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => `${theme.colors.bluish_gray}`};
  }
  &::placeholder {
    color: ${({ theme }) => `${theme.colors.bluish_gray}`};
  }
`;

const LongTermLeaveDateStartDateCell = ({
  value,
  row: { original, index },
  column: { info },
}) => {
  const { editingRow, setEditingRow } = info;
  const [editable, setEditable] = useState(false);
  const [startDate, setStartDate] = useState(value);

  const dispatch = useDispatch();

  const startDateRef = useRef(null);

  useEffect(() => {
    setEditable(editingRow === index);
  }, [editingRow]);

  const handleClickOutside = async (e) => {
    if (
      e.target !== document.activeElement &&
      e.target.classList.contains('modal-content')
    ) {
      setEditingRow(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const updateStartDate = async (date) => {
    dispatch(
      addLeaveDatesToUpdate({
        id: original.id,
        startDate: stringifyDate(date),
        deleteDates: false,
      }),
    );
  };

  return (
    <>
      {editable ? (
        <DatePickerContainer>
          <StyledDatePicker
            ref={startDateRef}
            id="longTermLeaveStartDate"
            name="startDate"
            locale="en-gb"
            dateFormat="yyyy.MM.dd"
            placeholderText={stringifyDate(startDate)}
            valueName="selected"
            selected={startDate ? new Date(startDate) : new Date(value)}
            defaultValue={new Date(startDate)}
            onChange={(selected) => {
              setStartDate(selected);
              updateStartDate(selected);
              return selected;
            }}
            autoComplete="off"
            showYearDropdown
            showMonthDropdown
          />
        </DatePickerContainer>
      ) : (
        stringifyDate(startDate)
      )}
    </>
  );
};

export default LongTermLeaveDateStartDateCell;

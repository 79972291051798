import React from 'react';
import styled from 'styled-components/macro';
import menuicon from '../../assets/images/menu.svg';

const StyledButton = styled.button`
  z-index: 9;
  border: none;
  position: absolute;
  left: 50px;
  top: 136px;
  width: 40px;
  height: 40px;
  background: none;
  transform: scale(${({ direction }) => direction});
  &:focus {
    outline: none;
  }
`;

const ToggleButton = ({ direction, onClick }) => {
  return (
    <StyledButton direction={direction} onClick={onClick}>
      <img src={menuicon} alt="sidebar icon" />
    </StyledButton>
  );
};

export default ToggleButton;

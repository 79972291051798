import React from 'react';
import styled from 'styled-components/macro';
import copyIcon from '../../assets/images/copy-icon.svg';
import nextIcon from '../../assets/images/next.svg';
import prevIcon from '../../assets/images/prev.svg';
import rightIcon from '../../assets/images/rightchev.svg';
import clearIcon from '../../assets/images/refresh.svg';

const StyledButton = styled.button`
  text-transform: uppercase;
  font-size: 1.125rem;
  font-family: 'futura-pt';
  font-weight: 600;
  width: ${({ width }) => width || 'auto'};
  display: ${({ inline, hide }) =>
    (!hide && (inline ? 'inline-block' : 'block')) || (hide && 'none')};
  margin: ${({ inline }) => (inline ? '4px' : '60px 0')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  border: 1px solid transparent;
  border-color: ${({ loading, theme }) =>
    loading ? theme.colors.asiance_red : 'transparent'};
  padding: 15px 30px;
  background-color: ${({ disabled, loading, theme }) =>
    (disabled && theme.colors.bluish_gray) ||
    (loading && 'white') ||
    theme.colors.asiance_red};
  color: ${({ loading, theme }) =>
    (loading && theme.colors.asiance_red) || 'white'};
  transition: all 0.2s ease;
  &:hover,
  &:focus {
    outline: none;
    color: ${({ disabled, loading, theme }) =>
      disabled || loading ? 'white' : theme.colors.asiance_red};
    border-color: ${({ disabled, theme }) =>
      disabled ? 'transparent' : theme.colors.asiance_red};
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.colors.bluish_gray : 'white'};
  }
  &:first-child {
    margin-left: 0;
  }
  &.underline {
    background: transparent;
    color: ${({ theme }) => theme.colors.asiance_navy};
    padding: 5px 0;
    margin-right: 20px;
    border: none;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.asiance_navy}`};
  }
  &.addbtn {
    font-size: 1.125rem;
    text-transform: none;
    color: ${({ theme }) => theme.colors.asiance_navy};
    background: none;
    border: none;
    border-bottom: 1px solid transparent;
    padding: 0;
    font-weight: 600;
    margin-left: 35px;
    margin-top: 20px;
    position: relative;
    &:before {
      content: '+';
      font-weight: 300;
      font-size: 1.75rem;
      color: white;
      background: ${({ theme }) => theme.colors.asiance_navy};
      border-radius: 50%;
      width: 26px;
      height: 26px;
      line-height: 26px;
      margin-right: 10px;
      position: absolute;
      top: -2px;
      left: -35px;
    }
    &:hover {
      border-bottom: 1px solid ${({ theme }) => theme.colors.asiance_navy};
    }
  }
  &.icon {
    color: ${({ theme }) => theme.colors.asiance_navy};
    display: block;
    width: 28px;
    height: 28px;
    background: transparent;
    padding: 0;
    margin: 0 4px;
    position: relative;
    border: none;
    &:hover,
    &:focus {
      border: none;
      background: none;
    }

    &.remove {
      border: none;
      &:before,
      &:after {
        content: '';
        width: 2px;
        height: 18px;
        background: ${({ theme }) => theme.colors.asiance_navy};
        position: absolute;
        top: 5px;
        left: 13px;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
    &.plus {
      &:after,
      &:before {
        content: ' ';
        position: absolute;
        display: block;
        background: ${({ theme }) => theme.colors.asiance_navy};
        width: 15px;
        height: 1px;
        top: 11px;
        left: 4px;
      }
      &:before {
        transform: rotate(90deg);
      }
    }
    &.minus {
      &:after {
        content: ' ';
        position: absolute;
        display: block;
        background: ${({ theme }) => theme.colors.asiance_navy};
        width: 15px;
        height: 1px;
        top: 11px;
        left: 4px;
      }
    }
    &.angle {
      &:before,
      &:after {
        content: '';
        width: 2px;
        height: 10px;
        background: ${({ theme }) => theme.colors.asiance_navy};
        position: absolute;
      }
      &.left {
        &:before {
          transform: rotate(45deg);
          top: 5px;
          left: 11px;
        }
        &:after {
          transform: rotate(-45deg);
          top: 11px;
          left: 11px;
        }
      }
      &.right {
        &:before {
          transform: rotate(-45deg);
          top: 5px;
          right: 10px;
        }
        &:after {
          transform: rotate(45deg);
          top: 11px;
          right: 10px;
        }
      }
    }
    &.copy {
      &:before {
        content: url(${copyIcon});
        position: absolute;
        top: 50%;
        left: 6px;
        transform: translateY(-50%);
      }
    }
    &.clear {
      display: inline-block;
      border: none;
      background: none;
      margin-left: 20px;
      &:focus {
        outline: none;
      }
      @media screen and (max-width: 991.98px) {
        margin-top: 15px;
      }
      &:before {
        content: url(${clearIcon});
        position: absolute;
        top: 50%;
        left: 6px;
        transform: translateY(-50%);
      }
    }
  }
  &.grey {
    background: ${({ theme }) => `${theme.colors.lighter_gray}`};
    color: ${({ theme }) => theme.colors.asiance_navy};
    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.asiance_navy};
      background: transparent;
    }
  }
  &.sub-button {
    background: ${({ theme }) => theme.colors.asiance_navy};
    font-weight: 700;
    padding: 15px 30px;
    margin: 0 10px;
    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.asiance_navy};
      color: ${({ theme }) => theme.colors.asiance_navy};
      background: white;
    }
  }
  &.nextbtn,
  &.prevbtn {
    background: white;
    border: ${({ theme }) => `1px solid ${theme.colors.asiance_navy}`};
    color: ${({ theme }) => `${theme.colors.asiance_navy}`};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.125rem;
    padding: 14px 18px;
  }
  &.nextbtn {
    &:after {
      content: url(${nextIcon});
      display: inline-block;
      margin-left: 8px;
      transform: translateY(-2px);
    }
  }
  &.prevbtn {
    &:before {
      content: url(${prevIcon});
      display: inline-block;
      margin-right: 8px;
      transform: translateY(-2px);
    }
  }

  &.redbtn {
    background-color: ${({ loading, theme }) =>
      (loading && 'white') || theme.colors.asiance_red};
    color: ${({ loading, theme }) =>
      (loading && theme.colors.asiance_red) || 'white'};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.125rem;
    padding: 14px 40px;
    width: ${({ width }) => width || 'auto'};
    min-width: 140px;
    &:hover,
    &:focus {
      border: ${({ theme }) => `1px solid ${theme.colors.asiance_red}`};
      color: ${({ theme }) => `${theme.colors.asiance_red}`};
      background: white;
    }
  }

  &.navybtn {
    background-color: ${({ loading, theme }) =>
      (loading && 'white') || theme.colors.asiance_navy};
    color: ${({ loading, theme }) =>
      (loading && theme.colors.asiance_navy) || 'white'};
    border-color: ${({ loading, theme }) =>
      loading ? theme.colors.asiance_navy : 'transparent'};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.125rem;
    padding: 14px 40px;
    width: ${({ width }) => width || 'auto'};
    min-width: 140px;
    &:hover,
    &:focus {
      border: ${({ theme }) => `1px solid ${theme.colors.asiance_navy}`};
      color: ${({ theme }) => `${theme.colors.asiance_navy}`};
      background: white;
    }
  }

  &.backtolistbtn {
    border: ${({ theme }) => `1px solid ${theme.colors.asiance_navy}`};
    color: ${({ theme }) => `${theme.colors.asiance_navy}`};
    background: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.125rem;
    padding: 14px 40px;
    width: ${({ width }) => width || 'auto'};
    &:hover,
    &:focus {
      background: ${({ theme }) => `${theme.colors.asiance_navy}`};
      color: white;
    }
  }

  &.greybtn {
    color: ${({ theme }) => `${theme.colors.asiance_navy}`};
    background: ${({ theme }) => `${theme.colors.bluish_light_gray}`};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.125rem;
    padding: 14px 40px;
    width: ${({ width }) => width || 'auto'};
    min-width: 140px;
    &:hover,
    &:focus {
      background: white;
      border: ${({ theme }) => `1px solid ${theme.colors.bluish_light_gray}`};
    }
    &:focus {
      border: ${({ theme }) => `1px solid ${theme.colors.bluish_light_gray}`};
    }
  }

  &.changebtn {
    color: ${({ theme }) => `${theme.colors.asiance_navy}`};
    border: 0;
    text-transform: none;
    background: none;
    font-weight: 700;
    font-size: 1.125rem;
    padding: 0;
    margin: 0;
    &:after {
      content: url(${rightIcon});
      display: inline-block;
      margin-left: 2px;
      transform: translateY(6px);
    }
    &:hover,
    &:focus {
      border: 0;
      &:after {
        margin-left: 5px;
      }
    }
  }

  &.deactivateBtn {
    color: ${({ theme }) => `${theme.colors.asiance_navy}`};
    border: 0;
    text-transform: none;
    background: none;
    font-weight: 400;
    font-size: 1.125rem;
    padding: 0;
    margin: 0;
    align-items: center;
    display: inline-flex;
    &:after {
      content: url(${rightIcon});
      display: inline-block;
      margin-left: 2px;
      transform: translateY(6px);
    }
    &:hover,
    &:focus {
      border: 0;
    }
  }

  &.sm-navybtn {
    background: ${({ theme }) => `${theme.colors.asiance_navy}`};
    color: white;
    text-transform: none;
    font-weight: 700;
    font-size: 1.125rem;
    padding: 9px 20px;
    margin: 0;
    &:hover,
    &:focus {
      border: ${({ theme }) => `1px solid ${theme.colors.asiance_navy}`};
      color: ${({ theme }) => `${theme.colors.asiance_navy}`};
      background: white;
    }
    &:focus {
      border: ${({ theme }) => `1px solid ${theme.colors.asiance_navy}`};
    }
  }
  &.plus,
  &.minus {
    display: block;
    width: 28px;
    height: 28px;
    background: transparent;
    padding: 0;
    margin: 0 4px;
    position: relative;
    &:hover {
      border-color: ${({ theme }) => theme.colors.bluish_light_gray};
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.row_gray};
    }
    &:focus {
      border-color: white;
      width: 28px;
      height: 28px;
    }
  }

  &.plus {
    &:after,
    &:before {
      content: ' ';
      position: absolute;
      display: block;
      background: ${({ theme }) => theme.colors.asiance_navy};
      width: 16px;
      height: 2px;
      top: 11px;
      left: 5px;
    }
    &:before {
      transform: rotate(90deg);
    }
  }
  &.minus {
    &:after {
      content: ' ';
      position: absolute;
      display: block;
      background: ${({ theme }) => theme.colors.asiance_navy};
      opacity: 0.3 !important;
      width: 16px;
      height: 2px;
      top: 11px;
      left: 5px;
    }
  }

  &.angle {
    margin: 0;
    position: relative;
    display: ${({ inline, hide }) =>
      (!hide && (inline ? 'inline-block' : 'block')) || (hide && 'none')};
    width: 28px;
    height: 28px;
    background: transparent;
    padding: 0;
    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.bluish_light_gray};
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.bluish_light_gray};
    }
    &:before,
    &:after {
      content: '';
      width: 2px;
      height: 12px;
      background: ${({ theme }) => theme.colors.asiance_navy};
      position: absolute;
    }
    &.left {
      &:before {
        transform: rotate(45deg);
        top: 3px;
        left: 11px;
      }
      &:after {
        transform: rotate(-45deg);
        top: 10px;
        left: 11px;
      }
    }
    &.right {
      &:before {
        transform: rotate(-45deg);
        top: 3px;
        right: 10px;
      }
      &:after {
        transform: rotate(45deg);
        top: 10px;
        right: 10px;
      }
    }
  }
  &.icontext {
    background: none;
    text-transform: none;
    font-weight: 400;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    line-height: 0.8;
    color: ${({ theme }) => `${theme.colors.asiance_navy}`};
    &:after {
      content: url(${({ icon }) => icon});
      margin-left: 10px;
    }
  }
  &.btnmargin {
    margin: 0;
    margin-left: 10px;
  }
  &.arrow-btn {
    margin: 0;
    border: none;
    background: none;
    color: ${({ theme }) => `${theme.colors.asiance_navy}`};
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    .arrow {
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      margin-left: 5px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
  &.teambtns {
    margin: 20px 0;
  }
  &.export-btn-margin-top {
    margin-top: 36px;
  }

  &.export-btn-no-margin {
    margin: 0;
  }

  &.getDataBtn {
    height: fit-content;
    margin: 0;
  }
`;

const Button = ({
  type,
  text,
  width,
  loading,
  disabled,
  inline,
  hide,
  icon,
  arrow,
  children,
  ...rest
}) => {
  return (
    <StyledButton
      type={type}
      width={width}
      loading={loading}
      disabled={disabled}
      inline={inline}
      hide={hide}
      icon={icon}
      {...rest}
    >
      {text || children}
      {arrow && <i className="arrow" />}
    </StyledButton>
  );
};

export default React.memo(Button);

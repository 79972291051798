/* eslint-disable no-console */
import React, { useState, useEffect, useMemo } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import enGb from 'date-fns/locale/en-GB';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  getDay,
  getMonth,
  format,
  addDays,
  subDays,
  startOfDay,
  getOverlappingDaysInIntervals,
  areIntervalsOverlapping,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  compareAsc,
  eachDayOfInterval,
  isWithinInterval,
} from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_USER_TIMESHEET,
  GET_LAST_CLOSING_DATE,
} from '../../apollo/queries';
import {
  ADD_OR_UPDATE_TIME,
  DELETE_TIME_ENTRIES,
  SUBMIT_TIMESHEET,
} from '../../apollo/mutations';
import {
  Button,
  Error,
  InlineLoader,
  DoubleCheckModal,
  CalendarListModal,
} from '../../components';
import {
  ToolBar,
  DateRange,
  DatePickerContainer,
  Container,
  Message,
  ButtonContainer,
  StyledReactTable,
} from './styled';
import {
  getTimeEntryColumns,
  mapGoogleCalendarToTimeEntries,
  mergeByDate,
  mergeByHashtagAndDesc,
  mapTimesheetStateToTableData,
  getEmptyEntry,
  getDailyTotal,
  getDailyTotalOfTheLastWeek,
  resetEntries,
} from '../../utils/tableData';
import { mapTimesheetStateToTimeEntriesInput } from '../../utils/formData';
import {
  getListOfDateTime,
  getCurrentWeek,
  formatMonthYear,
  stringifyDate,
} from '../../utils/datetime';
import {
  initGapiClient,
  fetchCalendarList,
  fetchCalendarEvents,
} from '../../utils/api';
import {
  requiredDescProjects,
  timesheetStatus,
  timesheetStartDate,
} from '../../constants/data';
import { errorMessage, errorLocation, errorField } from '../../constants/error';
import { selectWeek, saveTimeEntries } from '../../actions/timesheet';
import useAuth from '../../hooks/auth';
import useErrorHandler from '../../hooks/errorHandler';
import googleicon from '../../assets/images/google-icon.svg';
import copyicon from '../../assets/images/copy-icon.svg';

registerLocale('en-gb', enGb);

const Timesheet = ({ history }) => {
  const dispatch = useDispatch();
  const {
    error,
    timesheet: { selectedWeek, timeEntries },
  } = useSelector((state) => state);
  const { getAuthUser } = useAuth();
  const { id: userId, name } = getAuthUser();
  const firstName = name && name.split(' ')[0];
  const {
    handleAPIError,
    handleGoogleError,
    handleValidationError,
    clearErrorState,
  } = useErrorHandler();

  const [userStartDate, setUserStartDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [currentMonthYear, setCurrentMonthYear] = useState(null);
  const [googleCalendarList, setGoogleCalendarList] = useState(null);
  const [googleCalendarId, setGoogleCalendarId] = useState(null);
  const [modal, setModal] = useState(null);
  const [calendarShow, setCalendarShow] = useState(false);
  const [submitBtn, setSubmitBtn] = useState('hide');
  const [loading, setLoading] = useState(null);
  const [message, setMessage] = useState(null);
  const [lastDayToGo, setLastDayToGo] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date(selectedWeek.startDate),
  );
  const [highlightDates, setHighlightDates] = useState([]);
  const [hashtagOptions, setHashtagOptions] = useState([]);
  const [userLeaveDates, setUserLeaveDates] = useState([]);
  const [overlapWithLeave, setOverLapWithLeave] = useState({
    isDuringLongTermLeave: false,
    wholeWeekOverlaps: false,
    duringLeaveNoOverlapArr: [],
  });

  // TODO: 6 hours rule
  const dailyTotalValidation = useMemo(() => {
    const dailyTotal = getDailyTotal(timeEntries);

    const dailyTotalWithoutWeekend = dailyTotal.filter((obj) => {
      const day = getDay(new Date(obj.date));
      return day >= 1 && day <= 5;
    });

    if (
      overlapWithLeave.isDuringLongTermLeave &&
      !overlapWithLeave.wholeWeekOverlaps &&
      overlapWithLeave.duringLeaveNoOverlapArr.length > 0
    ) {
      const theseDatesShouldBeFilled = overlapWithLeave.duringLeaveNoOverlapArr.map(
        (date) => format(date, 'yyyy-MM-dd'),
      );

      return theseDatesShouldBeFilled.every((date) => {
        return dailyTotalWithoutWeekend.find(
          (entry) => entry.date === date && entry.duration >= 6 * 60,
        );
      });
    }

    return (
      dailyTotalWithoutWeekend.length === 5 &&
      dailyTotalWithoutWeekend.every((total) => total.duration >= 6 * 60)
    );
  }, [timeEntries]);
  // TODO: end
  const days = getListOfDateTime(
    selectedWeek.startDate,
    selectedWeek.endDate,
    'yyyy-MM-dd',
    'days',
  );
  const extra = {
    status,
    currentMonthYear,
    hashtagOptions,
  };
  const columns = useMemo(
    () => getTimeEntryColumns(days, extra, userLeaveDates),
    [
      selectedWeek.startDate,
      selectedWeek.endDate,
      status,
      currentMonthYear,
      hashtagOptions,
    ],
  );
  const tableData = useMemo(() => {
    clearErrorState();
    const timesheetTableData = mapTimesheetStateToTableData(timeEntries);
    return timesheetTableData;
  }, [timeEntries]);

  const variables =
    !selectedWeek.startDate && !selectedWeek.endDate
      ? {
          id: userId,
        }
      : {
          id: userId,
          assignedProjects: {
            startDate: format(selectedWeek.startDate, 'yyyy-MM-dd'),
            endDate: format(selectedWeek.endDate, 'yyyy-MM-dd'),
          },
        };

  const nothingFilledOut = timeEntries.every((entry) => !entry.projectId);

  const mutationOptions = {
    refetchQueries: [
      {
        query: GET_USER_TIMESHEET,
        variables,
      },
    ],
  };

  const [lastClosingDate, setLastClosingDate] = useState(null);
  const [addOrUpdateTime] = useMutation(ADD_OR_UPDATE_TIME, mutationOptions);
  const [deleteTimeEntries] = useMutation(DELETE_TIME_ENTRIES);
  const [submitTimesheet] = useMutation(SUBMIT_TIMESHEET, mutationOptions);
  const [getLastClosingMonth, { loading: lastLoading }] = useLazyQuery(
    GET_LAST_CLOSING_DATE,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ getLastClosingDate }) => {
        const lastClosingDated = new Date(getLastClosingDate);
        setLastClosingDate(lastClosingDated);
        const year = format(lastClosingDated, 'yyyy');
        const month = format(lastClosingDated, 'MM');
        const monthYear = Number(year + month);
        setCurrentMonthYear(monthYear);
      },
    },
  );
  const [
    getLastweekTimesheet,
    { loading: copyLoading, error: copyError },
  ] = useLazyQuery(GET_USER_TIMESHEET, {
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ getUser }) => {
      const assignedProjects = getUser.AssignedProjects;
      const savedTimeEntries = assignedProjects.reduce((timesheet, current) => {
        if (current.TimeEntries.length) {
          const entries = current.TimeEntries.map((row) => {
            const newEntry = {};
            newEntry.hashtag = row.hashtag;
            newEntry.projectId = row.projectId;
            newEntry.description = '';
            return newEntry;
          });
          return [...timesheet, ...entries];
        }
        return timesheet;
      }, []);

      if (!savedTimeEntries.length) {
        handleValidationError(errorLocation.TIMESHEET_LOAD, {
          message: errorMessage.NOTHING_TO_COPY,
        });
        setModal(null);
        return;
      }

      await deleteTimeEntries({
        variables: {
          startDate: format(selectedWeek.startDate, 'yyyy-MM-dd'),
          endDate: format(selectedWeek.endDate, 'yyyy-MM-dd'),
        },
      });

      const mergedEntries = mergeByDate(savedTimeEntries);
      const timesheetState = mergeByHashtagAndDesc(mergedEntries);
      const copiedTimesheet = resetEntries(timesheetState);
      dispatch(saveTimeEntries(copiedTimesheet));
      setModal(null);
    },
  });
  const [
    getUserTimesheet,
    {
      loading: timesheetLoading,
      // data: timesheetData,
    },
  ] = useLazyQuery(GET_USER_TIMESHEET, {
    variables,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ getUser }) => {
      setUserLeaveDates(getUser.LongTermLeaves);
      setStatus(getUser.timesheetStatus);
      setUserStartDate(getUser.createdAt);

      // setStatus to 'submitted' for newcomers
      const result = compareAsc(lastClosingDate, new Date(userStartDate));
      if (result <= 0) {
        setStatus('SUBMITTED');
      }

      const assignedProjects = getUser.AssignedProjects;
      const hashtags = assignedProjects
        .map((project) => ({
          value: project.id,
          label: project.hashtag,
          status: project.status,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setHashtagOptions(hashtags);

      const nothingSaved = assignedProjects.every(
        (project) => !project.TimeEntries.length,
      );
      if (nothingSaved) {
        if (!selectedWeek.startDate && !selectedWeek.endDate) {
          const today = new Date();
          const start = addDays(startOfWeek(today), 1);
          const end = addDays(endOfWeek(today), 1);
          setSelectedDate(start);
          const week = getCurrentWeek(start);
          setHighlightDates(week);
          if (!lastDayToGo) {
            setLastDayToGo(end);
          }
          dispatch(selectWeek(start, end));
        }
        const initialEntry = getEmptyEntry();
        dispatch(saveTimeEntries(initialEntry));
        setLoading(null);
        return;
      }

      const savedTimeEntries = assignedProjects.reduce((timesheet, current) => {
        if (current.TimeEntries.length) {
          const entries = current.TimeEntries.map((row) => {
            row.hashtag = current.hashtag;
            return row;
          });
          return [...timesheet, ...entries];
        }
        return timesheet;
      }, []);

      if (!savedTimeEntries.length) {
        const initialEntry = getEmptyEntry();
        dispatch(saveTimeEntries(initialEntry));
        setLoading(null);
        return;
      }

      const mergedEntries = mergeByDate(savedTimeEntries);
      const timesheetState = mergeByHashtagAndDesc(mergedEntries);

      const setCurrentWeekAndSaveEntries = (
        startDateOfWeek,
        endDateOfWeek,
        addingDays,
        entries,
      ) => {
        const start = addDays(startDateOfWeek, addingDays);
        const end = addDays(endDateOfWeek, addingDays);
        setSelectedDate(start);
        const week = getCurrentWeek(start);
        setHighlightDates(week);
        if (!lastDayToGo) {
          setLastDayToGo(end);
        }
        dispatch(selectWeek(start, end));
        dispatch(saveTimeEntries(entries));
      };

      const savedDate = assignedProjects.find(
        (project) => project.TimeEntries.length,
      );
      const singleDateOfSelectedWeek = new Date(savedDate.TimeEntries[0].date);
      const startDateOfSelectedWeek = startOfWeek(singleDateOfSelectedWeek);
      const endDateOfSelectedWeek = endOfWeek(singleDateOfSelectedWeek);

      const dailyTotal = getDailyTotal(timesheetState);
      const dailyTotalWithoutWeekend = dailyTotal.filter((obj) => {
        const day = getDay(new Date(obj.date));
        return day >= 1 && day <= 5;
      });

      const validatedDailyTotal =
        dailyTotalWithoutWeekend.length === 5 &&
        dailyTotalWithoutWeekend.every((total) => total.duration >= 6 * 60);

      const lastDayOfMonth = format(
        endOfMonth(new Date(formatMonthYear(currentMonthYear))),
        'yyyy-MM-dd',
      );
      const lastDayOfWeek = format(
        addDays(new Date(endDateOfSelectedWeek), 1),
        'yyyy-MM-dd',
      );

      if (
        getUser.LongTermLeaves &&
        getUser.LongTermLeaves.length > 0 &&
        selectedWeek.startDate &&
        selectedWeek.endDate
      ) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < getUser.LongTermLeaves.length; i++) {
          const { startDate, endDate } = getUser.LongTermLeaves[i];

          const selectedWeekIsDuringLeave = areIntervalsOverlapping(
            {
              start: new Date(selectedWeek.startDate),
              end: new Date(selectedWeek.endDate),
            },
            {
              start: new Date(startDate),
              end: new Date(endDate),
            },
            { inclusive: true },
          );

          if (selectedWeekIsDuringLeave) {
            const lastLeaveDateStartOfWeek = startOfWeek(new Date(endDate));
            const lastLeaveDate = endOfWeek(new Date(endDate));

            setSelectedDate(lastLeaveDateStartOfWeek);
            dispatch(selectWeek(lastLeaveDateStartOfWeek, lastLeaveDate));

            return;
          }
        }
      }

      const isLastWeek = lastDayOfMonth === lastDayOfWeek;
      const isSubmitted = status === timesheetStatus.SUBMITTED;

      const validated = isLastWeek
        ? validatedDailyTotal && isSubmitted
        : validatedDailyTotal;

      if (validated) {
        if (!selectedWeek.startDate && !selectedWeek.endDate) {
          const initialEntry = getEmptyEntry();
          setCurrentWeekAndSaveEntries(
            startDateOfSelectedWeek,
            endDateOfSelectedWeek,
            8,
            initialEntry,
          );
          setLoading(null);
          return;
        }
      }
      setCurrentWeekAndSaveEntries(
        startDateOfSelectedWeek,
        endDateOfSelectedWeek,
        1,
        timesheetState,
      );

      setLoading(null);
    },
  });

  useEffect(() => {
    dispatch(saveTimeEntries([]));
    getUserTimesheet();
    getLastClosingMonth();

    return () => {
      dispatch(selectWeek(null, null));
    };
  }, []);

  useEffect(() => {
    const startMonthYear =
      selectedWeek.startDate &&
      Number(format(selectedWeek.startDate, 'yyyyMM'));
    const lastDayOfMonth = format(
      endOfMonth(new Date(selectedWeek.startDate)),
      'yyyy-MM-dd',
    );
    const lastDayOfWeek = format(new Date(selectedWeek.endDate), 'yyyy-MM-dd');

    if (
      (selectedWeek.startDate &&
        selectedWeek.endDate &&
        getMonth(selectedWeek.startDate) !== getMonth(selectedWeek.endDate)) ||
      lastDayOfMonth === lastDayOfWeek
    ) {
      if (
        startMonthYear < currentMonthYear ||
        (startMonthYear === currentMonthYear && status !== timesheetStatus.OPEN)
      ) {
        setSubmitBtn('hide');
      } else if (overlapWithLeave.isDuringLongTermLeave) {
        setSubmitBtn('hide');
      } else {
        startMonthYear === currentMonthYear
          ? setSubmitBtn('show')
          : setSubmitBtn('disabled');
      }
    }
  }, [selectedWeek.startDate, selectedWeek.endDate, status]);

  const onClickDateRange = () => {
    clearErrorState();
    setCalendarShow(!calendarShow);
    setSelectedDate(selectedWeek.startDate);
    const week = getCurrentWeek(selectedWeek.startDate);
    setHighlightDates(week);
  };

  const handleDateChange = (date) => {
    setLoading({
      datepick: true,
    });
    const dayOfSelected = getDay(date);
    const convertedDate = dayOfSelected === 0 ? subDays(date, 7) : date;
    const start = addDays(startOfWeek(convertedDate), 1);
    const end = addDays(endOfWeek(convertedDate), 1);
    const week = getCurrentWeek(start);
    setSelectedDate(date);
    setHighlightDates(week);
    setCalendarShow(false);
    dispatch(selectWeek(start, end));
  };

  // **Check if user longTermLeaveDates Exists within selectedWeek and if non overlapped days pass the 6hr daily rule
  const isOverlappingWithLongTermLeaveDates = () => {
    const {
      startDate: selectedWeekStartDate,
      endDate: selectedWeekEndDate,
    } = selectedWeek;

    const results = {
      isDuringLongTermLeave: false,
      wholeWeekOverlaps: false,
      duringLeaveNoOverlapArr: [],
    };

    if (
      selectedWeekStartDate &&
      selectedWeekEndDate &&
      userLeaveDates.length > 0
    ) {
      const leaveDateThatOverlaps = {};
      const leaveDateExists = userLeaveDates.some((leaveDateArr) => {
        const {
          startDate: leaveStartDate,
          endDate: leaveEndDate,
        } = leaveDateArr;

        const checkWeekWithLeaveDateObj = areIntervalsOverlapping(
          {
            start: new Date(selectedWeekStartDate),
            end: new Date(selectedWeekEndDate),
          },
          {
            start: new Date(leaveStartDate),
            end: new Date(leaveEndDate),
          },
          { inclusive: true },
        );

        if (checkWeekWithLeaveDateObj) {
          leaveDateThatOverlaps.startDate = leaveStartDate;
          leaveDateThatOverlaps.endDate = leaveEndDate;
          setLastDayToGo(new Date(leaveEndDate));
        }
        return checkWeekWithLeaveDateObj;
      });

      const wholeWeekOverlaps = () => {
        const {
          startDate: leaveStartDate,
          endDate: leaveEndDate,
        } = leaveDateThatOverlaps;

        const checkIfWholeWeekOverlaps = getOverlappingDaysInIntervals(
          {
            start: new Date(selectedWeekStartDate),
            end: new Date(selectedWeekEndDate),
          },
          {
            start: new Date(leaveStartDate),
            end: new Date(leaveEndDate),
          },
          { inclusive: true },
        );

        return checkIfWholeWeekOverlaps >= 5;
      };

      const overlapDatesArr = [];
      const notOverLappingDatesArr = [];

      // get all the days in the selected week
      const allDaysInSelectedWeek = eachDayOfInterval({
        start: new Date(selectedWeek.startDate),
        end: new Date(selectedWeek.endDate),
      });

      // getting all the days in selected week that don't overlap with leave dates
      if (leaveDateExists) {
        allDaysInSelectedWeek.forEach((dateInArr) => {
          const checkThis = isWithinInterval(new Date(dateInArr), {
            start: startOfDay(new Date(leaveDateThatOverlaps.startDate)),
            end: startOfDay(new Date(leaveDateThatOverlaps.endDate)),
          });

          if (checkThis) {
            overlapDatesArr.push(dateInArr);
          } else {
            notOverLappingDatesArr.push(dateInArr);
          }
        });

        // filtering out monday-friday only from days of selected week that don't overlap with leave dates
        const notOverlappingWithoutWeekend = notOverLappingDatesArr.filter(
          (date) => {
            const day = getDay(new Date(date));
            return day >= 1 && day <= 5;
          },
        );

        results.isDuringLongTermLeave = leaveDateExists;
        results.wholeWeekOverlaps = wholeWeekOverlaps();
        results.duringLeaveNoOverlapArr = notOverlappingWithoutWeekend;
      }
    }
    return results;
  };

  useEffect(() => {
    setOverLapWithLeave(isOverlappingWithLongTermLeaveDates());
  }, [selectedWeek]);

  const validateRequiredField = (location) => {
    const missingProjects = timeEntries
      .filter((row) => !row.projectId)
      .map((row) => row.id);
    if (
      missingProjects &&
      missingProjects.length &&
      !overlapWithLeave.isDuringLongTermLeave &&
      userId !== 31
    ) {
      setLoading(null);
      handleValidationError(location, {
        message: errorMessage.PROJECTS_MISSING,
        args: missingProjects,
        field: errorField.PROJECT,
      });
      return false;
    }

    const missingRequiredFields = timeEntries
      .filter((row) => {
        const isRequired = requiredDescProjects.some(
          (required) => required.id === row.projectId,
        );
        return isRequired && !row.description;
      })
      .map((row) => row.id);
    if (missingRequiredFields && missingRequiredFields.length) {
      setLoading(null);
      handleValidationError(location, {
        message: errorMessage.DESCRIPTION_MISSING,
        args: missingRequiredFields,
        field: errorField.DESCRIPTION,
      });
      return false;
    }

    const emptyEntries = timeEntries
      .filter((row) => row.entries.every((entry) => entry.duration === 0))
      .map((row) => row.id);
    if (
      emptyEntries &&
      emptyEntries.length &&
      !overlapWithLeave.isDuringLongTermLeave &&
      userId !== 31
    ) {
      setLoading(null);
      handleValidationError(location, {
        message: errorMessage.TIME_ENTRY_MISSING,
        args: emptyEntries,
        field: errorField.DURATION,
      });
      return false;
    }

    return true;
  };

  const validateTimesheet = (location) => {
    if (
      nothingFilledOut &&
      !overlapWithLeave.isDuringLongTermLeave &&
      userId !== 31
    ) {
      setLoading(null);
      handleValidationError(location, {
        message: errorMessage.TIME_ENTRIES_EMPTY,
      });
      return false;
    }

    const requiredValidation = validateRequiredField(location);
    if (!requiredValidation) {
      return false;
    }

    return true;
  };

  const saveTimesheet = async (nextAction) => {
    const entries = mapTimesheetStateToTimeEntriesInput(timeEntries, userId);

    if (
      (overlapWithLeave.wholeWeekOverlaps && entries.length === 0) ||
      userId === 31
    ) {
      nextAction();
    }
    if (
      !overlapWithLeave.wholeWeekOverlaps &&
      entries.length === 0 &&
      userId !== 31
    ) {
      handleValidationError(errorLocation.TIMESHEET_SAVE, {
        message: errorMessage.TIME_ENTRIES_EMPTY,
      });
      setLoading(null);
    }
    if (
      !overlapWithLeave.isDuringLongTermLeave ||
      (overlapWithLeave.isDuringLongTermLeave && entries.length > 0) ||
      (userId === 31 && entries.length > 0)
    ) {
      try {
        const response = await addOrUpdateTime({
          variables: {
            entries,
          },
        });
        if (response && response.data) {
          const respondedTimesheet = response.data.addOrUpdateTimeEntries;
          const mergedEvents = mergeByDate(respondedTimesheet);
          const timesheetState = mergeByHashtagAndDesc(mergedEvents);
          dispatch(saveTimeEntries(timesheetState));
          nextAction();
        }
      } catch (err) {
        handleAPIError(err, errorLocation.TIMESHEET_SAVE);
      }
    }
  };

  const goPrev = async (location) => {
    clearErrorState();
    setLoading({
      prev: true,
    });

    const nextAction = () => {
      setSubmitBtn('hide');
      clearErrorState();
      const prevStart = subDays(selectedWeek.startDate, 7);
      const prevEnd = subDays(selectedWeek.endDate, 7);
      setSelectedDate(prevStart);
      const week = getCurrentWeek(prevStart);
      setHighlightDates(week);
      dispatch(selectWeek(prevStart, prevEnd));
      getUserTimesheet();
    };

    if (!nothingFilledOut) {
      const requiredValidation = validateRequiredField(location);
      if (requiredValidation) {
        await saveTimesheet(nextAction);
        return;
      }
      return;
    }
    nextAction();
  };

  const goNext = async (location) => {
    clearErrorState();
    setLoading({
      next: true,
    });

    const nextAction = () => {
      if (
        !dailyTotalValidation &&
        !overlapWithLeave.wholeWeekOverlaps &&
        userId !== 31
      ) {
        setLoading(null);
        handleValidationError(location, {
          message: errorMessage.MINIMUM_WORKING_HOURS,
        });
        return;
      }

      setSubmitBtn('hide');
      clearErrorState();

      const nextStart = addDays(selectedWeek.startDate, 7);
      const nextEnd = addDays(selectedWeek.endDate, 7);
      setSelectedDate(nextStart);
      const week = getCurrentWeek(nextStart);
      setHighlightDates(week);
      dispatch(selectWeek(nextStart, nextEnd));
      getUserTimesheet();
    };

    const requiredValidation = validateRequiredField(location);
    const timesheetValidation = validateTimesheet(location);
    if (requiredValidation && timesheetValidation) {
      await saveTimesheet(nextAction);
    }
  };

  const onClickGoogle = () => {
    clearErrorState();
    setModal('googleImport');
  };

  const onClickCopyLastWeek = () => {
    clearErrorState();
    setModal('copyLastWeek');
  };

  const handleCloseModal = () => {
    setModal(null);
    setLoading(null);
  };

  const openCalendarList = async () => {
    clearErrorState();
    setLoading({
      google: true,
    });

    let calendarList = [];

    try {
      await initGapiClient();
      calendarList = await fetchCalendarList();
    } catch (err) {
      setLoading(null);
      handleGoogleError(
        err,
        modal === 'googleImport'
          ? errorLocation.GOOGLE_LOAD
          : errorLocation.TIMESHEET_LOAD,
      );
      return;
    }

    if (!calendarList.length) {
      setLoading(null);
      handleValidationError(
        modal === 'googleImport'
          ? errorLocation.GOOGLE_LOAD
          : errorLocation.TIMESHEET_LOAD,
        {
          message: errorMessage.NO_CALENDAR_LIST,
        },
      );
      return;
    }

    const calendarOptions = calendarList.map((calendar) => ({
      value: calendar.id,
      label: calendar.summary,
    }));

    const ownCalendars = calendarOptions.filter((calendar) => {
      return calendar.label.toLowerCase().includes(firstName.toLowerCase());
    });
    const others = calendarOptions.filter((calendar) => {
      return !calendar.label.toLowerCase().includes(firstName.toLowerCase());
    });
    const orderedCalendarOptions = ownCalendars.concat(others);

    setGoogleCalendarList(orderedCalendarOptions);
    setLoading(null);
    setModal('calendarList');
  };

  const importGoogle = async () => {
    setLoading({
      google: true,
    });

    try {
      await deleteTimeEntries({
        variables: {
          startDate: format(selectedWeek.startDate, 'yyyy-MM-dd'),
          endDate: format(selectedWeek.endDate, 'yyyy-MM-dd'),
        },
      });

      const events = await fetchCalendarEvents(
        googleCalendarId,
        selectedWeek.startDate,
        selectedWeek.endDate,
      );
      const filteredEvents = events.filter((event) => {
        if (event.summary) {
          return event.summary[0] === '#';
        }
        return false;
      });

      if (!filteredEvents.length) {
        setLoading(null);
        handleValidationError(errorLocation.GOOGLE_LOAD, {
          message: errorMessage.NO_CALENDAR_EVENTS,
        });
        return;
      }
      const googleTimeEntries = mapGoogleCalendarToTimeEntries(
        filteredEvents,
        hashtagOptions,
        selectedWeek.startDate,
      );

      const mergedEvents = mergeByDate(googleTimeEntries);
      const timesheetState = mergeByHashtagAndDesc(mergedEvents);
      dispatch(saveTimeEntries(timesheetState));
    } catch (err) {
      handleAPIError(err, errorLocation.TIMESHEET_LOAD);
    }

    setLoading(null);
    setModal(null);
  };

  const copyLastWeek = async () => {
    const prevStart = subDays(selectedWeek.startDate, 7);
    const prevEnd = subDays(selectedWeek.endDate, 7);

    try {
      getLastweekTimesheet({
        variables: {
          id: userId,
          assignedProjects: {
            startDate: format(prevStart, 'yyyy-MM-dd'),
            endDate: format(prevEnd, 'yyyy-MM-dd'),
          },
        },
      });
    } catch (err) {
      handleAPIError(err, errorLocation.TIMESHEET_LOAD);
    }
  };

  const onAddRow = () => {
    clearErrorState();
    const emptyEntry = getEmptyEntry();
    const addedEntries = [...timeEntries, ...emptyEntry];
    dispatch(saveTimeEntries(addedEntries));
  };

  const onSave = async () => {
    clearErrorState();
    setLoading({
      save: true,
    });

    const nextAction = () => {
      setLoading(null);
      setMessage('Saved!');
      setTimeout(() => {
        setMessage(null);
      }, 1500);
    };

    const requiredValidation = validateRequiredField(
      errorLocation.TIMESHEET_SAVE,
    );
    const timesheetValidation = validateTimesheet(errorLocation.TIMESHEET_SAVE);
    if (requiredValidation && timesheetValidation) {
      await saveTimesheet(nextAction);
    }
  };

  const onSubmit = async () => {
    clearErrorState();
    setLoading({
      submit: true,
    });

    // TODO: 6 hours rule
    const currentMonthDays = days.filter((day) => {
      const date = new Date(day);
      const year = format(date, 'yyyy');
      const month = format(date, 'MM');
      const eachMonthYear = Number(year + month);
      return eachMonthYear === currentMonthYear;
    });
    const currentMonthDaysWithoutWeekend = currentMonthDays.filter((date) => {
      const day = getDay(new Date(date));
      return day >= 1 && day <= 5;
    });

    const dailyTotal = getDailyTotalOfTheLastWeek(
      timeEntries,
      currentMonthYear,
    );
    const dailyTotalWithoutWeekend = dailyTotal.filter((obj) => {
      const day = getDay(new Date(obj.date));
      return day >= 1 && day <= 5;
    });

    const validated =
      dailyTotalWithoutWeekend.length ===
        currentMonthDaysWithoutWeekend.length &&
      dailyTotalWithoutWeekend.every((total) => total.duration >= 6 * 60);
    if (
      !validated &&
      !overlapWithLeave.isDuringLongTermLeave &&
      userId !== 31
    ) {
      setLoading(null);
      handleValidationError(errorLocation.TIMESHEET_SAVE, {
        message: errorMessage.MINIMUM_WORKING_HOURS,
      });
      return;
    }
    // TODO: end

    const nextAction = async () => {
      try {
        const response = await submitTimesheet();
        if (response && response.data && response.data.submitTimesheet) {
          setLoading(null);
          setSubmitBtn('hide');
          setMessage('Saved & Submitted');
          setTimeout(() => {
            setMessage(null);
            history.push('/monthly-report');
          }, 100);
        }
      } catch (err) {
        handleAPIError(err, errorLocation.TIMESHEET_SAVE);
      }
    };

    const requiredValidation = validateRequiredField(
      errorLocation.TIMESHEET_SAVE,
    );
    const timesheetValidation = validateTimesheet(errorLocation.TIMESHEET_SAVE);
    if (requiredValidation && timesheetValidation) {
      await saveTimesheet(nextAction);
    }
  };

  return (
    <div className="content">
      <ToolBar>
        <Container>
          <Container>
            <span className="month">
              {selectedWeek.startDate
                ? format(selectedWeek.startDate, 'MMM')
                : ''}
            </span>
            <Button
              type="button"
              className={loading && loading.prev ? 'icon' : 'icon angle left'}
              disabled={
                (loading && (loading.prev || loading.next)) ||
                stringifyDate(selectedWeek.startDate) === timesheetStartDate
              }
              loading={loading && loading.prev}
              inline
              onClick={() => goPrev(errorLocation.TIMESHEET_LOAD)}
            >
              {loading && loading.prev ? (
                <ClipLoader size={10} loading={loading && loading.prev} />
              ) : (
                ''
              )}
            </Button>
            <DateRange
              role="button"
              tabIndex={0}
              onClick={onClickDateRange}
              onKeyPress={onClickDateRange}
            >
              {selectedWeek.startDate
                ? format(selectedWeek.startDate, 'yyyy. MM. dd')
                : 'YYYY.MM.DD'}{' '}
              -{' '}
              {selectedWeek.endDate
                ? format(selectedWeek.endDate, 'yyyy. MM. dd')
                : 'YYYY.MM.DD'}
            </DateRange>
            <DatePickerContainer show={calendarShow}>
              {userId !== 31 ? (
                <ReactDatePicker
                  locale="en-gb"
                  dateFormat="yyyy.MM.dd"
                  onChange={(date) => handleDateChange(date)}
                  selected={selectedDate}
                  minDate={new Date(timesheetStartDate)}
                  // TODO: 6 hours rule
                  maxDate={
                    dailyTotalValidation ? lastDayToGo : selectedWeek.endDate
                  }
                  // TODO: end
                  highlightDates={selectedDate ? highlightDates : []}
                  inline
                />
              ) : (
                <ReactDatePicker
                  locale="en-gb"
                  dateFormat="yyyy.MM.dd"
                  onChange={(date) => handleDateChange(date)}
                  selected={selectedDate}
                  minDate={new Date(timesheetStartDate)}
                  highlightDates={selectedDate ? highlightDates : []}
                  inline
                />
              )}
            </DatePickerContainer>
            <Button
              type="button"
              className={loading && loading.next ? 'icon' : 'icon angle right'}
              disabled={loading && (loading.prev || loading.next)}
              loading={loading && loading.next}
              inline
              onClick={() => goNext(errorLocation.TIMESHEET_LOAD)}
            >
              {loading && loading.next ? (
                <ClipLoader size={10} loading={loading.next} />
              ) : (
                ''
              )}
            </Button>
          </Container>
        </Container>
        <Container>
          <Button
            type="button"
            className="icontext"
            icon={copyicon}
            inline
            loading={copyLoading}
            onClick={nothingFilledOut ? copyLastWeek : onClickCopyLastWeek}
          >
            {copyLoading ? (
              <InlineLoader loading={copyLoading} />
            ) : (
              'Copy Last Week'
            )}
          </Button>
          <Button
            style={{ marginLeft: '30px' }}
            type="button"
            className="icontext"
            icon={googleicon}
            inline
            loading={loading && loading.google}
            onClick={nothingFilledOut ? openCalendarList : onClickGoogle}
          >
            {loading && loading.google ? (
              <InlineLoader loading={loading.google} />
            ) : (
              'Import from Google Calendar'
            )}
          </Button>
          <DoubleCheckModal
            show={modal === 'googleImport' && !nothingFilledOut}
            text={
              <div>
                <p style={{ fontWeight: 'bold' }}>
                  Please note that the existing data will be deleted.{' '}
                </p>
                <p>Do you want to import the data from Google Calendar?</p>
              </div>
            }
            loading={loading && loading.google}
            onClick={openCalendarList}
            handleClose={handleCloseModal}
            error={
              error && error.location === errorLocation.GOOGLE_LOAD
                ? error
                : null
            }
          />
          <CalendarListModal
            show={modal === 'calendarList'}
            calendarList={googleCalendarList}
            setGoogleCalendarId={setGoogleCalendarId}
            loading={loading && loading.google}
            onClick={importGoogle}
            handleClose={handleCloseModal}
            error={
              error && error.location === errorLocation.GOOGLE_LOAD
                ? error
                : null
            }
          />
          <DoubleCheckModal
            show={modal === 'copyLastWeek' && !nothingFilledOut}
            text={
              <div>
                <p style={{ fontWeight: 'bold' }}>
                  Please note that the existing data will be deleted.{' '}
                </p>
                <p>Do you want to proceed further?</p>
              </div>
            }
            loading={copyLoading}
            onClick={copyLastWeek}
            handleClose={() => setModal(null)}
            error={
              (error && error.location === errorLocation.TIMESHEET_LOAD
                ? error
                : null) || copyError
            }
          />
        </Container>
      </ToolBar>
      {error && error.location === errorLocation.TIMESHEET_LOAD && (
        <Error>{error.message}</Error>
      )}
      <StyledReactTable
        columns={columns}
        data={tableData}
        loading={
          (loading && (loading.prev || loading.next || loading.datepick)) ||
          timesheetLoading ||
          lastLoading
        }
        footer
        blank
      />
      {error && error.location === errorLocation.TIMESHEET_SAVE && (
        <Error right>{error.message}</Error>
      )}

      <Container>
        <Button type="button" className="addbtn" inline onClick={onAddRow}>
          Add a new project
        </Button>
      </Container>
      <ButtonContainer>
        <Button
          type="button"
          className="prevbtn"
          inline
          loading={loading && loading.prev}
          onClick={() => goPrev(errorLocation.TIMESHEET_SAVE)}
        >
          {loading && loading.prev ? (
            <InlineLoader loading={loading.prev} />
          ) : (
            'Previous Week'
          )}
        </Button>
        <Container>
          {message && <Message inline>{message}</Message>}
          <Button
            type="button"
            inline
            loading={loading && loading.save}
            onClick={onSave}
          >
            {loading && loading.save ? (
              <InlineLoader loading={loading.save} color="red" />
            ) : (
              'Save'
            )}
          </Button>
          {submitBtn === 'hide' && (
            <Button
              type="button"
              className="nextbtn"
              inline
              loading={loading && loading.next}
              onClick={() => goNext(errorLocation.TIMESHEET_SAVE)}
            >
              {loading && loading.next ? (
                <InlineLoader loading={loading.next} />
              ) : (
                'Next Week'
              )}
            </Button>
          )}
          <Button
            type="button"
            inline
            hide={submitBtn === 'hide'}
            disabled={submitBtn === 'disabled'}
            loading={loading && loading.submit}
            onClick={onSubmit}
          >
            {loading && loading.submit ? (
              <InlineLoader loading={loading.submit} color="red" />
            ) : (
              'Submit'
            )}
          </Button>
        </Container>
      </ButtonContainer>
    </div>
  );
};

export default Timesheet;

export const theme = {
  colors: {
    asiance_red: '#e43852',
    asiance_navy: '#2a3642',
    bluish_dark_gray: '#34414c',
    bluish_gray: '#9ca4ab',
    bluish_light_gray: '#e1e3e4',
    blue_gray: '#516478',
    dark_gray: '#555',
    light_gray: '#e5e5e5',
    lighter_gray: '#f2f2f2',
    footer_gray: '#ebedf0',
    row_gray: '#f6f6f6',
    lilac: '#b58dd6',
    red: '#f53154',
    yellow: '#EAA800',
    orange: '#ffa51e',
    green: '#159d5c',
    input_border: 'hsl(0, 0%, 80%)',
    dropdown_hover: '#deebff',
    dropdown_selected: '#2684FF',
    datepicker_selected: '#216ba5',
  },
  mixins: {
    getvw: (target) => {
      const vwContext = 1920 * 0.01;
      return `${target / vwContext}vw`;
    },
  },
};
